import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { CartState } from "./types";
import Organization from "../../models/Organization";
import SalesManagement from "../../models/SalesManagement";
import Cart from "../../models/Cart";
import Picture from "../../models/Picture";
import Video from "../../models/Video";

export const actions: ActionTree<CartState, RootState> = {
  async pagesCart(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number; salesManagementId: number }
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
    pictures: Array<Picture>;
    videos: Array<Video>;
    photoBookPictures: Array<Picture>;
    purchasedPictureIds: Set<number>;
    purchasedVideoIds: Set<number>;
  }> {
    const result = await context.rootState.apiClient.pagesCart(
      payload.organizationId,
      payload.salesManagementId
    );
    context.commit("setCart", result.cart);
    return result;
  },

  async fetchCart(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number; salesManagementId: number }
  ): Promise<Cart> {
    const cart = await context.rootState.apiClient.getCart(
      payload.organizationId,
      payload.salesManagementId
    );
    context.commit("setCart", cart);
    return cart;
  },
  // TODO: できれば、APIを呼ぶ前にカートを内容を更新したい。
  async addPictureToCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { pictureId: number; count: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.addPictureToCart(
      organizationId,
      salesManagementId,
      payload
    );
    context.commit("setCart", cart);
    return cart;
  },
  async removePictureFromCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { pictureId: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.removePictureFromCart(
      organizationId,
      salesManagementId,
      payload.pictureId
    );
    context.commit("setCart", cart);
    return cart;
  },
  async addVideoToCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { videoId: number; count: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.addVideoToCart(
      organizationId,
      salesManagementId,
      payload
    );
    context.commit("setCart", cart);
    return cart;
  },
  async removeVideoFromCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { videoId: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.removeVideoFromCart(
      organizationId,
      salesManagementId,
      payload.videoId
    );
    context.commit("setCart", cart);
    return cart;
  },
  async updateCartPhotoBook(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { mediaType: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.updateCartPhotoBook(
      organizationId,
      salesManagementId,
      payload.mediaType
    );
    context.commit("setCart", cart);
    return cart;
  },
  async deleteCartPhotoBook(context: {
    commit: Commit;
    state: CartState;
    rootState: RootState;
  }): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.deleteCartPhotoBook(
      organizationId,
      salesManagementId
    );
    context.commit("setCart", cart);
    return cart;
  },
  async updateCartPhotoBookOrderAmount(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { amount: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.updateCartPhotoBookOrderAmount(
      organizationId,
      salesManagementId,
      payload.amount
    );
    context.commit("setCart", cart);
    return cart;
  },
  async addPhotoBookPictureToCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { pictureId: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.addPhotoBookPictureToCart(
      organizationId,
      salesManagementId,
      payload.pictureId
    );
    context.commit("setCart", cart);
    return cart;
  },
  async removePhotoBookPictureFromCart(
    context: { commit: Commit; state: CartState; rootState: RootState },
    payload: { pictureId: number }
  ): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.removePhotoBookPictureFromCart(
      organizationId,
      salesManagementId,
      payload.pictureId
    );
    context.commit("setCart", cart);
    return cart;
  },
  async removeAllPhotoBookPicturesFromCart(context: {
    commit: Commit;
    state: CartState;
    rootState: RootState;
  }): Promise<Cart | undefined> {
    const organizationId =
      context.state.cart && context.state.cart.organizationId;
    const salesManagementId =
      context.state.cart && context.state.cart.salesManagementId;
    if (!organizationId || !salesManagementId) {
      return;
    }

    const cart = await context.rootState.apiClient.removeAllPhotoBookPicturesFromCart(
      organizationId,
      salesManagementId
    );
    context.commit("setCart", cart);
    return cart;
  },

  async pagesShowStore(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number; salesManagementId: number }
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
    pictures: Array<Picture>;
    videos: Array<Video>;
    photoBookPictures: Array<Picture>;
  }> {
    const result = await context.rootState.apiClient.pagesShowStore(
      payload.organizationId,
      payload.salesManagementId
    );
    context.commit("setCart", result.cart);
    return result;
  }
};
