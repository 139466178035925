import { PASSWORD_PATTERN } from "../data/constants";

class PasswordResetContinueForm {
  token: string;
  password?: string;
  passwordConfirmation?: string;

  constructor(data: { token: string }) {
    this.token = data.token;
  }

  passwordRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "パスワードを入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) || "パスワードは8文字以上で入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "パスワードは255文字以下で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワードは半角英数字記号で入力してください。"
    ];
  }

  passwordConfirmationRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "パスワード（確認）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) ||
        "パスワード（確認）は8文字以上で入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "パスワード（確認）は255文字以下で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワード（確認）は半角英数字記号で入力してください。",
      (v?: string) =>
        (!!v && v === this.password) ||
        "パスワードとパスワード（確認）が一致しません。"
    ];
  }

  toParams(): { token: string; password: string } {
    return {
      token: this.token,
      password: this.password || ""
    };
  }
}

export default PasswordResetContinueForm;
