<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">face</v-icon>
        <br />お子さまの選択
      </h1>

      <fr-processing-dialog
        :dialog="submitting"
        label="お顔を登録しています"
      ></fr-processing-dialog>

      <v-card v-if="kid && faceRegistration && faceDetectionResult">
        <p class="px-3 pt-4">登録するお子さまのお顔の枠を選択してください。</p>

        <v-form @submit.prevent="submitForm">
          <section>
            <h2 class="title-bar">{{ kid.name }}さんを選択</h2>

            <div class="face-select">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="
                  `0 0 ${photoDimension.width} ${photoDimension.height}`
                "
              >
                <image
                  :xlink:href="faceRegistration.thumbnailBigUrl"
                  :width="photoDimension.width"
                  :height="photoDimension.height"
                ></image>

                <rect
                  v-for="(face, i) in detectedFaces"
                  :key="face.uniqueKey"
                  :x="face.left"
                  :y="face.top"
                  :width="face.width"
                  :height="face.height"
                  class="face-select-frame"
                  :class="{ selected: i === selectedFaceIndex }"
                  @click="selectedFaceIndex = i"
                ></rect>

                <template v-if="selectedFace">
                  <rect
                    :x="selectedFace.left"
                    :y="selectedFace.top"
                    :width="selectedFace.width"
                    height="24"
                    class="selected-text-background"
                  ></rect>
                  <text
                    :x="selectedFace.left + 4"
                    :y="selectedFace.top + 20"
                    font-size="20"
                    class="selected-text"
                  >
                    選択中
                  </text>
                </template>
              </svg>
            </div>
          </section>

          <div v-if="errorMessage" class="px-3 pb-3 caption error--text">
            {{ errorMessage }}
          </div>

          <v-layout justify-center row>
            <v-btn
              type="submit"
              :disabled="submitting"
              class="v-btn--minwidth"
              color="primary"
            >
              登録する
            </v-btn>
          </v-layout>
          <v-layout justify-center row pb-4>
            <v-btn
              :disabled="submitting"
              class="v-btn--minwidth"
              color="negative"
              @click="cancelRegistration"
            >
              戻る
            </v-btn>
          </v-layout>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import ApiErrors from "../../lib/lookmee_photo/ApiErrors";

import FrProcessingDialog from "./components/FrProcessingDialog";

const POLLING_TRY_LIMIT = 20;
const POLLING_INTERVAL = 1000;

export default {
  components: {
    "fr-processing-dialog": FrProcessingDialog
  },
  mixins: [ApiErrorHandler],
  props: {
    kidId: {
      type: Number,
      required: true
    },
    faceRegistrationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      faceRegistration: null,
      selectedFaceIndex: null,
      submitting: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState("family", {
      kids: state => state.kids
    }),
    kid() {
      return this.kids.find(k => k.id === this.kidId);
    },
    faceDetectionResult() {
      return this.faceRegistration && this.faceRegistration.faceDetectionResult;
    },
    photoDimension() {
      return this.faceDetectionResult.photoDimension;
    },
    detectedFaces() {
      return this.faceDetectionResult.detectedFaces;
    },
    selectedFace() {
      return (
        this.selectedFaceIndex !== null &&
        this.detectedFaces[this.selectedFaceIndex]
      );
    },
    isValidFaceRegistration() {
      return (
        this.faceDetectionResult &&
        this.detectedFaces &&
        this.detectedFaces.length > 0 &&
        this.faceRegistration.kidId === this.kidId
      );
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.faceRegistration = await this.$store.dispatch(
          "fr/getFaceRegistration",
          {
            id: this.faceRegistrationId
          }
        );

        if (!this.isValidFaceRegistration) {
          console.warn("Invalid face registration.");
          console.warn(this.faceRegistration);
          this.$router.replace({ name: "notFound" });
        }
        if (this.faceRegistration.isFaceRegistrationFinished()) {
          this.$router.replace({ name: "listFrKidFaces" });
        }
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errorMessage = null;
      if (this.selectedFaceIndex === null) {
        this.errorMessage = "お子さまのお顔を選択してください。";
        return;
      }

      this.submitting = true;
      try {
        // Skip on retying due to timeout error.
        if (!this.faceRegistration.isFaceRegistrationProcessing()) {
          this.faceRegistration = await this.registerFaceAsync(
            this.faceRegistration.id,
            this.selectedFaceIndex
          );
          await new Promise(resolve => setTimeout(resolve, 2000));
        }

        this.faceRegistration = await this.pollingUntilFaceRegistrationFinished(
          this.faceRegistration.id
        );

        // LOOKME-9958 ここで face_registration と S3 内の写真を消すと FR が読み取れなくなるので消さないことにする
        // this.$store.dispatch("fr/deleteFaceRegistration", {
        //   id: this.faceRegistration.id
        // });

        this.$router.push({
          name: "listFrKidFaces",
          params: { kidId: this.kidId }
        });
      } catch (error) {
        if (typeof error === "string" || error instanceof String) {
          this.errorMessage = error;
        } else if (error instanceof ApiErrors) {
          this.handleApiErrors(error, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errorMessage = error.message;
        }
      } finally {
        this.submitting = false;
      }
    },
    async registerFaceAsync(faceRegistrationId, faceIndex) {
      try {
        return await this.$store.dispatch(
          "fr/startFaceRegistrationFaceRegistration",
          {
            id: faceRegistrationId,
            faceIndex: faceIndex
          }
        );
      } catch (errors) {
        if (errors.status === 400) {
          throw errors.getErrorMessage();
        } else {
          throw errors;
        }
      }
    },
    async pollingUntilFaceRegistrationFinished(faceRegistrationId) {
      for (let i = 0; i < POLLING_TRY_LIMIT; i++) {
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));

        const faceRegistration = await this.$store.dispatch(
          "fr/getFaceRegistration",
          {
            id: faceRegistrationId
          }
        );

        if (faceRegistration.isFaceRegistrationFinished()) {
          return faceRegistration;
        } else if (faceRegistration.isFailed()) {
          throw faceRegistration.errorMessage;
        }
      }

      throw "顔写真の登録がタイムアウトしました。しばらくお待ちいただいたのち、再度「登録する」ボタンを押してください。";
    },
    cancelRegistration() {
      this.$store.dispatch("fr/deleteFaceRegistration", {
        id: this.faceRegistration.id
      });

      this.$router.push({
        name: "listFrKidFaces",
        params: { kidId: this.kidId }
      });
    }
  }
};
</script>
