<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-text class="text-xs-center">
        お届け先を削除します。<br />
        よろしいですか？
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :disabled="submitting"
          :loading="submitting"
          @click="removeUserAddress"
        >
          OK
        </v-btn>
        <v-btn color="negative" :disabled="submitting" @click="dialog = false">
          キャンセル
        </v-btn>
      </v-card-actions>

      <div class="v-card__close">
        <v-icon color="primary" @click="dialog = false">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    userAddressId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    dialog: {
      get() {
        return !!this.userAddressId;
      },
      set(val) {
        if (!val) {
          this.$emit("cancel-remove");
        }
      }
    }
  },
  methods: {
    async removeUserAddress() {
      this.submitting = true;
      try {
        await this.$store.dispatch("family/deleteUserAddress", {
          userAddressId: this.userAddressId
        });
        // Update karte data and dataLayer
        await this.$store.dispatch("karte/getKarte");
        dataLayer.push({
          setting_address: this.$store.state.karte.isFamilyUserAddressExisting
        });
        this.$router.push({ name: "userAddressRemoveCompleted" });
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
        this.dialog = false;
      }
    }
  }
};
</script>
