<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />世帯電話番号の変更
      </h1>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <section>
          <h2 class="title-bar">世帯名</h2>
          <div class="px-3 pt-3">
            <v-text-field
              v-model="familyName"
              single-line
              validate-on-blur
              required
              disabled
              filled
              :rules="form.nameRules()"
            ></v-text-field>
          </div>
        </section>

        <section>
          <h2 class="title-bar">電話番号　※半角</h2>

          <div
            v-for="mobilePhoneForm in form.enabledMobilePhoneForms"
            :key="mobilePhoneForm.uniqueKey"
          >
            <v-text-field
              v-model="mobilePhoneForm.phoneNumber"
              type="tel"
              single-line
              outline
              validate-on-blur
              required
              :rules="mobilePhoneForm.phoneNumberRules()"
              class="px-3 pt-3"
            >
              <template
                v-if="form.enabledMobilePhoneForms.length > 1"
                slot="append-outer"
              >
                <a @click="removeMobilePhoneForm(mobilePhoneForm)">削除する</a>
              </template>
            </v-text-field>

            <v-divider class="mx-3"></v-divider>
          </div>

          <div class="pa-3">
            <a class="icon-link" @click="addNewMobilePhoneForm">
              <i class="material-icons">add_circle</i> 電話番号を追加する
            </a>
          </div>
        </section>

        <invalid-form-error v-if="$refs.form" :form="$refs.form" />

        <section class="mt-5">
          <div v-if="errors.length > 0" class="ma-3 caption error--text">
            <ul class="px-3">
              <li v-for="err in errors" :key="err.message">
                {{ err.message }}
              </li>
            </ul>
          </div>

          <v-layout justify-center row>
            <v-btn
              type="submit"
              :loading="submitting"
              :disabled="submitting"
              class="v-btn--minwidth"
              color="primary"
            >
              変更内容を確認する
            </v-btn>
          </v-layout>
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :to="{ name: 'showFamily' }"
            >
              戻る
            </v-btn>
          </v-layout>
        </section>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import { MobilePhoneForm, FamilyForm } from "../../../forms/FamilyForm";
import InvalidFormError from "../../../components/InvalidFormError";

export default {
  components: {
    "invalid-form-error": InvalidFormError
  },
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: FamilyForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  computed: {
    familyName: {
      get() {
        return this.form.name;
      },
      set(val) {
        this.form.name = val;
      }
    }
  },
  methods: {
    addNewMobilePhoneForm() {
      this.form.mobilePhoneForms.push(new MobilePhoneForm({}));
    },
    removeMobilePhoneForm(phoneForm) {
      const index = this.form.mobilePhoneForms.indexOf(phoneForm);
      if (phoneForm.id) {
        const newPhone = new MobilePhoneForm(phoneForm);
        newPhone.deleteFlag = true;
        this.$set(this.form.mobilePhoneForms, index, newPhone);
      } else {
        this.form.mobilePhoneForms.splice(index, 1);
      }
    },
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      const errors = this.form.validateDuplicatedValues();
      if (errors.length > 0) {
        this.errors = errors;
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch(
          "family/validateForUpdateFamily",
          this.form.toParams()
        );
        this.$emit("confirm");
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped>
/* 「削除する」リンクを改行させない */
.v-input__append-outer a {
  white-space: nowrap;
}
</style>
