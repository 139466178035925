import SalesManagementPrice from "./SalesManagementPrice";
import Group from "./Group";
import EnumValue from "./EnumValue";

import { formatDate } from "../lib/utils/date_time";

import { ORDER_TYPES, SALES_TYPES } from "../data/constants";

class SalesManagement {
  id: number;
  title: string;
  salesType: EnumValue<number>;
  salesStartDate: Date;
  salesEndDate: Date;
  postage: number | null;
  freePostageCampaign: boolean;
  paymentMethods: Array<EnumValue<number>>;
  orderTypes: Array<EnumValue<number>>;
  notices: Array<String>;
  prices: Array<SalesManagementPrice>;
  groups?: Array<Group>;

  constructor(data: {
    id: number;
    title: string;
    salesType: any;
    salesStartDate: string;
    salesEndDate: string;
    postage: number | null;
    freePostageCampaign: boolean;
    paymentMethods: Array<EnumValue<number>>;
    orderTypes: Array<EnumValue<number>>;
    notices: Array<string>;
    prices?: Array<any>;
    groups?: Array<any>;
  }) {
    this.id = data.id;
    this.title = data.title;
    this.salesType = data.salesType;
    this.salesStartDate = new Date(data.salesStartDate);
    this.salesEndDate = new Date(data.salesEndDate);
    this.postage = data.postage;
    this.freePostageCampaign = data.freePostageCampaign;
    this.paymentMethods = data.paymentMethods;
    this.orderTypes = data.orderTypes;
    this.notices = data.notices;

    this.prices = (data.prices || []).map(p => new SalesManagementPrice(p));

    if (data.groups) {
      this.groups = data.groups.map(g => new Group(g));
    }
  }

  get formattedSalesStartDate(): string {
    return formatDate(this.salesStartDate);
  }

  get formattedSalesEndDate(): string {
    return formatDate(this.salesEndDate);
  }

  get selectableOrderTypes(): Array<EnumValue<number>> {
    let selectableOrderTypeValues: Array<number> = [];
    if (this.prices) {
      if (this.prices.some(p => p.isForPrint)) {
        selectableOrderTypeValues.push(ORDER_TYPES.print);
      }
      if (this.prices.some(p => p.isForData)) {
        selectableOrderTypeValues.push(ORDER_TYPES.data);
      }
    }
    // TODO: Use #includes instead of #indexOf.
    return this.orderTypes.filter(
      ot => selectableOrderTypeValues.indexOf(ot.value) > -1
    );
  }

  get defaultPaymentMethod(): EnumValue<number> {
    return this.paymentMethods[0];
  }

  get isPhotoAndVideo(): boolean {
    return this.salesType && this.salesType.value === SALES_TYPES.photoAndVideo;
  }

  get isPhotoBook(): boolean {
    return this.salesType && this.salesType.value === SALES_TYPES.photoBook;
  }
}

export default SalesManagement;
