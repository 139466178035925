<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">list_alt</v-icon>
        <br />注文内容の詳細
      </h1>
      <v-card>
        <v-card-text>
          <p class="text-xs-center">注文のキャンセルが完了しました。</p>
          <p class="text-xs-center my-4">
            <img src="../../assets/images/illust_ok.png" />
          </p>
          <p class="text-xs-center">
            <router-link :to="{ name: 'listOrderHistories' }"
              >注文一覧へ戻る ≫</router-link
            >
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
