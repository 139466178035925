import VueRouter from "vue-router";
import ApiErrors from "../lib/lookmee_photo/ApiErrors";
import { Store } from "vuex";
import { RootState } from "../store/types";
import {
  API_ERROR_CODE_VALIDATION_SALES_FINISHED,
  API_ERROR_CODE_FAMILY_NOT_SELECTED
} from "../lib/lookmee_photo/ApiErrorCodes";

const GLOBAL_API_ERROR_CODES = new Set([
  API_ERROR_CODE_VALIDATION_SALES_FINISHED
]);

const mixin = {
  methods: {
    isGlobalApiErrors(errors: ApiErrors) {
      if (errors.status !== 400) {
        return true;
      }
      const errorCode = errors.getErrorCode();
      return !!errorCode && GLOBAL_API_ERROR_CODES.has(errorCode);
    },
    handleApiErrors(
      errors: ApiErrors,
      context: { store: Store<RootState>; router: VueRouter; sentry: any }
    ): void {
      switch (errors.status) {
        case 400:
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_VALIDATION_SALES_FINISHED: // 販売終了専用のエラー画面を表示。{
              context.router.push({ name: "salesFinished" });
              break;
            default:
              // エラーメッセージを表示するダイアログを表示。
              context.store.dispatch("openErrorDialog", {
                messages: errors.getErrorMessages()
              });
              break;
          }
          break;
        case 401:
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_FAMILY_NOT_SELECTED:
              context.router.push({ name: "listFamilies" });
              break;
            default:
              // Show loginRequiredDialog.
              context.store.dispatch("showRequireLoginDialog");
              break;
          }
          break;
        case 403: // 要画面更新ダイアログを表示し、画面更新。
          context.store.dispatch("showRequireRefreshDialog");
          break;
        case 404: // NotFound用のエラーページへ遷移。
          context.router.push({ name: "notFound" });
          break;
        case 0: // ネットワークエラーのダイアログを表示。
          context.store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
          break;
        default: {
          // システムエラー画面へ遷移。
          console.error(errors);
          context.sentry.captureMessage(errors.getErrorMessage());
          const errorNo = errors.getErrorNo();
          if (errorNo) {
            context.router.push({ name: "serverError", query: { errorNo } });
          } else {
            context.router.push({ name: "serverError" });
          }
        }
      }
    }
  }
};

export default mixin;
