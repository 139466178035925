import * as dayjs from "dayjs";

import Organization from "./Organization";
import OrderAddress from "./OrderAddress";
import OrderSummary from "./OrderSummary";
import OrderDetail from "./OrderDetail";

class Order {
  id: number;
  userId: number;
  type: string | null;
  ownerName: string;
  ownerEmail: string;
  paymentMethod: number;
  isAccepted: boolean;
  paymentStatus: string | null;
  deliveryStatus: string | undefined;
  totalBillingAmount: number;
  isPhotoPrintOrder: boolean;
  isPhotoDataOrder: boolean;
  isVideoDataOrder: boolean;
  isPhotoBookOrder: boolean;
  isPhotoDataDownloadable: boolean;
  isVideoDataDownloadable: boolean;
  isDvdOrder: boolean;
  isGiftOrder: boolean;
  orderedDate: Date;
  acceptedDate: Date | undefined;
  paymentExpirationDate: Date | undefined;
  salesManagementTitle: string | undefined;
  representativeImageUrl: string | undefined;

  organization: Organization | undefined;
  address: OrderAddress | undefined;
  children: Array<Order>;
  summaries: Array<OrderSummary>;
  details: Array<OrderDetail>;
  representativeDetail?: OrderDetail | null;

  constructor(data: {
    id: number;
    userId: number;
    type: string | null;
    ownerName: string;
    ownerEmail: string;
    paymentMethod: number;
    paymentStatus: string | null;
    isAccepted: boolean;
    deliveryStatus: string;
    totalBillingAmount: number;
    isPhotoPrintOrder: boolean;
    isPhotoDataOrder: boolean;
    isVideoDataOrder: boolean;
    isPhotoBookOrder: boolean;
    isDvdOrder: boolean;
    isPhotoDataDownloadable: boolean;
    isVideoDataDownloadable: boolean;
    isGiftOrder: boolean;
    orderedDate: string;
    acceptedDate: string | undefined;
    paymentExpirationDate: string | undefined;
    salesManagementTitle: string | undefined;
    organization?: any;
    address?: any;
    children?: Array<Order>;
    summaries?: Array<OrderSummary>;
    details?: Array<OrderDetail>;
    representativeDetail?: OrderDetail | null;
  }) {
    this.id = data.id;
    this.userId = data.userId;
    this.type = data.type;
    this.ownerName = data.ownerName;
    this.ownerEmail = data.ownerEmail;
    this.paymentMethod = data.paymentMethod;
    this.paymentStatus = data.paymentStatus;
    this.isAccepted = data.isAccepted;
    this.deliveryStatus = data.deliveryStatus;
    this.totalBillingAmount = data.totalBillingAmount;
    this.isPhotoPrintOrder = data.isPhotoPrintOrder;
    this.isPhotoDataOrder = data.isPhotoDataOrder;
    this.isVideoDataOrder = data.isVideoDataOrder;
    this.isPhotoBookOrder = data.isPhotoBookOrder;
    this.isPhotoDataDownloadable = data.isPhotoDataDownloadable;
    this.isVideoDataDownloadable = data.isVideoDataDownloadable;
    this.isGiftOrder = data.isGiftOrder;
    this.isDvdOrder = data.isDvdOrder;
    this.orderedDate = dayjs(data.orderedDate).toDate();
    if (data.acceptedDate) {
      this.acceptedDate = dayjs(data.acceptedDate).toDate();
    }
    if (data.paymentExpirationDate) {
      this.paymentExpirationDate = dayjs(data.paymentExpirationDate).toDate();
    }
    if (data.salesManagementTitle) {
      this.salesManagementTitle = data.salesManagementTitle;
    }

    if (data.organization) {
      this.organization = new Organization(data.organization);
    }
    if (data.address) {
      this.address = new OrderAddress(data.address);
    }
    this.children = data.children
      ? data.children.map((c: any) => new Order(c))
      : [];
    this.summaries = data.summaries
      ? data.summaries.map((os: any) => new OrderSummary(os))
      : [];
    this.details = data.details
      ? data.details.map((os: any) => new OrderDetail(os))
      : [];
    if (data.representativeDetail) {
      this.representativeDetail = new OrderDetail(data.representativeDetail);
    }
  }

  get uniqueKey() {
    return `order-${this.id}`;
  }

  get pictureOrderDetails(): Array<OrderDetail> {
    return this.details ? this.details.filter(d => d.picture) : [];
  }

  get videoOrderDetails(): Array<OrderDetail> {
    return this.details ? this.details.filter(d => d.video) : [];
  }

  get isDataDownloadOrder() {
    return (
      this.isPhotoDataOrder ||
      (this.isVideoDataOrder &&
        this.organization &&
        this.organization.videoDownloadAvailable)
    );
  }

  get isPaid() {
    return this.paymentStatus === "paid";
  }

  get isUnpaid() {
    return this.paymentStatus === "unpaid";
  }

  get isExpired() {
    return this.paymentStatus === "expired";
  }

  get isPhotoOrVideoDataOrder() {
    return this.isPhotoDataOrder || this.isVideoDataOrder;
  }

  get canCancel() {
    return this.paymentStatus === "unpaid" && !this.isAccepted;
  }
}

export default Order;
