class Group {
  id: number;
  name: string;
  year: number;
  organizationId: number;

  constructor(data: {
    id: number;
    name: string;
    year: number;
    organizationId: number;
  }) {
    this.id = data.id;
    this.name = data.name;
    this.year = data.year;
    this.organizationId = data.organizationId;
  }

  get uniqueKey() {
    return `group-${this.id}`;
  }

  get name_with_year() {
    return `${this.name}（${this.year}）`;
  }
}

export default Group;
