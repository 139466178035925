<template>
  <div>
    <h1 class="title-page primary--text">世帯情報の登録</h1>

    <v-form ref="form" lazy-validation @submit.prevent="submitForm">
      <section>
        <h2 class="title-bar">メールアドレス</h2>
        <div class="pa-3">
          <span class="txt-mute font-weight-bold">{{ form.email }}</span>
        </div>
      </section>

      <section>
        <h2 class="title-bar">電話番号　※半角</h2>
        <div class="px-3 pt-3">
          <v-text-field
            v-model="phoneNumber"
            type="tel"
            single-line
            outline
            validate-on-blur
            required
            :rules="form.phoneNumberRules()"
          ></v-text-field>
        </div>
      </section>

      <section>
        <h2 class="title-bar">パスワード　※半角</h2>
        <div class="px-3 pt-3">
          <p class="caption error--text mb-1">
            ※8桁以上の半角英数字で入力してください。
          </p>
          <v-text-field
            v-model="password"
            type="password"
            single-line
            outline
            validate-on-blur
            required
            :rules="form.passwordRules()"
          ></v-text-field>
        </div>
      </section>

      <section>
        <h2 class="title-bar">パスワード（確認）</h2>
        <div class="px-3 pt-3">
          <v-text-field
            v-model="passwordConfirmation"
            type="password"
            single-line
            outline
            validate-on-blur
            required
            :rules="form.passwordConfirmationRules()"
          ></v-text-field>
        </div>
      </section>

      <v-layout justify-center row>
        <v-btn type="submit" class="v-btn--minwidth" color="primary">
          次へ
        </v-btn>
      </v-layout>
      <v-layout justify-center row>
        <v-btn class="v-btn--minwidth" color="negative" @click="$emit('prev')">
          戻る
        </v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import UserForm from "../../forms/UserForm";

export default {
  props: {
    form: {
      type: UserForm,
      required: true
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.form.phoneNumber;
      },
      set(val) {
        this.form.phoneNumber = val;
      }
    },
    password: {
      get() {
        return this.form.password;
      },
      set(val) {
        this.form.password = val;
      }
    },
    passwordConfirmation: {
      get() {
        return this.form.passwordConfirmation;
      },
      set(val) {
        this.form.passwordConfirmation = val;
      }
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("next");
    }
  }
};
</script>
