class OrderAddress {
  id: number;
  name: string;
  postcode: string;
  addr1: string;
  addr2: string | undefined;
  tel: string;

  constructor(data: {
    id: number;
    name: string;
    postcode: string;
    addr1: string;
    addr2: string;
    tel: string;
  }) {
    this.id = data.id;
    this.name = data.name;
    this.postcode = data.postcode;
    this.addr1 = data.addr1;
    this.addr2 = data.addr2;
    this.tel = data.tel;
  }

  get uniqueKey() {
    return `orderAddress-${this.id}`;
  }
}

export default OrderAddress;
