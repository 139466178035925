<template>
  <div>
    <section>
      <v-layout pa-3>
        <v-flex>
          販売予定についてのご案内<br />
          お写真等の再販売を含めた販売スケジュールや掲載内容については、園・施設、保護者会のご担当者様に調整いただいております。<br />
          恐れ入りますが、ご担当者様まで直接お問い合わせ下さいますようお願いいたします。<br />
        </v-flex>
      </v-layout>
    </section>

    <section class="py-3">
      <v-layout justify-center row>
        <v-btn class="v-btn--minwidth" color="negative" :to="{ hash: '' }">
          戻る
        </v-btn>
      </v-layout>
    </section>
  </div>
</template>

<script>
export default {};
</script>
