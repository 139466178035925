<template>
  <v-flex xs4 md3 mb-3>
    <square-video-frame
      :video="detail.video"
      hide-play-time
      @play="$emit('play')"
    >
    </square-video-frame>

    <table class="table-vertical">
      <tr>
        <td class="text-xs-right id-num">{{ detail.video.fileNo }}</td>
      </tr>
    </table>
  </v-flex>
</template>

<script>
import OrderDetail from "../../../models/OrderDetail";

import SquareVideoFrame from "../../../components/SquareVideoFrame";

export default {
  components: {
    "square-video-frame": SquareVideoFrame
  },
  props: {
    detail: {
      type: OrderDetail,
      required: true
    }
  }
};
</script>
