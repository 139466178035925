<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">notifications_none</v-icon>
        <br />お知らせ詳細
      </h1>
      <v-card>
        <v-card-text>
          <div class="card-box-head">
            <p v-if="isAnnouncement()" class="card-box-head__date">
              {{ notification.displayStartDate }}
            </p>
            <h2 class="card-box-head__title">
              {{ notification.title }}
            </h2>
          </div>
          <div class="card-box-body">
            <p class="notification-body-content">{{ notification.body }}</p>
          </div>

          <v-layout v-if="isReminder()" justify-center row>
            <v-btn
              :to="{ path: notification.confirmPath }"
              color="primary"
              class="v-btn--minwidth"
            >
              確認する
            </v-btn>
          </v-layout>

          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :to="{
                name: 'listNotifications',
                params: { reload: isNotificationsAvail }
              }"
            >
              戻る
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { mapGetters } from "vuex";

export default {
  mixins: [ApiErrorHandler],

  props: {
    notificationId: {
      type: Number,
      required: true
    },
    notificationType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notification: []
    };
  },
  computed: {
    ...mapGetters({
      isNotificationsAvail: "notifications/isNotificationsAvail"
    }),
    notificationTypes() {
      return ["announcement", "reminder"];
    }
  },
  created() {
    this.invalidNotificationType();
    this.fetchNotification();
  },
  methods: {
    async fetchNotification() {
      this.$store.dispatch("startLoading");
      try {
        // when notification type announcemnet
        if (this.isAnnouncement()) {
          this.notification = await this.$store.dispatch(
            `notifications/fetchAnnouncement`,
            { announcementId: this.notificationId }
          );
        }
        // when notification type reminder
        if (this.isReminder()) {
          this.notification = await this.$store.dispatch(
            `notifications/fetchReminder`,
            { reminderId: this.notificationId }
          );
        }
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    invalidNotificationType() {
      if (this.notificationTypes.indexOf(this.notificationType) !== -1) {
        return true;
      } else {
        this.$router.push({ name: "notFound" });
      }
    },
    isAnnouncement() {
      return this.notificationType === "announcement";
    },
    isReminder() {
      return this.notificationType == "reminder";
    }
  }
};
</script>

<style>
.notification-body-content {
  white-space: pre-wrap;
}
</style>
