<template>
  <v-layout v-if="order">
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">list_alt</v-icon>
        <br />注文内容の詳細
      </h1>

      <section>
        <h2 class="title-bar">注文情報</h2>
        <div class="pa-3">
          <table class="table-vertical">
            <tr>
              <th>園・施設名：</th>
              <td class="text-xs-right">{{ organizationName }}</td>
            </tr>
            <tr>
              <th>販売タイトル：</th>
              <td class="text-xs-right">{{ order.salesManagementTitle }}</td>
            </tr>
            <tr>
              <th>注文ID：</th>
              <td class="text-xs-right">{{ order.id }}</td>
            </tr>
            <tr>
              <th>注文日：</th>
              <td class="text-xs-right">{{ orderedDate }}</td>
            </tr>
            <tr>
              <th>決済：</th>
              <td class="text-xs-right">
                <template v-if="order.isUnpaid">
                  未了（{{ paymentExpirationDate }}）
                </template>
                <template v-else-if="order.isPaid">
                  完了（{{ paymentDate }}）
                </template>
                <template v-else-if="order.isExpired">
                  有効期限切れ
                </template>
              </td>
            </tr>
          </table>

          <template v-if="order.canCancel && isOrderUserCurrentUser">
            <div class="btn-group text-xs-center mt-4">
              <v-btn
                color="primary"
                class="v-btn--minwidth"
                :href="paymentLink"
              >
                決済画面はこちら
              </v-btn>
            </div>

            <ul class="list-vertical mt-2">
              <li>※決済完了後の注文のキャンセルはお受けできません。</li>
            </ul>

            <div class="text-xs-right mt-3">
              <a @click.stop="cancelDialog = true">注文をキャンセルする</a>
            </div>

            <v-dialog v-model="cancelDialog" max-width="290">
              <v-card>
                <v-card-text class="text-xs-center">
                  この注文を取り消します。<br />
                  よろしいですか？
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    color="primary"
                    :disabled="submitting"
                    :loading="submitting"
                    @click="cancelOrder"
                  >
                    OK
                  </v-btn>
                  <v-btn
                    color="negative"
                    :disabled="submitting"
                    @click="cancelDialog = false"
                  >
                    キャンセル
                  </v-btn>
                </v-card-actions>
                <div class="v-card__close">
                  <v-icon color="primary" @click="cancelDialog = false">
                    highlight_off
                  </v-icon>
                </div>
              </v-card>
            </v-dialog>
          </template>
        </div>
      </section>

      <section>
        <h2 class="title-bar">注文者</h2>
        <div class="pa-3">
          <table class="table-vertical">
            <tr>
              <td colspan="2">
                {{ order.ownerName }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                {{ order.ownerEmail }}
              </td>
            </tr>
          </table>
        </div>
      </section>

      <order-address-section
        v-if="order.address"
        :address="order.address"
      ></order-address-section>

      <order-address-section
        v-for="(addr, i) in giftOrderAddresses"
        :key="addr.uniqueKey"
        :address="addr"
        is-gift
        :gift-no="i + 1"
      >
      </order-address-section>

      <section>
        <h2 class="title-bar">お支払い方法</h2>
        <div class="pa-3">
          <span v-if="order.paymentMethod === 1">
            園・教室での現金払い
          </span>
          <span v-else-if="order.paymentMethod === 2">
            クレジット決済（手数料無料）
          </span>
          <span v-else-if="order.paymentMethod === 3">
            コンビニ決済（手数料200円）
          </span>
        </div>
      </section>

      <section>
        <h2 class="title-bar">発送状況</h2>
        <div class="pa-3">
          <template v-if="order.isPhotoPrintOrder">
            <span v-if="order.isUnpaid">
              発送前
            </span>
            <span v-else-if="order.isPaid">
              発送済み
            </span>
            <template v-else-if="order.isExpired">
              決済の有効期限切れです
            </template>
          </template>
          <template v-else-if="order.isPhotoOrVideoDataOrder">
            <span v-if="order.isUnpaid">
              決済が完了していません
            </span>
            <span v-else-if="order.isPaid">
              データをダウンロードできます
            </span>
            <template v-else-if="order.isExpired">
              決済の有効期限切れです
            </template>
          </template>
          <template v-else-if="order.isDvdOrder">
            <span>このご注文は発送状況を確認できません。</span>
          </template>
          <template v-else-if="order.isPhotoBookOrder">
            <span
              >この商品は発送状況を確認できません。<br />
              お届けまで決済完了後１ヶ月程度お時間をいただきます。
            </span>
          </template>
        </div>
      </section>

      <section v-if="order.isPaid && order.isDataDownloadOrder">
        <h2 class="title-bar">ダウンロード</h2>
        <div class="btn-group text-xs-center pa-3">
          <v-btn
            v-if="order.isPhotoDataOrder"
            color="primary"
            :disabled="!order.isPhotoDataDownloadable"
            :to="{ name: 'downloadPictures', params: { orderId: order.id } }"
          >
            写真ダウンロード<span v-if="!order.isPhotoDataDownloadable"
              >(準備中)</span
            >
          </v-btn>
          <v-btn
            v-if="
              order.isVideoDataOrder &&
                order.organization.videoDownloadAvailable
            "
            color="primary"
            :disabled="!order.isVideoDataDownloadable"
            :to="{ name: 'downloadVideos', params: { orderId: order.id } }"
          >
            動画ダウンロード<span v-if="!order.isVideoDataDownloadable"
              >(準備中)</span
            >
          </v-btn>
        </div>
      </section>

      <section>
        <h2 class="title-bar">購入金額</h2>
        <div class="pa-3">
          <table class="table-horizontal caption">
            <tr>
              <th>明細種別</th>
              <th>単価</th>
              <th>数量</th>
              <th>合計</th>
            </tr>
            <tr v-for="summary in order.summaries" :key="summary.uniqueKey">
              <td>
                {{ mediaTypeLabel(summary.mediaTypeLabel, order.isGiftOrder) }}
              </td>
              <td>{{ summary.unitPrice }}円</td>
              <td>{{ summary.totalQuantity }}</td>
              <td>{{ summary.subtotal }}円</td>
            </tr>
            <template v-for="o in order.children">
              <template v-if="order.children">
                <template v-for="summary in o.summaries">
                  <tr :key="summary.uniqueKey">
                    <td>
                      {{
                        mediaTypeLabel(summary.mediaTypeLabel, o.isGiftOrder)
                      }}
                    </td>
                    <td>{{ summary.unitPrice }}円</td>
                    <td>{{ summary.totalQuantity }}</td>
                    <td>{{ summary.subtotal }}円</td>
                  </tr>
                </template>
              </template>
            </template>
          </table>
        </div>
      </section>

      <section>
        <div class="pa-3">
          <table class="table-vertical">
            <tr>
              <th class="title va-middle">合計金額</th>
              <td class="text-xs-right display-1">
                {{ allOrderTotalBillingAmount }}円
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section>
        <h2 class="title-bar">注文内容</h2>
        <div v-if="order.pictureOrderDetails.length > 0" class="pa-3">
          <h3 class="title-display">注文写真</h3>
          <v-layout wrap class="photo-list">
            <order-detail-picture-cell
              v-for="detail in order.pictureOrderDetails"
              :key="detail.uniqueKey"
              :detail="detail"
            >
            </order-detail-picture-cell>
          </v-layout>
        </div>

        <div v-if="order.videoOrderDetails.length > 0" class="pa-3">
          <h3 class="title-display">注文動画</h3>
          <v-layout wrap class="photo-list">
            <order-detail-video-cell
              v-for="detail in order.videoOrderDetails"
              :key="detail.uniqueKey"
              :detail="detail"
              @play="openVideoPlayer(detail.video)"
            >
            </order-detail-video-cell>
          </v-layout>

          <overlay-video-player
            v-if="playingVideo"
            :video="playingVideo"
            @close="closeVideoPlayer"
          >
          </overlay-video-player>
        </div>
      </section>

      <section>
        <div class="pa-3">
          <div class="btn-group text-xs-center">
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :to="{ name: 'listOrderHistories' }"
            >
              戻る
            </v-btn>
          </div>
        </div>
      </section>
    </v-flex>
  </v-layout>
</template>

<script>
import dayjs from "dayjs";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { API_ERROR_CODE_NOT_FOUND_FOR_SELECTED_FAMILY } from "../../lib/lookmee_photo/ApiErrorCodes";

import { DATE_FORMAT } from "../../data/constants";

import OrderAddressSection from "./components/OrderAddressSection";
import OrderDetailPictureCell from "./components/OrderDetailPictureCell";
import OrderDetailVideoCell from "./components/OrderDetailVideoCell";
import OverlayVideoPlayer from "../../components/OverlayVideoPlayer";

export default {
  components: {
    "order-address-section": OrderAddressSection,
    "order-detail-picture-cell": OrderDetailPictureCell,
    "order-detail-video-cell": OrderDetailVideoCell,
    "overlay-video-player": OverlayVideoPlayer
  },
  mixins: [ApiErrorHandler],
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: undefined,
      cancelDialog: false,
      playingVideo: undefined,
      submitting: false
    };
  },
  computed: {
    allOrders() {
      let orders = [this.order];
      if (this.order.children) {
        orders = orders.concat(this.order.children);
      }
      return orders;
    },
    isOrderUserCurrentUser() {
      return this.order.userId === this.$store.state.user.user.id;
    },
    organizationName() {
      return this.order.organization ? this.order.organization.name : "";
    },
    orderedDate() {
      return dayjs(this.order.orderedDate).format(DATE_FORMAT);
    },
    paymentDate() {
      return this.order.acceptedDate
        ? dayjs(this.order.acceptedDate).format(DATE_FORMAT)
        : "";
    },
    paymentExpirationDate() {
      return this.order.paymentExpirationDate
        ? dayjs(this.order.paymentExpirationDate).format(DATE_FORMAT)
        : "";
    },
    paymentLink() {
      return `/site/orders/${this.orderId}/payment`;
    },
    giftOrderAddresses() {
      return this.allOrders
        .filter(o => o.isGiftOrder)
        .map(o => o.address)
        .filter(addr => !!addr);
    },
    allOrderTotalBillingAmount() {
      return this.allOrders.reduce((sum, o) => sum + o.totalBillingAmount, 0);
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.order = await this.$store.dispatch("orderHistory/fetchOrder", {
          orderId: this.orderId
        });
      } catch (errors) {
        if (
          errors.status === 404 &&
          errors.getErrorCode() === API_ERROR_CODE_NOT_FOUND_FOR_SELECTED_FAMILY
        ) {
          this.$router.push({ name: "notFoundForFamily" });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    mediaTypeLabel(label, isGift) {
      return isGift ? `プレゼント ${label}` : label;
    },
    async cancelOrder() {
      this.submitting = true;
      try {
        const response = await this.$store.dispatch(
          "orderHistory/cancelOrder",
          { orderId: this.orderId }
        );
        this.$router.push({ name: "orderCancelCompleted" });
        this.cancelDialog = false;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
      }
    },
    openVideoPlayer(video) {
      this.playingVideo = video;
    },
    closeVideoPlayer() {
      this.playingVideo = undefined;
    }
  }
};
</script>
