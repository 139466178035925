import Organization from "../models/Organization";
import Group from "../models/Group";

import {
  ISSUED_CODE1_PATTERN,
  ISSUED_CODE2_PATTERN,
  ISSUED_CODE3_PATTERN,
  KID_BIRTH_DATE_PATTERN
} from "../data/constants";
import * as dayjs from "dayjs";

class KidsClassRegistrationForm {
  issuedCode1: string;
  issuedCode2: string;
  issuedCode3: string;
  isNewKid: boolean;
  kidId: number | null;
  familyName: string;
  kidName: string;
  kidBirthDate: string;
  groupId: number | null;

  organization: Organization | null;
  groups: Array<Group>;

  constructor(data: { familyName?: string }) {
    this.issuedCode1 = "";
    this.issuedCode2 = "";
    this.issuedCode3 = "";
    this.isNewKid = false;
    this.kidId = null;
    this.familyName = data.familyName || "";
    this.kidName = "";
    this.kidBirthDate = "";
    this.groupId = null;

    this.organization = null;
    this.groups = [];
  }

  issuedCode(): string {
    return [this.issuedCode1, this.issuedCode2, this.issuedCode3].join("");
  }

  organizationName(): string {
    return this.organization ? this.organization.name : "";
  }

  selectedGroup(): Group | undefined {
    return this.groups.find(g => g.id === this.groupId);
  }

  issuedCode1Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（左）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 3 && v.length <= 5) ||
        "登録用コード（左）は3文字以上5文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE1_PATTERN)) ||
        "登録用コード（左）は半角数字で入力してください。"
    ];
  }

  issuedCode2Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（中央）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（中央）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE2_PATTERN)) ||
        "登録用コード（中央）は半角数字で入力してください。"
    ];
  }

  issuedCode3Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（右）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（右）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE3_PATTERN)) ||
        "登録用コード（右）は半角数字で入力してください。"
    ];
  }

  kidIdRules(): Array<Function> {
    return [(v?: number) => !!v || "お子さまを選択してください。"];
  }

  kidNameRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "お子さまのお名前を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 50) ||
        "お子さまのお名前は50文字以内で入力してください。"
    ];
  }

  kidBirthDateRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "お子さまの生年月日を入力してください。",
      (v?: string) =>
        (!!v && !!v.match(KID_BIRTH_DATE_PATTERN)) ||
        "お子さまの生年月日は8ケタの半角数字で入力してください。",
      (v?: string) => {
        // Check v is valid date string.
        // If v is invalid date, dayjs does not raise error but somehow interpret it.
        // For example, v is '20199999' (invalid date), then dayjs returns '20270607'.
        // So we can check invalid date to compare v and formatted dayjs.
        return (
          (!!v && dayjs(v).format("YYYYMMDD") === v) ||
          "お子さまの生年月日は存在する日付を入力してください。"
        );
      },
      (v?: string) => {
        // Check inputted date is not future date.
        return (
          (!!v && dayjs(v).toDate() <= new Date()) ||
          "お子さまの生年月日は過去の日付を入力してください。"
        );
      }
    ];
  }

  groupIdRules(): Array<Function> {
    return [(v?: number) => !!v || "クラスを選択してください。"];
  }

  toRegisterKidApiParams(): {
    issuedCode: string;
    groupId: number;
    kidName: string;
    kidBirthDate: string;
  } {
    return {
      issuedCode: this.issuedCode(),
      groupId: this.groupId || 0,
      kidName: this.kidName,
      kidBirthDate: this.kidBirthDate
    };
  }

  toTransferKidApiParams(): {
    issuedCode: string;
    groupId: number;
    kidId: number;
    kidBirthDate: string;
  } {
    return {
      issuedCode: this.issuedCode(),
      groupId: this.groupId || 0,
      kidId: this.kidId || 0,
      kidBirthDate: this.kidBirthDate
    };
  }
}

export default KidsClassRegistrationForm;
