<template>
  <v-layout row wrap>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons">shopping_cart</v-icon>
        <br />カートの中身
      </h1>

      <v-layout>
        <v-flex xs12 mx-3>
          <p class="text-xs-center">
            カートに入っている商品はありません。
          </p>

          <div class="my-3">
            <img src="../../../../../assets/images/cart/empty.png" />
          </div>

          <section class="text-xs-center pb-4">
            <router-link
              :to="{ name: 'organizationTop', params: { organizationId } }"
            >
              園トップページへ>>
            </router-link>
          </section>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    organizationId: {
      type: Number,
      required: true
    }
  }
};
</script>
