<template>
  <v-layout row wrap>
    <v-flex xs5 text-xs-center class="pr-3">
      <square-video-frame
        :video="video"
        hide-play-time
        no-cursor
      ></square-video-frame>
    </v-flex>

    <v-flex xs7 text-xs-left class="order-detail px-2">
      <table>
        <tr>
          <td>動画番号</td>
          <td class="text-xs-right">{{ video.fileNo }}</td>
        </tr>
        <tr v-if="videoPrice">
          <td>データ価格</td>
          <td class="text-xs-right">{{ videoPrice.price }} 円</td>
        </tr>
      </table>

      <v-divider></v-divider>

      <v-layout row wrap align-center justify-center>
        <v-flex
          xs12
          text-xs-center
          class="font-weight-bold my-2"
          :class="{ title: $vuetify.breakpoint.name !== 'xs' }"
        >
          {{ orderAmount }} 本
        </v-flex>
      </v-layout>

      <div class="text-xs-right">
        <a
          href="#"
          class="remove-item-link"
          @click.stop.prevent="$emit('remove-video-order', video)"
        >
          削除する
        </a>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import Video from "../../../../../../models/Video";

import SquareVideoFrame from "../../../../../../components/SquareVideoFrame";

export default {
  components: {
    "square-video-frame": SquareVideoFrame
  },
  props: {
    video: {
      type: Video,
      required: true
    },
    orderAmount: {
      type: Number,
      required: true
    },
    priceMap: {
      type: Map, // Map<number, number>
      required: true
    }
  },
  computed: {
    videoPrice() {
      return this.priceMap.get(this.video.mediaType);
    }
  }
};
</script>

<style scoped>
.order-detail table {
  width: 100%;
  font-size: 11px;
}

.order-detail table th {
  width: 50%;
}

a.remove-item-link {
  font-size: 12px;
  color: #545454;
  text-decoration: underline;
}
</style>
