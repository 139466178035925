import PREFECTURES from "../data/prefectures";
import {
  POSTCODE_AVAILABLE_CHARS_PATTERN,
  POSTCODE_PATTERN,
  USER_ADDRESS_TELEPHONE_AVAILABLE_CHARS_PATTERN
} from "../data/constants";

import zenNumSymbolToHankaku from "../lib/utils/zenkaku_hankaku";

class UserAddressForm {
  id?: number;
  fullName: string;
  postcode: string;
  prefecture: string;
  city: string;
  street: string;
  building: string | null | undefined;
  telephone: string;

  constructor(data: {
    id?: number;
    fullName?: string;
    postcode?: string;
    prefecture?: string;
    city?: string;
    street?: string;
    building?: string | null;
    telephone?: string;
    defaultAddress?: boolean;
  }) {
    this.id = data.id;
    this.fullName = data.fullName || "";
    this.postcode = data.postcode || "";
    this.prefecture = data.prefecture || "";
    this.city = data.city || "";
    this.street = data.street || "";
    this.building = data.building;
    this.telephone = data.telephone || "";
  }

  static prefectures(): Array<string> {
    return PREFECTURES;
  }

  static formatPostcode(str: string) {
    return zenNumSymbolToHankaku(str);
  }

  static formatTelephone(str: string) {
    return zenNumSymbolToHankaku(str).replace(/-/g, "");
  }

  isNew(): boolean {
    return !this.id;
  }

  isValidPostcode(): boolean {
    return !!this.postcode && POSTCODE_PATTERN.test(this.postcode);
  }

  toParams(): {
    id?: number;
    fullName: string;
    postcode: string;
    prefecture: string;
    city: string;
    street: string;
    building?: string | null;
    telephone: string;
  } {
    return {
      id: this.id,
      fullName: this.fullName,
      postcode: this.postcode,
      prefecture: this.prefecture,
      city: this.city,
      street: this.street,
      building: this.building,
      telephone: this.telephone
    };
  }

  fullNameRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "お名前を入力してください。",
      (v: string) =>
        v.length <= 255 || "お名前は255文字以内で入力してください。"
    ];
  }

  postcodeRules(): Array<Function> {
    // 全角 => 半角変換後の値でバリデーションするため、1つのfunctionでチェックする。
    return [
      (v?: string) => {
        if (!v || v.length <= 0) {
          return "郵便番号を入力してください。";
        }

        const formattedPostcode = UserAddressForm.formatPostcode(v);
        if (!formattedPostcode.match(POSTCODE_AVAILABLE_CHARS_PATTERN)) {
          return "郵便番号は半角英数字、ハイフンのみで入力してください。";
        }
        if (!formattedPostcode.match(POSTCODE_PATTERN)) {
          return "郵便番号の書式が正しくありません。";
        }
        return true;
      }
    ];
  }

  prefectureRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "都道府県を入力してください。",
      (v: string) =>
        PREFECTURES.includes(v) !== undefined ||
        "都道府県に選択できない値が指定されています。"
    ];
  }

  cityRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "市区町村を入力してください。",
      (v: string) =>
        v.length <= 255 || "市区町村は255文字以内で入力してください。"
    ];
  }

  streetRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "番地を入力してください。",
      (v: string) => v.length <= 255 || "番地は255文字以内で入力してください。"
    ];
  }

  buildingRules(): Array<Function> {
    return [
      (v?: string) =>
        !v ||
        v.length <= 255 ||
        "建物名・部屋番号は255文字以内で入力してください。"
    ];
  }

  telephoneRules(): Array<Function> {
    return [
      (v?: string) => {
        if (!v || v.length <= 0) {
          return "電話番号を入力してください。";
        }

        const formattedTelephone = UserAddressForm.formatTelephone(v);
        if (
          !formattedTelephone.match(
            USER_ADDRESS_TELEPHONE_AVAILABLE_CHARS_PATTERN
          )
        ) {
          return "電話番号は半角数字で入力してください。";
        }
        if (formattedTelephone.length < 10 || formattedTelephone.length > 13) {
          return "電話番号は10文字以上13文字以内で入力してください。";
        }
        return true;
      }
    ];
  }
}

export default UserAddressForm;
