const VueFlowplayerPlugin = {};
export default VueFlowplayerPlugin.install = function(Vue, { flowplayer }) {
  Vue.flowplayer = flowplayer;

  Object.defineProperties(Vue.prototype, {
    flowplayer: {
      get() {
        return flowplayer;
      }
    }
  });
};
