<template>
  <v-layout row wrap>
    <v-flex xs12 offset-md1 md10>
      <h1 class="primary--text subheading mb-2 font-weight-bold text-xs-center">
        注文内容の確認
      </h1>

      <h2 class="store-title">注文内容</h2>

      <div v-if="estimate" class="px-2">
        <template v-if="salesManagement.isPhotoAndVideo">
          <v-layout row wrap my-3>
            <v-flex xs12 md2>
              <h3>注文写真</h3>
            </v-flex>
            <v-flex xs12 md10>
              <template v-if="cartPictures.length < 1">
                <p>写真はありません。</p>
              </template>
              <template v-else>
                <v-layout row wrap my-1>
                  <v-flex
                    v-for="p in cartPictures"
                    :key="p.uniqueKey"
                    xs4
                    md2
                    text-xs-center
                  >
                    <div class="pa-1">
                      <square-picture-frame
                        :picture="p"
                        hide-zoom-icon
                        border
                        no-cursor
                        background-color="#FFFFFF"
                      >
                      </square-picture-frame>
                    </div>
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>

          <template v-if="cartVideos.length > 0">
            <v-layout row wrap my-3>
              <v-flex xs12 md2>
                <h3>注文動画</h3>
              </v-flex>
              <v-flex xs12 md10>
                <v-layout row wrap my-1>
                  <v-flex xs12 text-xs-left>
                    <video-download-notice
                      :organization-video-downloadable="
                        organization.videoDownloadAvailable
                      "
                    >
                    </video-download-notice>
                  </v-flex>
                  <v-flex
                    v-for="v in cartVideos"
                    :key="v.uniqueKey"
                    xs4
                    md2
                    text-xs-center
                  >
                    <div class="pa-1">
                      <square-video-frame
                        :video="v"
                        hide-play-time
                        border
                        no-cursor
                        background-color="#FFFFFF"
                      >
                      </square-video-frame>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>
          </template>
        </template>
        <template v-if="salesManagement.isPhotoBook">
          <v-layout row wrap my-3>
            <v-flex xs12 md2>
              <h3>フォトブックの種類</h3>
            </v-flex>
            <v-flex xs12 md10>
              {{ photoBookPrice.mediaType.label }}
              <p class="caption">
                ページ数：{{ photoBookPrice.photoBookPageNum }}ページ<br />
                写真選択枚数：{{
                  photoBookPrice.photoBookMaxPictureCount
                }}枚まで<br />
              </p>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>
        </template>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>注文者</h3>
          </v-flex>
          <v-flex xs12 md10>
            {{ orderForm.ownerName }}<br />
            {{ orderForm.ownerEmail }}
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <template v-if="userAddress">
          <v-layout row wrap my-3>
            <v-flex xs12 md2>
              <h3>お届け先</h3>
            </v-flex>
            <v-flex xs12 md10>
              {{ userAddress.fullName }} 様<br />
              住所： {{ userAddress.fullAddress }}<br />
              電話番号： {{ userAddress.telephone }}
            </v-flex>
          </v-layout>

          <v-divider></v-divider>
        </template>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>お支払い方法</h3>
          </v-flex>
          <v-flex xs12 md10>
            {{ paymentMethodLabel }}
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>お支払い期限</h3>
          </v-flex>
          <v-flex xs12 md10>
            ご注文完了後１か月以内
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>購入金額</h3>
          </v-flex>
          <v-flex xs12 md10>
            <template v-if="estimate">
              <table class="order-estimate text-xs-center">
                <thead>
                  <tr>
                    <th class="text-xs-left">明細種別</th>
                    <th>単価(税込)</th>
                    <th>数量</th>
                    <th>合計(税込)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="s in estimate.summaries" :key="s.mediaType">
                    <td class="text-xs-left">{{ s.label }}</td>
                    <td>{{ s.unitPrice }}円</td>
                    <td>{{ s.quantity }}</td>
                    <td class="text-xs-right">{{ s.total }}円</td>
                  </tr>
                </tbody>
              </table>

              <v-layout row wrap align-center class="px-3 my-5">
                <v-flex xs-6 text-xs-left class="title">
                  合計金額(税込)
                </v-flex>
                <v-flex xs-6 text-xs-right class="display-1 font-weight-bold">
                  {{ estimate.sumTotal }} 円
                </v-flex>
              </v-layout>
            </template>
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>販売期間</h3>
          </v-flex>
          <v-flex xs12 md10>
            {{ salesManagement.formattedSalesStartDate }}
            〜
            {{ salesManagement.formattedSalesEndDate }}
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>商品のお届け</h3>
          </v-flex>
          <v-flex xs12 md10>
            当社にて代金入金確認後、ご登録のメールアドレスに決済完了メールを送信いたします。<br />
            その後、順次発送のお手続きを開始いたします。<br />
            ・プリント写真の場合、当社にて代金入金確認後、3週間程度でのお届け予定となっております。発送方法はメール便での発送となります。<br />
            ・フォトブックの場合は、当社にて代金入金確認後、2か月程度でのお届けとなっております。発送方法はメール便での発送となります。<br />
            ・写真データ購入の場合、当社にて代金入金確認後、注文履歴一覧に表示されている「写真のDL」からダウンロードが可能です。<br />
            ・動画データ購入の場合、当社にて代金入金確認後、注文履歴一覧に表示されている「動画のDL」からダウンロードが可能です。<br />
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>キャンセルについて</h3>
          </v-flex>
          <v-flex xs12 md10>
            注文のキャンセルの詳細は
            <a href="/site/law" target="_blank">こちら</a><br />
            ※すでにご決済が完了している場合は、キャンセルを行うことが出来ません。<br />
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>返品について</h3>
          </v-flex>
          <v-flex xs12 md10>
            ・返品(返金)に関しましては、原則受け付けません。<br />
            ・不良品に限り、商品到着後7日以内にご連絡いただいた場合のみ交換を受け付けます。またその際の送料は当社が負担いたします。<br />
            ・商品交換についてのご連絡先：info@lookme-e.com<br />
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap my-3>
          <v-flex xs12 md2>
            <h3>配送先不明等の商品について</h3>
          </v-flex>
          <v-flex xs12 md10>
            ・ご注文の商品が宛名不明などの理由で返送されることがございます。<br />
            ・返送された商品は、再発送のためにお届け先の確認をさせていただきますが、ご連絡が取れない場合がございます。<br />
            ・商品が当社に返送されてから6か月間、再送先が確認できない場合は、商品を廃棄させていただきますので、予めご了承ください。<br />
            ・なお、廃棄費用を請求する可能性があることも、予めご了承ください。<br />
          </v-flex>
        </v-layout>

        <v-divider></v-divider>
      </div>

      <v-layout row wrap text-xs-center px-2>
        <v-flex xs12>
          <v-alert
            :value="errors.length > 0"
            color="error"
            class="text-xs-left mb-3"
          >
            <ul v-for="err in errors" :key="err.message">
              <li>{{ err.message }}</li>
            </ul>
          </v-alert>

          <v-btn
            large
            color="primary"
            class="navigation-button"
            :disabled="!estimate || submitting"
            :loading="submitting"
            @click.native="onClickPurchaseButton"
          >
            注文内容を確定する
          </v-btn>
        </v-flex>

        <v-flex xs12>
          <v-btn
            dark
            large
            color="negative"
            class="navigation-button"
            :disabled="submitting"
            @click.native="onClickPrevStepButton"
          >
            戻る
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { compact } from "lodash";

import ApiErrorHandler from "../../../../../mixins/ApiErrorHandler";

import OrderForm from "../../../../../forms/OrderForm";
import Organization from "../../../../../models/Organization";
import SalesManagement from "../../../../../models/SalesManagement";

import SquarePictureFrame from "../../../../../components/SquarePictureFrame";
import SquareVideoFrame from "../../../../../components/SquareVideoFrame";
import VideoDownloadNotice from "../../../../../components/VideoDownloadNotice";
import { API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE } from "../../../../../lib/lookmee_photo/ApiErrorCodes";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame,
    "square-video-frame": SquareVideoFrame,
    "video-download-notice": VideoDownloadNotice
  },
  mixins: [ApiErrorHandler],
  props: {
    organization: {
      type: Organization,
      required: true
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    pictureMap: {
      type: Map,
      required: true
    },
    videoMap: {
      type: Map,
      required: true
    },
    orderForm: {
      type: OrderForm,
      required: true
    }
  },
  data() {
    return {
      estimate: null,
      errors: [],
      userAddress: null,
      submitting: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    // TODO: Do not refer vuex store directly, should be passed from parent.
    ...mapState("cart", {
      cart: "cart"
    }),
    ...mapGetters({
      cartPhotoBookMediaType: "cart/photoBookMediaType",
      cartPhotoBookOrderAmount: "cart/photoBookOrderAmount",
      cartPhotoBookPictureIds: "cart/photoBookPictureIds"
    }),
    cartPictures() {
      return compact(
        this.cart.pictureOrders.map(o => this.pictureMap.get(o.id))
      );
    },
    cartVideos() {
      return compact(this.cart.videoOrders.map(o => this.videoMap.get(o.id)));
    },
    photoBookPrice() {
      return this.salesManagement.prices.find(
        p => p.mediaType.value === this.cartPhotoBookMediaType
      );
    },
    paymentMethodLabel() {
      const paymentMethod = this.salesManagement.paymentMethods.find(
        pm => pm.value === this.orderForm.paymentMethod
      );
      return paymentMethod ? paymentMethod.label : "";
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organization.id;
      const salesManagementId = this.salesManagement.id;

      this.$store.dispatch("startLoading");
      try {
        const [estimate, userAddress] = await Promise.all([
          this.apiClient.estimateOrder(organizationId, salesManagementId, {
            orderType: this.orderForm.orderType,
            paymentMethod:
              this.orderForm.paymentMethod ||
              this.salesManagement.defaultPaymentMethod.value,
            pictureOrders: this.cart.pictureOrders,
            videoOrders: this.cart.videoOrders,
            photoBookOrder: {
              mediaType: this.cartPhotoBookMediaType,
              orderAmount: this.cartPhotoBookOrderAmount,
              pictureIds: Array.from(this.cartPhotoBookPictureIds)
            }
          }),
          this.orderForm.isRequireUserAddress()
            ? this.apiClient.getUserAddress(this.orderForm.userAddressId)
            : Promise.resolve()
        ]);

        this.estimate = estimate;
        this.userAddress = userAddress;
      } catch (errors) {
        if (
          !this.isGlobalApiErrors(errors) ||
          (errors.status === 503 &&
            errors.getErrorCode() ===
              API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE)
        ) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async onClickPurchaseButton() {
      const organizationId = this.organization.id;
      const salesManagementId = this.salesManagement.id;

      this.errors = [];
      this.submitting = true;
      try {
        const {
          orderId,
          orderOrderedDateString,
          paymentUrl
        } = await this.apiClient.purchaseOrder(
          organizationId,
          salesManagementId,
          {
            orderType: this.orderForm.orderType,
            paymentMethod: this.orderForm.paymentMethod,
            ownerName: this.orderForm.ownerName,
            ownerEmail: this.orderForm.ownerEmail,
            userAddressId: this.orderForm.userAddressId,
            pictureOrders: this.cart.pictureOrders,
            videoOrders: this.cart.videoOrders,
            photoBookOrder: {
              mediaType: this.cartPhotoBookMediaType,
              orderAmount: this.cartPhotoBookOrderAmount,
              pictureIds: Array.from(this.cartPhotoBookPictureIds)
            }
          }
        );

        const PAYMENT_METHOD_TEXT = {
          1: "cash",
          2: "credit_card",
          3: "convenience_store"
        };

        if (orderId && this.salesManagement.isPhotoAndVideo) {
          dataLayer.push({
            photo_quantity_data: this.estimate.dataPictureQuantity(),
            photo_quantity_movie: this.estimate.videoQuantity(),
            photo_quantity_print: this.estimate.printPictureQuantity(),
            photo_quantity_pro: this.estimate.proPictureQuantity(),
            photo_quantity_teacher: this.estimate.teacherPictureQuantity(),
            photo_quantity_total: this.estimate.photoQuantityTotal(),
            photo_price_data: this.estimate.dataPicturePriceTotal(),
            photo_price_movie: this.estimate.videoPriceTotal(),
            photo_price_print: this.estimate.printPicturePriceTotal(),
            photo_price_pro: this.estimate.proPicturePriceTotal(),
            photo_price_teacher: this.estimate.teacherPicturePriceTotal(),
            photo_price_postage: this.estimate.postagePriceTotal(),
            price_total: this.estimate.sumTotal,
            photo_payment: PAYMENT_METHOD_TEXT[this.orderForm.paymentMethod],
            photo_sales_management_id: salesManagementId,
            photo_order_id: orderId,
            photo_order_date: orderOrderedDateString
          });
        } else if (orderId && this.salesManagement.isPhotoBook) {
          dataLayer.push({
            album_size: this.estimate.photobookSize(),
            album_buy_quantity: this.estimate.photobookQuantity(),
            album_price_total: this.estimate.sumTotal,
            album_price_postage: this.estimate.postagePriceTotal(),
            album_payment: PAYMENT_METHOD_TEXT[this.orderForm.paymentMethod],
            sales_management_id: this.salesManagement.id,
            album_order_id: orderId,
            album_order_date: orderOrderedDateString
          });
        }
        this.$emit("complete-purchase", { orderId, paymentUrl });
      } catch (errors) {
        if (
          !this.isGlobalApiErrors(errors) ||
          (errors.status === 503 &&
            errors.getErrorCode() ===
              API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE)
        ) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    onClickPrevStepButton() {
      this.$emit("prev-step");
    }
  }
};
</script>

<style scoped>
h3 {
  color: #939393;
  font-size: 11pt;
  font-weight: normal;
  margin-bottom: 2px;
}

table.order-estimate {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table.order-estimate tr {
  border-bottom: 1px dotted #dedede;
}

table.order-estimate tr th,
table.order-estimate tr td {
  padding: 8px 0 8px;
}
</style>
