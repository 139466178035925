import { ActionTree } from "vuex";
import { RootState } from "../types";
import { FrState } from "./types";
import Face from "../../models/Face";
import FaceRegistration from "../../models/FaceRegistration";
import OrderDetailPicture from "../../models/OrderDetailPicture";

export const actions: ActionTree<FrState, RootState> = {
  async getFaces(
    context: { rootState: RootState },
    payload: { kidId: number }
  ): Promise<Array<Face>> {
    return await context.rootState.apiClient.getFaces(payload.kidId);
  },
  async deleteFace(
    context: { rootState: RootState },
    payload: { kidId: number; uuid: number }
  ): Promise<void> {
    await context.rootState.apiClient.deleteFace(payload.kidId, payload.uuid);
  },
  async getFaceRegistration(
    context: { rootState: RootState },
    payload: { id: number }
  ): Promise<FaceRegistration> {
    return await context.rootState.apiClient.getFaceRegistration(payload.id);
  },
  async createFaceRegistration(
    context: { rootState: RootState },
    payload: {
      kidId: number;
      sourceType: number;
      orderDetailPictureId?: number;
    }
  ): Promise<FaceRegistration> {
    return await context.rootState.apiClient.createFaceRegistration(payload);
  },
  async startFaceDetectionFaceRegistration(
    context: { rootState: RootState },
    payload: { id: number }
  ): Promise<FaceRegistration> {
    return await context.rootState.apiClient.startFaceDetectionFaceRegistration(
      payload.id
    );
  },
  async deleteFaceRegistration(
    context: { rootState: RootState },
    payload: { id: number }
  ): Promise<void> {
    await context.rootState.apiClient.deleteFaceRegistration(payload.id);
  },
  async startFaceRegistrationFaceRegistration(
    context: { rootState: RootState },
    payload: { id: number; faceIndex: number }
  ): Promise<FaceRegistration> {
    return await context.rootState.apiClient.startFaceRegistrationFaceRegistration(
      payload.id,
      payload.faceIndex
    );
  },
  async pagesFrPurchasedPictures(context: {
    rootState: RootState;
  }): Promise<{ purchasedPictures: Array<OrderDetailPicture> }> {
    return await context.rootState.apiClient.pagesFrPurchasedPictures();
  }
};
