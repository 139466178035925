<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />メールアドレスの登録完了
      </h1>
      <v-card>
        <v-card-text v-if="activated">
          <p class="text-xs-center">
            メールアドレスの登録が完了しました。<br />
            引き続きお子さまの写真をお楽しみください。
          </p>

          <img src="../../assets/images/completed.png" alt="" />

          <v-layout justify-center row>
            <v-btn class="v-btn--minwidth" color="primary" href="/">
              お子さまの写真を見る
            </v-btn>
          </v-layout>
        </v-card-text>

        <v-card-text v-if="hasError">
          <p class="error--text">
            URLの有効期限が切れている、もしくは不正なURLです。
          </p>
          <p class="error--text">
            お手数ですが、再度ログインして確認メールを再送してください。
          </p>

          <v-layout justify-center row>
            <v-btn class="v-btn--minwidth" color="primary" href="/">
              ログインする
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activated: false,
      hasError: false
    };
  },
  async created() {
    await this.activateEmail();
    if (this.$store.state.user.user) {
      await this.reloadUser();
    }
  },
  methods: {
    async activateEmail() {
      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("activateUser", { token: this.token });
        this.activated = true;
      } catch (errors) {
        if (errors.status === 404) {
          this.hasError = true;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async reloadUser() {
      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("user/fetchCurrentUser");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
