<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons">airport_shuttle</v-icon>
        <br />園・施設の登録
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">登録が完了しました。</p>
          <p class="text-xs-center">
            <img src="../../../assets/images/completed.png" />
          </p>
          <section class="py-3">
            <v-layout justify-center row>
              <v-btn
                block
                type="submit"
                color="primary"
                :to="{ name: 'listFrKids' }"
              >
                続けて顔認識の登録をする
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                block
                color="negative"
                :to="{
                  name: 'organizationTop',
                  params: { organizationId: form.organization.id }
                }"
              >
                後で登録する（お子さまの写真を見る）
              </v-btn>
            </v-layout>
          </section>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";

export default {
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  }
};
</script>
