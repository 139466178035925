<template>
  <v-layout row wrap>
    <v-flex xs12 offset-md1 md10>
      <template v-if="salesManagement && organization">
        <v-stepper :value="currentStep" class="pb-3">
          <v-stepper-header class="mb-3">
            <v-stepper-step :complete="currentStep > 1" step="1"
              >注文方法・枚数の選択</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="currentStep > 2" step="2"
              >ご注文者・お届け先の入力</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="currentStep > 3" step="3"
              >お支払い方法の選択</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="currentStep > 4" step="4"
              >内容確認</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="5">完了</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content :step="currentStep" class="pa-0">
              <transition name="component-fade" mode="out-in">
                <component
                  :is="currentSubPage"
                  v-bind="{
                    organizationId,
                    organization,
                    salesManagement,
                    orderForm,
                    pictureMap,
                    videoMap,
                    photoBookPictureMap,
                    orderId,
                    paymentUrl
                  }"
                  @update:orderType="orderForm.orderType = $event"
                  @update:ownerName="orderForm.ownerName = $event"
                  @update:ownerEmail="orderForm.ownerEmail = $event"
                  @update:userAddressId="orderForm.userAddressId = $event"
                  @update:paymentMethod="orderForm.paymentMethod = $event"
                  @prev-step="goPrevPage"
                  @next-step="goNextPage"
                  @complete-purchase="onCompletePurchase"
                >
                </component>
              </transition>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from ".././../../../mixins/ApiErrorHandler";

import OrderForm from "../../../../forms/OrderForm";

import SelectOrderTypeSubPage from "./Show/SelectOrderType";
import InputOrdererSubPage from "./Show/InputOrderer";
import SelectPaymentMethodSubPage from "./Show/SelectPaymentMethod";
import ConfirmSubPage from "./Show/Confirm";
import CompleteSubPage from "./Show/Complete";

export default {
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    }
  },
  data() {
    const orderForm = new OrderForm({
      ownerName: "",
      ownerEmail: this.$store.state.user.user.email,
      userAddressId: null,
      orderType: null,
      paymentMethod: null
    });
    return {
      organization: null,
      salesManagement: null,
      pictures: [],
      videos: [],
      photoBookPictures: [],
      orderForm: orderForm,
      orderId: null,
      paymentUrl: null
    };
  },
  computed: {
    currentStep() {
      switch (this.$route.hash) {
        case "#select_order_type":
          return 1;
        case "#input_orderer":
          return 2;
        case "#select_payment_method":
          return 3;
        case "#confirm":
          return 4;
        case "#complete":
          return 5;
        default:
          return 1;
      }
    },
    currentSubPage() {
      switch (this.$route.hash) {
        case "#select_order_type":
          return SelectOrderTypeSubPage;
        case "#input_orderer":
          return InputOrdererSubPage;
        case "#select_payment_method":
          return SelectPaymentMethodSubPage;
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        default:
          return null;
      }
    },
    pictureMap() {
      return new Map(this.pictures.map(p => [p.id, p]));
    },
    videoMap() {
      return new Map(this.videos.map(v => [v.id, v]));
    },
    photoBookPictureMap() {
      return new Map(this.photoBookPictures.map(p => [p.id, p]));
    }
  },
  async created() {
    const checkVersionResult = await this.$store.dispatch("checkVersion");
    if (!checkVersionResult) {
      this.$store.dispatch("showRequireRefreshDialog");
      return;
    }

    this.$router.replace({ hash: "#select_order_type" });

    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.$store.dispatch("startLoading");
      try {
        const {
          organization,
          salesManagement,
          pictures,
          videos,
          photoBookPictures
        } = await this.$store.dispatch("cart/pagesShowStore", {
          organizationId,
          salesManagementId
        });

        this.organization = organization;
        this.salesManagement = salesManagement;
        this.pictures = pictures;
        this.videos = videos;
        this.photoBookPictures = photoBookPictures;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    goPrevPage() {
      switch (this.$route.hash) {
        case "#input_orderer":
          this.$router.push({ hash: "#select_order_type" });
          break;
        case "#select_payment_method":
          this.$router.push({ hash: "#input_orderer" });
          break;
        case "#confirm":
          this.$router.push({ hash: "#select_payment_method" });
          break;
        default:
        // nothing to do
      }
    },
    goNextPage(event) {
      switch (this.$route.hash) {
        case "#select_order_type":
          this.$router.push({ hash: "#input_orderer" });
          break;
        case "#input_orderer":
          this.$router.push({ hash: "#select_payment_method" });
          break;
        case "#select_payment_method":
          this.$router.push({ hash: "#confirm" });
          break;
        default:
        // nothing to do
      }
    },
    onCompletePurchase({ orderId, paymentUrl }) {
      this.orderId = orderId;
      this.paymentUrl = paymentUrl;

      this.$store.commit("cart/clearCart");

      this.$router.push({ hash: "#complete" });
    }
  }
};
</script>

<style>
h2.store-title {
  font-size: 14px !important;
  font-weight: bold;
  border-bottom: none;
  background-color: #f6f6f6;
  padding: 11px 0 10px 15px;
  margin-bottom: 8px;
  text-align: left;
}
</style>
