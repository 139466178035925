import * as Sentry from "@sentry/browser";

const VueSentryPlugin = {};
export default VueSentryPlugin.install = function(
  Vue,
  { dsn, debug, environment, release, userId }
) {
  Sentry.init({
    dsn,
    debug,
    environment,
    release,
    integrations: [new Sentry.Integrations.Vue({ Vue })]
  });

  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });
  });

  Vue.sentry = Sentry;

  Object.defineProperties(Vue.prototype, {
    sentry: {
      get() {
        return Sentry;
      }
    }
  });
};
