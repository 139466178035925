<template>
  <v-layout row wrap>
    <v-flex xs12 offset-sm1 sm10 offset-md2 md8>
      <v-dialog
        v-if="galleryDialog"
        v-model="galleryDialog"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '80%'"
        content-class="picture-gallery-dialog mx-1"
      >
        <picture-gallery
          :pictures="salesItemPictures"
          :initial-index="galleryIndex"
          :purchased-picture-ids="purchasedPictureIds"
          :offset-count="currentOffsetPictureCount"
          :all-count="allPictureCount"
          @move="onMovePictureGallery"
          @close="closePictureGallery"
        >
          <div slot-scope="{ targetPicture }">
            <v-divider class="my-2"></v-divider>

            <cart-picture-button
              :organization-id="organizationId"
              :sales-management-id="salesManagementId"
              :picture="targetPicture"
              html-class="title px-5"
            >
            </cart-picture-button>

            <p class="cart-picture-button-description ma-0">
              ※プリント枚数は注文画面で選択できます。
            </p>
          </div>
        </picture-gallery>
      </v-dialog>

      <overlay-video-player
        v-if="playTargetVideo"
        :video="playTargetVideo"
        @close="closeVideoPlayer"
      >
      </overlay-video-player>

      <template v-if="organization.id && salesManagement">
        <h1 class="title-page">
          <v-icon class="material-icons-outlined">photo_camera</v-icon>
          <br />{{ salesManagement.title }}
        </h1>

        <v-layout v-if="salesManagement">
          <v-flex xs12 offset-md1 mx-3>
            <group-event-select-box
              :groups="groups"
              :group-id="currentGroupId"
              :events="selectableEvents"
              :event-id="eventId"
              @change-group="onChangeGroup"
              @change-event="onChangeEvent"
            ></group-event-select-box>

            <sales-descriptions
              :prices="salesManagement.prices"
            ></sales-descriptions>

            <v-divider id="salesItemsTop"></v-divider>

            <template v-if="reloadingSalesItems">
              <div class="my-5">
                <v-progress-circular
                  size="50"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
            <template v-else-if="salesItems">
              <template v-if="salesItems.length < 1">
                <p>写真はありません。</p>
              </template>
              <template v-else>
                <div
                  v-show="shouldShowVideoDownloadNotice"
                  class="text-xs-left ma-2"
                >
                  <video-download-notice
                    :organization-video-downloadable="
                      !!organization.videoDownloadAvailable
                    "
                  >
                  </video-download-notice>
                </div>

                <sales-item-list
                  :kid-ids="familyKidIds"
                  :organization-id="organizationId"
                  :sales-management-id="salesManagementId"
                  :sales-type="salesManagement.salesType.value"
                  :events="events"
                  :sales-items="salesItems"
                  :purchased-picture-ids="purchasedPictureIds"
                  :purchased-video-ids="purchasedVideoIds"
                  @click-picture="openPictureGallery($event)"
                  @click-video="openVideoPlayer($event)"
                ></sales-item-list>
              </template>
            </template>
          </v-flex>
        </v-layout>

        <div v-if="allPages > 0" class="text-xs-center py-2 my-3">
          <v-pagination
            :value="page"
            :length="allPages"
            circle
            @input="onChangePage"
          >
          </v-pagination>
        </div>

        <div class="text-xs-center">
          <v-btn
            large
            color="primary"
            class="navigation-button"
            :to="{
              name: 'organizationCart',
              params: {
                organizationId: organizationId,
                salesManagementId: salesManagementId
              }
            }"
          >
            カートを見る（{{ cartItemCount }}）
          </v-btn>
        </div>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../../../../mixins/ApiErrorHandler";

import Event from "../../../../models/Event";
import SalesManagement from "../../../../models/SalesManagement";

import CartPictureButton from "../components/CartPictureButton";
import PictureGallery from "../../../../components/PictureGallery";
import OverlayVideoPlayer from "../../../../components/OverlayVideoPlayer";
import VideoDownloadNotice from "../../../../components/VideoDownloadNotice";

import GroupEventSelectBox from "../components/GroupEventSelectBox";
import SalesDescriptions from "../components/SalesDescriptions";
import SalesItemList from "../components/SalesItemList";

export default {
  components: {
    "cart-picture-button": CartPictureButton,
    "picture-gallery": PictureGallery,
    "overlay-video-player": OverlayVideoPlayer,
    "video-download-notice": VideoDownloadNotice,
    "group-event-select-box": GroupEventSelectBox,
    "sales-descriptions": SalesDescriptions,
    "sales-item-list": SalesItemList
  },
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    groupId: {
      type: Number,
      default: undefined
    },
    eventId: {
      type: [Number, String],
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    groups: {
      type: Array, // Array<Group>
      required: true
    },
    purchasedPictureIds: {
      type: Set, // Set<number>
      required: true
    },
    purchasedVideoIds: {
      type: Set, // Set<number>
      required: true
    }
  },
  data() {
    return {
      reloadingSalesItems: false,
      galleryDialog: false,
      galleryIndex: 0,
      playTargetVideo: null,
      events: [],
      allPictureCount: 0,
      allVideoCount: 0,
      allPages: 0,
      perPage: 0,
      salesItems: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      organization: "organization"
    }),
    ...mapGetters({
      familyKidIds: "family/kidIds",
      cartItemCount: "cart/itemCount"
    }),
    defaultGroup() {
      return this.groups[0];
    },
    selectableEvents() {
      let selectableEvents = [];

      // 動画イベントを1つにまとめる
      const videoEvents = this.events.filter(e => e.isVideo);
      if (videoEvents.length > 0) {
        const e = videoEvents[0];
        selectableEvents.push(
          new Event({
            ...e,
            id: "video",
            eventName: "動画",
            picturesCount: 0,
            videosCount: videoEvents.reduce((sum, e) => sum + e.videosCount, 0)
          })
        );
      }

      // TODAY & AUTOCLIP写真イベントを1つにまとめる
      const todayEvents = this.events.filter(e => e.isTodayOrAutoclip);
      if (todayEvents.length > 0) {
        const e = todayEvents[0];
        selectableEvents.push(
          new Event({
            ...e,
            id: "today_or_autoclip",
            eventName: "日常写真",
            picturesCount: todayEvents.reduce(
              (sum, e) => sum + e.picturesCount,
              0
            ),
            videosCount: 0
          })
        );
      }

      selectableEvents = selectableEvents.concat(
        this.events.filter(e => !e.isVideo && !e.isTodayOrAutoclip)
      );
      return selectableEvents.filter(e => e.itemCount > 0);
    },
    currentGroupId() {
      return (
        this.groupId || (this.defaultGroup ? this.defaultGroup.id : undefined)
      );
    },
    currentOffsetPictureCount() {
      return Math.max(this.perPage * (this.page - 1) - this.allVideoCount, 0);
    },
    salesItemPictures() {
      return this.salesItems.filter(s => s.isPicture()).map(s => s.picture);
    },
    shouldShowVideoDownloadNotice() {
      return this.salesItems.some(i => i.isVideo());
    }
  },
  watch: {
    groupId: function() {
      this.reloadEvents();
    },
    $route: function() {
      this.reloadSalesItems();
    }
  },
  created() {
    this.reloadEvents();
    this.reloadSalesItems();
  },
  methods: {
    async reloadEvents() {
      if (!this.currentGroupId) {
        return;
      }

      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;
      try {
        this.events = await this.apiClient.getEvents(
          organizationId,
          salesManagementId,
          { groupId: this.currentGroupId }
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    },
    async reloadSalesItems() {
      if (!this.currentGroupId) {
        return;
      }

      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.salesItems = [];
      this.allPages = 0;
      this.reloadingSalesItems = true;
      try {
        const { salesItems, pagination } = await this.apiClient.getSalesItems(
          organizationId,
          salesManagementId,
          {
            groupId: this.currentGroupId,
            eventId: this.eventId,
            page: this.page
          }
        );
        this.salesItems = salesItems;
        // TODO: paginationはオブジェクトそのままdataに使いたい。dataが多い。
        this.allPictureCount = pagination.allPictureCount;
        this.allVideoCount = pagination.allVideoCount;
        this.allPages = pagination.allPages;
        this.perPage = pagination.perPage;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.reloadingSalesItems = false;
      }
    },
    onChangeGroup(groupId) {
      if (groupId === this.currentGroupId) {
        return;
      }

      this.$router.replace({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: groupId
        }
      });
    },
    onChangeEvent(eventId) {
      if (eventId === this.eventId) {
        return;
      }

      this.$router.replace({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: this.groupId,
          eventId: eventId
        }
      });
    },
    onChangePage(page) {
      if (page === this.page) {
        return;
      }

      this.$vuetify.goTo("#salesItemsTop", { offset: -75 });
      this.$router.push({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: this.groupId,
          eventId: this.eventId,
          page: page
        }
      });
    },
    openPictureGallery(picture) {
      this.galleryIndex = this.salesItemPictures.findIndex(p => p === picture);
      this.galleryDialog = true;
    },
    onMovePictureGallery({ picture }) {
      this.$vuetify.goTo(`#${picture.uniqueKey}`, {
        duration: 20,
        offset: -100
      });
    },
    closePictureGallery() {
      this.galleryIndex = null;
      this.galleryDialog = false;
    },
    openVideoPlayer(video) {
      this.playTargetVideo = video;
    },
    closeVideoPlayer() {
      this.playTargetVideo = null;
    }
  }
};
</script>

<style scoped>
p.cart-picture-button-description {
  font-size: 10px;
  color: #666666;
}
</style>
