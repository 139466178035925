<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />世帯の選択
      </h1>
      <v-card elevation-1>
        <template v-if="families">
          <template>
            <v-list>
              <template v-for="(family, index) in families">
                <v-list-tile
                  :key="family.id"
                  @click="onFamilySelection(family.id)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ family.name }}</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-icon>navigate_next</v-icon>
                  </v-list-tile-action>
                </v-list-tile>

                <v-divider
                  v-if="index !== families.length - 1"
                  :key="`divider-${family.id}`"
                ></v-divider>
              </template>
            </v-list>
          </template>
        </template>
      </v-card>
    </v-flex>
    <terms-and-policy
      v-if="agreementVersions"
      :agreement-versions="agreementVersions"
    />
  </v-layout>
</template>

<script>
import dayjs from "dayjs";
import { addToDataLayerObjectFromArray } from "../../lib/utils/data_layer";

import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import TermsAndPolicy from "../../components/TermsAndPolicy.vue";

export default {
  components: {
    "terms-and-policy": TermsAndPolicy
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      families: null,
      selectedFamily: undefined,
      agreementVersions: undefined
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      currentFamily: "family",
      karte: "karte"
    })
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.families = await this.$store.dispatch("family/fetchFamilies");
        this.agreementVersions = await this.apiClient.getAgreementVersions();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    loadNotifications() {
      this.$store.dispatch("notifications/initNotifications").catch(errors => {
        // skip error handling because this API call is executed in background
        if (errors.is50xError()) {
          this.sentry.captureMessage(errors.getErrorMessage());
        }
      });
    },
    updateDataLayer() {
      let dataLayerData = {
        mail_ok: this.selectedFamily.promotionMailDeliverable,
        setting_fr: this.karte.isFrUsed,
        setting_fr_previous: this.karte.previousFrRegisteredDate,
        setting_address: this.karte.isFamilyUserAddressExisting
      };

      // Send data for max 10 kids
      // child_birthday1 to child_birthday10
      // birthDate can be null
      const kidsBirthdays = this.selectedFamily.kids
        .filter(k => k.birthDate)
        .map(k => {
          const dayjsDate = dayjs(k.birthDate);
          return dayjsDate.format("YYYY-MM-DD");
        });
      dataLayerData = addToDataLayerObjectFromArray(
        "child_birthday",
        dataLayerData,
        kidsBirthdays,
        10
      );

      dataLayer.push(dataLayerData);
    },
    async onFamilySelection(selectedFamilyId) {
      let family = this.currentFamily;

      if (selectedFamilyId !== this.currentFamily.id) {
        await this.changeFamily(selectedFamilyId);
        family = this.selectedFamily;

        await this.$store.dispatch("karte/getKarte");

        this.updateDataLayer();
      }

      if (family.organizations.length === 1) {
        this.$router.push({
          name: "organizationTop",
          params: { organizationId: family.organizations[0].id }
        });
      } else {
        this.$router.push({
          name: "organizations"
        });
      }
    },
    async changeFamily(selectedFamilyId) {
      this.$store.dispatch("startLoading");

      try {
        this.selectedFamily = await this.$store.dispatch("changeFamily", {
          familyId: selectedFamilyId
        });

        this.loadNotifications();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
