<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md0 md12>
      <template v-if="organization.id">
        <h1 class="title-page">
          <v-icon class="material-icons-outlined">home</v-icon>
          <br />{{ organization.name }}
        </h1>

        <template v-if="salesManagements">
          <template v-if="salesManagements.length < 1">
            <v-card elevation-1>
              <v-card-text>
                <p>
                  現在、写真が販売されていません。次回の写真販売開始までお待ちください。
                </p>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-layout wrap justify-center>
              <v-flex
                v-for="sm in salesManagements"
                :key="sm.id"
                class="mb-3 xs12 md5 mx-3"
              >
                <v-card>
                  <v-layout>
                    <v-flex xs12 text-xs-center>
                      <div class="pa-3">
                        <v-icon medium color="primary" class="my-2">
                          {{ getIconName(sm) }}
                        </v-icon>

                        <h2 class="subheading font-weight-bold">
                          {{ getSalesManagementTitle(sm) }}
                        </h2>
                        <v-divider
                          class="primary mt-2"
                          style="border-bottom-width:2px;"
                        ></v-divider>

                        <div class="subheading mt-2">
                          <p class="font-weight-bold caption mb-0">
                            販売期間：{{ sm.formattedSalesStartDate }}〜{{
                              sm.formattedSalesEndDate
                            }}
                          </p>
                        </div>
                      </div>
                      <v-card
                        v-for="notice in sm.notices"
                        :key="notice"
                        flat
                        dark
                        color="warning"
                        class="campaign-banner mb-3"
                      >
                        <span class="subheading font-weight-bold">{{
                          notice
                        }}</span>
                      </v-card>

                      <div class="pb-3">
                        <v-btn
                          large
                          color="primary"
                          class="navigation-button"
                          :to="getRouterParams(sm)"
                        >
                          さっそく見る
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </template>
      </template>
    </v-flex>
    <terms-and-policy
      v-if="agreementVersions"
      :agreement-versions="agreementVersions"
    />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { API_ERROR_CODE_NOT_FOUND_FOR_SELECTED_FAMILY } from "../../lib/lookmee_photo/ApiErrorCodes";

import { SALES_TYPES } from "../../data/constants";
import TermsAndPolicy from "../../components/TermsAndPolicy.vue";

export default {
  components: {
    "terms-and-policy": TermsAndPolicy
  },
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      salesManagements: null,
      agreementVersions: undefined
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      organization: "organization"
    })
  },
  async created() {
    await this.fetchInitData();

    // For Karte
    const hasSale = this.salesManagements.length > 0;
    const isfreePostageCampaign = hasSale
      ? this.salesManagements[0].freePostageCampaign
      : undefined;

    if (this.organization.id) {
      dataLayer.push({
        en_id: this.organization.id,
        onsale: hasSale,
        // postage_free: Is there a free postage campaign?
        postage_free: isfreePostageCampaign
      });
    }
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organizationId;

      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("organization/fetchOrganization", {
          organizationId
        });
        this.salesManagements = await this.apiClient.getSalesManagements(
          organizationId
        );
        this.agreementVersions = await this.apiClient.getAgreementVersions();
      } catch (errors) {
        if (
          errors.status === 404 &&
          errors.getErrorCode() === API_ERROR_CODE_NOT_FOUND_FOR_SELECTED_FAMILY
        ) {
          this.$router.push({ name: "notFoundForFamily" });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    getIconName(salesManagement) {
      switch (salesManagement.salesType.value) {
        case SALES_TYPES.photoAndVideo:
          return "photo_camera";
        case SALES_TYPES.photoBook:
          return "fas fa-book";
        default:
          return "";
      }
    },
    getSalesManagementTitle(salesManagement) {
      if (salesManagement.salesType.value === SALES_TYPES.photoBook) {
        return `【フォトブック】${salesManagement.title}`;
      } else {
        return salesManagement.title;
      }
    },
    getRouterParams(salesManagement) {
      switch (salesManagement.salesType.value) {
        case SALES_TYPES.photoAndVideo:
          return {
            name: "organizationMain",
            params: {
              organizationId: this.organization.id,
              salesManagementId: salesManagement.id
            }
          };
        case SALES_TYPES.photoBook:
          return {
            name: "organizationSelectPhotoBookType",
            params: {
              organizationId: this.organization.id,
              salesManagementId: salesManagement.id
            }
          };
        default:
          return "";
      }
    }
  }
};
</script>
