<template>
  <section>
    <h2 class="title-bar">
      <template v-if="isGift">
        プレゼントお届け先{{ giftNo }}
      </template>
      <template v-else>
        お届け先
      </template>
    </h2>

    <div class="pa-3">
      <table class="table-vertical">
        <tr>
          <td colspan="2">{{ address.name }} 様</td>
        </tr>
        <tr>
          <th>住所：</th>
          <td class="text-xs-right">
            〒{{ address.postcode }} {{ address.addr1 }} {{ address.addr2 }}
          </td>
        </tr>
        <tr>
          <th>電話番号：</th>
          <td class="text-xs-right">{{ address.tel }}</td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
import OrderAddress from "../../../models/OrderAddress";

export default {
  props: {
    address: {
      type: OrderAddress,
      required: true
    },
    isGift: {
      type: Boolean,
      default: false
    },
    giftNo: {
      type: Number,
      default: null
    }
  }
};
</script>
