import "@babel/polyfill";

import Vue from "vue";

// init Vuex and store
import store from "./store";

// init Vuetify and its styles
import Vuetify from "vuetify";

import "./assets/stylesheets/main.styl";
import theme from "./data/theme.json";

Vue.use(Vuetify, { theme, options: { customProperties: true } });

// Font Awesome
import "@fortawesome/fontawesome-free/css/all.css";

// init vue-router
import VueRouter from "vue-router";
import routes from "./pages/routes";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash && !to.meta.switchSubPageByHash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    }
  }
});

const BASE_TITLE = "ルクミーフォト";
router.beforeEach((to, _from, next) => {
  // Family selected check
  if (
    !to.meta.familyNotRequired &&
    store.getters["user/isAuthenticated"] &&
    !store.getters["family/isFamilySelected"]
  ) {
    next({ name: "listFamilies" });
    return;
  }

  // Change page title
  document.title = to.meta.title
    ? `${to.meta.title} | ${BASE_TITLE}`
    : BASE_TITLE;
  next();
});

// Sentry
import VueSentryPlugin from "./plugins/vue-sentry";

// flowplayer
import VueFlowplayerPlugin from "../common/plugins/vue-flowplayer";

import App from "./layouts/App";
import User from "./models/User";
import Family from "./models/Family";
import Cart from "./models/Cart";
import Organization from "./models/Organization";
import Karte from "./models/Karte";
import { camelizeKeys } from "humps";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("app");
  const props = camelizeKeys(JSON.parse(el.getAttribute("data-props")), {
    deep: true
  });

  const user = props.user ? new User(props.user) : null;
  const family = props.family ? new Family(props.family) : null;
  const organization = props.organization
    ? new Organization(props.organization)
    : null;
  const cart = props.cart ? new Cart(props.cart) : null;
  const flowplayerToken = props.flowplayerToken;
  const karte = props.karte ? new Karte(props.karte) : null;

  if (props.environment !== "development") {
    Vue.use(VueSentryPlugin, {
      dsn: props.sentryDsn,
      debug: props.environment !== "production",
      environment: props.environment,
      release: props.clientVersion,
      userId: user && user.id
    });
  }

  // TODO: webpack内でimportしたflowplayerだと動かないので、仕方なく別ロードして参照している。
  const flowplayer = window.flowplayer;
  if (flowplayer) {
    Vue.use(VueFlowplayerPlugin, { flowplayer });
  }

  store.commit("init", {
    environment: props.environment,
    clientVersion: props.clientVersion,
    errorMessages: props.errorMessages,
    flowplayerToken: props.flowplayerToken || ""
  });
  store.commit("user/setUser", user);
  store.commit("family/init", { family });
  store.commit("organization/init", { organization });
  store.commit("cart/init", { cart });
  store.commit("karte/init", karte);

  const app = new Vue({
    el,
    router,
    store,
    render: h => h(App)
  });
});
