<template>
  <v-layout row wrap>
    <v-flex xs12 md6 offset-md3>
      <h1 class="primary--text subheading mb-2 font-weight-bold text-xs-center">
        お支払い方法の選択
      </h1>

      <v-form ref="form" lazy-validation>
        <h2 class="store-title">お支払い方法の選択</h2>

        <div class="px-2">
          <v-radio-group
            v-model="mutablePaymentMethod"
            :rules="orderForm.paymentMethodRules()"
          >
            <v-radio
              v-for="pm in salesManagement.paymentMethods"
              :key="pm.value"
              :label="pm.label"
              :value="pm.value"
              class="my-3"
            ></v-radio>
          </v-radio-group>
        </div>

        <v-layout row wrap text-xs-center px-2>
          <v-flex xs12>
            <v-btn
              large
              color="primary"
              class="navigation-button"
              @click="onClickNextStepButton"
            >
              注文内容の確認へ
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-btn
              dark
              large
              color="negative"
              class="navigation-button"
              @click="onClickPrevStepButton"
            >
              戻る
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import OrderForm from "../../../../../forms/OrderForm";
import SalesManagement from "../../../../../models/SalesManagement";

export default {
  props: {
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    orderForm: {
      type: OrderForm,
      required: true
    }
  },
  computed: {
    mutablePaymentMethod: {
      get() {
        return this.orderForm.paymentMethod;
      },
      set(val) {
        this.$emit("update:paymentMethod", val);
      }
    }
  },
  methods: {
    onClickPrevStepButton() {
      this.$emit("prev-step");
    },
    onClickNextStepButton() {
      if (!this.$refs.form.validate()) {
        const firstErrorElem = this.$refs.form.inputs.find(el => el.hasError);
        this.$vuetify.goTo(firstErrorElem, { offset: -80 });
        return;
      }
      this.$emit("next-step");
    }
  }
};
</script>
