import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { NotificationsState } from "./types";
import { RootState } from "../types";

const initialState: NotificationsState = {
  announcements: [],
  reminders: []
};

export const notifications: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
