import * as dayjs from "dayjs";
import { formatDate } from "../lib/utils/date_time";

class Reminder {
  id: number;
  title: string;
  startDate: Date;
  body?: string | undefined;
  isNew: boolean;
  confirmPath?: string | null;

  constructor(data: {
    id: number;
    title: string;
    startDate: string;
    body?: string | undefined;
    isNew: boolean;
    confirmPath?: null;
  }) {
    this.id = data.id;
    this.title = data.title;
    this.startDate = dayjs(data.startDate).toDate();
    this.body = data.body;
    this.isNew = data.isNew;
    this.confirmPath = data.confirmPath;
  }

  get uniqueKey(): string {
    return `reminder-${this.id}`;
  }

  get displayStartDate(): string {
    return formatDate(this.startDate);
  }
}

export default Reminder;
