<template>
  <v-layout v-if="order">
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">save_alt</v-icon>
        <br />動画のダウンロード
      </h1>

      <div class="pa-3">
        <p>
          「ダウンロード」ボタンから動画をダウンロードできます。スマートフォンでの保存方法は、<a
            href="https://lookmee.tayori.com/faq/6dee8ffd1c5ae520a999d257ebf2fc8d2b2f835d/detail/beb67ecd8c0efa0a434a3ed0cef05fc14c527bda/"
            target="_blank"
            >よくある質問</a
          >をご覧ください。
        </p>

        <overlay-video-player
          v-if="playingVideo"
          :video="playingVideo"
          @close="closeVideoPlayer"
        >
        </overlay-video-player>

        <v-layout wrap class="photo-list">
          <v-flex
            v-for="detail in order.videoOrderDetails"
            :key="detail.uniqueKey"
            xs4
            md3
            mb-3
          >
            <square-video-frame
              :video="detail.video"
              hide-play-time
              @play="openVideoPlayer(detail.video)"
            >
            </square-video-frame>

            <table class="table-vertical">
              <tr>
                <td class="text-xs-right id-num">{{ detail.video.fileNo }}</td>
              </tr>
            </table>

            <div class="text-xs-center">
              <v-btn
                small
                class="v-btn--minwidth"
                color="primary"
                :href="
                  `/site/orders/${orderId}/order_details/${detail.id}/download_video`
                "
              >
                ダウンロード
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </div>

      <v-layout justify-center row my-4>
        <v-btn
          class="v-btn--minwidth"
          color="negative"
          :to="{ name: 'showOrderHistory', params: { orderId } }"
        >
          戻る
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import SquareVideoFrame from "../../components/SquareVideoFrame";
import OverlayVideoPlayer from "../../components/OverlayVideoPlayer";

export default {
  components: {
    "square-video-frame": SquareVideoFrame,
    "overlay-video-player": OverlayVideoPlayer
  },
  mixins: [ApiErrorHandler],
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: null,
      playingVideo: null
    };
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const order = await this.$store.dispatch("orderHistory/fetchOrder", {
          orderId: this.orderId
        });
        if (
          !order.isVideoDataDownloadable ||
          !order.organization.videoDownloadAvailable
        ) {
          this.$router.replace({ name: "notFound" });
        }

        this.order = order;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    openVideoPlayer(video) {
      this.playingVideo = video;
    },
    closeVideoPlayer() {
      this.playingVideo = null;
    }
  }
};
</script>
