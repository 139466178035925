<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">notifications_none</v-icon>
        <br />お知らせ一覧
      </h1>
      <div v-if="notifications">
        <template v-if="isNotificationsAvail">
          お知らせはまだありません
        </template>

        <template v-else>
          <v-card>
            <v-list two-line>
              <div v-for="reminder in reminders" :key="reminder.uniqueKey">
                <div>
                  <reminder-row
                    :key="reminder.uniqueKey"
                    :reminder="reminder"
                  ></reminder-row>
                </div>

                <v-divider
                  v-if="announcements.length > 0"
                  class="mx-3"
                ></v-divider>
              </div>

              <div
                v-for="(announcement, i) in announcements"
                :key="announcement.uniqueKey"
              >
                <div>
                  <announcement-row
                    :key="announcement.uniqueKey"
                    :announcement="announcement"
                  ></announcement-row>
                </div>

                <v-divider
                  v-if="i !== announcements.length - 1"
                  class="mx-3"
                ></v-divider>
              </div>
            </v-list>
          </v-card>
        </template>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import AnnouncementRow from "./components/AnnouncementRow";
import ReminderRow from "./components/ReminderRow";
import Announcement from "../../models/Announcement";
import Reminder from "../../models/Reminder";
import { mapGetters } from "vuex";

export default {
  components: {
    "announcement-row": AnnouncementRow,
    "reminder-row": ReminderRow
  },
  mixins: [ApiErrorHandler],
  props: {
    reload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      notifications: undefined
    };
  },
  computed: {
    ...mapGetters({
      isNotificationsAvail: "notifications/isNotificationsAvail",
      getNotifications: "notifications/getNotifications"
    }),
    reminders() {
      return this.getNotifications.filter(n => this.isReminder(n));
    },
    announcements() {
      return this.getNotifications.filter(n => this.isAnnouncement(n));
    }
  },
  created() {
    if (this.reload) {
      this.initNotifications();
    } else {
      this.setFromStore();
    }
  },
  methods: {
    async initNotifications() {
      this.$store.dispatch("startLoading");
      try {
        this.notifications = await this.$store.dispatch(
          "notifications/initNotifications"
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    isAnnouncement(object) {
      return object instanceof Announcement;
    },
    isReminder(object) {
      return object instanceof Reminder;
    },
    setFromStore() {
      this.notifications = true;
    }
  }
};
</script>
