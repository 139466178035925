import EnumValue from "./EnumValue";

class SalesManagementPrice {
  id: number;
  mediaType: EnumValue<number>;
  isForPrint: boolean;
  isForData: boolean;
  price: number;
  photoBookWarnPictureCount: number | null;
  photoBookMaxPictureCount: number | null;
  photoBookPageNum: number | null;

  constructor(data: {
    id: number;
    mediaType: EnumValue<number>;
    isForPrint: boolean;
    isForData: boolean;
    price: number;
    photoBookWarnPictureCount: number | null;
    photoBookMaxPictureCount: number | null;
    photoBookPageNum: number | null;
  }) {
    this.id = data.id;
    this.mediaType = data.mediaType;
    this.isForPrint = data.isForPrint;
    this.isForData = data.isForData;
    this.price = data.price;
    this.photoBookWarnPictureCount = data.photoBookWarnPictureCount;
    this.photoBookMaxPictureCount = data.photoBookMaxPictureCount;
    this.photoBookPageNum = data.photoBookPageNum;
  }

  get uniqueKey(): string {
    return `salesManagementPrice-${this.id}`;
  }
}

export default SalesManagementPrice;
