<template>
  <div>
    <v-btn
      v-if="!isIncludedInCart"
      color="primary"
      :small="isSmall"
      :class="htmlClass"
      :loading="submitting"
      @click="addVideoToCart(video)"
    >
      <v-icon :small="isSmall">shopping_cart</v-icon>
      に入れる
    </v-btn>
    <v-btn
      v-else
      dark
      color="negative"
      :small="isSmall"
      :class="htmlClass"
      :loading="submitting"
      @click="removeVideoFromCart(video)"
    >
      <v-icon :small="isSmall">shopping_cart</v-icon>
      から外す
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../../../../mixins/ApiErrorHandler";

import Video from "../../../../models/Video";

// TODO: componentsでは直接storeを変更しないようにしたい。どこでstoreを変更しているかわからなくなりそう。
export default {
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    video: {
      type: Video,
      required: true
    },
    htmlClass: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    ...mapGetters({
      cartVideoIds: "cart/videoIds"
    }),
    isSmall() {
      return !!this.small;
    },
    isIncludedInCart() {
      return this.cartVideoIds.has(this.video.id);
    }
  },
  methods: {
    async addVideoToCart(picture) {
      this.submitting = true;
      try {
        await this.$store.dispatch("cart/addVideoToCart", {
          videoId: picture.id,
          count: 1
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    async removeVideoFromCart(picture) {
      this.submitting = true;
      try {
        await this.$store.dispatch("cart/removeVideoFromCart", {
          videoId: picture.id
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
