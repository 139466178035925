<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">email</v-icon>
        <br />メールをご確認ください
      </h1>

      <v-card>
        <v-card-text>
          <p>
            パスワードを再設定するためのメールをお送りしました。メールに記載のURLにアクセスしてパスワードの再設定をしてください。
          </p>

          <div class="my-3">
            <img src="../../assets/images/illust_mail.png" />
          </div>

          <p class="text-xs-center">
            <router-link
              :to="{
                name: 'staticMailNotReceived',
                query: { backTo: 'sendEmailPasswordReset' }
              }"
            >
              メールが届かない方はこちら ≫
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
