<template>
  <v-layout row wrap>
    <v-flex xs12 offset-sm2 sm8 offset-md2 md8>
      <v-dialog
        v-if="galleryDialog"
        v-model="galleryDialog"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '80%'"
        content-class="picture-gallery-dialog mx-1"
      >
        <picture-gallery
          :pictures="cartPictures"
          :initial-index="galleryIndex"
          :purchased-picture-ids="purchasedPictureIds"
          :offset-count="0"
          :all-count="cartPictures.length"
          @move="onMovePictureGallery"
          @close="closePictureGallery"
        >
          <div slot-scope="{ targetPicture }">
            <v-divider class="my-2"></v-divider>

            <v-btn
              dark
              color="negative"
              class="title px-5"
              @click="removeTarget = targetPicture"
            >
              <v-icon left>far fa-trash-alt</v-icon>
              削除する
            </v-btn>
          </div>
        </picture-gallery>
      </v-dialog>

      <overlay-video-player
        v-if="playTargetVideo"
        :video="playTargetVideo"
        @close="closeVideoPlayer"
      >
      </overlay-video-player>

      <confirm-remove-cart-item-dialog
        :target="removeTarget"
        @complete="handleCompleteConfirmRemoveCartItemDialog"
        @cancel="removeTarget = null"
      >
      </confirm-remove-cart-item-dialog>

      <h1 class="title-page">
        <v-icon class="material-icons">shopping_cart</v-icon>
        <br />カートの中身
      </h1>

      <v-layout>
        <v-flex xs12 mx-3>
          <v-layout row wrap class="photo-list">
            <v-flex
              v-for="picture in cartPictures"
              :id="picture.uniqueKey"
              :key="picture.uniqueKey"
              xs4
              md3
              mb-3
            >
              <square-picture-frame
                :picture="picture"
                @click-picture="openPictureGallery(picture)"
              >
              </square-picture-frame>

              <table class="table-vertical">
                <tr>
                  <td>
                    <v-icon
                      v-if="picture.hasFace(familyKidIds)"
                      small
                      color="warning"
                    >
                      face
                    </v-icon>
                    <v-icon
                      v-if="purchasedPictureIds.has(picture.id)"
                      small
                      color="error"
                    >
                      photo_filter
                    </v-icon>
                  </td>
                  <td class="text-xs-right id-num">{{ picture.fileNo }}</td>
                </tr>
              </table>

              <div class="text-xs-center">
                <v-btn
                  dark
                  small
                  color="negative"
                  class="caption pa-0 mx-0"
                  @click="removeTarget = picture"
                >
                  <v-icon small class="mr-2">far fa-trash-alt</v-icon>
                  削除する
                </v-btn>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="photo-list">
            <v-flex
              v-for="video in cartVideos"
              :key="video.uniqueKey"
              xs4
              md3
              mb-3
            >
              <square-video-frame :video="video" @play="openVideoPlayer(video)">
              </square-video-frame>

              <table class="table-vertical">
                <tr>
                  <td>
                    <v-icon
                      v-if="purchasedVideoIds.has(video.id)"
                      small
                      color="error"
                    >
                      photo_filter
                    </v-icon>
                  </td>
                  <td class="text-xs-right id-num">{{ video.fileNo }}</td>
                </tr>
              </table>

              <div class="text-xs-center">
                <v-btn
                  dark
                  small
                  color="negative"
                  class="caption pa-0 mx-0"
                  @click="removeTarget = video"
                >
                  <v-icon small class="mr-2">far fa-trash-alt</v-icon>
                  削除する
                </v-btn>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-btn
                large
                color="primary"
                class="navigation-button"
                :to="{
                  name: 'organizationStore',
                  params: { organizationId, salesManagementId }
                }"
              >
                注文手続きへ進む
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-btn
                dark
                large
                color="negative"
                class="navigation-button"
                :to="{
                  name: 'organizationMain',
                  params: { organizationId, salesManagementId }
                }"
              >
                写真一覧へ戻る
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from ".././../../../../mixins/ApiErrorHandler";

import ConfirmRemoveCartItemDialog from "../../../../../components/ConfirmRemoveCartItemDialog";
import SquarePictureFrame from "../../../../../components/SquarePictureFrame";
import SquareVideoFrame from "../../../../../components/SquareVideoFrame";
import PictureGallery from "../../../../../components/PictureGallery";
import OverlayVideoPlayer from "../../../../../components/OverlayVideoPlayer";

export default {
  components: {
    "confirm-remove-cart-item-dialog": ConfirmRemoveCartItemDialog,
    "square-picture-frame": SquarePictureFrame,
    "square-video-frame": SquareVideoFrame,
    "picture-gallery": PictureGallery,
    "overlay-video-player": OverlayVideoPlayer
  },
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    pictures: {
      type: Array, // Array<Picture>
      required: true
    },
    videos: {
      type: Array, // Array<Video>
      required: true
    },
    purchasedPictureIds: {
      type: Set, // Set<number>
      required: true
    },
    purchasedVideoIds: {
      type: Set, // Set<number>
      required: true
    }
  },
  data() {
    return {
      galleryDialog: false,
      galleryIndex: null,
      playTargetVideo: null,
      removeTarget: null,
      removing: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    ...mapGetters({
      familyKidIds: "family/kidIds",
      cartPictureIds: "cart/pictureIds",
      cartVideoIds: "cart/videoIds"
    }),
    cartPictures() {
      return this.pictures.filter(p => this.cartPictureIds.has(p.id));
    },
    cartVideos() {
      return this.videos.filter(v => this.cartVideoIds.has(v.id));
    }
  },
  methods: {
    openPictureGallery(picture) {
      this.galleryIndex = this.cartPictures.findIndex(p => p === picture);
      this.galleryDialog = true;
    },
    onMovePictureGallery({ picture }) {
      this.$vuetify.goTo(`#${picture.uniqueKey}`, {
        duration: 100,
        offset: -100
      });
    },
    closePictureGallery() {
      this.galleryIndex = null;
      this.galleryDialog = false;
    },
    openVideoPlayer(video) {
      this.playTargetVideo = video;
    },
    closeVideoPlayer() {
      this.playTargetVideo = null;
    },
    handleCompleteConfirmRemoveCartItemDialog() {
      this.removeTarget = null;
      this.galleryDialog = false;
    }
  }
};
</script>

<style>
.picture-gallery-dialog {
  max-height: 98% !important;
}
</style>
