<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />登録内容の確認
      </h1>

      <v-form @submit.prevent="submitForm">
        <v-card>
          <v-card-title>園名</v-card-title>
          <div class="pa-3">
            {{ form.organizationName() }}
          </div>
          <v-card-title>クラス</v-card-title>
          <div class="pa-3">
            {{ groupName }}
          </div>
          <v-card-title>お子さまの氏名（漢字）</v-card-title>
          <div class="pa-3">{{ form.familyName }} {{ form.kidName }}</div>
          <v-card-title>お子さまの生年月日</v-card-title>
          <div class="pa-3">{{ formattedKidBirthDate }}（{{ kidAge }}歳）</div>
          <div class="pb-4">
            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-layout justify-center row>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
              >
                登録する
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                :disabled="submitting"
                class="v-btn--minwidth"
                color="negative"
                @click="$emit('back-to-select-group')"
              >
                戻る
              </v-btn>
            </v-layout>
          </div>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters, mapState } from "vuex";
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";
import {
  API_ERROR_CODE_VALIDATION_MATCHING_ERROR_KID_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_MATCHING_ERROR_MULTIPLE_KIDS
} from "../../../lib/lookmee_photo/ApiErrorCodes";
import { addToDataLayerObjectFromArray } from "../../../lib/utils/data_layer";

export default {
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters("family", {
      familyName: "familyName"
    }),
    ...mapState({
      family: "family"
    }),
    groupName() {
      const group = this.form.selectedGroup();
      return group.name;
    },
    kidBirthDate() {
      return dayjs(this.form.kidBirthDate);
    },
    formattedKidBirthDate() {
      return this.kidBirthDate.format("YYYY年M月D日");
    },
    kidAge() {
      const now = dayjs();
      return now.diff(this.kidBirthDate, "year");
    }
  },
  methods: {
    async submitForm() {
      this.errors = [];
      this.submitting = true;
      try {
        await this.$store.dispatch(
          "kid/registerKid",
          this.form.toRegisterKidApiParams()
        );

        // Update kids in family.
        await this.$store.dispatch("family/fetchFamily");

        // Update dateLayer child_birthday data for Karte
        let dataLayerData = {};

        const kidsBirthdays = this.family.kids
          .filter(k => k.birthDate)
          .map(k => {
            const dayjsDate = dayjs(k.birthDate);
            return dayjsDate.format("YYYY-MM-DD");
          });
        dataLayerData = addToDataLayerObjectFromArray(
          "child_birthday",
          dataLayerData,
          kidsBirthdays,
          10
        );
        dataLayer.push(dataLayerData);

        // Display organization_name in Drawer.
        const organizationId = this.form.organization.id;
        this.$store.dispatch("organization/fetchOrganization", {
          organizationId
        });

        this.$emit("next-to-complete");
      } catch (errors) {
        if (errors.status === 400) {
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_VALIDATION_MATCHING_ERROR_KID_NOT_FOUND:
              this.$emit("matching-error", {
                errorCause: "kid_not_found",
                errorCode: errors.getErrorNo()
              });
              break;
            case API_ERROR_CODE_VALIDATION_MATCHING_ERROR_MULTIPLE_KIDS:
              this.$emit("matching-error", {
                errorCause: "multiple_kids",
                errorCode: errors.getErrorNo()
              });
              break;
            default:
              this.errors = errors.errors;
          }
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
