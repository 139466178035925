<template>
  <v-form ref="form" @submit.prevent="submitForm()">
    <v-layout>
      <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
        <h1 class="title-page">
          <v-icon class="material-icons-outlined">email</v-icon>
          <br />園・施設からのお知らせ
        </h1>
        <v-card>
          <v-card-title>電話番号の設定</v-card-title>
          <v-card-text>
            <p class="mb-3 grey--text text--lighten-1">
              {{ user.email }}
            </p>
            <v-divider class="mb-4"></v-divider>
            <v-text-field
              v-model="emergencyPhoneNumber"
              label="電話番号"
              outline
              :rules="form.emergencyPhoneNumberRules()"
              validate-on-blur
              required
            ></v-text-field>
            <p class="caption">
              この番号に園・施設からお電話する場合があります。
            </p>

            <section v-if="errors.length > 0" class="mb-3">
              <div class="caption error--text">
                <ul v-for="err in errors" :key="err.message">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </section>

            <v-layout justify-center row mt-5>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
              >
                設定を完了する
              </v-btn>
            </v-layout>

            <v-layout justify-center row>
              <v-btn
                class="v-btn--minwidth"
                color="negative"
                :disabled="submitting"
                :to="{ name: 'listUserEmergencies' }"
              >
                戻る
              </v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { mapState } from "vuex";
import UpdateUserForm from "../../forms/UpdateUserForm";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      form: null,
      submitting: false,
      errors: []
    };
  },
  computed: {
    emergencyPhoneNumber: {
      get() {
        return this.form.emergencyPhoneNumber;
      },
      set(val) {
        this.form.emergencyPhoneNumber = val;
      }
    },
    ...mapState("user", {
      user: state => state.user
    })
  },
  created() {
    this.form = new UpdateUserForm(this.$store.state.user.user);
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.resetErrors();
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch(
          "user/setEmergency",
          this.form.emergencyParams()
        );
        this.$router.push({ name: "listUserEmergencies" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors["errors"];
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    resetErrors() {
      this.errors = [];
    }
  }
};
</script>
