<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">vpn_key</v-icon>
        <br />パスワードを忘れた場合
      </h1>

      <v-card>
        <v-card-text>
          <p v-if="invalidUrlError" class="error--text">
            認証URLの有効期限が切れている、もしくは不正なURLです。<br />
            お手数ですが、いま一度ご登録をお願いいたします。
          </p>

          <p>
            登録したメールアドレスを入力してください。<br />
            パスワード再設定をするためのメールをお送りします。
          </p>

          <v-form ref="form" lazy-validation @submit.prevent="submitForm">
            <v-text-field
              v-model="email"
              type="email"
              label="メールアドレス"
              validate-on-blur
              required
              :rules="form.emailRules()"
              class="my-4"
            ></v-text-field>

            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-btn
              block
              type="submit"
              :loading="submitting"
              :disabled="submitting"
              color="primary"
            >
              送信する
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import PasswordResetNewForm from "../../forms/PasswordResetNewForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    invalidUrlError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: new PasswordResetNewForm(),
      submitting: false,
      errors: []
    };
  },
  computed: {
    email: {
      get() {
        return this.form.email;
      },
      set(val) {
        this.form.email = val;
      }
    }
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("createPasswordReset", this.form.toParams());

        this.$router.push({ name: "sendEmailPasswordReset" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
