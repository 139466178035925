class OrderSummary {
  id: number;
  mediaType: number;
  mediaTypeLabel: string;
  totalQuantity: number;
  unitPrice: number;
  subtotal: number;

  constructor(data: {
    id: number;
    mediaType: number;
    mediaTypeLabel: string;
    totalQuantity: number;
    unitPrice: number;
    subtotal: number;
  }) {
    this.id = data.id;
    this.mediaType = data.mediaType;
    this.mediaTypeLabel = data.mediaTypeLabel;
    this.totalQuantity = data.totalQuantity;
    this.unitPrice = data.unitPrice;
    this.subtotal = data.subtotal;
  }

  get uniqueKey() {
    return `orderSummary-${this.id}`;
  }
}

export default OrderSummary;
