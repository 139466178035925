import { MutationTree } from "vuex";
import { KarteState } from "./types";
import Karte from "../../models/Karte";

const setProps = function(state: KarteState, karte: Karte): any {
  state.isFrUsed = karte.isFrUsed;
  state.previousFrRegisteredDate = karte.previousFrRegisteredDate;
  state.isFamilyUserAddressExisting = karte.isFamilyUserAddressExisting;
};

export const mutations: MutationTree<KarteState> = {
  init(state: KarteState, karte: Karte): any {
    // For the login screen there is no karte data
    if (karte) {
      setProps(state, karte);
    }
  },
  setKarte(state: KarteState, karte: Karte): any {
    setProps(state, karte);
  }
};
