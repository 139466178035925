<template>
  <div>
    <v-layout>
      <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
        <h1 class="title-page">
          <v-icon class="material-icons-outlined">account_circle</v-icon
          ><br />ログインして登録
        </h1>
        <v-card>
          <div class="pa-3">
            <p>
              招待されたご家族の写真・動画を閲覧するためにお持ちのルクミーフォトアカウントでログインしてください。
            </p>
            <p>
              ※ルクミーフォトのアカウントをお持ちでない場合は、<router-link
                :to="{ name: 'invitedFamilyRegistration', query: { token } }"
                >こちら</router-link
              >から登録してください。
            </p>
          </div>
          <v-alert :value="errors.length > 0" color="error" class="mb-3">
            <ul v-for="err in errors" :key="err.message">
              <li>{{ errorMessage(err) }}</li>
            </ul>
          </v-alert>
          <v-form ref="form" @submit.prevent="onSubmit()">
            <section>
              <div class="title-bar">世帯</div>
              <div class="pa-3">
                <div class="grey--text text--lighten-1">
                  {{ inviterFamilyName }}
                </div>
              </div>
            </section>

            <section>
              <div class="title-bar">メールアドレス</div>
              <div class="px-3 pt-3">
                <v-text-field
                  v-model="loginForm.email"
                  type="text"
                  single-line
                  outline
                  validate-on-blur
                  required
                ></v-text-field>
              </div>
            </section>

            <section>
              <div class="title-bar">パスワード</div>
              <div class="px-3 pt-3">
                <v-text-field
                  v-model="loginForm.password"
                  type="password"
                  single-line
                  outline
                  validate-on-blur
                ></v-text-field>
              </div>
            </section>

            <section>
              <div class="pb-4">
                <v-layout justify-center row class="my-3">
                  <v-btn
                    type="submit"
                    :disabled="submitting"
                    :loading="submitting"
                    class="v-btn--minwidth"
                    color="primary"
                  >
                    ログイン
                  </v-btn>
                </v-layout>
              </div>
            </section>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import {
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_FAMILY_NOT_MATCH
} from "../../lib/lookmee_photo/ApiErrorCodes";

export default {
  mixins: [ApiErrorHandler],
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      inviterFamilyName: "",
      inviterFamilyId: 0,
      loginForm: {
        email: "",
        password: "",
        rememberMe: false
      },
      submitting: false,
      isAddFamilySuccessful: false,
      errors: []
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    })
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    errorMessage(error) {
      if (
        error.code ===
          API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_FAMILY_NOT_MATCH ||
        error.code === API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_NOT_FOUND
      ) {
        return "ログインに失敗しました。お手数ですが、招待メールに記載のURLをクリックしていま一度登録作業をお願いします。";
      } else {
        return error.message;
      }
    },
    async fetchInitData() {
      this.$store.dispatch("startLoading");

      try {
        const res = await this.$store.dispatch(
          "familyInvitation/verifyInvitationToken",
          { token: this.token }
        );

        this.inviterFamilyName = res.family.name;
        this.inviterFamilyId = res.familyInvitation.familyId;
      } catch (errors) {
        if (errors.status === 404 || errors.status === 400) {
          this.$router.push({ name: "invitedFamilyInvalidUrl" });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async onSubmit() {
      await this.addUserToFamily();

      if (this.isAddFamilySuccessful === true) {
        await this.logIn();
      }
    },
    async logIn() {
      this.errors = [];
      this.submitting = true;

      try {
        await this.apiClient.login(this.loginForm);
        // TODO: swap order of setFamily and fetchCurrentUser and
        //       remove before_action :abort_if_not_family_selected
        //       from current_users_controller
        await this.$store.dispatch("user/fetchCurrentUser");
        await this.$store.dispatch("changeFamily", {
          familyId: this.inviterFamilyId
        });
        this.$router.push({ name: "invitedFamilyLoginComplete" });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errors = errors.errors;
        }
      } finally {
        this.submitting = false;
      }
    },
    async addUserToFamily() {
      this.submitting = true;

      try {
        await this.$store.dispatch("familyInvitation/createFamiliesUser", {
          email: this.loginForm.email,
          password: this.loginForm.password,
          token: this.token,
          familyId: this.inviterFamilyId
        });

        this.isAddFamilySuccessful = true;
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errors = errors.errors;
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
