<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8>
      <v-card elevation-1>
        <v-card-text>
          <p>
            サーバーでエラーが発生しています。<br />
            しばらくお待ちいただいてから、再度アクセスをお願いいたします。<br />
          </p>

          <p v-if="errorNo">
            エラーが何回も発生する場合、お手数ですが下記エラー番号を添えて事務局へお問い合わせください。<br />
            エラー番号: {{ errorNo }}
          </p>
          <p v-else>
            エラーが何回も発生する場合、お手数ですが事務局へお問い合わせください。
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    errorNo: {
      type: String,
      default: undefined
    }
  }
};
</script>
