import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { FamilyState } from "./types";

import Family from "../../models/Family";
import UserAddress from "../../models/UserAddress";

export const actions: ActionTree<FamilyState, RootState> = {
  async fetchFamily(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<Family> {
    const family = await context.rootState.apiClient.getFamily();
    context.commit("setFamily", family);
    return family;
  },

  async fetchFamilies(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<Family[]> {
    const families = await context.rootState.apiClient.getFamilies();
    return families;
  },

  async validateForUpdateFamily(
    context: { commit: Commit; rootState: RootState },
    payload: {
      name: string;
      mobilePhones: Array<{
        id?: number;
        phoneNumber?: string;
        deleteFlag: boolean;
      }>;
    }
  ): Promise<boolean> {
    return await context.rootState.apiClient.validateForUpdateFamily(payload);
  },

  async updateFamily(
    context: { commit: Commit; rootState: RootState },
    payload: {
      name: string;
      mobilePhones: Array<{
        id?: number;
        phoneNumber?: string;
        deleteFlag: boolean;
      }>;
    }
  ): Promise<Family> {
    const family = await context.rootState.apiClient.updateFamily(payload);
    context.commit("setFamily", family);
    return family;
  },

  async getUserAddresses(context: {
    rootState: RootState;
  }): Promise<Array<UserAddress>> {
    return await context.rootState.apiClient.getUserAddresses();
  },

  async getUserAddress(
    context: { rootState: RootState },
    payload: { userAddressId: number }
  ): Promise<UserAddress> {
    return await context.rootState.apiClient.getUserAddress(
      payload.userAddressId
    );
  },

  async validateForCreateUserAddress(
    context: { rootState: RootState },
    payload: {
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<void> {
    await context.rootState.apiClient.validateForCreateUserAddress(payload);
  },

  async createUserAddress(
    context: { rootState: RootState },
    payload: {
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<UserAddress> {
    return await context.rootState.apiClient.createUserAddress(payload);
  },

  async validateForUpdateUserAddress(
    context: { rootState: RootState },
    payload: {
      id: number;
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<void> {
    await context.rootState.apiClient.validateForUpdateUserAddress(
      payload.id,
      payload
    );
  },

  async updateUserAddress(
    context: { rootState: RootState },
    payload: {
      id: number;
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<UserAddress> {
    return await context.rootState.apiClient.updateUserAddress(
      payload.id,
      payload
    );
  },

  async makeDefaultUserAddress(
    context: { rootState: RootState },
    payload: { userAddressId: number }
  ): Promise<UserAddress> {
    return await context.rootState.apiClient.makeDefaultUserAddress(
      payload.userAddressId
    );
  },

  async deleteUserAddress(
    context: { rootState: RootState },
    payload: { userAddressId: number }
  ): Promise<void> {
    await context.rootState.apiClient.deleteUserAddress(payload.userAddressId);
  }
};
