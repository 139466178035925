<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">email</v-icon
        ><br />ルクミーからのメールが届かない方は
      </h1>
      <v-card>
        <v-card-text>
          <p class="red--text">
            迷惑メール対策などでメールの受信制限をされていると、「ルクミー」からのメールを受け取れない場合があります。
          </p>
          <p>
            ルクミーのドメインである「<span class="font-weight-bold"
              >lookme-e.com</span
            >」を指定受信設定し、再度メールを送信してください。
          </p>
          <p>
            ※設定方法は以下よりご確認いただき、ご不明点はキャリア各社へお問い合わせください。
          </p>
          <ul class="list-link">
            <li>
              <a
                href="https://www.nttdocomo.co.jp/info/spam_mail/spmode/domain/"
                target="_blank"
              >
                ■ docomoサイトで詳細を確認
              </a>
            </li>
            <li>
              <a
                href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/"
                target="_blank"
                >■ auサイトで詳細を確認
              </a>
            </li>
            <li>
              <a
                href="https://www.softbank.jp/mobile/support/mail/antispam/mms/whiteblack/"
                target="_blank"
                >■ SoftBankサイトで詳細を確認
              </a>
            </li>
          </ul>
        </v-card-text>
        <div class="v-sheet">
          <p class="caption">
            ドメイン設定をしてもメールが届かない方はこちらからお問い合わせください。<br />
            <span class="font-weight-bold"
              >ルクミーカスタマーセンター
              <a href="tel:0120886659">0120-886-659</a></span
            ><br />
            (受付時間：24時間365日サポート)
          </p>
        </div>
        <v-card-text>
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :to="{ name: backTo }"
            >
              戻る
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    backTo: {
      type: String,
      default: "login"
    }
  }
};
</script>
