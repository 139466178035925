<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <keep-alive :include="['InputSubPage']">
        <component :is="currentSubPage" :form="form"></component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import InputSubPage from "./New/Input";
import ConfirmSubPage from "./New/Confirm";
import CompleteSubPage from "./New/Complete";
import CompleteExpiredCodeSubPage from "./New/CompleteExpiredCode";
import InquiryForm from "../../forms/InquiryForm";

export default {
  data() {
    return {
      form: new InquiryForm({
        email: this.$store.state.user.user.email
      })
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        case "#complete-expired-code":
          return CompleteExpiredCodeSubPage;
        default:
          return InputSubPage;
      }
    }
  }
};
</script>
