<template>
  <v-dialog v-model="dialog" max-width="100%">
    <v-card class="pa-3">
      <p class="font-weight-bold pr-3">
        新しい{{ agreement.title }}への同意が必要です
      </p>
      <p class="text-wrap">
        {{ agreement.title }}が変更になりました。 <br />
        {{ descriptionText }}ルクミーフォトをご利用いただくためには、
        <a :href="agreement.url" target="_blank">
          最新の{{ agreement.title }}（リンク先が別ウインドウで開きます）
          <v-icon
            class="material-icons-outlined"
            :style="{ color: $vuetify.theme.primary, 'font-size': '16px' }"
          >
            open_in_new
          </v-icon>
        </a>
        への同意が必要となります。<br />
        上記のリンクを確認後、「{{
          agreement.title
        }}に同意する」ボタンを押して同意してください。
      </p>
      <div class="caption caption-color">
        このお知らせは、1日1回表示されます。今は確認できない場合、「今は同意しない」ボタンを押して閉じることができます。
      </div>

      <v-card-actions class="btn-container">
        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="submitting"
          @click="agree"
        >
          {{ agreement.title }}に同意する
        </v-btn>
        <a @click="skip"> 今は同意しない{{ skipBtnText }} </a>
      </v-card-actions>

      <div class="v-card__close">
        <v-icon color="primary" @click="skip">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import AgreementVersion from "../../models/AgreementVersion";

export default {
  mixins: [ApiErrorHandler],
  props: {
    agreement: {
      type: AgreementVersion,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    descriptionText() {
      return this.agreement.dueDate ? `${this.agreement.dueDate}以降、` : "";
    },
    skipBtnText() {
      return this.agreement.dueDate
        ? `（${this.agreement.dueDate}までに確認する）`
        : "";
    },
    dialog: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          // ここを通るのは、下記２つのケース（v-model="dialog" が false になるケース）
          // 1. v-dialogの背景のグレイオーバーレイをクリックしてダイアログを閉じる場合。
          // 2. ESCキーでダイアログを閉じた場合。
          this.skip();
        }
      }
    }
  },
  methods: {
    async agree() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        await this.apiClient.agreeTermsAndPolicy({
          id: this.agreement.id,
          category: this.agreement.category
        });

        this.$emit("close-dialog");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
      }
    },
    async skip() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        await this.apiClient.skipTermsAndPolicy({
          id: this.agreement.id,
          category: this.agreement.category
        });

        this.$emit("close-dialog");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped>
/* ダイアログを下に固定するためのスタイル */
.v-dialog__content {
  height: initial;
  top: initial;
  bottom: 0;
}

.btn-container {
  display: flex;
  margin-top: 16px;
  padding: 0px;
  gap: 16px;
}
.v-btn {
  width: 300px;
}
@media (max-width: 600px) {
  .btn-container {
    flex-direction: column;
  }
  .v-btn {
    width: 100%;
  }
}

.text-wrap {
  margin-bottom: 8px;
}

.caption-color {
  color: #545454;
}
</style>
