<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />
        <template v-if="form.isNew()">
          お届け先の追加
        </template>
        <template v-else>
          お届け先の編集
        </template>
      </h1>

      <v-card>
        <v-card-text>
          <template v-if="form.isNew()">
            <p class="text-xs-center">登録が完了しました。</p>
          </template>
          <template v-else>
            <p class="text-xs-center">編集が完了しました。</p>
          </template>

          <p class="text-xs-center my-4">
            <img src="../../../assets/images/illust_ok.png" />
          </p>

          <p class="text-xs-center">
            <router-link :to="{ name: 'listUserAddresses' }"
              >お届け先情報へ戻る ≫</router-link
            >
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import UserAddressForm from "../../../forms/UserAddressForm";

export default {
  props: {
    form: {
      type: UserAddressForm,
      required: true
    }
  }
};
</script>
