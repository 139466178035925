import { padding } from "./string";

/**
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = padding(date.getMonth() + 1, 2, "0");
  const day = padding(date.getDate(), 2, "0");
  return `${year}年${month}月${day}日`;
}
