<template>
  <v-layout v-if="order">
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">save_alt</v-icon>
        <br />写真のダウンロード
      </h1>

      <v-layout justify-center row>
        <v-btn
          class="v-btn--minwidth"
          color="primary"
          :href="`/site/orders/${orderId}/download_zip`"
        >
          写真をまとめてダウンロード
        </v-btn>
      </v-layout>

      <div class="pa-3">
        <p>
          写真をまとめてダウンロードできます（ZIP形式）。スマートフォンでの保存や解凍方法については、<a
            href="https://lookmee.tayori.com/faq/6dee8ffd1c5ae520a999d257ebf2fc8d2b2f835d/detail/961cbeb6fb45d7bfe05143ff17a160c434565055/"
            target="_blank"
            >よくある質問</a
          >をご覧ください。
        </p>
        <p>個別に保存したい場合は、写真をタップして保存も可能です。</p>

        <v-layout wrap class="photo-list">
          <v-flex
            v-for="detail in order.pictureOrderDetails"
            :key="detail.uniqueKey"
            xs4
            md3
            mb-3
          >
            <router-link
              :to="{
                name: 'downloadPicture',
                params: { orderId: orderId, orderDetailId: detail.id }
              }"
            >
              <square-picture-frame :picture="detail.picture" hide-zoom-icon>
              </square-picture-frame>
            </router-link>

            <table class="table-vertical">
              <tr>
                <td class="text-xs-right id-num">
                  {{ detail.picture.fileNo }}
                </td>
              </tr>
            </table>
          </v-flex>
        </v-layout>
      </div>

      <v-layout justify-center row my-4>
        <v-btn
          class="v-btn--minwidth"
          color="negative"
          :to="{ name: 'showOrderHistory', params: { orderId: orderId } }"
        >
          戻る
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import SquarePictureFrame from "../../components/SquarePictureFrame";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame
  },
  mixins: [ApiErrorHandler],
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: undefined
    };
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const order = await this.$store.dispatch("orderHistory/fetchOrder", {
          orderId: this.orderId
        });
        if (!order.isPhotoDataDownloadable) {
          this.$router.replace({ name: "notFound" });
        }

        this.order = order;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
