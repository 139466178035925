const ZENKAKU_TO_HANKAKU_MAP = new Map<string, string>([
  ["０", "0"],
  ["１", "1"],
  ["２", "2"],
  ["３", "3"],
  ["４", "4"],
  ["５", "5"],
  ["６", "6"],
  ["７", "7"],
  ["８", "8"],
  ["９", "9"],
  ["－", "-"],
  ["ー", "-"],
  ["−", "-"],
  ["＋", "+"]
]);

export default function zenNumSymbolToHankaku(str: string): string {
  return str.replace(
    /[０-９－ー−＋]/g,
    s => ZENKAKU_TO_HANKAKU_MAP.get(s) || ""
  );
}
