<template>
  <div>
    <agreement-dialog
      v-for="agreement in skippableAgreements"
      :key="agreement.id"
      :agreement="agreement"
      :show="currentAgreementId === agreement.id"
      @close-dialog="nextAgreement()"
    />
    <agreement-page
      v-for="agreement in unSkippableAgreements"
      :key="agreement.id"
      :agreement="agreement"
      :show="currentAgreementId === agreement.id"
      @close-dialog="nextAgreement()"
    />
  </div>
</template>

<script>
import AgreementDialog from "./TermsAndPolicy/AgreementDialog.vue";
import AgreementPage from "./TermsAndPolicy/AgreementPage.vue";

export default {
  components: {
    "agreement-dialog": AgreementDialog,
    "agreement-page": AgreementPage
  },
  props: {
    agreementVersions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      displayAgreementVersionIndex: 0
    };
  },
  computed: {
    skippableAgreements() {
      return this.agreementVersions.filter(agreement => agreement.canSkip);
    },
    unSkippableAgreements() {
      return this.agreementVersions.filter(agreement => !agreement.canSkip);
    },
    currentAgreementId() {
      if (this.displayAgreementVersionIndex >= this.agreementVersions.length) {
        return null;
      }
      return this.agreementVersions[this.displayAgreementVersionIndex].id;
    }
  },
  methods: {
    nextAgreement() {
      this.displayAgreementVersionIndex++;
    }
  }
};
</script>
