<template>
  <div
    class="square-wrapper"
    :class="{ border: border, 'no-cursor': noCursor }"
    :style="wrapperStyles"
  >
    <div class="square-spacer"></div>
    <div class="square-content">
      <div
        class="photo-frame"
        :style="printSizeStyle(picture)"
        @click="handleClicked"
      >
        <div class="photo-content" :style="backgroundImageStyle(picture)"></div>
      </div>

      <div v-if="!hideZoomIcon" class="photo-frame-overlay photo-zoom-icon">
        <v-icon small dark @click="handleClicked">
          zoom_in
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import Picture from "../models/Picture";
import OrderDetailPicture from "../models/OrderDetailPicture";

export default {
  props: {
    picture: {
      type: [Picture, OrderDetailPicture],
      required: true
    },
    hideZoomIcon: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    noCursor: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "#000000"
    }
  },
  computed: {
    wrapperStyles() {
      return {
        "background-color": `${this.backgroundColor}`
      };
    }
  },
  methods: {
    printSizeStyle(picture) {
      const widthRatio = Math.min(picture.printAspectRatio, 1.0);
      const heightRatio = Math.min(1.0 / picture.printAspectRatio, 1.0);

      return {
        width: `${100 * widthRatio}%`,
        height: `${100 * heightRatio}%`,
        left: `${((1.0 - widthRatio) / 2) * 100}%`,
        top: `${((1.0 - heightRatio) / 2) * 100}%`
      };
    },
    backgroundImageStyle(picture) {
      const bgImgSize = 1.0 / picture.printAreaRatio;

      let bgImgSizeX, bgImgSizeY;
      if (picture.isTrimmingX()) {
        bgImgSizeX = "auto";
        bgImgSizeY = `${bgImgSize * 100}%`;
      } else {
        bgImgSizeX = `${bgImgSize * 100}%`;
        bgImgSizeY = "auto";
      }
      return {
        "background-image": `url(${picture.thumbnailUrl})`,
        "background-size": `${bgImgSizeX} ${bgImgSizeY}`
      };
    },
    handleClicked() {
      this.$emit("click-picture");
    }
  }
};
</script>

<style scoped>
.square-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.square-wrapper.border {
  border: 1px solid #d8d5d5;
}

.square-wrapper.no-cursor {
  cursor: default;
}

.square-wrapper .square-spacer {
  width: 100%;
  padding-bottom: 100%;
}

.square-wrapper .square-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.photo-frame-overlay {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.photo-zoom-icon {
  right: 0;
  bottom: 0;
}

.photo-frame {
  position: absolute;
}

.photo-frame .photo-content {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
