import * as dayjs from "dayjs";
import { formatDate } from "../lib/utils/date_time";

class Announcement {
  id: number;
  title: string;
  startDate: Date;
  body?: string | undefined;
  isNew: boolean;
  confirm_path?: null;

  constructor(data: {
    id: number;
    title: string;
    startDate: string;
    body?: string | undefined;
    isNew: boolean;
    confirm_path?: null;
  }) {
    this.id = data.id;
    this.title = data.title;
    this.startDate = dayjs(data.startDate).toDate();
    this.body = data.body;
    this.isNew = data.isNew;
    this.confirm_path = data.confirm_path;
  }

  get uniqueKey(): string {
    return `announcement-${this.id}`;
  }

  get displayStartDate(): string {
    return formatDate(this.startDate);
  }
}

export default Announcement;
