<template>
  <v-layout>
    <v-flex xs12 offset-md1 md10>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">list_alt</v-icon>
        <br />注文履歴
      </h1>

      <v-container grid-list-xl>
        <v-layout v-if="orders" class="px-3" row wrap>
          <template v-if="orders.length === 0">
            注文履歴がありません。
          </template>
          <template v-else>
            <v-flex
              v-for="(order, i) in orders"
              :key="order.uniqueKey"
              xs12
              sm6
            >
              <order-row :key="order.uniqueKey" :order="order"></order-row>

              <v-divider
                v-if="i !== orders.length - 1"
                class="my-4"
              ></v-divider>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
    </v-flex>
    <terms-and-policy
      v-if="agreementVersions"
      :agreement-versions="agreementVersions"
    />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import OrderRow from "./components/OrderRow";
import TermsAndPolicy from "../../components/TermsAndPolicy.vue";

export default {
  components: {
    "order-row": OrderRow,
    "terms-and-policy": TermsAndPolicy
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      orders: undefined,
      agreementVersions: undefined
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    })
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.orders = await this.$store.dispatch("orderHistory/fetchOrders");
        this.agreementVersions = await this.apiClient.getAgreementVersions();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
