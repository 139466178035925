<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />新規登録
      </h1>

      <v-card>
        <v-card-text>
          <h2 class="title-caution">まだご登録は完了していません！</h2>
          <p class="body-1">
            登録手続きのメールを送信しました。1週間以内にメールに記載のURLにアクセスしてください。メールが届かない場合は下記をご確認の上再度お試しください。
          </p>
        </v-card-text>
        <div class="v-sheet">
          <h3 class="caption">メールが届かない場合</h3>
          <ul class="caption">
            <li>
              迷惑メール防止設定による受信拒否が考えられます。<br />
              <router-link
                :to="{
                  name: 'staticMailNotReceived',
                  query: { backTo: 'sendEmailUserRegistration' }
                }"
              >
                ドメイン指定受信方法
              </router-link>
              をご確認ください。
            </li>
            <li>
              アドレスの入力ミスが考えられます。<br />お手数ですが最初からやり直してください。
            </li>
            <li>迷惑メールフォルダに入っている可能性があります。</li>
          </ul>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
