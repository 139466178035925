<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentSubPage"
          :form="form"
          :error-cause="matchingErrorCause"
          :error-code="matchingErrorCode"
          @next-to-select-group="$router.push({ hash: '#select_group' })"
          @back-to-input-code="$router.push({ hash: '#input_code' })"
          @next-to-confirm="$router.push({ hash: '#confirm' })"
          @back-to-select-group="$router.push({ hash: '#select_group' })"
          @next-to-complete="$router.push({ hash: '#complete' })"
          @matching-error="handleMatchingError"
          @reset-matching-error="resetMatchingError"
        ></component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import InputCodeSubPage from "./New/InputCode";
import SelectGroupSubPage from "./New/SelectGroup";
import ConfirmSubPage from "./New/Confirm";
import CompleteSubPage from "./New/Complete";
import MatchingErrorSubPage from "./New/MatchingError";
import KidsClassRegistrationForm from "../../forms/KidsClassRegistrationForm";

export default {
  data() {
    return {
      form: new KidsClassRegistrationForm({
        familyName: this.$store.state.family.name
      }),
      matchingErrorCause: null,
      matchingErrorCode: null
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#select_group":
          return SelectGroupSubPage;
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        case "#matching_error":
          return MatchingErrorSubPage;
        default:
          return InputCodeSubPage;
      }
    }
  },
  created() {
    this.$router.push({ hash: "#input_code" });
  },
  methods: {
    handleMatchingError({ errorCause, errorCode }) {
      this.matchingErrorCause = errorCause;
      this.matchingErrorCode = errorCode;
      this.$router.push({ hash: "#matching_error" });
    },
    resetMatchingError() {
      this.$router.push({ hash: "#input_code" });
      this.matchingErrorCause = null;
      this.matchingErrorCode = null;
    }
  }
};
</script>
