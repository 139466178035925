<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons">airport_shuttle</v-icon>
        <br />クラスの選択
      </h1>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <v-card>
          <section>
            <h2 class="title-bar">園名</h2>
            <div class="pa-3">
              <span class="txt-mute font-weight-bold">{{
                form.organizationName()
              }}</span>
            </div>
          </section>

          <section>
            <h2 class="title-bar">クラスの選択</h2>
            <div class="pa-3">
              <v-radio-group v-model="groupId" :rules="form.groupIdRules()">
                <v-radio
                  v-for="g in form.groups"
                  :key="g.uniqueKey"
                  :label="g.name"
                  :value="g.id"
                ></v-radio>
              </v-radio-group>
            </div>
          </section>

          <section class="py-3">
            <v-layout justify-center row>
              <v-btn type="submit" class="v-btn--minwidth" color="primary">
                次へ
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                class="v-btn--minwidth"
                color="negative"
                @click="$emit('back-to-input-code')"
              >
                戻る
              </v-btn>
            </v-layout>
          </section>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";

export default {
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  },
  computed: {
    groupId: {
      get() {
        return this.form.groupId;
      },
      set(val) {
        this.form.groupId = val;
      }
    }
  },
  created() {
    const selectableGroupIds = this.form.groups.map(g => g.id);
    if (!selectableGroupIds.includes(this.groupId)) {
      this.groupId = null;
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("next-to-confirm");
    }
  }
};
</script>
