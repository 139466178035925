class UserAddress {
  id: number;
  fullName: string;
  postcode: string;
  prefecture: string;
  city: string;
  street: string;
  building: string | null;
  telephone: string;
  defaultAddress: boolean;

  constructor(data: {
    id: number;
    fullName: string;
    postcode: string;
    prefecture: string;
    city: string;
    street: string;
    building: string | null;
    telephone: string;
    defaultAddress: boolean;
  }) {
    this.id = data.id;
    this.fullName = data.fullName;
    this.postcode = data.postcode;
    this.prefecture = data.prefecture;
    this.city = data.city;
    this.street = data.street;
    this.building = data.building;
    this.telephone = data.telephone;
    this.defaultAddress = data.defaultAddress;
  }

  get uniqueKey(): string {
    return `userAddress-${this.id}`;
  }

  get fullAddress(): string {
    let addresses = [
      `〒${this.postcode}`,
      this.prefecture,
      this.city,
      this.street
    ];
    if (this.building && this.building.length > 0) {
      addresses.push(this.building);
    }
    return addresses.join(" ");
  }
}

export default UserAddress;
