<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />メールアドレスの確認・変更
      </h1>

      <v-card>
        <v-card-title>メールアドレス</v-card-title>

        <div>
          <div
            class="pa-3"
            :class="{
              'bg-red__lighten': !user.isActivated && !sentEmail
            }"
          >
            {{ user.email }}

            <div v-if="!user.isActivated" class="mt-3">
              <p
                v-if="['notSend', 'sending'].includes(activationEmailStatus)"
                class="caption my-1"
              >
                このメールアドレスは本登録が完了していません。<br />
                以下ボタンをクリックし、
                <span class="red--text">
                  確認メール内に記載されたURLへアクセス
                </span>
                して登録を完了してください。
              </p>

              <v-layout justify-center row>
                <v-btn
                  class="v-btn--minwidth"
                  :color="
                    activationEmailStatus === 'sent' ? 'disabled' : 'error'
                  "
                  :disabled="
                    ['sending', 'sent'].includes(activationEmailStatus)
                  "
                  :loading="activationEmailStatus === 'sending'"
                  @click="sendActivationMail(user.email)"
                >
                  <template v-if="activationEmailStatus === 'sent'">
                    送信しました
                  </template>
                  <template v-else>
                    確認メールを再送する
                  </template>
                </v-btn>
              </v-layout>
            </div>
          </div>
        </div>

        <template v-if="!user.isActivated">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-3">
            <p class="caption">
              ※メールが届かない方は
              <router-link
                :to="{
                  name: 'staticMailNotReceived',
                  query: { backTo: 'showUserEmail' }
                }"
              >
                こちら
              </router-link>
            </p>
          </div>
        </template>

        <section class="mt-3 pb-4">
          <v-layout justify-center row>
            <v-btn
              href="/site/account_settings/email"
              target="_blank"
              class="v-btn--minwidth"
              color="primary"
            >
              メールアドレスを変更する
              <v-icon class="ml-1">open_in_new</v-icon>
            </v-btn>
          </v-layout>
        </section>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      sendingEmail: false,
      sentEmail: false
    };
  },
  computed: {
    activationEmailStatus() {
      if (this.sentEmail) {
        return "sent";
      } else if (this.sendingEmail) {
        return "sending";
      } else {
        return "notSend";
      }
    },
    ...mapState("user", {
      user: state => state.user
    })
  },
  methods: {
    async sendActivationMail() {
      if (this.sendingEmail) {
        return;
      }

      this.sendingEmail = true;
      try {
        await this.$store.dispatch("sendActivationEmail");
        this.sentEmail = true;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.sendingEmail = false;
      }
    }
  }
};
</script>
