<template>
  <v-layout row justify-center>
    <v-dialog :value="!!target" persistent max-width="290">
      <v-card>
        <v-card-text class="text-xs-center">
          カートから削除しますか？
        </v-card-text>

        <v-card-text>
          <div class="text-xs-center">
            <v-btn
              color="primary"
              :loading="removing"
              :disabled="removing"
              @click="removeItemFromCart(target)"
            >
              OK
            </v-btn>
            <v-btn
              dark
              color="negative"
              :disabled="removing"
              @click="$emit('cancel')"
            >
              キャンセル
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../mixins/ApiErrorHandler";

import Picture from "../models/Picture";
import Video from "../models/Video";

// TODO: componentsではstoreを直接変更しないようにする。
export default {
  mixins: [ApiErrorHandler],
  props: {
    target: {
      type: [Picture, Video],
      default: null
    }
  },
  data() {
    return {
      removing: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    })
  },
  methods: {
    removeItemFromCart(item) {
      if (item instanceof Picture) {
        this.removePictureFromCart(item);
      } else if (item instanceof Video) {
        this.removeVideoFromCart(item);
      }
    },
    async removePictureFromCart(picture) {
      this.removing = true;
      try {
        const cart = await this.$store.dispatch("cart/removePictureFromCart", {
          pictureId: picture.id
        });
        this.$emit("update:cart", cart);
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.removing = false;
        this.$emit("complete");
      }
    },
    async removeVideoFromCart(video) {
      this.removing = true;
      try {
        const cart = await this.$store.dispatch("cart/removeVideoFromCart", {
          videoId: video.id
        });
        this.$emit("update:cart", cart);
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.removing = false;
        this.$emit("complete");
      }
    }
  }
};
</script>
