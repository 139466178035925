import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

Vue.use(Vuex);

// Please comment these lines when you use Designer Mode.
import axios from "./axios";
import LookmeePhotoApiClient from "../lib/lookmee_photo/ApiClient";
const apiClient = new LookmeePhotoApiClient({
  axios: axios,
  version: 0
});

// For designer development
// import MockLookmeePhotoApiClient from "../mock/lib/apis/ApiClient";
// const apiClient = new MockLookmeePhotoApiClient({ version: 0, csrfToken: "" });

const initialState: RootState = {
  environment: "",
  apiClient: apiClient,
  clientVersion: 0,
  drawer: undefined,

  loading: false,
  loginRequired: false,
  refreshRequired: false,
  errorDialog: false,
  errorMessages: [],

  flowplayerToken: ""
};

import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

import { user } from "./user";
import { family } from "./family";
import { organization } from "./organization";
import { cart } from "./cart";
import { orderHistory } from "./orderHistory";
import { notifications } from "./notifications";
import { fr } from "./fr";
import { inquiry } from "./inquiry";
import { kid } from "./kid";
import { familyInvitation } from "./familyInvitation";
import { karte } from "./karte";

const store: StoreOptions<RootState> = {
  state: initialState,
  getters,
  mutations,
  actions,
  modules: {
    user,
    family,
    organization,
    cart,
    orderHistory,
    notifications,
    fr,
    inquiry,
    kid,
    familyInvitation,
    karte
  }
};

export default new Vuex.Store<RootState>(store);
