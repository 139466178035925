<template>
  <v-layout row wrap>
    <v-flex xs12 md6 offset-md3 px-2>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 text-xs-center>
            <p>
              ご注文ありがとうございます。
            </p>
            <p class="title font-weight-bold">
              <strong>決済はまだ完了していません。</strong>
            </p>
          </v-flex>

          <v-flex xs12 text-xs-left mb-3>
            <p>
              下記のボタンから決済ページへ移動して、お支払いを完了させてください。
            </p>
            <ul class="notices">
              <li>お支払い後の注文のキャンセルはお受けできません。</li>
            </ul>
          </v-flex>

          <v-flex xs12 text-xs-center my-2>
            <v-btn
              large
              color="primary"
              class="navigation-button"
              :href="paymentUrl"
            >
              決済ページへ移動する
            </v-btn>
          </v-flex>

          <v-flex xs12 text-xs-center my-2>
            <router-link
              :to="{
                name: 'organizationTop',
                params: { organizationId: organizationId }
              }"
            >
              トップページへ戻る
            </router-link>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    paymentUrl: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
ul.notices {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

ul.notices li {
  padding-left: 1em;
  text-indent: -1em;
}

ul.notices li:before {
  content: "※";
  padding-right: 5px;
}
</style>
