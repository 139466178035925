<template>
  <v-dialog v-model="mutableDialog" max-width="290" persistent>
    <v-card>
      <v-card-text class="text-xs-center">
        選択できる枚数の上限（{{ maxPicturesLimit }}枚）を超えています。
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="negative" @click="mutableDialog = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    maxPicturesLimit: {
      type: Number,
      required: true
    }
  },
  computed: {
    mutableDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        if (!val) {
          this.$emit("close");
        }
      }
    }
  }
};
</script>
