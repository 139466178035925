<template>
  <v-dialog v-model="mutableDialog" max-width="640">
    <fr-processing-dialog
      :dialog="submitting"
      label="お顔を検出中です"
    ></fr-processing-dialog>

    <v-card>
      <v-layout wrap justify-center>
        <v-flex md6>
          <v-card-text class="text-xs-center">
            <img
              src="../../../assets/images/illust_pcsp.png"
              alt=""
              width="250"
            />

            <!--
             A trick is used to use <v-btn> as <input type="file">
             * <input type="file"> tag is hidden.
             * When <v-btn> is clicked, then triggers onClick event to <input> tag.
             * Open file dialog.
             * If file is specified, @change is triggered and uploadLocalPhoto is called.
            -->
            <v-btn
              color="primary"
              block
              @click="$refs.uploadFormLocalFileField.click()"
            >
              お持ちの写真データから
            </v-btn>
            <input
              ref="uploadFormLocalFileField"
              type="file"
              accept=".jpg,.jpeg,image/jpeg"
              style="display: none;"
              @change="uploadLocalPhoto($event)"
            />
            <div v-if="errorMessage" class="caption error--text">
              {{ errorMessage }}
            </div>
          </v-card-text>
        </v-flex>

        <v-divider class="mb1"></v-divider>

        <v-flex md6>
          <v-card-text class="text-xs-center">
            <img
              src="../../../assets/images/illust_site.png"
              alt=""
              width="250"
            />
            <v-btn
              color="primary"
              block
              :to="{ name: 'listFrPurchasedPictures', params: { kidId } }"
            >
              購入した写真から
            </v-btn>
          </v-card-text>
        </v-flex>
      </v-layout>

      <div class="v-card__close">
        <v-icon color="primary" @click="close">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";
import ApiErrors from "../../../lib/lookmee_photo/ApiErrors";

import FrProcessingDialog from "./FrProcessingDialog";

import { SOURCE_TYPE_LOCAL } from "../../../models/FaceRegistration";

const MAXIMUM_UPLOAD_FILE_SIZE = 8 * 1024 * 1024; // 8MB

const POLLING_TRY_LIMIT = 20;
const POLLING_INTERVAL = 1000;

export default {
  components: {
    "fr-processing-dialog": FrProcessingDialog
  },
  mixins: [ApiErrorHandler],
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    kidId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errorMessage: null
    };
  },
  computed: {
    mutableDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        if (!val) {
          this.close();
        }
      }
    }
  },
  methods: {
    async uploadLocalPhoto(event) {
      if (this.submitting) {
        return;
      }

      this.errorMessage = null;
      const file = event.target.files[0];
      if (!file) {
        this.errorMessage = "ファイルを選択してください。";
        return;
      }
      if (file.size > MAXIMUM_UPLOAD_FILE_SIZE) {
        this.errorMessage =
          "ファイルサイズが8MBを超えています。8MB以内のファイルを選択してください。";
        return;
      }
      if (file.type !== "image/jpeg") {
        this.errorMessage =
          "JPEG形式以外の画像が指定されています。JPEG形式の画像を選択してください。";
        return;
      }

      let faceRegistration = null;
      this.submitting = true;
      try {
        faceRegistration = await this.createFaceRegistration(this.kidId);

        await this.uploadFileToS3(faceRegistration.uploadUrl, file);

        faceRegistration = await this.detectFacesAsync(faceRegistration.id);

        await new Promise(resolve => setTimeout(resolve, 2000));

        faceRegistration = await this.pollingUntilFaceDetectionFinished(
          faceRegistration.id
        );

        const faceDetectionResult = faceRegistration.faceDetectionResult;
        if (!faceDetectionResult || !faceDetectionResult.hasDetectedFaces()) {
          throw "選択した写真の中にお顔が検出されませんでした。別の写真を選択してください。";
        }

        this.$router.push({
          name: "selectFrKidFace",
          params: { kidId: this.kidId, faceRegistrationId: faceRegistration.id }
        });
      } catch (error) {
        if (typeof error === "string" || error instanceof String) {
          this.errorMessage = error;
        } else if (error instanceof ApiErrors) {
          this.handleApiErrors(error, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errorMessage = error.message;
        }

        if (faceRegistration) {
          // LOOKME-11545 失敗した顔登録の画像ファイルが何なのか調査するため、下記エラーのた場合はface_registrationを削除をするのをやめる。
          if (
            this.errorMessage !==
            "画像処理中にエラーが発生しました。お手数ですが、別の写真を選択してください。"
          ) {
            this.$store.dispatch("fr/deleteFaceRegistration", {
              id: faceRegistration.id
            });
          }
        }
      } finally {
        this.$refs.uploadFormLocalFileField.value = "";
        this.submitting = false;
      }
    },
    async createFaceRegistration(kidId) {
      try {
        return await this.$store.dispatch("fr/createFaceRegistration", {
          kidId,
          sourceType: SOURCE_TYPE_LOCAL
        });
      } catch (errors) {
        if (errors.status === 400) {
          throw errors.getErrorMessage();
        } else {
          throw errors;
        }
      }
    },
    async uploadFileToS3(uploadUrl, file) {
      try {
        await fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-type": "image/jpeg"
          }
        });
      } catch (error) {
        if (this.sentry) {
          this.sentry.captureException(error);
        }
        throw "画像アップロード中にエラーが発生しました。お手数ですが、再度写真を選択してください。";
      }
    },
    async detectFacesAsync(faceRegistrationId) {
      try {
        return await this.$store.dispatch(
          "fr/startFaceDetectionFaceRegistration",
          { id: faceRegistrationId }
        );
      } catch (errors) {
        if (this.sentry) {
          this.sentry.captureMessage(errors.getErrorMessage());
        }
        throw "顔検出中にシステムエラーが発生しました。お手数ですが、再度写真を選択してください。";
      }
    },
    async pollingUntilFaceDetectionFinished(faceRegistrationId) {
      for (let i = 0; i < POLLING_TRY_LIMIT; i++) {
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));

        const faceRegistration = await this.$store.dispatch(
          "fr/getFaceRegistration",
          {
            id: faceRegistrationId
          }
        );

        if (faceRegistration.isFaceDetectionFinished()) {
          return faceRegistration;
        } else if (faceRegistration.isFailed()) {
          throw faceRegistration.errorMessage;
        }
      }

      throw "顔検出がタイムアウトしました。お手数ですが、再度写真を選択してください。";
    },
    close() {
      this.errorMessage = null;
      this.$emit("close");
    }
  }
};
</script>
