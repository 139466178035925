<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">face</v-icon>
        <br />顔認識機能
      </h1>

      <v-card>
        <div class="py-4 px-2">
          <p class="px-3">
            ルクミーフォトでは顔認識機能をご利用いただけます。お子さまのお顔が写った写真を登録すると、
            <span class="font-weight-bold">自動検出した写真を閲覧</span
            >することができます。
          </p>
          <p>
            <router-link :to="{ name: 'aboutFr' }">
              <img
                src="../../assets/images/bnr_fr_first.png"
                alt="顔認識機能のご案内"
              />
            </router-link>
          </p>
        </div>

        <section>
          <h2 class="title-bar">顔写真を登録するお子さま</h2>

          <div class="pa-3">
            <v-radio-group v-model="selectedKidId">
              <v-radio
                v-for="kid in kids"
                :key="kid.uniqueKey"
                :label="kid.name"
                :value="kid.id"
              >
              </v-radio>
            </v-radio-group>
          </div>
        </section>

        <v-layout justify-center row pb-4>
          <v-btn
            class="v-btn--minwidth"
            color="primary"
            :disabled="!kidIds.has(selectedKidId)"
            :to="{ name: 'listFrKidFaces', params: { kidId: selectedKidId } }"
          >
            登録・変更する
          </v-btn>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      selectedKidId: 0
    };
  },
  computed: {
    ...mapState("family", {
      kids: state => state.kids
    }),
    ...mapGetters({ kidIds: "family/kidIds" })
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      try {
        await this.$store.dispatch("family/fetchFamily");

        if (this.kids.length > 0) {
          this.selectedKidId = this.kids[0].id;
        }
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    }
  }
};
</script>
