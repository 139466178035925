import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { OrganizationState } from "./types";
import Organization from "../../models/Organization";
import Group from "../../models/Group";
import SalesManagement from "../../models/SalesManagement";
import Cart from "../../models/Cart";

export const actions: ActionTree<OrganizationState, RootState> = {
  async fetchOrganization(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number }
  ): Promise<Organization> {
    const organization = await context.rootState.apiClient.getOrganization(
      payload.organizationId
    );
    context.commit("setOrganization", organization);
    return organization;
  },

  async pagesSalesManagement(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number; salesManagementId: number }
  ): Promise<{
    organization: Organization;
    groups: Array<Group>;
    salesManagement: SalesManagement;
    cart: Cart;
    purchasedPictureIds: Set<number>;
    purchasedVideoIds: Set<number>;
  }> {
    const result = await context.rootState.apiClient.pagesSalesManagement(
      payload.organizationId,
      payload.salesManagementId
    );
    context.commit("setOrganization", result.organization);
    return result;
  },

  async pagesSalesManagementSelectPhotoBookType(
    context: { commit: Commit; rootState: RootState },
    payload: { organizationId: number; salesManagementId: number }
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
  }> {
    const result = await context.rootState.apiClient.pagesSalesManagementSelectPhotoBookType(
      payload.organizationId,
      payload.salesManagementId
    );
    context.commit("setOrganization", result.organization);
    return result;
  }
};
