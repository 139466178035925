<template>
  <div>
    <v-select
      :items="groups"
      item-text="name_with_year"
      item-value="id"
      :value="groupId"
      label="クラス選択"
      no-data-text="選択可能なクラスがありません。"
      outline
      @change="$emit('change-group', $event)"
    ></v-select>

    <v-select
      :items="events"
      item-text="eventNameWithCount"
      item-value="id"
      :value="eventId"
      label="イベントを選択してください"
      no-data-text="選択可能なイベントがありません。"
      clearable
      outline
      @change="$emit('change-event', $event)"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true
    },
    groupId: {
      type: Number,
      default: undefined
    },
    events: {
      type: Array,
      required: true
    },
    eventId: {
      type: [Number, String],
      default: undefined
    }
  }
};
</script>
