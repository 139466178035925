import { MAIL_ADDRESS_PATTERN } from "../data/constants";

class UserRegistrationForm {
  valid: boolean;
  email: string;
  termsAgreed: boolean;
  privacyPolicyAgreed: boolean;

  constructor(data: {
    email: string;
    termsAgreed: boolean;
    privacyPolicyAgreed: boolean;
  }) {
    this.valid = true;
    this.email = data.email || "";
    this.termsAgreed = data.termsAgreed || false;
    this.privacyPolicyAgreed = data.privacyPolicyAgreed || false;
  }

  emailRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "メールアドレスを入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "メールアドレスは255文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(MAIL_ADDRESS_PATTERN)) ||
        "メールアドレスの書式が正しくありません。"
    ];
  }

  termsAgreedRules(): Array<Function> {
    return [
      (v?: boolean) => !!v || "利用規約に同意しますにチェックしてください。"
    ];
  }

  privacyPolicyAgreedRules(): Array<Function> {
    return [
      (v?: boolean) =>
        !!v || "個人情報の取扱いに同意しますにチェックしてください。"
    ];
  }
}

export default UserRegistrationForm;
