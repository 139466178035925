import { escapeHtml } from "../lib/utils/string";

class Event {
  id: number;
  groupId: number;
  eventName: string;
  comment: string | null;
  takenDate: string;
  eventType: string;
  isToday: boolean;
  isAutoclip: boolean;
  picturesCount?: number;
  videosCount?: number;

  constructor(data: {
    id: number;
    groupId: number;
    eventName: string;
    comment: string | null;
    takenDate: string;
    eventType: string;
    isToday: boolean;
    isAutoclip: boolean;
    picturesCount?: number;
    videosCount?: number;
  }) {
    this.id = data.id;
    this.groupId = data.groupId;
    this.eventName = data.eventName;
    this.comment = data.comment;
    this.takenDate = data.takenDate;
    this.eventType = data.eventType;
    this.isToday = data.isToday;
    this.isAutoclip = data.isAutoclip;
    this.picturesCount = data.picturesCount;
    this.videosCount = data.videosCount;
  }

  get itemCount(): number {
    switch (this.eventType) {
      case "picture":
        return this.picturesCount || 0;
      case "video":
        return this.videosCount || 0;
      default:
        return 0;
    }
  }

  get unitLabel(): string {
    switch (this.eventType) {
      case "picture":
        return "枚";
      case "video":
        return "本";
      default:
        return "";
    }
  }

  get eventNameWithCount(): string {
    return `${this.eventName}（${this.itemCount} ${this.unitLabel}）`;
  }

  get commentForHtml(): string {
    if (!this.comment) {
      return "";
    }

    return escapeHtml(this.comment)
      .split(/\r?\n/)
      .join("<br>");
  }

  get isPicture(): boolean {
    return this.eventType === "picture";
  }

  get isVideo(): boolean {
    return this.eventType === "video";
  }

  get isTodayOrAutoclip(): boolean {
    return this.isToday || this.isAutoclip;
  }
}

export default Event;
