import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { KarteState } from "./types";
import { RootState } from "../types";

const initialState: KarteState = {
  isFrUsed: undefined,
  previousFrRegisteredDate: undefined,
  isFamilyUserAddressExisting: undefined
};

export const karte: Module<KarteState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
