var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('keep-alive',[_c(_vm.currentSubPage,_vm._b({tag:"component"},'component',{
      organizationId: _vm.organizationId,
      salesManagementId: _vm.salesManagementId,
      salesManagement: _vm.salesManagement,
      pictures: _vm.pictures,
      videos: _vm.videos,
      photoBookPictures: _vm.photoBookPictures,
      purchasedPictureIds: _vm.purchasedPictureIds,
      purchasedVideoIds: _vm.purchasedVideoIds
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }