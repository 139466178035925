<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />世帯情報
      </h1>

      <v-card v-if="family">
        <v-card-title>世帯名</v-card-title>

        <div class="pa-3">
          {{ family.name }}
        </div>

        <v-card-title>電話番号</v-card-title>

        <div v-for="(phone, i) in family.mobilePhones" :key="phone.uniqueKey">
          <div class="pa-3">
            {{ phone.phoneNumber }}
          </div>
          <v-divider
            v-if="i !== family.mobilePhones.length - 1"
            class="mx-3"
          ></v-divider>
        </div>

        <section class="mt-3 pb-4">
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="primary"
              :to="{ name: 'editFamily' }"
            >
              世帯電話番号の変更
            </v-btn>
          </v-layout>
        </section>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      family: null
    };
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.family = await this.$store.dispatch("family/fetchFamily");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
