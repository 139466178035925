<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <i class="fas fa-book"></i><br />フォトブックの作成
      </h1>

      <v-card>
        <v-card-text>
          <ul class="list-indent">
            <li class="caption">
              ※ お届けまで決済完了後１ヶ月程度お時間をいただきます。
            </li>
            <li class="caption">※ 写真と一緒に注文することは出来ません。</li>
          </ul>
        </v-card-text>

        <section v-if="salesManagement">
          <h2 class="title-bar">フォトブックの種類を選択</h2>

          <div class="pa-3">
            <v-radio-group v-model="selectedMediaType">
              <template v-for="price in salesManagement.prices">
                <v-radio
                  :key="price.uniqueKey"
                  :value="price.mediaType.value"
                  :label="price.mediaType.label"
                >
                </v-radio>
                <p :key="'p-' + price.uniqueKey" class="body-1 ml-4 pl-2">
                  ページ数：{{ price.photoBookPageNum }}ページ<br />
                  写真選択枚数：{{ price.photoBookMaxPictureCount }}枚まで<br />
                  価格：{{ price.price }}円
                </p>
              </template>
            </v-radio-group>
          </div>

          <div v-if="errors.length > 0" class="ma-3 caption error--text">
            <ul class="px-3">
              <li v-for="err in errors" :key="err.message">
                {{ err.message }}
              </li>
            </ul>
          </div>

          <div class="pb-4">
            <v-layout justify-center row>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
                @click="setPhotoBookType"
              >
                作成する
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                :disabled="submitting"
                class="v-btn--minwidth"
                color="negative"
                :to="{ name: 'organizationTop', params: { organizationId } }"
              >
                戻る
              </v-btn>
            </v-layout>
          </div>
        </section>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      salesManagement: null,
      selectedMediaType: null,
      submitting: false,
      errors: []
    };
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.$store.dispatch("startLoading");
      try {
        const { salesManagement, cart } = await this.$store.dispatch(
          "organization/pagesSalesManagementSelectPhotoBookType",
          {
            organizationId,
            salesManagementId
          }
        );
        // TODO: This operation should be done in Vuex actions.
        this.$store.commit("cart/setCart", cart);

        this.salesManagement = salesManagement;
        this.selectedMediaType =
          cart.photoBook.mediaType || this.firstPhotoBookType();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async setPhotoBookType() {
      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.errors = [];
      this.submitting = true;
      try {
        await this.$store.dispatch("cart/updateCartPhotoBook", {
          mediaType: this.selectedMediaType
        });

        this.$router.push({
          name: "organizationMain",
          params: { organizationId, salesManagementId }
        });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    firstPhotoBookType() {
      const prices = this.salesManagement.prices;
      return prices.length > 0 ? prices[0].mediaType.value : null;
    }
  }
};
</script>
