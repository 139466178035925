<template>
  <v-dialog v-model="show" fullscreen hide-overlay>
    <v-card>
      <header
        class="header"
        :style="{ 'border-color': $vuetify.theme.secondary }"
      >
        <img
          src="../../assets/images/title.svg"
          alt="ルクミーフォト"
          width="auto"
          height="36px"
        />
      </header>

      <div v-if="agreement" class="content-container">
        <div class="content-inner">
          <p>
            <b>新しい{{ agreement.title }}への同意が必要です</b>
          </p>

          <p>
            {{ agreement.title }}が変更になりました。<br />
            ルクミーフォトをご利用いただくためには、最新の{{
              agreement.title
            }}への同意が必要となります。<br />
            以下の内容を確認し、「{{
              agreement.title
            }}に同意する」ボタンを押して同意してください。<br />
          </p>

          <iframe class="content-iframe" :src="contentUrl" frameborder="0" />

          <div class="btn-container">
            <v-btn
              color="primary"
              :disabled="submitting"
              :loading="submitting"
              @click="agree()"
            >
              {{ agreement.title }}に同意する
            </v-btn>
            <a id="logout-button" href="#" @click="onClickLogoutMenu">
              同意せず、ログインを中止する
            </a>
          </div>

          <div class="leave-btn-wrap">
            <router-link :to="{ name: 'withdrawalConfirmation' }">
              同意せず、退会する
            </router-link>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import AgreementVersion from "../../models/AgreementVersion";

export default {
  mixins: [ApiErrorHandler],
  props: {
    agreement: {
      type: AgreementVersion,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    contentUrl() {
      if (this.agreement.category === "user_terms") {
        return this.agreement.url + "?header=no";
      } else if (this.agreement.category === "user_privacy_policy") {
        return this.agreement.url + "?webview=true";
      } else {
        return this.agreement.url;
      }
    }
  },
  methods: {
    async agree() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        await this.apiClient.agreeTermsAndPolicy({
          id: this.agreement.id,
          category: this.agreement.category
        });

        this.$emit("close-dialog");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
      }
    },
    onClickLogoutMenu() {
      if (!confirm("ログアウトしますか？")) {
        return;
      }

      this.logout();
    },
    async logout() {
      try {
        await this.apiClient.logout();
        window.location.href = "/";
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    }
  }
};
</script>

<style scoped>
.header {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0 0 5px 0;
}

.content-container {
  padding: 44px 20px;
  display: flex;
  justify-content: center;
}

.content-inner {
  width: 860px;
}
@media (max-width: 600px) {
  .content-inner {
    width: 100%;
  }
}

.content-iframe {
  width: 100%;
  height: 375px;
  margin-bottom: 24px;
  border-radius: 10px;
  border: 2px solid #dddddd;
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  gap: 24px;
  align-items: center;
}
.v-btn {
  width: 260px;
}
@media (max-width: 600px) {
  .btn-container {
    flex-direction: column;
  }
  .v-btn {
    width: 100%;
  }
}

.leave-btn-wrap {
  display: flex;
  justify-content: center;
}
</style>
