import { ActionTree } from "vuex";
import { RootState } from "../types";
import { kidState } from "./types";
import Kid from "../../models/Kid";
import Organization from "../../models/Organization";
import Group from "../../models/Group";
import KidsBelong from "../../models/KidsBelong";

export const actions: ActionTree<kidState, RootState> = {
  async getKids(context: {
    rootState: RootState;
  }): Promise<{
    kids: Array<Kid>;
    kidsBelongs: Array<KidsBelong>;
    groups: Array<Group>;
    organizations: Array<Organization>;
  }> {
    return await context.rootState.apiClient.getKids();
  },

  async getKid(
    context: { rootState: RootState },
    payload: { kidId: number }
  ): Promise<{
    kid: Kid;
    kidsBelongs: Array<KidsBelong>;
    groups: Array<Group>;
    organizations: Array<Organization>;
  }> {
    return await context.rootState.apiClient.getKid(payload.kidId);
  },

  async registerKid(
    context: {
      rootState: RootState;
    },
    payload: {
      issuedCode: string;
      groupId: number;
      kidName: string;
      kidBirthDate: string;
    }
  ): Promise<Kid> {
    return await context.rootState.apiClient.registerKid(payload);
  },

  async transferKid(
    context: {
      rootState: RootState;
    },
    payload: {
      issuedCode: string;
      groupId: number;
      kidId: number;
      kidBirthDate: string;
    }
  ): Promise<Kid> {
    return await context.rootState.apiClient.transferKid(payload);
  }
};
