import { padding } from "../lib/utils/string";

class Video {
  id: number;
  year: number;
  fileNo: number;
  takenDatetime: Date | null;
  length: number | null;
  mediaType: number;
  thumbnailUrl: string | null;
  hlsFileUrl: string | null;

  constructor(data: {
    id: number;
    year: number;
    fileNo: number;
    takenDatetime: string | null;
    length: number | null;
    mediaType: number;
    thumbnailUrl: string | null;
    hlsFileUrl: string | null;
  }) {
    this.id = data.id;
    this.year = data.year;
    this.fileNo = data.fileNo;
    this.takenDatetime =
      data.takenDatetime !== null ? new Date(data.takenDatetime) : null;
    this.length = data.length;
    this.mediaType = data.mediaType;
    this.thumbnailUrl = data.thumbnailUrl;
    this.hlsFileUrl = data.hlsFileUrl;
  }

  get uniqueKey(): string {
    return `video-${this.id}`;
  }

  get formattedPlayTime(): string {
    if (!this.length) {
      return "";
    }

    const minutes = Math.floor(this.length / 60);
    const seconds = this.length % 60;
    return `${minutes}:${padding(seconds, 2, "0")}`;
  }

  hasFace(): boolean {
    return false;
  }
}

export default Video;
