<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentSubPage"
          v-bind="{ form }"
          @back-to-input="$router.push({ hash: '#input' })"
          @forward-to-confirm="$router.push({ hash: '#confirm' })"
          @forward-to-complete="$router.push({ hash: '#complete' })"
        >
        </component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import InputSubPage from "./components/InputSubPage";
import ConfirmSubPage from "./components/ConfirmSubPage";
import CompleteSubPage from "./components/CompleteSubPage";

import UserAddressForm from "../../forms/UserAddressForm";

export default {
  data() {
    return {
      form: new UserAddressForm({})
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#input":
          return InputSubPage;
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        default:
          return null;
      }
    }
  },
  created() {
    this.$router.replace({ hash: "#input" });
  }
};
</script>
