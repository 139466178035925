import * as dayjs from "dayjs";

class AgreementVersion {
  readonly id: number;
  readonly category: string;
  readonly url: string;
  readonly displayableRequiredBeginOn: string | null;
  readonly canSkip: boolean = false;

  constructor(agreementVersion: {
    id: number;
    category: string;
    url: string;
    displayableRequiredBeginOn: string | null;
    canSkip: boolean;
  }) {
    this.id = agreementVersion.id;
    this.category = agreementVersion.category;
    this.url = agreementVersion.url;
    this.displayableRequiredBeginOn =
      agreementVersion.displayableRequiredBeginOn;
    this.canSkip = agreementVersion.canSkip;
  }

  get title(): string {
    if (this.category === "user_terms") {
      return "利用規約";
    } else if (this.category === "user_privacy_policy") {
      return "個人情報の取り扱い";
    } else {
      return "";
    }
  }

  get dueDate(): string | null {
    if (!this.displayableRequiredBeginOn) {
      return null;
    }
    const dayjsDate = dayjs(this.displayableRequiredBeginOn);
    return dayjsDate.format("YYYY年MM月DD日");
  }
}

export default AgreementVersion;
