<template>
  <v-flex xs4 md3 mb-3>
    <square-picture-frame :picture="detail.picture" hide-zoom-icon no-cursor>
    </square-picture-frame>

    <table class="table-vertical">
      <tr>
        <td>{{ detail.quantity }}枚</td>
        <td class="text-xs-right id-num">{{ detail.picture.fileNo }}</td>
      </tr>
    </table>
  </v-flex>
</template>

<script>
import OrderDetail from "../../../models/OrderDetail";

import SquarePictureFrame from "../../../components/SquarePictureFrame";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame
  },
  props: {
    detail: {
      type: OrderDetail,
      required: true
    }
  }
};
</script>
