<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />パスワードの設定
      </h1>

      <v-card>
        <v-card-text>
          <v-form ref="form" lazy-validation @submit.prevent="submitForm">
            <section>
              <h2 class="body-1">現在のパスワード</h2>
              <v-text-field
                v-model="currentPassword"
                type="password"
                single-line
                outline
                validate-on-blur
                required
                :rules="form.currentPasswordRules()"
              ></v-text-field>

              <p class="caption">
                パスワードを忘れた場合は
                <a href="/site/password_resets/new" target="_blank">こちら</a>
              </p>
            </section>

            <v-divider class="my-4"></v-divider>

            <section>
              <h2 class="body-1">新しいパスワード</h2>
              <p class="caption error--text mb-1">
                ※8桁以上の半角英数字で入力してください。
              </p>
              <v-text-field
                v-model="newPassword"
                type="password"
                single-line
                outline
                validate-on-blur
                required
                :rules="form.newPasswordRules()"
              ></v-text-field>
            </section>

            <section>
              <h2 class="body-1">新しいパスワード（確認）</h2>
              <v-text-field
                v-model="newPasswordConfirmation"
                type="password"
                single-line
                outline
                validate-on-blur
                required
                :rules="form.newPasswordConfirmationRules()"
              ></v-text-field>
            </section>

            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-layout justify-center row>
              <v-btn class="v-btn--minwidth" type="submit" color="primary">
                設定を完了する
              </v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import PasswordChangeForm from "../../forms/PasswordChangeForm";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      form: new PasswordChangeForm(),
      submitting: false,
      errors: []
    };
  },
  computed: {
    currentPassword: {
      get() {
        return this.form.currentPassword;
      },
      set(val) {
        this.form.currentPassword = val;
      }
    },
    newPassword: {
      get() {
        return this.form.newPassword;
      },
      set(val) {
        this.form.newPassword = val;
      }
    },
    newPasswordConfirmation: {
      get() {
        return this.form.newPasswordConfirmation;
      },
      set(val) {
        this.form.newPasswordConfirmation = val;
      }
    }
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("changePassword", this.form.toParams());

        this.$router.push({ name: "completedPasswordChange" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
