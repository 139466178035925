<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8>
      <v-card elevation-1>
        <v-card-text>
          <p>
            エラーが発生しました。<br />
            下記エラーの原因をご確認ください。
          </p>

          <ul>
            <li v-for="(msg, i) in errorMessages" :key="`error-${i}`">
              {{ msg }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  computed: {
    errorMessages() {
      return this.$store.state.errorMessages || [];
    }
  },
  destroyed() {
    this.$store.dispatch("clearErrorMessages");
  }
};
</script>
