<template>
  <div class="overlay">
    <v-layout
      row
      wrap
      align-center
      align-content-center
      justify-center
      class="video-wrapper"
    >
      <v-flex xs12 text-xs-right class="icon-wrapper">
        <v-icon color="white" class="pa-2" @click="close">
          far fa-times-circle
        </v-icon>
      </v-flex>

      <v-flex xs12 text-xs-center>
        <div ref="player" class="fp-slim"></div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Video from "../models/Video";

export default {
  props: {
    video: {
      type: Video,
      required: true
    }
  },
  computed: {
    ...mapState({
      flowplayerToken: "flowplayerToken"
    }),
    playerOptions() {
      return {
        src: this.video.hlsFileUrl,
        autoplay: true,
        hlsjs: {
          native: true
        },
        poster: this.video.thumbnailUrl,
        token: this.flowplayerToken
      };
    }
  },
  mounted() {
    this.flowplayer(this.$refs.player, this.playerOptions);

    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    handleKeyUp(event) {
      switch (event.keyCode) {
        case 27: // ESC
          this.close();
          break;
        default:
        // nothing to do
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style>
.flowplayer {
  max-width: 800px;
  max-height: calc(
    100vh - 45px
  ) !important; /* 閉じるアイコン(45px)分をマイナス */
}
</style>

<style scoped>
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1001;
}

.video-wrapper {
  width: 100%;
  height: 100%;
}

.icon-wrapper {
  max-width: 800px;
}
</style>
