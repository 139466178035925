<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">email</v-icon>
        <br />仮登録状態のメールアドレスがあります
      </h1>
      <v-card>
        <v-card-text>
          <p>
            以下のアドレスは、以下の想定される要因のため、「ルクミー」にてメール配信が確認できていません。ご確認いただき、状況に応じた対応をお願いたします。
          </p>
          <p class="font-weight-bold">
            ①迷惑メール防止設定による受信拒否<br />
            →
            <router-link
              :to="{
                name: 'staticMailNotReceived',
                query: { backTo: 'listInactivatedEmails' }
              }"
              >ドメイン</router-link
            >指定受信方法 をご確認ください。
          </p>
          <p class="font-weight-bold">
            ②ルクミーからのメールに記載されたURLをクリックしていない<br />
            →URLをクリックしてください。
          </p>
          <p class="red--text mt-3">
            上記をご確認の上、「確認メールの送信」ボタンを押してください。
          </p>
        </v-card-text>
        <v-card-title>仮登録状態のメールアドレス</v-card-title>
        <v-card-text>
          <template v-if="!user.isActivated">
            <p>
              {{ user.email }}
            </p>

            <p class="caption">
              ※「確認メールの送信」ボタンを押すと、ルクミーからURLが記載されたメールが届きます。
              URLをクリックして登録が完了となります。
            </p>

            <div class="px-3 caption error--text">
              <ul
                v-for="err in errors"
                v-show="errors.length > 0"
                :key="err.message"
              >
                <li>{{ err.fullMessage }}</li>
              </ul>
            </div>

            <v-layout justify-center row mt-5>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
                @click="sendActivationEmail"
              >
                確認メールの送信
              </v-btn>
            </v-layout>
          </template>

          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :disabled="submitting"
              :to="{ name: 'listUserEmergencies' }"
            >
              戻る
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { mapState } from "vuex";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  computed: {
    ...mapState("user", {
      user: "user"
    })
  },
  methods: {
    async sendActivationEmail() {
      this.submitting = true;
      this.errors = [];
      try {
        await this.$store.dispatch("user/sendActivationEmail");
        this.$router.push({ name: "sendActivateMailsCompleted" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
