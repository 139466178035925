<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />お問い合わせ
      </h1>
      <v-card>
        <v-card-text>
          <p>初回登録用コードを確認しました</p>
          <p>ご入力いただいた初回登録用コードは有効期限が切れています。</p>
          <p>
            お手数ですが、園・施設から新しい初回登録用コードをお受け取りください。
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
