<template>
  <keep-alive>
    <component
      :is="currentSubPage"
      v-bind="{
        organizationId,
        salesManagementId,
        groupId,
        eventId,
        page,
        salesManagement,
        groups,
        purchasedPictureIds,
        purchasedVideoIds
      }"
    >
    </component>
  </keep-alive>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import { SALES_TYPES } from "../../../data/constants";

import PhotoAndVideoSubPage from "./Show/PhotoAndVideo";
import PhotoBookSubPage from "./Show/PhotoBook";

export default {
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    groupId: {
      type: Number,
      default: undefined
    },
    eventId: {
      type: [Number, String],
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      salesManagement: null,
      groups: [],
      purchasedPictureIds: new Set(),
      purchasedVideoIds: new Set()
    };
  },
  computed: {
    currentSubPage() {
      switch (this.salesManagement && this.salesManagement.salesType.value) {
        case SALES_TYPES.photoAndVideo:
          return PhotoAndVideoSubPage;
        case SALES_TYPES.photoBook:
          return PhotoBookSubPage;
        default:
          return null;
      }
    }
  },
  async created() {
    const checkVersionResult = await this.$store.dispatch("checkVersion");
    if (!checkVersionResult) {
      this.$store.dispatch("showRequireRefreshDialog");
      return;
    }

    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.$store.dispatch("startLoading");
      try {
        const {
          groups,
          salesManagement,
          cart,
          purchasedPictureIds,
          purchasedVideoIds
        } = await this.$store.dispatch("organization/pagesSalesManagement", {
          organizationId,
          salesManagementId
        });
        // TODO: This operation should be done in Vuex actions.
        this.$store.commit("cart/setCart", cart);

        this.groups = groups;
        this.salesManagement = salesManagement;
        this.purchasedPictureIds = purchasedPictureIds;
        this.purchasedVideoIds = purchasedVideoIds;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>

<style>
.picture-gallery-dialog {
  max-height: 98% !important;
}
</style>
