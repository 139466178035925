<template>
  <div class="text-xs-center">
    <v-dialog v-model="showDialog" width="400">
      <v-card>
        <v-card-text class="text-xs-center">
          <p>
            エラーが発生しました。
          </p>

          <template v-if="errorMessages">
            <template v-if="errorMessages.length > 1">
              <ul>
                <li v-for="(msg, i) in errorMessages" :key="`error-${i}`">
                  {{ msg }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>
                {{ errorMessages[0] }}
              </p>
            </template>
          </template>
        </v-card-text>

        <v-card-text class="text-xs-center">
          <v-btn color="primary" @click.native="showDialog = false">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    showDialog: {
      get() {
        return !!this.value;
      },
      set(val) {
        this.$emit("input", val ? val : null);
      }
    }
  }
};
</script>
