<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">help_outline</v-icon>
        <br />入力内容の確認
      </h1>

      <template v-if="isSaleResale">
        <sale-resale-category></sale-resale-category>
      </template>

      <template v-else>
        <section>
          <h2 class="title-bar">お名前</h2>
          <v-layout pa-3>
            <v-flex>
              {{ form.name }}
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">メールアドレス</h2>
          <v-layout pa-3>
            <v-flex>
              {{ form.email }}
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">電話番号　※半角</h2>
          <v-layout pa-3>
            <v-flex>
              {{ form.tel }}
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">園・施設</h2>
          <div class="pa-3">
            {{ form.organizationName }}
          </div>
        </section>

        <section>
          <h2 class="title-bar">お問い合わせ分類</h2>
          <div class="pa-3">
            <template v-if="isRegExpireCode">
              登録用コード期限切れ
            </template>
            <template v-if="isOtherInquiry">
              ご意見・その他
            </template>
            <template v-if="isSaleResale">
              販売終了・再販予定について
            </template>
          </div>
        </section>

        <section v-if="isRegExpireCode">
          <h2 class="title-bar">期限切れコードの入力</h2>
          <div class="pa-3">
            {{ form.issuedCode1 }}-{{ form.issuedCode2 }}-{{ form.issuedCode3 }}
          </div>
        </section>

        <section v-if="isOtherInquiry">
          <h2 class="title-bar">ご意見・お問い合わせ内容</h2>
          <div class="pa-3">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="form.text"></span>
          </div>
        </section>

        <section v-if="errors.length > 0" class="py-3">
          <v-layout justify-center="center" row>
            <div class="caption error--text">
              <ul v-for="err in errors" :key="err.message">
                <li>{{ err.message }}</li>
              </ul>
            </div>
          </v-layout>
        </section>

        <section class="py-3">
          <v-layout justify-center row>
            <v-btn
              type="submit"
              class="v-btn--minwidth"
              color="primary"
              :loading="submitting"
              :disabled="submitting"
              @click="submitForm"
            >
              送信する
            </v-btn>
          </v-layout>
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :to="{ hash: '' }"
              :disabled="submitting"
            >
              戻る
            </v-btn>
          </v-layout>
        </section>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";
import InquiryForm from "../../../forms/InquiryForm";
import SaleResaleCategory from "../components/SaleResaleCategory";
import {
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_USED,
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_EXPIRED
} from "../../../lib/lookmee_photo/ApiErrorCodes";

export default {
  components: {
    "sale-resale-category": SaleResaleCategory
  },
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: InquiryForm,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      submitting: false
    };
  },
  computed: {
    isRegExpireCode() {
      return this.form.category === "0";
    },
    isOtherInquiry() {
      return this.form.category === "1";
    },
    isSaleResale() {
      return this.form.category === "2";
    }
  },
  methods: {
    async submitForm() {
      if (this.isRegExpireCode) {
        this.submitting = true;
        this.$store.dispatch("startLoading");

        try {
          await this.$store.dispatch("inquiry/verifyIssuedFamily", {
            code: this.form.issuedCode()
          });
          this.createInquiry();
        } catch (errors) {
          if (errors.status === 400) {
            switch (errors.getErrorCode()) {
              case API_ERROR_CODE_VALIDATION_ISSUED_CODE_NOT_FOUND:
                this.errors = errors["errors"];
                break;
              case API_ERROR_CODE_VALIDATION_ISSUED_CODE_USED:
                this.createInquiry();
                break;
              case API_ERROR_CODE_VALIDATION_ISSUED_CODE_EXPIRED:
                this.$router.push({ hash: "complete-expired-code" });
                break;
              default:
                this.handleApiErrors(errors, {
                  store: this.$store,
                  router: this.$router,
                  sentry: this.sentry
                });
            }
          } else {
            this.handleApiErrors(errors, {
              store: this.$store,
              router: this.$router,
              sentry: this.sentry
            });
          }
        } finally {
          this.submitting = false;
          this.$store.dispatch("endLoading");
        }
      } else if (this.isOtherInquiry) {
        this.createInquiry();
      }
    },
    async createInquiry() {
      this.resetErrors();
      this.submitting = true;
      this.$store.dispatch("startLoading");

      try {
        await this.$store.dispatch(
          "inquiry/createInquiry",
          this.form.toParams()
        );
        this.$router.push({ hash: "complete" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors["errors"];
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
        this.$store.dispatch("endLoading");
      }
    },
    resetErrors() {
      this.errors = [];
    }
  }
};
</script>
