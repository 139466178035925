<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-text class="text-xs-center">
        設定を解除しますか？
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :disabled="submitting"
          :loading="submitting"
          @click="submitForm()"
        >
          OK
        </v-btn>
        <v-btn color="negative" :disabled="submitting" @click="closeDialog">
          キャンセル
        </v-btn>
      </v-card-actions>
      <div class="v-card__close">
        <v-icon color="primary" @click="closeDialog">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("user/disableEmergency");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>
