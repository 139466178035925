import FaceDetectionResult from "./FaceDetectionResult";

export const SOURCE_TYPE_LOCAL = 1;
export const SOURCE_TYPE_PURCHASED = 2;

class FaceRegistration {
  id: number;
  kidId: number;
  status: string;
  sourceType: number;
  uploadUrl: string | null;
  thumbnailBigUrl: string | null;
  faceDetectionResult: FaceDetectionResult | null;
  errorMessage: string | null;

  constructor(data: {
    id: number;
    kidId: number;
    status: string;
    sourceType: number;
    uploadUrl: string | null;
    thumbnailBigUrl: string | null;
    faceDetectionResult: any | null;
    errorMessage: string | null;
  }) {
    this.id = data.id;
    this.kidId = data.kidId;
    this.status = data.status;
    this.sourceType = data.sourceType;
    this.uploadUrl = data.uploadUrl;
    this.thumbnailBigUrl = data.thumbnailBigUrl;
    this.faceDetectionResult = data.faceDetectionResult
      ? new FaceDetectionResult(data.faceDetectionResult)
      : null;
    this.errorMessage = data.errorMessage;
  }

  get uniqueKey(): string {
    return `faceRegistration-${this.id}`;
  }

  isFaceDetectionFinished(): boolean {
    return this.status === "face_detection_finished";
  }

  isFaceRegistrationProcessing(): boolean {
    return this.status === "face_registration_processing";
  }

  isFaceRegistrationFinished(): boolean {
    return this.status === "face_registration_finished";
  }

  isFailed(): boolean {
    return this.status === "failed";
  }
}

export default FaceRegistration;
