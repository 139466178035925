import { ActionTree, Commit } from "vuex";
import { RootState } from "./types";

import UserRegistration from "../models/UserRegistration";
import User from "../models/User";
import Organization from "../models/Organization";
import Group from "../models/Group";
import PasswordReset from "../models/PasswordReset";
import Family from "../models/Family";

// TODO: actionが増えてきたので、サブストアに分離すべきか？
export const actions: ActionTree<RootState, RootState> = {
  startLoading(context: { commit: Commit }): any {
    context.commit("setLoading", true);
  },
  endLoading(context: { commit: Commit }): any {
    context.commit("setLoading", false);
  },
  showRequireLoginDialog(context: { commit: Commit }): any {
    context.commit("setLoginRequired", true);
  },
  showRequireRefreshDialog(context: { commit: Commit }): any {
    context.commit("setRefreshRequired", true);
  },
  openErrorDialog(
    context: { commit: Commit },
    payload: { messages: Array<string> }
  ) {
    context.commit("setErrorDialog", {
      dialog: true,
      messages: payload.messages
    });
  },
  closeErrorDialog(context: { commit: Commit }) {
    context.commit("setErrorDialog", { dialog: false, messages: [] });
  },
  clearErrorMessages(context: { commit: Commit }) {
    context.commit("setErrorMessages");
  },
  toggleDrawer(context: { commit: Commit; state: RootState }) {
    context.commit("setDrawer", !context.state.drawer);
  },
  async checkVersion(context: { state: RootState }): Promise<boolean> {
    const requiredVersion = await context.state.apiClient.getVersion();
    if (!requiredVersion) {
      // 何らかの事情でバージョンが取れない。エラーにすると身動きが取れなくなるかもしれないため、スルーする。
      return true;
    } else if (context.state.clientVersion >= requiredVersion) {
      return true;
    } else {
      console.warn(
        `Client must be updated, clientVersion: ${context.state.clientVersion} < requiredVersion: ${requiredVersion}`
      );
      return false;
    }
  },
  async getUserRegistration(
    context: { state: RootState },
    payload: { token: string }
  ): Promise<UserRegistration> {
    return await context.state.apiClient.getUserRegistration(payload.token);
  },
  async createUserRegistration(
    context: { state: RootState },
    payload: { email: string }
  ): Promise<UserRegistration> {
    return await context.state.apiClient.createUserRegistration(payload.email);
  },
  async registerUser(
    context: { state: RootState },
    payload: {
      token: string;
      issuedCode: string;
      familyName: string;
      kidName: string;
      kidBirthDate: string;
      groupId: number;
      email: string;
      phoneNumber: string;
      password: string;
    }
  ): Promise<User> {
    return await context.state.apiClient.registerUser(payload);
  },
  async changeFamily(
    context: { commit: Commit; state: RootState },
    payload: { familyId: number }
  ): Promise<Family> {
    const family = await context.state.apiClient.changeFamily(payload.familyId);

    context.commit("family/setFamily", family);
    context.commit("cart/clearCart");
    context.commit("organization/clearOrganization");

    return family;
  },
  async verifyIssuedFamily(
    context: { state: RootState },
    payload: { code: string; kidId?: number | null }
  ): Promise<[Organization, Array<Group>]> {
    return await context.state.apiClient.verifyIssuedFamily(payload);
  },
  async sendActivationEmail(context: { state: RootState }): Promise<void> {
    await context.state.apiClient.sendActivationEmail();
  },
  async activateUser(
    context: { state: RootState },
    payload: { token: string }
  ): Promise<void> {
    await context.state.apiClient.activateUser(payload.token);
  },
  async verifyPasswordReset(
    context: { state: RootState },
    payload: { token: string }
  ): Promise<PasswordReset> {
    return await context.state.apiClient.verifyPasswordReset(payload.token);
  },
  async createPasswordReset(
    context: { state: RootState },
    payload: { email: string }
  ): Promise<PasswordReset> {
    return await context.state.apiClient.createPasswordReset(payload.email);
  },
  async doPasswordReset(
    context: { state: RootState },
    payload: { token: string; password: string }
  ): Promise<void> {
    await context.state.apiClient.doPasswordReset(
      payload.token,
      payload.password
    );
  },
  async changePassword(
    context: { state: RootState },
    payload: { currentPassword: string; newPassword: string }
  ): Promise<void> {
    await context.state.apiClient.changePassword(
      payload.currentPassword,
      payload.newPassword
    );
  }
};
