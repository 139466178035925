import User from "../models/User";

import {
  MAIL_ADDRESS_PATTERN,
  PHONE_NUMBER_AVAILABLE_CHARS_PATTERN,
  PHONE_NUMBER_FIXED_PATTERN,
  PHONE_NUMBER_MOBILE_PATTERN
} from "../data/constants";

class UpdateUserForm {
  // NOTE:
  // Now this is only used for changing email addresses.
  // If you have other attributes that you want to change, remove this comment and add it.
  email?: string;
  emergency?: boolean;
  emergencyPhoneNumber?: string | null;

  constructor(private user: User) {
    this.email = user.email;
    this.emergency = user.emergency;
    this.emergencyPhoneNumber = user.emergencyPhoneNumber;
  }

  emailRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "メールアドレスを入力してください。",

      (v?: string) =>
        (!!v && v !== this.user.email) ||
        "現在登録中のメールアドレスが入力されています。",

      (v?: string) =>
        (!!v && v.length <= 255) ||
        "メールアドレスは255文字以内で入力してください。",

      (v?: string) =>
        (!!v && !!v.match(MAIL_ADDRESS_PATTERN)) ||
        "メールアドレスの書式が正しくありません。"
    ];
  }

  emergencyPhoneNumberRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "電話番号を入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PHONE_NUMBER_AVAILABLE_CHARS_PATTERN)) ||
        "電話番号は半角数字とハイフンで入力してください。",
      (v?: string) =>
        (!!v &&
          (!!v.match(PHONE_NUMBER_FIXED_PATTERN) ||
            !!v.match(PHONE_NUMBER_MOBILE_PATTERN))) ||
        "電話番号に誤りがあります。正しい番号が入力されているか確認してください。"
    ];
  }

  toUpdateParams(): { email: string } {
    return { email: this.email || "" };
  }

  emergencyParams(): {
    emergency: number;
    emergencyPhoneNumber: string;
  } {
    return {
      emergency: 1,
      emergencyPhoneNumber: this.emergencyPhoneNumber || ""
    };
  }
}

export default UpdateUserForm;
