class User {
  id: number;
  firstName: string | null;
  firstNameKana: string | null;
  lastName: string | null;
  lastNameKana: string | null;
  email: string;
  isActivated: boolean;
  emergency: boolean;
  emergencyPhoneNumber: string | null;
  createdAt: string;

  constructor(data: {
    id: number;
    firstName: string | null;
    firstNameKana: string | null;
    lastName: string | null;
    lastNameKana: string | null;
    email: string;
    isActivated: boolean;
    emergency: boolean;
    emergencyPhoneNumber: string | null;
    createdAt: string;
  }) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.firstNameKana = data.firstNameKana;
    this.lastName = data.lastName;
    this.lastNameKana = data.lastNameKana;
    this.email = data.email;
    this.isActivated = data.isActivated;
    this.emergency = data.emergency;
    this.emergencyPhoneNumber = data.emergencyPhoneNumber;
    this.createdAt = data.createdAt;
  }
}

export default User;
