import Axios from "axios";
import * as Qs from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";

const axios = Axios.create({
  timeout: 65000,
  baseURL: "/site/api/"
});

// URLパラメーターのキーのcamelCase - snake_case変換、およびQueryString化
axios.defaults.paramsSerializer = (params: any): string => {
  return Qs.stringify(decamelizeKeys(params), { arrayFormat: "brackets" });
};

// クライアントからAPIにPostする際のキーのcamelCase -> snake_case変換
if (axios.defaults.transformRequest instanceof Array) {
  axios.defaults.transformRequest.unshift((data: any): any => {
    switch (typeof data) {
      case "object":
        return decamelizeKeys(data);
      default:
        return data;
    }
  });
}

// APIレスポンスのキーのsnake_case -> camelCase変換
if (axios.defaults.transformResponse instanceof Array) {
  axios.defaults.transformResponse.push((data: any): any => {
    switch (typeof data) {
      case "object":
        return camelizeKeys(data);
      default:
        return data;
    }
  });
}

export default axios;
