class KidsBelong {
  id: number;
  kidId: number;
  groupId: number;

  constructor(data: { id: number; kidId: number; groupId: number }) {
    this.id = data.id;
    this.kidId = data.kidId;
    this.groupId = data.groupId;
  }

  get uniqueKey() {
    return `kids-belong-${this.id}`;
  }
}

export default KidsBelong;
