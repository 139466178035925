import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { OrganizationState } from "./types";
import { RootState } from "../types";

const initialState: OrganizationState = {
  id: undefined,
  uid: undefined,
  name: undefined,
  videoDownloadAvailable: undefined
};

export const organization: Module<OrganizationState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
