<template>
  <div>
    <div class="square-wrapper">
      <div class="square-spacer"></div>
      <div class="square-content">
        <img :src="face.downloadUrl" alt="" />
      </div>
    </div>

    <div>
      <v-btn color="negative" small block @click="$emit('delete', face.uuid)">
        削除する
      </v-btn>
    </div>
  </div>
</template>

<script>
import Face from "../../../models/Face";

export default {
  props: {
    face: {
      type: Face,
      required: true
    }
  }
};
</script>

<style scoped>
.square-wrapper {
  position: relative;
  width: 100%;
  cursor: auto;
}

.square-wrapper .square-spacer {
  width: 100%;
  padding-bottom: 100%;
}

.square-wrapper .square-content {
  max-width: 100%;
}

.square-wrapper .square-content img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
}
</style>
