<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md1 md10>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">save_alt</v-icon>
        <br />スマートフォンへ写真を保存
      </h1>
      <div class="pa-3">
        <p>
          ①写真を<span class="font-weight-bold">2秒ほど長押し</span
          >してください。<br />
          ②画像（イメージ）を保存などの表示をタップしてください。
        </p>
        <img :src="originalPictureUrl" />
      </div>

      <v-layout justify-center row my-4>
        <v-btn
          class="v-btn--minwidth"
          color="negative"
          :to="{ name: 'downloadPictures', params: { orderId: orderId } }"
        >
          戻る
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    orderId: {
      type: Number,
      required: true
    },
    orderDetailId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      originalPictureUrl: ""
    };
  },
  created() {
    this.originalUrl();
  },
  methods: {
    async originalUrl() {
      this.$store.dispatch("startLoading");
      try {
        this.originalPictureUrl = await this.$store.dispatch(
          "orderHistory/getOriginalPictureUrl",
          { orderId: this.orderId, orderDetailId: this.orderDetailId }
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
