<template>
  <div>
    <h2 class="store-title">
      <template v-if="userAddressForm.isNew()">
        新しいお届け先の入力
      </template>
      <template v-else>
        お届け先の編集
      </template>
    </h2>

    <v-form @submit.prevent="submitUserAddressForm">
      <v-container>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              v-model="userAddressForm.fullName"
              label="お名前"
              required
              outline
              hide-details
              :error="errorAttributes.has('fullName')"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 my-2>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-alert
              :value="errorAutoCompleteAddress"
              color="error"
              class="mx-3"
            >
              {{ errorAutoCompleteAddress }}
            </v-alert>
            <v-text-field
              v-model="userAddressForm.postcode"
              label="郵便番号"
              required
              outline
              hide-details
              :error="errorAttributes.has('postcode')"
              @change="onChangePostcode"
            >
              <v-btn
                slot="append-outer"
                style="top: -12px;"
                color="primary"
                :disabled="
                  !userAddressForm.isValidPostcode() || autoCompletingAddress
                "
                :loading="autoCompletingAddress"
                @click.native="autoCompleteAddress"
              >
                住所を自動入力
              </v-btn>
            </v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-select
              v-model="userAddressForm.prefecture"
              :items="prefectures"
              label="都道府県"
              required
              outline
              hide-details
              :error="errorAttributes.has('prefecture')"
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="userAddressForm.city"
              label="市区町村"
              required
              outline
              hide-details
              placeholder="名古屋市中区丸の内"
              :error="errorAttributes.has('city')"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="userAddressForm.street"
              label="番地"
              required
              outline
              hide-details
              placeholder="2-16-3"
              :error="errorAttributes.has('street')"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="userAddressForm.building"
              label="建物名・部屋番号"
              outline
              hide-details
              placeholder="丸の内A・Tビル 10F"
              :error="errorAttributes.has('building')"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="userAddressForm.telephone"
              type="tel"
              label="電話番号"
              required
              outline
              hide-details
              placeholder="0120886659"
              :error="errorAttributes.has('telephone')"
              @change="onChangeTelephone"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-alert
              :value="errorMessages.length > 0"
              color="error"
              class="mb-3"
            >
              <ul v-for="msg in errorMessages" :key="msg">
                <li>{{ msg }}</li>
              </ul>
            </v-alert>
          </v-flex>

          <v-flex xs12 text-xs-center>
            <v-btn
              large
              type="submit"
              style="width: 60%;"
              color="primary"
              class="title"
              :loading="submitting"
              :disabled="submitting"
            >
              登録する
            </v-btn>
          </v-flex>
          <v-flex xs12 text-xs-center>
            <v-btn
              large
              dark
              style="width: 60%;"
              color="negative"
              class="title"
              @click.native="$emit('cancel')"
            >
              戻る
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../mixins/ApiErrorHandler";

import * as yubinbango from "../lib/vendor/yubinbango-core";
import zenNumSymbolToHankaku from "../lib/utils/zenkaku_hankaku";
import camelcase from "camelcase";

import UserAddressForm from "../forms/UserAddressForm";

import UserAddress from "../models/UserAddress";

export default {
  mixins: [ApiErrorHandler],
  props: {
    userAddress: {
      type: UserAddress,
      default: undefined
    }
  },
  data() {
    return {
      userAddressForm: new UserAddressForm(this.userAddress || {}),
      userAddressFormErrors: [],
      submitting: false,
      autoCompletingAddress: false,
      errorAutoCompleteAddress: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    isNew() {
      return !this.userAddress;
    },
    prefectures() {
      return UserAddressForm.prefectures();
    },
    errorMessages() {
      return this.userAddressFormErrors.map(e => e.message);
    },
    errorAttributes() {
      const errorAttrs = this.userAddressFormErrors
        .filter(e => !!e.key)
        .map(e => camelcase(e.key));
      return new Set(errorAttrs);
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
  methods: {
    autoCompleteAddress() {
      if (
        !this.userAddressForm.isValidPostcode() ||
        this.autoCompletingAddress
      ) {
        return;
      }

      this.autoCompletingAddress = true;
      this.errorAutoCompleteAddress = null;
      new yubinbango.YubinBango.Core(
        this.userAddressForm.postcode,
        result => {
          this.userAddressForm.prefecture = result.region;
          this.userAddressForm.city = result.locality + result.street;
          // result.extendedを使うと丁目、番地まで入力できるが、ビル名まで含まれている場合があるので使用しない

          this.autoCompletingAddress = false;
        },
        error => {
          console.error(error);
          this.autoCompletingAddress = false;
          this.errorAutoCompleteAddress =
            "郵便番号に対応する住所が見つかりません。";
        }
      );
    },
    async submitUserAddressForm() {
      if (this.submitting) {
        return;
      }

      this.userAddressFormErrors = [];
      this.submitting = true;
      try {
        let userAddress;
        if (this.userAddressForm.isNew()) {
          userAddress = await this.apiClient.createUserAddress(
            this.userAddressForm
          );
        } else {
          userAddress = await this.apiClient.updateUserAddress(
            this.userAddressForm.id,
            this.userAddressForm
          );
        }

        this.$emit("complete", userAddress);
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.userAddressFormErrors = errors.errors;
        }
      } finally {
        this.submitting = false;
      }
    },
    onChangePostcode(val) {
      this.userAddressForm.postcode = zenNumSymbolToHankaku(val);
    },
    onChangeTelephone(val) {
      this.userAddressForm.telephone = zenNumSymbolToHankaku(val);
    }
  }
};
</script>
