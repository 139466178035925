/*
 * 郵便番号から住所を自動入力するためのライブラリ
 * 現在npmにあるバージョンだと、webpackから参照できないらしい。
 *   https://github.com/yubinbango/yubinbango-core/issues/4
 * そのためオリジナルファイルを改変することで対応する。
 * ただ、元のファイルに修正が入り、npmに反映されたら、そちらから取得するようにしてほしい。
 *
 * リポジトリ
 *   https://github.com/yubinbango/yubinbango-core
 * オリジナルファイル
 *   https://github.com/yubinbango/yubinbango-core/raw/gh-pages/yubinbango-core.ts
 */

declare global {
  interface Window {
    $yubin: Function;
  }
}

let CACHE: { [key: string]: any } = {};
// module YubinBango { 元ファイルはmoduleを使っていたが、使い方的にnamespaceの方が適切と判断。
export namespace YubinBango {
  export class Core {
    URL = "https://yubinbango.github.io/yubinbango-data/data";
    REGION: Array<string | undefined> = [
      undefined,
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県"
    ];

    // original code start
    // constructor(inputVal: string = '', callback??) {
    // original code end
    // customized code start
    constructor(
      inputVal: string = "",
      callback: Function,
      errorCallback?: Function
    ) {
      // customized code end
      if (inputVal) {
        // 全角の数字を半角に変換 ハイフンが入っていても数字のみの抽出
        const a: string = inputVal.replace(/[０-９]/g, (s: string) =>
          String.fromCharCode(s.charCodeAt(0) - 65248)
        );
        const b: RegExpMatchArray = a.match(/\d/g) || [];
        const c: string = b.join("");
        const yubin7: string | undefined = this.chk7(c);
        // 7桁の数字の時のみ作動
        if (yubin7) {
          // original code start
          // this.getAddr(yubin7, callback);
          // original code end
          // customized code start
          this.getAddr(yubin7, callback, errorCallback);
          // customized code end
        } else {
          callback(this.addrDic());
        }
      }
    }

    chk7(val: string): string | undefined {
      if (val.length === 7) {
        return val;
      }
      return;
    }

    addrDic(
      region_id = "",
      region = "",
      locality = "",
      street = "",
      extended = ""
    ): { [key: string]: string } {
      return {
        region_id: region_id,
        region: region,
        locality: locality,
        street: street,
        extended: extended
      };
    }

    selectAddr(addr: string[]): { [key: string]: string } {
      if (addr && addr[0] && addr[1]) {
        return this.addrDic(
          addr[0],
          this.REGION[parseInt(addr[0])],
          addr[1],
          addr[2],
          addr[3]
        );
      } else {
        return this.addrDic();
      }
    }

    // original code start
    //
    // jsonp(url: string, fn) {
    //   window['$yubin'] = (data) => fn(data);
    //   const scriptTag = document.createElement("script");
    //   scriptTag.setAttribute("type", "text/javascript");
    //   scriptTag.setAttribute("charset", "UTF-8");
    //   scriptTag.setAttribute("src", url);
    //   document.head.appendChild(scriptTag);
    // }
    // getAddr(yubin7: string, fn):{[key:string]: string} {
    //   const yubin3 = yubin7.substr(0, 3);
    //   // 郵便番号上位3桁でキャッシュデータを確認
    //   if (yubin3 in CACHE && yubin7 in CACHE[yubin3]) {
    //     return fn(this.selectAddr(CACHE[yubin3][yubin7]));
    //   } else {
    //     this.jsonp(`${this.URL}/${yubin3}.js`, (data) => {
    //       CACHE[yubin3] = data;
    //       return fn(this.selectAddr(data[yubin7]));
    //     });
    //   }
    // }
    //
    // original code end

    // customized code start
    // オリジナルのコードは存在しない郵便番号を入力した時、何もできないという問題点がある。
    // エラーメッセージなどを表示したいので、別途コードを追加する。
    getAddr(yubin7: string, fn: Function, errorFn?: Function): void {
      const yubin3 = yubin7.substr(0, 3);
      if (yubin3 in CACHE) {
        if (yubin7 in CACHE[yubin3]) {
          return fn(this.selectAddr(CACHE[yubin3][yubin7]));
        } else {
          return errorFn && errorFn();
        }
      }

      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${this.URL}/${yubin3}.js`, true);
      xhr.onload = e => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            window["$yubin"] = (data: any) => {
              CACHE[yubin3] = data;
              const addr = data[yubin7];
              if (addr) {
                return fn(this.selectAddr(addr));
              } else if (errorFn) {
                return errorFn(e);
              }
            };

            const scriptTag = document.createElement("script");
            scriptTag.setAttribute("type", "text/javascript");
            scriptTag.setAttribute("charset", "UTF-8");
            scriptTag.innerText = xhr.responseText;
            document.head && document.head.appendChild(scriptTag);
          } else {
            if (errorFn) {
              errorFn(e);
            }
          }
        }
      };
      xhr.onerror = function(e) {
        if (errorFn) {
          errorFn(e);
        }
      };
      xhr.send();
    }
    // customized code end
  }
}
