import { ActionTree } from "vuex";
import { RootState } from "../types";
import { inquiryState } from "./types";
import Organization from "../../models/Organization";

export const actions: ActionTree<inquiryState, RootState> = {
  async pagesNewInquiry(context: {
    rootState: RootState;
  }): Promise<{ organizations: Array<Organization> }> {
    return await context.rootState.apiClient.pagesNewInquiry();
  },
  async validateNewInquiry(
    context: { rootState: RootState },
    payload: {
      name: string;
      email: string;
      tel: string;
      organizationId: number | null;
      organizationName: string;
      category: number;
      issuedCode: string;
      text: string | undefined;
    }
  ): Promise<void> {
    return await context.rootState.apiClient.validateNewInquiry(payload);
  },
  async verifyIssuedFamily(
    context: { rootState: RootState },
    payload: { code: string }
  ): Promise<void> {
    await context.rootState.apiClient.verifyIssuedFamily(payload);
  },
  async createInquiry(
    context: { rootState: RootState },
    payload: {
      name: string;
      email: string;
      tel: string;
      organizationId: number | null;
      organizationName: string;
      category: number;
      issuedCode: string;
      text: string | undefined;
    }
  ): Promise<void> {
    return await context.rootState.apiClient.createInquiry(payload);
  }
};
