// TODO: share with server side
export const CUSTOMER_SUPPORT_EMAIL = "info@lookme-e.com";
export const CUSTOMER_SUPPORT_TEL = "0120-886-659";

export const ISSUED_CODE1_PATTERN = /^[0-9]{3,5}$/;
export const ISSUED_CODE2_PATTERN = /^[0-9]{3}$/;
export const ISSUED_CODE3_PATTERN = /^[0-9]{3}$/;
export const KID_BIRTH_DATE_PATTERN = /^\d{8}$/;
export const MAIL_ADDRESS_PATTERN = /^[a-zA-Z0-9_\-+/*?][a-zA-Z0-9_\-+/*?.]*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const PHONE_NUMBER_AVAILABLE_CHARS_PATTERN = /^[0-9-]*$/;
export const PHONE_NUMBER_FIXED_PATTERN = /^0[1-9]{1}[0-9]{0,3}[-]{0,1}[0-9]{1,4}[-]{0,1}[0-9]{4}$/;
export const PHONE_NUMBER_MOBILE_PATTERN = /^0[1-9]{1}0[-]{0,1}[0-9]{4}[-]{0,1}[0-9]{4}$/;
export const USER_ADDRESS_TELEPHONE_AVAILABLE_CHARS_PATTERN = /^[0-9]*$/;
export const PASSWORD_PATTERN = /^[\x20-\x7e]{8,}$/;
export const POSTCODE_AVAILABLE_CHARS_PATTERN = /^[\d-]*$/;
export const POSTCODE_PATTERN = /^\d{3}-?\d{4}$/;

export const ORDER_TYPES = {
  print: 1,
  data: 2
};

export const ORDER_PICTURE_SIZE_TYPES = {
  pro_l: 0,
  pro_2l: 1,
  pro_kg: 2,
  pro_k: 3,
  pro_2l_with_caption: 4,
  teacher_l: 5,
  teacher_2l: 6,
  teacher_2l_with_caption: 10
};
export const MEDIA_TYPES = {
  pro_l_print: ORDER_PICTURE_SIZE_TYPES["pro_l"] * 100 + ORDER_TYPES["print"],
  pro_l_data: ORDER_PICTURE_SIZE_TYPES["pro_l"] * 100 + ORDER_TYPES["data"],
  teacher_l_print:
    ORDER_PICTURE_SIZE_TYPES["teacher_l"] * 100 + ORDER_TYPES["print"],
  teacher_l_data:
    ORDER_PICTURE_SIZE_TYPES["teacher_l"] * 100 + ORDER_TYPES["data"],

  pro_2l_print: ORDER_PICTURE_SIZE_TYPES["pro_2l"] * 100 + ORDER_TYPES["print"],
  pro_2l_data: ORDER_PICTURE_SIZE_TYPES["pro_2l"] * 100 + ORDER_TYPES["data"],
  teacher_2l_print:
    ORDER_PICTURE_SIZE_TYPES["teacher_2l"] * 100 + ORDER_TYPES["print"],
  teacher_2l_data:
    ORDER_PICTURE_SIZE_TYPES["teacher_2l"] * 100 + ORDER_TYPES["data"],

  pro_kg_print: ORDER_PICTURE_SIZE_TYPES["pro_kg"] * 100 + ORDER_TYPES["print"],
  pro_kg_data: ORDER_PICTURE_SIZE_TYPES["pro_kg"] * 100 + ORDER_TYPES["data"],

  pro_k_print: ORDER_PICTURE_SIZE_TYPES["pro_k"] * 100 + ORDER_TYPES["print"],
  pro_k_data: ORDER_PICTURE_SIZE_TYPES["pro_k"] * 100 + ORDER_TYPES["data"],

  pro_2l_with_caption_print:
    ORDER_PICTURE_SIZE_TYPES["pro_2l_with_caption"] * 100 +
    ORDER_TYPES["print"],
  pro_2l_with_caption_data:
    ORDER_PICTURE_SIZE_TYPES["pro_2l_with_caption"] * 100 + ORDER_TYPES["data"],

  teacher_2l_with_caption_print:
    ORDER_PICTURE_SIZE_TYPES["teacher_2l_with_caption"] * 100 +
    ORDER_TYPES["print"],
  teacher_2l_with_caption_data:
    ORDER_PICTURE_SIZE_TYPES["teacher_2l_with_caption"] * 100 +
    ORDER_TYPES["data"],

  photo_book_mini: 701,
  photo_book_standard: 711,

  dvd: 801,
  video: 1101,

  postage: 90,
  convenience_store_settlement_commission: 91,
  consumption_tax: 92,
  discount_campaign: 900,
  special_discount: 999,

  postcard: 1201,
  postcard_basic_charge: 1202,
  postcard_print_charge: 1203,
  postcard_postcard_charge: 1204
};

export const TEACHER_PICTURE_MEDIA_TYPES = [
  MEDIA_TYPES["teacher_l_print"],
  MEDIA_TYPES["teacher_l_data"],
  MEDIA_TYPES["teacher_2l_print"],
  MEDIA_TYPES["teacher_2l_data"],
  MEDIA_TYPES["teacher_2l_with_caption_print"],
  MEDIA_TYPES["teacher_2l_with_caption_data"]
];
export const PRO_PICTURE_MEDIA_TYPES = [
  MEDIA_TYPES["pro_l_print"],
  MEDIA_TYPES["pro_l_data"],
  MEDIA_TYPES["pro_2l_print"],
  MEDIA_TYPES["pro_2l_data"],
  MEDIA_TYPES["pro_kg_print"],
  MEDIA_TYPES["pro_kg_data"],
  MEDIA_TYPES["pro_k_print"],
  MEDIA_TYPES["pro_k_data"],
  MEDIA_TYPES["pro_2l_with_caption_print"],
  MEDIA_TYPES["pro_2l_with_caption_data"]
];

export const PRINT_PICTURE_MEDIA_TYPES = [
  MEDIA_TYPES["teacher_l_print"],
  MEDIA_TYPES["teacher_2l_print"],
  MEDIA_TYPES["teacher_2l_with_caption_print"],
  MEDIA_TYPES["pro_l_print"],
  MEDIA_TYPES["pro_2l_print"],
  MEDIA_TYPES["pro_kg_print"],
  MEDIA_TYPES["pro_k_print"],
  MEDIA_TYPES["pro_2l_with_caption_print"]
];
export const DATA_PICTURE_MEDIA_TYPES = [
  MEDIA_TYPES["teacher_l_data"],
  MEDIA_TYPES["teacher_2l_data"],
  MEDIA_TYPES["teacher_2l_with_caption_data"],
  MEDIA_TYPES["pro_l_data"],
  MEDIA_TYPES["pro_2l_data"],
  MEDIA_TYPES["pro_kg_data"],
  MEDIA_TYPES["pro_k_data"],
  MEDIA_TYPES["pro_2l_with_caption_data"]
];

export const VIDEO_MEDIA_TYPES = [MEDIA_TYPES["video"]];

export const PHOTO_BOOK_MEDIA_TYPES = [
  MEDIA_TYPES["photo_book_mini"],
  MEDIA_TYPES["photo_book_standard"]
];

export const SALES_TYPES = {
  photoAndVideo: 0,
  photoBook: 7
};

// TODO: i18n対応
export const DATE_FORMAT = "YYYY年M月D日";
