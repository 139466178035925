<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />登録内容の確認
      </h1>

      <v-card>
        <section>
          <div class="title-bar">世帯</div>
          <div class="pa-3">
            <div class="grey--text text--lighten-1">
              {{ inviterFamilyName }}
            </div>
          </div>
        </section>
        <section>
          <div class="title-bar">メールアドレス</div>
          <div class="pa-3">
            <div class="grey--text text--lighten-1">
              {{ form.email }}
            </div>
          </div>
        </section>
        <section>
          <div class="title-bar">パスワード</div>
          <div class="pa-3">
            <div class="grey--text text--lighten-1">
              ＊＊＊＊＊＊＊＊
            </div>
          </div>
        </section>

        <section v-if="errors.length > 0" class="py-3">
          <v-layout justify-center="center" row>
            <div class="caption error--text">
              <ul v-for="err in errors" :key="err.message">
                <li>{{ errorMessage(err) }}</li>
              </ul>
            </div>
          </v-layout>
        </section>

        <section v-else-if="isEmailTaken" class="py-3">
          <v-layout justify-center="center" row>
            <div class="caption error--text mx-2">
              メールアドレスが既に使用されています。<br />
              ルクミーフォトに登録済みの方は、<router-link
                :to="{ name: 'invitedFamilyLogin', params: { token } }"
                >こちら</router-link
              >からログインしてください。<br />
              登録した覚えの無い方はこちらまでお問い合わせください。{{
                customerSupportTel
              }}
            </div>
          </v-layout>
        </section>

        <section class="mt-3 pb-4">
          <v-layout justify-center row>
            <v-btn
              type="submit"
              :disabled="submitting"
              :loading="submitting"
              class="v-btn--minwidth"
              color="primary"
              @click.prevent="onSubmit"
            >
              登録する
            </v-btn>
          </v-layout>
          <v-layout justify-center row>
            <v-btn
              :disabled="submitting"
              class="v-btn--minwidth"
              color="negative"
              :to="{ hash: '' }"
            >
              戻る
            </v-btn>
          </v-layout>
        </section>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import InvitedUserRegistrationForm from "../../../forms/InvitedUserRegistrationForm";
import {
  API_ERROR_CODE_VALIDATION_TAKEN,
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_USED,
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_EXPIRED,
  API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_FAMILY_NOT_MATCH
} from "../../../lib/lookmee_photo/ApiErrorCodes";
import { CUSTOMER_SUPPORT_TEL } from "../../../data/constants";

export default {
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: InvitedUserRegistrationForm,
      required: true
    },
    inviterFamilyName: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: [],
      isEmailTaken: false
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    customerSupportTel() {
      return CUSTOMER_SUPPORT_TEL;
    }
  },
  methods: {
    async onSubmit() {
      const success = await this.createInvitedUser();

      if (success) {
        await this.logIn();
      }
    },
    async createInvitedUser() {
      this.resetError();
      this.submitting = true;
      let success = false;

      try {
        await this.$store.dispatch(
          "familyInvitation/createInvitedUser",
          this.form.createUserParams()
        );

        success = true;
      } catch (errors) {
        if (errors.status === 400) {
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_VALIDATION_TAKEN:
              this.isEmailTaken = true;
              break;
            default:
              this.errors = errors.errors;
          }
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }

      return success;
    },

    async logIn() {
      this.resetError();
      this.submitting = true;

      try {
        await this.apiClient.login(this.form.logInParams());
        await this.$store.dispatch("user/fetchCurrentUser");
        await this.$store.dispatch("changeFamily", {
          familyId: this.form.familyId
        });

        this.$router.push({ name: "invitedFamilyLoginComplete" });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errors = errors.errors;
        }
      } finally {
        this.submitting = false;
      }
    },
    errorMessage(error) {
      switch (error.code) {
        case API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_NOT_FOUND:
        case API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_FAMILY_NOT_MATCH:
          return "登録に失敗しました。お手数ですが、招待メールに記載のURLをクリックしていま一度登録作業をお願いします。";
        case API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_USED:
          return "招待URLが使用済みのため登録できません。お手数ですが、招待者へ再度招待メールの送信をご依頼ください。";
        case API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_EXPIRED:
          return "招待URLの使用期限が切れているため登録できません。お手数ですが、招待者へ再度招待メールの送信をご依頼ください。";
        default:
          return error.message;
      }
    },
    resetError() {
      this.errors = [];
      this.isEmailTaken = false;
    }
  }
};
</script>
