<template>
  <div>
    <div class="square-wrapper" style="background-color: #EAEAEA;">
      <div class="square-spacer"></div>
      <div class="square-content">
        <v-layout
          justify-center
          align-center
          fill-height
          class="text-xs-center caption"
        >
          写真を登録して<br />ください
        </v-layout>
      </div>
    </div>

    <div>
      <v-btn
        color="primary"
        small
        block
        :disabled="!hasKidsBelongs"
        @click="$emit('register')"
      >
        登録する
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasKidsBelongs: {
      type: Boolean,
      default: false,
      required: true
    }
  }
};
</script>

<style scoped>
.square-wrapper {
  position: relative;
  width: 100%;
}

.square-wrapper .square-spacer {
  width: 100%;
  padding-bottom: 100%;
}

.square-wrapper .square-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
