import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { inquiryState } from "./types";
import { RootState } from "../types";

const initialState: inquiryState = {};

export const inquiry: Module<inquiryState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
