<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <keep-alive :include="['InputSubPage']">
        <component
          :is="currentSubPage"
          :inviter-family-name="inviterFamilyName"
          :form="form"
          :token="token"
        ></component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import InputSubPage from "./Registration/Input";
import ConfirmSubPage from "./Registration/Confirm";

import InvitedUserRegistrationForm from "../../forms/InvitedUserRegistrationForm";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      inviterFamilyName: "",
      form: new InvitedUserRegistrationForm({}),
      token: this.$route.query.token
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#confirm":
          return ConfirmSubPage;
        default:
          return InputSubPage;
      }
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const { family, familyInvitation } = await this.$store.dispatch(
          "familyInvitation/verifyInvitationToken",
          { token: this.token }
        );
        this.inviterFamilyName = family.name;
        this.setInviteduserRegistrationForm(familyInvitation);

        this.$router.replace({ hash: "#input" });
      } catch (errors) {
        if (errors.status === 404 || errors.status === 400) {
          this.$router.push({ name: "invitedFamilyInvalidUrl" });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    setInviteduserRegistrationForm(familyInvitation) {
      this.form.familyId = familyInvitation.familyId;
      this.form.token = familyInvitation.token;
      this.form.email = familyInvitation.email;
    }
  }
};
</script>
