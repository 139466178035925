<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />
        お届け先の情報
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">お届け先を削除しました。</p>
          <p class="text-xs-center my-4">
            <img src="../../assets/images/illust_ok.png" />
          </p>

          <p class="text-xs-center">
            <router-link :to="{ name: 'listUserAddresses' }"
              >お届け先情報へ戻る ≫</router-link
            >
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
