<template>
  <v-list dense>
    <v-list-group v-if="prices">
      <v-list-tile slot="activator" color="info">
        <v-list-tile-content>
          <v-list-tile-title>販売価格について</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile v-for="price in prices" :key="price.uniqueKey" color="info">
        <v-list-tile-content>{{ price.mediaType.label }}</v-list-tile-content>
        <v-list-tile-action>{{ price.price }} 円</v-list-tile-action>
      </v-list-tile>
    </v-list-group>

    <v-list-tile color="info">
      <v-list-tile-content>
        <v-list-tile-title>写真に表示されるアイコンについて</v-list-tile-title>
      </v-list-tile-content>

      <v-list-tile-action>
        <v-tooltip fixed bottom>
          <v-icon slot="activator" color="primary">
            help
          </v-icon>

          <ul class="pa-0" style="list-style: none;">
            <li>
              <v-icon small dark>face</v-icon>
              ... 顔認識機能でマッチした写真
            </li>
            <li>
              <v-icon small dark>photo_filter</v-icon>
              ... 以前に購入済み
            </li>
          </ul>
        </v-tooltip>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  props: {
    prices: {
      type: Array,
      default: undefined
    }
  }
};
</script>
