<template>
  <div>
    <v-btn
      v-if="!isInCart"
      color="primary"
      :small="small"
      :loading="submitting"
      :disabled="isDisabled"
      @click="onClickSelectButton"
    >
      選択する
    </v-btn>
    <v-btn
      v-else
      dark
      color="negative"
      :small="small"
      :loading="submitting"
      @click="onClickDeselectButton"
    >
      解除する
    </v-btn>
    <p v-if="isLowResolution" class="caption error--text">
      画素数が小さいため選択できません<br />（縦横720ピクセル未満）
    </p>
  </div>
</template>

<script>
export default {
  props: {
    pictureId: {
      type: Number,
      required: true
    },
    isLowResolution: {
      type: Boolean,
      required: true
    },
    isInCart: {
      type: Boolean,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    addToCart: {
      type: Function,
      required: true
    },
    removeFromCart: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    isDisabled() {
      return this.isLowResolution;
    }
  },
  methods: {
    async onClickSelectButton() {
      this.submitting = true;
      try {
        await this.addToCart(this.pictureId);
      } finally {
        this.submitting = false;
      }
    },
    async onClickDeselectButton() {
      this.submitting = true;
      try {
        await this.removeFromCart(this.pictureId);
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
