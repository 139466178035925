import * as dayjs from "dayjs";

import Organization from "../models/Organization";
import Group from "../models/Group";

import {
  ISSUED_CODE1_PATTERN,
  ISSUED_CODE2_PATTERN,
  ISSUED_CODE3_PATTERN,
  KID_BIRTH_DATE_PATTERN,
  PHONE_NUMBER_AVAILABLE_CHARS_PATTERN,
  PHONE_NUMBER_FIXED_PATTERN,
  PHONE_NUMBER_MOBILE_PATTERN,
  PASSWORD_PATTERN
} from "../data/constants";

class UserForm {
  token?: string;
  issuedCode1?: string;
  issuedCode2?: string;
  issuedCode3?: string;
  familyName?: string;
  kidName?: string;
  kidBirthDate?: string;
  organization?: Organization;
  groups?: Array<Group>;
  groupId?: number;
  email?: string;
  phoneNumber?: string;
  password?: string;
  passwordConfirmation?: string;

  issuedCode(): string {
    return [
      this.issuedCode1 || "",
      this.issuedCode2 || "",
      this.issuedCode3 || ""
    ].join("");
  }

  issuedCode1Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（左）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 3 && v.length <= 5) ||
        "登録用コード（左）は3文字以上5文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE1_PATTERN)) ||
        "登録用コード（左）は半角数字で入力してください。"
    ];
  }

  issuedCode2Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（中央）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（中央）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE2_PATTERN)) ||
        "登録用コード（中央）は半角数字で入力してください。"
    ];
  }

  issuedCode3Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（右）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（右）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE3_PATTERN)) ||
        "登録用コード（右）は半角数字で入力してください。"
    ];
  }

  familyNameRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "お子さまの姓（世帯名）を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 50) ||
        "お子さまの姓（世帯名）は50文字以内で入力してください。"
    ];
  }

  kidNameRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "お子さまのお名前（名）を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 50) ||
        "お子さまのお名前（名）は50文字以内で入力してください。"
    ];
  }

  kidBirthDateRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "お子さまの生年月日を入力してください。",
      (v?: string) =>
        (!!v && !!v.match(KID_BIRTH_DATE_PATTERN)) ||
        "お子さまの生年月日は8ケタの半角数字で入力してください。",
      (v?: string) => {
        // Check v is valid date string.
        // If v is invalid date, dayjs does not raise error but somehow interpret it.
        // For example, v is '20199999' (invalid date), then dayjs returns '20270607'.
        // So we can check invalid date to compare v and formatted dayjs.
        return (
          (!!v && dayjs(v).format("YYYYMMDD") === v) ||
          "お子さまの生年月日は存在する日付を入力してください。"
        );
      },
      (v?: string) => {
        // Check inputted date is not future date.
        return (
          (!!v && dayjs(v).toDate() <= new Date()) ||
          "お子さまの生年月日は過去の日付を入力してください。"
        );
      }
    ];
  }

  groupIdRules(): Array<Function> {
    return [(v?: number) => !!v || "クラスを選択してください。"];
  }

  phoneNumberRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "電話番号を入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PHONE_NUMBER_AVAILABLE_CHARS_PATTERN)) ||
        "電話番号は半角数字とハイフンで入力してください。",
      (v?: string) =>
        (!!v &&
          (!!v.match(PHONE_NUMBER_FIXED_PATTERN) ||
            !!v.match(PHONE_NUMBER_MOBILE_PATTERN))) ||
        "電話番号に誤りがあります。正しい番号が入力されているか確認してください。"
    ];
  }

  passwordRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "パスワードを入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) || "パスワードは8文字以上で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワードは半角英数字記号で入力してください。"
    ];
  }

  passwordConfirmationRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "パスワード（確認）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) ||
        "パスワード（確認）は8文字以上で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワード（確認）は半角英数字記号で入力してください。",
      (v?: string) =>
        (!!v && v === this.password) ||
        "パスワードとパスワード（確認）が一致しません。"
    ];
  }

  toRegisterUserParams(): {
    issuedCode: string;
    familyName: string;
    kidName: string;
    kidBirthDate: string;
    groupId: number;
    email: string;
    phoneNumber: string;
    password: string;
  } {
    return {
      issuedCode: this.issuedCode(),
      familyName: this.familyName || "",
      kidName: this.kidName || "",
      kidBirthDate: this.kidBirthDate || "",
      groupId: this.groupId || -1,
      email: this.email || "",
      phoneNumber: this.phoneNumber || "",
      password: this.password || ""
    };
  }
}

export default UserForm;
