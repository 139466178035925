import { ORDER_TYPES } from "../data/constants";

class OrderForm {
  constructor({
    ownerName,
    ownerEmail,
    userAddressId,
    orderType,
    paymentMethod
  }) {
    this.ownerName = ownerName;
    this.ownerEmail = ownerEmail;
    this.userAddressId = userAddressId;
    this._orderType = orderType;
    this.paymentMethod = paymentMethod;
  }

  get orderType() {
    return this._orderType;
  }

  set orderType(val) {
    this._orderType = val;
    if (!this.isRequireUserAddress()) {
      this.userAddressId = null;
    }
  }

  ownerNameRules() {
    return [
      v => (!!v && v.length > 0) || "お名前を入力してください。",
      v => (v && v.length <= 255) || "お名前は255文字以内で入力してください。"
    ];
  }

  ownerEmailRules(isActivated) {
    return [() => isActivated || ""];
  }

  userAddressIdRules() {
    if (!this.isRequireUserAddress()) {
      return [];
    }
    return [v => !!v || "お届け先を選択してください。"];
  }

  paymentMethodRules() {
    return [v => !!v || "お支払い方法を選択してください。"];
  }

  pictureCountable() {
    return this.orderType === ORDER_TYPES.print;
  }

  isRequireUserAddress() {
    return this.orderType === ORDER_TYPES.print;
  }
}

export default OrderForm;
