import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { OrderHistoryState } from "./types";
import Order from "../../models/Order";

export const actions: ActionTree<OrderHistoryState, RootState> = {
  async fetchOrders(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<Array<Order>> {
    return await context.rootState.apiClient.getOrders();
  },
  async fetchOrder(
    context: { commit: Commit; rootState: RootState },
    payload: { orderId: number }
  ): Promise<Order> {
    return await context.rootState.apiClient.getOrder(payload.orderId);
  },
  async getOriginalPictureUrl(
    context: { commit: Commit; rootState: RootState },
    payload: { orderId: number; orderDetailId: number }
  ): Promise<string> {
    return await context.rootState.apiClient.getOriginalPictureUrl(
      payload.orderId,
      payload.orderDetailId
    );
  },
  async cancelOrder(
    context: { commit: Commit; rootState: RootState },
    payload: { orderId: number }
  ): Promise<object> {
    return await context.rootState.apiClient.cancelOrder(payload.orderId);
  }
};
