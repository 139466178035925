<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />登録完了
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">
            これで登録は完了です！<br />
            お子さまの写真をお楽しみください。
          </p>
          <img src="../../assets/images/completed.png" alt="" />
          <div class="my-4">
            <v-layout justify-center row>
              <v-btn
                block
                class="v-btn--minwidth"
                color="primary"
                :to="forwardTo"
              >
                お子さまの写真を見る
              </v-btn>
            </v-layout>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      family: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      environment: "environment",
      user: "user",
      karte: "karte"
    }),
    forwardTo() {
      if (this.family && this.family.organizations.length === 1) {
        return {
          name: "organizationTop",
          params: { organizationId: this.family.organizations[0].id }
        };
      } else {
        return { name: "organizations" };
      }
    }
  },
  created() {
    this.getFamily();
  },
  methods: {
    async getFamily() {
      this.$store.dispatch("startLoading");

      try {
        this.family = await this.apiClient.getFamily();

        await this.$store.dispatch("karte/getKarte");

        this.updateDataLayer();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    updateDataLayer() {
      const user = this.user.user;
      let userId;

      if (this.environment === "production") {
        userId = user.id;
      } else {
        userId = this.environment + "_" + user.id;
      }

      dataLayer.push({
        user_id: userId,
        mail_address: user.email,
        mail_ok: this.family.promotionMailDeliverable,
        setting_fr: this.karte.isFrUsed,
        setting_fr_previous: this.karte.previousFrRegisteredDate,
        setting_address: this.karte.isFamilyUserAddressExisting
      });
    }
  }
};
</script>
