import { PASSWORD_PATTERN } from "../data/constants";

class InvitedUserRegistrationForm {
  familyId: number;
  token: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAgreed: boolean;
  privacyPolicyAgreed: boolean;

  constructor(data: {
    familyId: number;
    token: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    termsAgreed: boolean;
    privacyPolicyAgreed: boolean;
  }) {
    this.familyId = data.familyId;
    this.token = data.token;
    this.email = data.email;
    this.password = data.password;
    this.passwordConfirmation = data.passwordConfirmation;
    this.termsAgreed = data.termsAgreed || false;
    this.privacyPolicyAgreed = data.privacyPolicyAgreed || false;
  }

  termsAgreedRules(): Array<Function> {
    return [
      (v?: boolean) => !!v || "利用規約に同意しますにチェックしてください。"
    ];
  }

  privacyPolicyAgreedRules(): Array<Function> {
    return [
      (v?: boolean) =>
        !!v || "個人情報の取扱いに同意しますにチェックしてください。"
    ];
  }

  passwordRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "パスワードを入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) || "パスワードは8文字以上で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワードは半角英数字記号で入力してください。"
    ];
  }

  passwordConfirmationRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "パスワード（確認）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) ||
        "パスワード（確認）は8文字以上で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "パスワード（確認）は半角英数字記号で入力してください。",
      (v?: string) =>
        (!!v && v === this.password) ||
        "パスワードとパスワード（確認）が一致しません。"
    ];
  }

  createUserParams(): {
    familyId: number;
    token: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    termsAgreed: boolean;
    privacyPolicyAgreed: boolean;
  } {
    return {
      familyId: this.familyId,
      token: this.token,
      email: this.email,
      password: this.password || "",
      passwordConfirmation: this.passwordConfirmation || "",
      termsAgreed: this.termsAgreed,
      privacyPolicyAgreed: this.privacyPolicyAgreed
    };
  }

  logInParams(): {
    email: string;
    password: string;
    rememberMe: boolean;
  } {
    return {
      email: this.email,
      password: this.password,
      rememberMe: false
    };
  }
}

export default InvitedUserRegistrationForm;
