import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { FamilyState } from "./types";
import { RootState } from "../types";

const initialState: FamilyState = {
  id: undefined,
  name: undefined,
  kids: [],
  organizations: [],
  family: undefined
};

export const family: Module<FamilyState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
