import { MutationTree } from "vuex";
import { OrganizationState } from "./types";
import Organization from "../../models/Organization";

const setProps = function(
  state: OrganizationState,
  organization: Organization
) {
  state.id = organization.id;
  state.uid = organization.uid;
  state.name = organization.name;
  state.videoDownloadAvailable = organization.videoDownloadAvailable;
};

export const mutations: MutationTree<OrganizationState> = {
  init(state: OrganizationState, data: { organization?: Organization }): any {
    if (data.organization) {
      setProps(state, data.organization);
    }
  },
  setOrganization(state: OrganizationState, organization: Organization): any {
    setProps(state, organization);
  },
  clearOrganization(state: OrganizationState) {
    state.id = undefined;
    state.uid = undefined;
    state.name = undefined;
    state.videoDownloadAvailable = undefined;
  }
};
