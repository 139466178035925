<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />
        <template v-if="form.isNew()">
          登録内容の確認
        </template>
        <template v-else>
          変更内容の確認
        </template>
      </h1>

      <v-form @submit.prevent="submitForm">
        <section>
          <h2 class="title-bar">お届け先</h2>

          <div class="pa-3">
            <p>
              {{ form.fullName }} 様<br />
              住所： 〒{{ form.postcode }} {{ form.prefecture }}{{ form.city
              }}{{ form.street }}
              {{ form.building }}
            </p>
            <v-divider class="mb-3"></v-divider>
            <p>電話番号： {{ form.telephone }}</p>
          </div>

          <div v-if="errors.length > 0" class="ma-3 caption error--text">
            <ul class="px-3">
              <li v-for="err in errors" :key="err.message">
                {{ err.message }}
              </li>
            </ul>
          </div>

          <v-layout justify-center row mt-5>
            <v-btn
              type="submit"
              :loading="submitting"
              :disabled="submitting"
              class="v-btn--minwidth"
              color="primary"
            >
              登録する
            </v-btn>
          </v-layout>

          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="negative"
              :disabled="submitting"
              @click="$emit('back-to-input')"
            >
              戻る
            </v-btn>
          </v-layout>
        </section>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import UserAddressForm from "../../../forms/UserAddressForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: UserAddressForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      this.submitting = true;
      try {
        if (this.form.isNew()) {
          await this.$store.dispatch(
            "family/createUserAddress",
            this.form.toParams()
          );

          // Update karte data and dataLayer
          await this.$store.dispatch("karte/getKarte");
          dataLayer.push({
            setting_address: this.$store.state.karte.isFamilyUserAddressExisting
          });
        } else {
          await this.$store.dispatch(
            "family/updateUserAddress",
            this.form.toParams()
          );
        }

        this.$emit("forward-to-complete");
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
