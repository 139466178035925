<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />クラスの追加
      </h1>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <v-card>
          <v-card-title>園名</v-card-title>
          <div class="pa-3">
            {{ form.organizationName() }}
          </div>
          <v-card-title>クラスの選択</v-card-title>
          <div class="pa-3">
            <v-radio-group v-model="groupId" :rules="form.groupIdRules()">
              <v-radio
                v-for="g in form.groups"
                :key="g.uniqueKey"
                :label="g.name"
                :value="g.id"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="pb-4">
            <v-layout justify-center row>
              <v-btn type="submit" class="v-btn--minwidth" color="primary">
                登録する
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                class="v-btn--minwidth"
                color="negative"
                @click="$emit('back-to-input-code')"
              >
                戻る
              </v-btn>
            </v-layout>
          </div>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";

export default {
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  },
  computed: {
    groupId: {
      get() {
        return this.form.groupId;
      },
      set(val) {
        this.form.groupId = val;
      }
    }
  },
  created() {
    const selectableGroupIds = this.form.groups.map(g => g.id);
    if (!selectableGroupIds.includes(this.groupId)) {
      this.groupId = null;
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("next-to-confirm");
    }
  }
};
</script>
