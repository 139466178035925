<template>
  <v-dialog v-model="mutableDialog" max-width="290">
    <v-card>
      <v-card-text class="text-xs-center">
        選択した写真はすべて解除されます。<br />
        削除してよろしいですか？
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          :loading="submitting"
          :disabled="submitting"
          color="primary"
          @click="submit"
        >
          OK
        </v-btn>
        <v-btn color="negative" :disabled="submitting" @click="close">
          キャンセル
        </v-btn>
      </v-card-actions>

      <div class="v-card__close">
        <v-icon color="primary" @click="close">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    removeAllPhotoBookPictures: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    mutableDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        if (!val) {
          this.close();
        }
      }
    }
  },
  methods: {
    async submit() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        this.removeAllPhotoBookPictures();

        this.$emit("close");
      } finally {
        this.submitting = false;
      }
    },
    close() {
      if (this.submitting) {
        return;
      }
      this.$emit("close");
    }
  }
};
</script>
