<template>
  <div>
    <div class="square-wrapper" style="background-color: #EAEAEA;">
      <div class="square-spacer"></div>
      <div v-if="hasKidsBelongs" class="square-content">
        <img src="../../../../common/assets/images/fr/illust_registering.png" />
      </div>
      <div v-else class="square-content">
        <v-layout
          justify-center
          align-center
          fill-height
          class="text-xs-center caption"
        >
          顔写真の登録中にエラーが<br />
          発生しました。<br />
          お手数ですが、<br />
          別の写真を<br />
          登録してください。
        </v-layout>
      </div>
    </div>
    <div v-if="face.isDestroyable">
      <v-btn color="negative" small block @click="$emit('delete', face.uuid)">
        削除する
      </v-btn>
    </div>
  </div>
</template>

<script>
import Face from "../../../models/Face";

export default {
  props: {
    face: {
      type: Face,
      required: true
    },
    hasKidsBelongs: {
      type: Boolean,
      default: false,
      required: true
    }
  }
};
</script>

<style scoped>
.square-wrapper {
  position: relative;
  width: 100%;
}

.square-wrapper .square-spacer {
  width: 100%;
  padding-bottom: 100%;
}

.square-wrapper .square-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
