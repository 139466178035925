<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8 text-xs-center>
      <h1 class="title-page">
        <v-icon class="material-icons">bookmark_border</v-icon
        ><br />特定商取引法に基づく表記
      </h1>
      <v-card v-if="isReady" elevation-1>
        <v-card-text>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="law-container text-xs-left mt-2" v-html="law"></div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      law: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    isReady() {
      return !!this.law;
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.law = await this.apiClient.getLaw();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>

<style scoped>
.law-container >>> dl dt {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.law-container >>> dl dd {
  font-size: 13px;
  margin-left: 8px;
}

.law-container >>> dl hr {
  margin: 10px 0 10px;
}
</style>
