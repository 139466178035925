<template>
  <v-navigation-drawer v-model="mutableDrawer" fixed app>
    <v-list>
      <template v-if="!!organization.id">
        <v-list-tile :to="`/site/organizations/${organization.id}`">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ organization.name }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider></v-divider>
      </template>

      <v-list-tile
        :to="{ name: 'listNotifications', params: { reload: true } }"
      >
        <v-list-tile-action>
          <v-icon>notifications</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>
            お知らせ
            <v-chip
              v-if="!!displayedNotificationNewBadge"
              label
              color="red"
              text-color="white"
              class="text-xs-center side-menu-new-bedge"
              >NEW
            </v-chip>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'listOrderHistories' }">
        <v-list-tile-action>
          <v-icon>list_alt</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>注文履歴</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'listFrKids' }">
        <v-list-tile-action>
          <v-icon>face</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>顔認識の登録</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-group prepend-icon="account_circle">
        <v-list-tile slot="activator">
          <v-list-tile-title>基本情報</v-list-tile-title>
        </v-list-tile>

        <v-divider></v-divider>

        <v-list-tile :to="{ name: 'showFamily' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>世帯情報の確認</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile :to="{ name: 'showUserEmail' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>メールアドレスの確認・変更</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile :to="{ name: 'listKids' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>お子さまの追加</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile href="/site/families_users/new" target="_blank">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>他の保護者を招待</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>open_in_new</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile :to="{ name: 'listUserAddresses' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>お届け先の変更・追加</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile :to="{ name: 'showPasswordChange' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>パスワードの変更</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile href="/site/credit_card">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>クレジットカードの登録・変更</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile :to="{ name: 'withdrawalConfirmation' }">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>退会</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'newKidsClassRegistration' }">
        <v-list-tile-action>
          <v-icon>airport_shuttle</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>園・施設の登録</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'organizations' }" exact>
        <v-list-tile-action>
          <v-icon>swap_horiz</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>園・施設の切り替え</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'listFamilies' }" exact>
        <v-list-tile-action>
          <v-icon>swap_horiz</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>世帯の切り替え</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile
        href="https://lookmee.tayori.com/faq/6dee8ffd1c5ae520a999d257ebf2fc8d2b2f835d"
        target="_blank"
      >
        <v-list-tile-action>
          <v-icon>import_contacts</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>よくある質問</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>open_in_new</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-tile :to="{ name: 'newInquiry' }">
        <v-list-tile-action>
          <v-icon>help_outline</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>お問い合わせ</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider></v-divider>

      <v-list-group prepend-icon="bookmark_border">
        <v-list-tile slot="activator">
          <v-list-tile-title>その他</v-list-tile-title>
        </v-list-tile>

        <v-divider></v-divider>

        <v-list-tile
          v-if="isOrganizationEmergencyEnabled"
          :to="{ name: 'listUserEmergencies' }"
        >
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>園・施設からのお知らせ</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile to="/site/law">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>特定商法取引法に基づく表記</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>navigate_next</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile href="/site/privacy_policy" target="_blank">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>個人情報の取り扱い</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>open_in_new</v-icon>
          </v-list-tile-action>
        </v-list-tile>

        <v-divider inset></v-divider>

        <v-list-tile href="/site/terms" target="_blank">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>利用規約</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>open_in_new</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-tile @click="onClickLogoutMenu">
        <v-list-tile-action>
          <v-icon>exit_to_app</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>ログアウト</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>navigate_next</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../mixins/ApiErrorHandler";

import { setInterval, clearInterval } from "timers";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      privacyPolicyText: "",
      pollingNotificationsTimerId: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      organization: "organization"
    }),
    ...mapGetters({
      notifications: "notifications/getNotifications",
      isOrganizationEmergencyEnabled: "family/isOrganizationEmergencyEnabled"
    }),
    displayedNotificationNewBadge() {
      return this.notifications.find(notification => notification.isNew);
    },
    mutableDrawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      }
    }
  },
  created() {
    this.loadNotifications();
    this.pollingNotificationsTimerId = setInterval(() => {
      this.loadNotifications();
    }, 1000 * 60 * 60);
  },
  beforeDestroy() {
    clearInterval(this.pollingNotificationsTimerId);
  },
  methods: {
    loadNotifications() {
      this.$store.dispatch("notifications/initNotifications").catch(errors => {
        // skip error handling because this API call is executed in background
        if (errors.is50xError()) {
          this.sentry.captureMessage(errors.getErrorMessage());
        }
      });
    },
    onClickLogoutMenu: function() {
      if (!confirm("ログアウトしますか？")) {
        return;
      }

      this.logout();
    },
    async logout() {
      try {
        await this.apiClient.logout();
        window.location.href = "/";
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    }
  }
};
</script>

<style scoped>
.dialog-title {
  font-size: 15px;
  background-color: #e0e0e0;
}
.side-menu-new-bedge {
  height: 16px;
}
</style>
