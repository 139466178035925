import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { CartState } from "./types";
import { RootState } from "../types";

const initialState: CartState = {
  cart: undefined
};

export const cart: Module<CartState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
