<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />
        <template v-if="form.isNew()">
          お届け先の追加
        </template>
        <template v-else>
          お届け先の編集
        </template>
      </h1>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <section>
          <h2 class="title-bar">新しいお届け先の入力</h2>

          <div class="pa-3">
            <v-text-field
              v-model="fullName"
              label="お名前"
              outline
              validate-on-blur
              required
              :rules="form.fullNameRules()"
            ></v-text-field>

            <v-divider class="mb-3"></v-divider>

            <v-layout
              v-if="errorAutoCompleteAddress"
              class="ma-3 caption error--text"
            >
              <v-flex>
                {{ errorAutoCompleteAddress }}
              </v-flex>
            </v-layout>

            <v-layout row justify-space-between>
              <v-flex>
                <v-text-field
                  v-model="postcode"
                  label="郵便番号"
                  outline
                  validate-on-blur
                  required
                  :rules="form.postcodeRules()"
                  @change="onChangePostcode"
                >
                  <v-btn
                    slot="append-outer"
                    small
                    class="v-btn--minwidth mt-0"
                    color="primary"
                    :disabled="!form.isValidPostcode() || autoCompletingAddress"
                    :loading="autoCompletingAddress"
                    @click.native="autoCompleteAddress"
                  >
                    住所を自動入力
                  </v-btn>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <v-select
                  ref="prefectureForm"
                  v-model="prefecture"
                  :items="prefectures"
                  label="都道府県"
                  outline
                  validate-on-blur
                  required
                  :rules="form.prefectureRules()"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <v-text-field
                  ref="cityForm"
                  v-model="city"
                  label="市区町村"
                  outline
                  validate-on-blur
                  required
                  :rules="form.cityRules()"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="street"
                  label="番地"
                  outline
                  validate-on-blur
                  required
                  :rules="form.streetRules()"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="building"
                  label="建物名・部屋番号"
                  outline
                  validate-on-blur
                  :rules="form.buildingRules()"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-divider class="mb-3"></v-divider>

            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="telephone"
                  label="電話番号"
                  outline
                  validate-on-blur
                  required
                  :rules="form.telephoneRules()"
                  @change="onChangeTelephone"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <invalid-form-error v-if="$refs.form" :form="$refs.form" />

            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-layout justify-center row mt-5>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
              >
                <template v-if="form.isNew()">
                  登録内容を確認する
                </template>
                <template v-else>
                  変更内容を確認する
                </template>
              </v-btn>
            </v-layout>

            <v-layout justify-center row>
              <v-btn
                class="v-btn--minwidth"
                color="negative"
                :disabled="submitting"
                :to="{ name: 'listUserAddresses' }"
              >
                戻る
              </v-btn>
            </v-layout>
          </div>
        </section>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import * as yubinbango from "../../../lib/vendor/yubinbango-core";

import UserAddressForm from "../../../forms/UserAddressForm";
import InvalidFormError from "../../../components/InvalidFormError";

export default {
  components: {
    "invalid-form-error": InvalidFormError
  },
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: UserAddressForm,
      required: true
    }
  },
  data() {
    return {
      autoCompletingAddress: false,
      errorAutoCompleteAddress: null,
      submitting: false,
      errors: []
    };
  },
  computed: {
    prefectures() {
      return UserAddressForm.prefectures();
    },
    fullName: {
      get() {
        return this.form.fullName;
      },
      set(val) {
        this.form.fullName = val;
      }
    },
    postcode: {
      get() {
        return this.form.postcode;
      },
      set(val) {
        this.form.postcode = val;
      }
    },
    prefecture: {
      get() {
        return this.form.prefecture;
      },
      set(val) {
        this.form.prefecture = val;
      }
    },
    city: {
      get() {
        return this.form.city;
      },
      set(val) {
        this.form.city = val;
      }
    },
    street: {
      get() {
        return this.form.street;
      },
      set(val) {
        this.form.street = val;
      }
    },
    building: {
      get() {
        return this.form.building;
      },
      set(val) {
        this.form.building = val;
      }
    },
    telephone: {
      get() {
        return this.form.telephone;
      },
      set(val) {
        this.form.telephone = val;
      }
    }
  },
  methods: {
    autoCompleteAddress() {
      if (!this.form.isValidPostcode() || this.autoCompletingAddress) {
        return;
      }

      this.autoCompletingAddress = true;
      this.errorAutoCompleteAddress = null;
      new yubinbango.YubinBango.Core(
        this.postcode,
        result => {
          this.prefecture = result.region;
          this.city = result.locality + result.street;
          // result.extendedを使うと丁目、番地まで入力できるが、ビル名まで含まれている場合があるので使用しない

          // Reset validation to clear error messages.
          this.$refs.prefectureForm.resetValidation();
          this.$refs.cityForm.resetValidation();

          this.autoCompletingAddress = false;
        },
        error => {
          console.error(error);
          this.autoCompletingAddress = false;
          this.errorAutoCompleteAddress =
            "郵便番号に対応する住所が見つかりません。";
        }
      );
    },
    onChangePostcode(val) {
      this.postcode = UserAddressForm.formatPostcode(val);
    },
    onChangeTelephone(val) {
      this.telephone = UserAddressForm.formatTelephone(val);
    },
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        if (this.form.isNew()) {
          await this.$store.dispatch(
            "family/validateForCreateUserAddress",
            this.form
          );
        } else {
          await this.$store.dispatch(
            "family/validateForUpdateUserAddress",
            this.form
          );
        }

        this.$emit("forward-to-confirm");
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
