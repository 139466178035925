<template>
  <div class="text-xs-center">
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card>
        <v-card-text class="text-xs-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
