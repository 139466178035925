class Face {
  uuid: string;
  kidId: number;
  downloadUrl: string;
  hasFrFaces: boolean;
  isDestroyable: boolean;

  constructor(data: {
    uuid: string;
    kidId: number;
    downloadUrl: string;
    hasFrFaces: boolean;
    isDestroyable: boolean;
  }) {
    this.uuid = data.uuid;
    this.kidId = data.kidId;
    this.downloadUrl = data.downloadUrl;
    this.hasFrFaces = data.hasFrFaces;
    this.isDestroyable = data.isDestroyable;
  }

  get uniqueKey() {
    return `face-${this.uuid}`;
  }
}

export default Face;
