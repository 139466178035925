<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentSubPage"
          v-bind="{ form }"
          @back-to-input="$router.push({ hash: '#input' })"
          @forward-to-confirm="$router.push({ hash: '#confirm' })"
          @forward-to-complete="$router.push({ hash: '#complete' })"
        >
        </component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import InputSubPage from "./components/InputSubPage";
import ConfirmSubPage from "./components/ConfirmSubPage";
import CompleteSubPage from "./components/CompleteSubPage";

import UserAddressForm from "../../forms/UserAddressForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    userAddressId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: null
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#input":
          return InputSubPage;
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        default:
          return null;
      }
    }
  },
  async created() {
    await this.fetchInitData();

    this.$router.replace({ hash: "#input" });
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const userAddress = await this.$store.dispatch(
          "family/getUserAddress",
          { userAddressId: this.userAddressId }
        );
        this.form = new UserAddressForm(userAddress);
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
