<template>
  <v-bottom-sheet hide-overlay persistent :value="true">
    <v-list style="padding-top: 0;">
      <v-card
        flat
        dark
        color="warning"
        class="campaign-banner mb-0 font-weight-bold subheading text-xs-center pa-1"
      >
        現在の選択枚数
        <span class="display-1">{{ currentPicturesCount }}</span
        >枚
        <span class="display-1">/</span>
        <span class="display-1">{{ maxPicturesCount }}</span
        >枚
      </v-card>

      <v-container pt-2 pb-0 px-3>
        <v-layout row justify-space-between>
          <v-flex xs8>
            <ul class="list-vertical caption">
              <li>
                <a
                  href="#"
                  @click.stop.prevent="$emit('on-click-remove-all-link')"
                  >選択写真をすべて解除する</a
                >
              </li>
              <li>
                <a
                  href="#"
                  @click.stop.prevent="$emit('on-click-change-size-link')"
                  >フォトブックのサイズを変更する</a
                >
              </li>
            </ul>
          </v-flex>

          <v-flex xs4>
            <v-btn
              color="primary"
              block
              @click="$emit('on-click-go-cart-page-button')"
            >
              注文する
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: {
    currentPicturesCount: {
      type: Number,
      required: true
    },
    maxPicturesCount: {
      type: Number,
      required: true
    }
  }
};
</script>
