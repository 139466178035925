import { MutationTree } from "vuex";
import { RootState } from "./types";

export const mutations: MutationTree<RootState> = {
  init(
    state: RootState,
    data: {
      environment: string;
      clientVersion: number;
      csrfToken: string;
      errorMessages: Array<string>;
      flowplayerToken: string;
    }
  ): any {
    state.environment = data.environment;
    state.apiClient.version = data.clientVersion;
    state.clientVersion = data.clientVersion;
    state.errorMessages = data.errorMessages;
    state.flowplayerToken = data.flowplayerToken;
  },
  setLoading(state: RootState, loading: boolean) {
    state.loading = loading;
  },
  setLoginRequired(state: RootState, value: boolean) {
    state.loginRequired = value;
  },
  setRefreshRequired(state: RootState, value: boolean) {
    state.refreshRequired = value;
  },
  setErrorDialog(
    state: RootState,
    data: { dialog: boolean; messages: Array<string> }
  ) {
    state.errorDialog = data.dialog;
    state.errorMessages = data.messages;
  },
  setErrorMessages(state: RootState, data: { messages: Array<string> }) {
    state.errorMessages = data.messages;
  },
  setDrawer(state: RootState, val: boolean) {
    state.drawer = val;
  }
};
