class Organization {
  id: number;
  uid: string;
  name: string;
  videoDownloadAvailable?: boolean;
  isEmergencyEnabled: boolean;

  constructor(data: {
    id: number;
    uid: string;
    name: string;
    videoDownloadAvailable?: boolean;
    isEmergencyEnabled: boolean;
  }) {
    this.id = data.id;
    this.uid = data.uid;
    this.name = data.name;
    this.videoDownloadAvailable = data.videoDownloadAvailable;
    this.isEmergencyEnabled = data.isEmergencyEnabled;
  }

  get uniqueKey(): string {
    return `organization-${this.id}`;
  }
}

export default Organization;
