<template>
  <div class="text-xs-center">
    <v-dialog v-model="showDialog" persistent width="400">
      <v-card>
        <v-card-text class="text-xs-center">
          <p>
            新しいバージョンがリリースされたため、画面の再読み込みが必要です。<br />
            <template v-if="showCartCaption">
              <span class="caption"
                >※カートに入っている商品は削除されません。</span
              >
            </template>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-xs-center">
          <v-btn color="primary" @click="onClickReloadButton">
            再読み込みする
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    showCartCaption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    onClickReloadButton() {
      window.location.reload();
    }
  }
};
</script>
