<template>
  <v-layout row wrap>
    <v-flex xs12 text-xs-center>
      <v-card>
        <v-layout row wrap>
          <template v-if="targetPicture">
            <v-btn
              fab
              dark
              :small="$vuetify.breakpoint.name === 'xs'"
              :disabled="index <= 0"
              class="prev-picture-button d-flex"
              @click.native="prevPicture"
            >
              <v-icon dark class="d-inline-flex">navigate_before</v-icon>
            </v-btn>

            <v-flex xs12 offset-md1 md10 class="gallery">
              <v-card-text class="pa-2">
                <div class="text-xs-center">
                  <p class="caption info--text mt-2 mb-0">
                    {{ offsetCount + index + 1 }} / {{ allCount }}
                  </p>

                  <v-icon
                    class="close-button ma-2"
                    color="primary"
                    @click="$emit('close')"
                  >
                    far fa-times-circle
                  </v-icon>
                </div>

                <v-layout
                  ref="galleryWrapper"
                  v-resize="handleWindowResize"
                  v-touch="{
                    left: () => nextPicture(),
                    right: () => prevPicture()
                  }"
                  class="gallery-picture my-1"
                >
                  <template v-if="galleryWidth && galleryHeight">
                    <transition :name="transitionName">
                      <template v-for="(p, i) in pictures">
                        <div
                          v-if="i === index"
                          :key="p.uniqueKey"
                          class="gallery-picture-frame elevation-7"
                          :style="printSizeStyle(p)"
                        >
                          <div
                            class="gallery-picture-content"
                            :style="backgroundImageStyle(p)"
                          >
                            <div
                              class="watermark"
                              :style="{ backgroundImage: `url(${watermark})` }"
                            />
                            <div
                              class="stop-screenshot"
                              :style="stopScreenshotStyle(p)"
                            />
                          </div>
                        </div>
                      </template>
                    </transition>
                  </template>
                </v-layout>

                <div class="text-xs-center text-lg-right mt-3">
                  <button class="switch-watermark" @click="switchWatermark">
                    透かしマークを切り替える
                  </button>
                </div>

                <div class="gallery-info-row text-xs-left mt-3">
                  <v-chip
                    v-if="targetPicture.hasFace(familyKidIds)"
                    outline
                    small
                    disabled
                    color="warning"
                  >
                    <v-avatar class="mr-0">
                      <v-icon small color="warning">face</v-icon>
                    </v-avatar>
                    顔認識
                  </v-chip>
                  <v-chip
                    v-if="isPurchased(targetPicture)"
                    outline
                    small
                    disabled
                    color="error"
                  >
                    <v-avatar class="mr-0">
                      <v-icon small color="error">photo_filter</v-icon>
                    </v-avatar>
                    購入済
                  </v-chip>
                </div>

                <v-layout row wrap mt-1 class="negative--text caption">
                  <v-flex xs9 text-xs-left px-2>
                    {{ targetPicture.sizeTypeLabel }}
                  </v-flex>

                  <v-flex xs3 text-xs-right px-2>
                    No.{{ targetPicture.fileNo }}
                  </v-flex>
                </v-layout>

                <slot :targetPicture="targetPicture"></slot>
              </v-card-text>
            </v-flex>

            <v-btn
              fab
              dark
              :small="$vuetify.breakpoint.name === 'xs'"
              :disabled="index >= pictures.length - 1"
              class="next-picture-button d-flex"
              @click.native="nextPicture"
            >
              <v-icon dark class="d-inline-flex">navigate_next</v-icon>
            </v-btn>
          </template>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

const watermark1 = require("../assets/images/watermark1.svg");
const watermark2 = require("../assets/images/watermark2.svg");

export default {
  props: {
    pictures: {
      type: Array,
      required: true
    },
    initialIndex: {
      type: Number,
      required: true
    },
    purchasedPictureIds: {
      type: Set,
      required: true
    },
    preloadNum: {
      type: Number,
      default: 3
    },
    offsetCount: {
      type: Number,
      default: 0
    },
    allCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      index: this.initialIndex,
      galleryWidth: null,
      galleryHeight: null,
      transitionName: "show-next",
      watermark: watermark1
    };
  },
  computed: {
    ...mapGetters({
      familyKidIds: "family/kidIds"
    }),
    galleryAspectRatio() {
      // 印刷範囲表示のため現在の画像表示領域のアスペクト比を保存。リサイズ時に更新。
      return this.galleryWidth / this.galleryHeight;
    },
    targetPicture() {
      return this.pictures[this.index];
    },
    preloadTargetPictures() {
      let begin = this.index - this.preloadNum;
      if (begin < 0) {
        begin = 0;
      }
      const end = this.index + this.preloadNum + 1;

      return this.pictures.slice(begin, end);
    }
  },
  watch: {
    preloadTargetPictures: function(val) {
      this.preloadPictures(val);
    }
  },
  mounted() {
    this.resetGalleryAspectRatio();
    this.preloadPictures(this.preloadTargetPictures);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    preloadPictures(pictures) {
      pictures.forEach(p => {
        const image = new Image();
        image.src = p.thumbnailBigUrl;
      });
    },
    isPurchased(picture) {
      return this.purchasedPictureIds.has(picture.id);
    },
    printSizeStyle(picture) {
      const widthRatio = Math.min(
        picture.printAspectRatio / this.galleryAspectRatio,
        1.0
      );
      const heightRatio = Math.min(
        this.galleryAspectRatio / picture.printAspectRatio,
        1.0
      );

      return {
        width: `${100 * widthRatio}%`,
        height: `${100 * heightRatio}%`,
        left: `${((1.0 - widthRatio) / 2) * 100}%`,
        top: `${((1.0 - heightRatio) / 2) * 100}%`
      };
    },
    backgroundImageStyle(picture) {
      // しまぷりの印刷範囲に合わせた表示を行うためのスタイル指定を返す。
      // 詳細は https://unifa-e.atlassian.net/wiki/spaces/photo/pages/724338331
      const bgImgSize = 1.0 / picture.printAreaRatio;

      let bgImgSizeX, bgImgSizeY;
      if (picture.isTrimmingX()) {
        bgImgSizeX = "auto";
        bgImgSizeY = `${bgImgSize * 100}%`;
      } else {
        bgImgSizeX = `${bgImgSize * 100}%`;
        bgImgSizeY = "auto";
      }
      return {
        "background-image": `url(${picture.thumbnailBigUrl})`,
        "background-size": `${bgImgSizeX} ${bgImgSizeY}`
      };
    },
    stopScreenshotStyle(picture) {
      if (picture.width > picture.height) {
        return "background-position: left 50% bottom 15%";
      } else {
        return "background-position: left 50% bottom 10%";
      }
    },
    switchWatermark() {
      if (this.watermark === watermark1) {
        this.watermark = watermark2;
      } else {
        this.watermark = watermark1;
      }
    },
    handleWindowResize() {
      if (this.$refs.galleryWrapper) {
        this.resetGalleryAspectRatio();
      }
    },
    resetGalleryAspectRatio() {
      this.galleryWidth = this.$refs.galleryWrapper.clientWidth;
      this.galleryHeight = this.$refs.galleryWrapper.clientHeight;
    },
    handleKeyUp(event) {
      // ESCでダイアログを閉じる、左キーで戻る、右キーで進ませたい。
      switch (event.keyCode) {
        case 27: // ESC
          this.$emit("close");
          break;
        case 37: // left
          this.prevPicture();
          break;
        case 39: // right
          this.nextPicture();
          break;
        default:
        // nothing to do
      }
    },
    prevPicture() {
      if (this.index > 0) {
        this.transitionName = "show-prev";
        this.index -= 1;

        this.$emit("move", { picture: this.targetPicture });
      }
    },
    nextPicture() {
      if (this.index < this.pictures.length - 1) {
        this.transitionName = "show-next";
        this.index += 1;

        this.$emit("move", { picture: this.targetPicture });
      }
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.prev-picture-button {
  position: absolute;
  left: 0;
  top: 40%;
  z-index: 210;
  background-color: rgba(84, 84, 84, 0.4) !important;
}

.next-picture-button {
  position: absolute;
  right: 0;
  top: 40%;
  z-index: 210;
  background-color: rgba(84, 84, 84, 0.4) !important;
}

.gallery {
  overflow-x: hidden;
}

.gallery-picture {
  position: relative;
  width: 100%;
  height: calc(96vh - 300px);
}

.gallery-picture .gallery-picture-frame {
  position: absolute;
}

.gallery-picture .gallery-picture-frame .gallery-picture-content {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.gallery-picture .gallery-picture-frame .gallery-picture-content .watermark {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: repeat;
  z-index: 205;
}

.gallery-picture
  .gallery-picture-frame
  .gallery-picture-content
  .stop-screenshot {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/stop_screenshot.svg");
  background-size: 70% 10%;
  background-repeat: no-repeat;
  z-index: 210;
}

.switch-watermark {
  padding: 10px;
  border: 1px solid #ff8400;
  border-radius: 10px;
  color: #ff8400;
  font-weight: 700;
}

.gallery-info-row {
  min-height: 32px;
}

.show-next-enter-active,
.show-next-leave-active,
.show-prev-enter-active,
.show-prev-leave-active {
  transition: all 0.3s;
}

.show-next-enter,
.show-prev-leave-to {
  transform: translateX(100vw);
  -ms-transform: translateX(
    175%
  ); /* IE11のみ、vwでの指定が逆方向に動いてしまうため%で指定 */
}

.show-next-leave-to,
.show-prev-enter {
  transform: translateX(-100vw);
  -ms-transform: translateX(
    -175%
  ); /* IE11のみ、vwでの指定が逆方向に動いてしまうため%で指定 */
}
</style>
