<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-text class="text-xs-center">
        登録されている顔写真を削除します。<br />
        この操作は取り消せません。<br />
        よろしいですか？
      </v-card-text>

      <v-card-actions class="justify-center pb-4">
        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="submitting"
          @click="deleteFace"
        >
          OK
        </v-btn>
        <v-btn color="negative" :disabled="submitting" @click="cancel">
          キャンセル
        </v-btn>
      </v-card-actions>

      <div class="v-card__close">
        <v-icon color="primary" @click="cancel">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    kidId: {
      type: Number,
      required: true
    },
    faceUuid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    dialog: {
      get() {
        return !!this.faceUuid;
      },
      set(val) {
        if (!val) {
          this.cancel();
        }
      }
    }
  },
  methods: {
    async deleteFace() {
      if (this.submitting || !this.faceUuid) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("fr/deleteFace", {
          kidId: this.kidId,
          uuid: this.faceUuid
        });

        this.$emit("complete", this.faceUuid);
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.submitting = false;
      }
    },
    cancel() {
      if (this.submitting) {
        return;
      }

      this.$emit("cancel");
    }
  }
};
</script>
