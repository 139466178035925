<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />保護者情報の登録
      </h1>

      <div class="pa-3">
        <p>
          招待されたご家族の写真・動画を閲覧するためにアカウントを登録してください。
        </p>
        <p>
          ※既にルクミーフォトのアカウントをお持ちの場合は、<router-link
            :to="{ name: 'invitedFamilyLogin', params: { token } }"
            >こちら</router-link
          >からログインしてご利用ください。
        </p>
      </div>
      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <section>
          <div class="title-bar">世帯</div>
          <div class="pa-3">
            <div class="grey--text text--lighten-1">
              {{ inviterFamilyName }}
            </div>
          </div>
        </section>

        <section>
          <div class="title-bar">メールアドレス</div>
          <div class="pa-3">
            <div class="grey--text text--lighten-1">
              {{ form.email }}
            </div>
          </div>
        </section>

        <section>
          <div class="title-bar">パスワード　※半角</div>
          <div class="px-3 pt-3">
            <div class="caption error--text mb-1">
              ※8桁以上の半角英数字で入力してください。
            </div>
            <v-text-field
              v-model="password"
              type="password"
              single-line
              outline
              validate-on-blur
              required
              :rules="form.passwordRules()"
            ></v-text-field>
          </div>
        </section>

        <section>
          <div class="title-bar">パスワード（確認）</div>
          <div class="px-3 pt-3">
            <v-text-field
              v-model="passwordConfirmation"
              type="password"
              single-line
              outline
              validate-on-blur
              required
              :rules="form.passwordConfirmationRules()"
            ></v-text-field>
          </div>
        </section>

        <section>
          <div class="px-3">
            <v-checkbox
              v-model="termsAgreed"
              validate-on-blur
              :rules="form.termsAgreedRules()"
            >
              <template slot="label">
                <a href="/site/terms" target="_blank" @click.stop="">利用規約</a
                >に同意します。
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="privacyPolicyAgreed"
              validate-on-blur
              :rules="form.privacyPolicyAgreedRules()"
            >
              <template slot="label">
                <a href="/site/privacy_policy" target="_blank" @click.stop=""
                  >個人情報の取扱い</a
                >に同意します。
              </template>
            </v-checkbox>
          </div>
        </section>

        <section>
          <v-layout justify-center row class="my-3">
            <v-btn type="submit" class="v-btn--minwidth" color="primary">
              次へ
            </v-btn>
          </v-layout>
        </section>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import InvitedUserRegistrationForm from "../../../forms/InvitedUserRegistrationForm";

export default {
  name: "InputSubPage",
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: InvitedUserRegistrationForm,
      required: true
    },
    inviterFamilyName: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      privacyPolicyText: ""
    };
  },
  computed: {
    password: {
      get() {
        return this.form.password;
      },
      set(val) {
        this.form.password = val;
      }
    },
    passwordConfirmation: {
      get() {
        return this.form.passwordConfirmation;
      },
      set(val) {
        this.form.passwordConfirmation = val;
      }
    },
    termsAgreed: {
      get() {
        return this.form.termsAgreed;
      },
      set(val) {
        this.form.termsAgreed = val;
      }
    },
    privacyPolicyAgreed: {
      get() {
        return this.form.privacyPolicyAgreed;
      },
      set(val) {
        this.form.privacyPolicyAgreed = val;
      }
    }
  },
  methods: {
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$router.push({ hash: "confirm" });
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
