<template>
  <keep-alive>
    <component
      :is="currentSubPage"
      v-bind="{
        organizationId,
        salesManagementId,
        salesManagement,
        pictures,
        videos,
        photoBookPictures,
        purchasedPictureIds,
        purchasedVideoIds
      }"
    >
    </component>
  </keep-alive>
</template>

<script>
import { mapGetters } from "vuex";
import ApiErrorHandler from "../../../../mixins/ApiErrorHandler";

import { SALES_TYPES } from "../../../../data/constants";

import EmptyCartSubPage from "./Show/EmptyCart";
import PhotoAndVideoSubPage from "./Show/PhotoAndVideo";
import PhotoBookSubPage from "./Show/PhotoBook";

export default {
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      salesManagement: null,
      pictures: null,
      videos: null,
      photoBookPictures: null,
      purchasedPictureIds: new Set(),
      purchasedVideoIds: new Set()
    };
  },
  computed: {
    ...mapGetters("cart", {
      cartIsEmpty: "isEmpty"
    }),
    currentSubPage() {
      if (!this.salesManagement) {
        return null;
      }

      if (this.cartIsEmpty) {
        return EmptyCartSubPage;
      }

      switch (this.salesManagement.salesType.value) {
        case SALES_TYPES.photoAndVideo:
          return PhotoAndVideoSubPage;
        case SALES_TYPES.photoBook:
          return PhotoBookSubPage;
        default:
          return null;
      }
    }
  },
  async created() {
    const checkVersionResult = await this.$store.dispatch("checkVersion");
    if (!checkVersionResult) {
      this.$store.dispatch("showRequireRefreshDialog");
      return;
    }

    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.$store.dispatch("startLoading");
      try {
        const {
          organization,
          salesManagement,
          pictures,
          videos,
          photoBookPictures,
          purchasedPictureIds,
          purchasedVideoIds
        } = await this.$store.dispatch("cart/pagesCart", {
          organizationId,
          salesManagementId
        });
        // TODO: This operation should be done in Vuex actions.
        this.$store.commit("organization/setOrganization", organization);

        this.salesManagement = salesManagement;
        this.purchasedPictureIds = purchasedPictureIds;
        this.purchasedVideoIds = purchasedVideoIds;
        this.pictures = pictures;
        this.videos = videos;
        this.photoBookPictures = photoBookPictures;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>

<style>
.picture-gallery-dialog {
  max-height: 98% !important;
}
</style>
