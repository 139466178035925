<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />お子さまの追加
      </h1>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <v-card>
          <v-card-title>登録用コードの入力</v-card-title>
          <div class="pa-3">
            <v-layout align-center justify-center row fill-height>
              <v-flex xs4 sm3 text-xs-center>
                <v-text-field
                  ref="code1Field"
                  v-model="issuedCode1"
                  maxlength="5"
                  placeholder="12345"
                  single-line
                  outline
                  hide-details
                  validate-on-blur
                  required
                  :rules="form.issuedCode1Rules()"
                  class="text-xs-center"
                ></v-text-field>
              </v-flex>
              <v-flex xs1 sm1 text-xs-center>
                ー
              </v-flex>
              <v-flex xs3 sm3 text-xs-center>
                <v-text-field
                  ref="code2Field"
                  v-model="issuedCode2"
                  maxlength="3"
                  placeholder="123"
                  single-line
                  outline
                  hide-details
                  validate-on-blur
                  required
                  :rules="form.issuedCode2Rules()"
                ></v-text-field>
              </v-flex>
              <v-flex xs1 sm1 text-xs-center>
                ー
              </v-flex>
              <v-flex xs3 sm3 text-xs-center>
                <v-text-field
                  ref="code3Field"
                  v-model="issuedCode3"
                  maxlength="3"
                  placeholder="456"
                  single-line
                  outline
                  hide-details
                  validate-on-blur
                  required
                  :rules="form.issuedCode3Rules()"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </div>

          <ul class="mx-3 caption error--text">
            <template v-if="$refs.code1Field">
              <li
                v-for="(msg, i) in $refs.code1Field.validations"
                :key="`code1FieldErrorMessage-${i}`"
              >
                {{ msg }}
              </li>
            </template>
            <template v-if="$refs.code2Field">
              <li
                v-for="(msg, i) in $refs.code2Field.validations"
                :key="`code2FieldErrorMessage-${i}`"
              >
                {{ msg }}
              </li>
            </template>
            <template v-if="$refs.code3Field">
              <li
                v-for="(msg, i) in $refs.code3Field.validations"
                :key="`code3FieldErrorMessage-${i}`"
              >
                {{ msg }}
              </li>
            </template>
          </ul>

          <v-card-title>お子さまの氏名（漢字）</v-card-title>
          <div class="px-3">
            <v-text-field
              :value="form.familyName"
              single-line
              solo
              flat
              hide-details
              required
              disabled
            >
              <template slot="prepend">
                <label>姓</label>
              </template>
            </v-text-field>
          </div>
          <p class="caption error--text mt-2 mx-2">
            ※氏名が漢字でない方は、英語表記で入力してください。
          </p>
          <div class="px-3">
            <v-text-field
              v-model="kidName"
              single-line
              outline
              validate-on-blur
              required
              :rules="form.kidNameRules()"
            >
              <template slot="prepend">
                <label>名</label>
              </template>
            </v-text-field>
          </div>

          <v-card-title>お子さまの生年月日</v-card-title>
          <p class="caption error--text mt-2 mx-2">
            ※2019年1月23日生まれの場合、「20190123」と8ケタの半角数字で入力してください。
          </p>
          <div class="px-3">
            <v-text-field
              v-model="kidBirthDate"
              type="number"
              placeholder="20190123"
              single-line
              outline
              validate-on-blur
              required
              :rules="form.kidBirthDateRules()"
            ></v-text-field>
          </div>

          <issued-code-error-message
            :error-type="issuedCodeError"
          ></issued-code-error-message>

          <div class="pb-4">
            <v-layout justify-center row>
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                class="v-btn--minwidth"
                color="primary"
              >
                次へ
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                class="v-btn--minwidth"
                color="negative"
                :disabled="submitting"
                :to="{ name: 'listKids' }"
              >
                戻る
              </v-btn>
            </v-layout>
          </div>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";
import IssuedCodeErrorMessage from "../../../components/messages/IssuedCodeErrorMessage";
import {
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_USED,
  API_ERROR_CODE_VALIDATION_ISSUED_CODE_EXPIRED
} from "../../../lib/lookmee_photo/ApiErrorCodes";

export default {
  components: {
    "issued-code-error-message": IssuedCodeErrorMessage
  },
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      issuedCodeError: null
    };
  },
  computed: {
    issuedCode1: {
      get() {
        return this.form.issuedCode1;
      },
      set(val) {
        this.form.issuedCode1 = val;
      }
    },
    issuedCode2: {
      get() {
        return this.form.issuedCode2;
      },
      set(val) {
        this.form.issuedCode2 = val;
      }
    },
    issuedCode3: {
      get() {
        return this.form.issuedCode3;
      },
      set(val) {
        this.form.issuedCode3 = val;
      }
    },
    kidName: {
      get() {
        return this.form.kidName;
      },
      set(val) {
        this.form.kidName = val;
      }
    },
    kidBirthDate: {
      get() {
        return this.form.kidBirthDate;
      },
      set(val) {
        this.form.kidBirthDate = val;
      }
    }
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.issuedCodeError = null;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        this.form.isNewKid = true;
        const [organization, groups] = await this.$store.dispatch(
          "verifyIssuedFamily",
          { code: this.form.issuedCode() }
        );
        if (groups.length === 0) {
          this.issuedCodeError = "noGroups";
        } else {
          this.form.organization = organization;
          this.form.groups = groups;
          this.$emit("next-to-select-group");
        }
      } catch (errors) {
        if (errors.status === 400) {
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_VALIDATION_ISSUED_CODE_NOT_FOUND:
              this.issuedCodeError = "notFound";
              break;
            case API_ERROR_CODE_VALIDATION_ISSUED_CODE_USED:
              this.issuedCodeError = "used";
              break;
            case API_ERROR_CODE_VALIDATION_ISSUED_CODE_EXPIRED:
              this.issuedCodeError = "expired";
              break;
            default:
              this.handleApiErrors(errors, {
                store: this.$store,
                router: this.$router,
                sentry: this.sentry
              });
          }
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
