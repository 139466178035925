<template>
  <v-layout>
    <v-flex v-if="isReady" xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">home</v-icon>
        <br />{{ organization.name }}
      </h1>

      <v-card elevation-1>
        <v-card-text>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <p>
                決済の手続きが完了しました。<br />
                <br />
                <span class="caption">
                  ＊コンビニ決済を選択された場合は、レジにてお支払い後決済完了となります。
                </span>
              </p>

              <div class="my-3">
                <img
                  src="../../assets/images/completed.png"
                  class="payment-completed-image"
                />
              </div>

              <router-link :to="{ name: 'listOrderHistories' }">
                注文履歴へ戻る ≫
              </router-link>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    isReady() {
      return this.order && this.organization;
    },
    organization() {
      return this.order && this.order.organization;
    }
  },
  async created() {
    await this.fetchInitData();

    this.updateDataLayer();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.order = await this.apiClient.getOrder(this.orderId);
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    updateDataLayer() {
      const isAccepted = this.order.isAccepted;
      const acceptedDate =
        isAccepted && this.order.acceptedDate
          ? dayjs(this.order.acceptedDate).format("YYYY-MM-DD")
          : null;

      if (this.order.isPhotoBookOrder) {
        dataLayer.push({
          album_pay_complete: isAccepted,
          album_pay_date: acceptedDate
        });
      } else {
        dataLayer.push({
          photo_pay_complete: isAccepted,
          photo_pay_date: acceptedDate
        });
      }
    }
  }
};
</script>

<style scoped>
.payment-completed-image {
  max-width: 100%;
}
</style>
