import { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";

import UserRegistration from "../../models/UserRegistration";
import PasswordReset from "../../models/PasswordReset";
import User from "../../models/User";
import Family from "../../models/Family";
import Face from "../../models/Face";
import FaceRegistration from "../../models/FaceRegistration";
import UserAddress from "../../models/UserAddress";
import Organization from "../../models/Organization";
import Group from "../../models/Group";
import SalesManagement from "../../models/SalesManagement";
import Event from "../../models/Event";
import SalesItem from "../../models/SalesItem";
import Cart, { PictureOrder, VideoOrder } from "../../models/Cart";
import Picture from "../../models/Picture";
import Video from "../../models/Video";
import Order from "../../models/Order";
import OrderDetailPicture from "../../models/OrderDetailPicture";
import StoreEstimate from "../../models/StoreEstimate";
import Announcement from "../../models/Announcement";
import Reminder from "../../models/Reminder";
import Notifications from "../../models/Notifications";
import Kid from "../../models/Kid";
import KidsBelong from "../../models/KidsBelong";
import FamilyInvitation from "../../models/FamilyInvitation";
import Karte from "../../models/Karte";
import AgreementVersion from "../../models/AgreementVersion";

import ApiErrors from "./ApiErrors";
import ApiError from "./ApiError";

class ApiClient {
  axios: AxiosInstance;
  version: number;

  constructor(config: { axios: AxiosInstance; version: number }) {
    this.axios = this.setAxiosInterceptors(config.axios);
    this.version = config.version;
  }

  private setAxiosInterceptors(axios: AxiosInstance): AxiosInstance {
    // 全てのAPIリクエストでセットするHTTPヘッダー
    axios.interceptors.request.use(
      (config: AxiosRequestConfig): AxiosRequestConfig => {
        config.headers["X-Client-Version"] = this.version;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    return axios;
  }

  async login(data: {
    email: string;
    password: string;
    rememberMe: boolean;
  }): Promise<string> {
    try {
      const response = await this.axios.post("session", {
        session: {
          email: data.email,
          password: data.password,
          rememberMe: data.rememberMe
        }
      });
      return response.headers.location;
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async logout(): Promise<void> {
    try {
      await this.axios.delete("session");
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getCurrentUser(): Promise<User> {
    try {
      const response = await this.axios.get("current_user");
      return new User(response.data.user);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getVersion(): Promise<number> {
    try {
      const response = await this.axios.get("version");
      return response.data.version;
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getLaw(): Promise<string> {
    try {
      const response = await this.axios.get("law");
      return response.data.law;
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getUserRegistration(token: string): Promise<UserRegistration> {
    try {
      const response = await this.axios.get(`user_registrations/${token}`);
      return new UserRegistration(response.data.userRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createUserRegistration(email: string): Promise<UserRegistration> {
    try {
      const response = await this.axios.post("user_registrations", {
        user_registration: {
          email: email
        }
      });
      return new UserRegistration(response.data.userRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async registerUser(data: {
    token: string;
    issuedCode: string;
    familyName: string;
    kidName: string;
    kidBirthDate: string;
    groupId: number;
    email: string;
    phoneNumber: string;
    password: string;
  }): Promise<User> {
    try {
      const response = await this.axios.post("users/register", {
        user: {
          token: data.token,
          issuedCode: data.issuedCode,
          familyName: data.familyName,
          kidName: data.kidName,
          kidBirthDate: data.kidBirthDate,
          groupId: data.groupId,
          email: data.email,
          phoneNumber: data.phoneNumber,
          password: data.password
        }
      });
      return new User(response.data.user);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async changeFamily(familyId: number): Promise<Family> {
    try {
      const response = await this.axios.post("session/change_family", {
        familyId: familyId
      });
      return new Family(response.data.family);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async verifyIssuedFamily(data: {
    code: string;
    kidId?: number | null;
  }): Promise<[Organization, Array<Group>]> {
    try {
      const response = await this.axios.post("issued_families/verify", {
        issued_family: {
          code: data.code,
          kidId: data.kidId
        }
      });
      const organization = new Organization(response.data.organization);
      const groups = response.data.groups.map((g: any) => new Group(g));
      return [organization, groups];
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async sendActivationEmail(): Promise<void> {
    try {
      await this.axios.post(`users/send_activation_email`);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async activateUser(token: string): Promise<void> {
    try {
      await this.axios.post(`users/activate`, { user: { token: token } });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async setEmergency(data: {
    emergency: number;
    emergencyPhoneNumber: string | null;
  }): Promise<User> {
    try {
      // await this.axios.put(`emails/${data.id}/update_emergency_email`, {
      const response = await this.axios.put(`user_emergency`, {
        user: {
          emergency: data.emergency,
          emergencyPhoneNumber: data.emergencyPhoneNumber
        }
      });
      return new User(response.data.user);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async verifyPasswordReset(token: string): Promise<PasswordReset> {
    try {
      const response = await this.axios.post(`password_resets/verify`, {
        password_reset: {
          token: token
        }
      });
      const email = response.data.passwordReset.email;
      return new PasswordReset({ email, token });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createPasswordReset(email: string): Promise<PasswordReset> {
    try {
      await this.axios.post(`password_resets`, {
        password_reset: {
          email: email
        }
      });
      return new PasswordReset({ email });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async doPasswordReset(token: string, password: string): Promise<void> {
    try {
      await this.axios.post(`password_resets/reset_password`, {
        password_reset: {
          token: token,
          password: password
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getFamily(): Promise<Family> {
    try {
      const response = await this.axios.get("family");
      return new Family(response.data.family);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getFamilies(): Promise<Family[]> {
    try {
      const response = await this.axios.get("families");
      return response.data.families.map((f: any) => new Family(f));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async validateForUpdateFamily(data: {
    name: string;
    mobilePhones: Array<{
      id?: number;
      phoneNumber?: string;
      deleteFlag?: boolean;
    }>;
  }): Promise<boolean> {
    try {
      await this.axios.post("family/validate_for_update", { family: data });
      return true;
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async updateFamily(data: {
    name: string;
    mobilePhones: Array<{
      id?: number;
      phoneNumber?: string;
      deleteFlag?: boolean;
    }>;
  }): Promise<Family> {
    try {
      const response = await this.axios.patch("family", { family: data });
      return new Family(response.data.family);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async validateForUpdateUserEmail(data: { email: string }): Promise<boolean> {
    try {
      await this.axios.post("user_email/validate_for_update", { user: data });
      return true;
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async updateUserEmail(data: { email: string }): Promise<User> {
    try {
      const response = await this.axios.patch("user_email", { user: data });
      return new User(response.data.user);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getFaces(kidId: number): Promise<Array<Face>> {
    try {
      const response = await this.axios.get(`kids/${kidId}/faces`);
      return response.data.faces.map((f: any) => new Face(f));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async deleteFace(kidId: number, uuid: number): Promise<void> {
    try {
      await this.axios.delete(`kids/${kidId}/faces/${uuid}`);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async changePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    try {
      await this.axios.post("users/change_password", {
        user: { currentPassword, newPassword }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getUserAddresses(): Promise<Array<UserAddress>> {
    try {
      const response = await this.axios.get("user_addresses");
      return response.data.userAddresses.map((ua: any) => new UserAddress(ua));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getUserAddress(id: number): Promise<UserAddress> {
    try {
      const response = await this.axios.get(`user_addresses/${id}`);
      return new UserAddress(response.data.userAddress);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async validateForCreateUserAddress(data: {
    fullName: string;
    postcode: string;
    prefecture: string;
    city: string;
    street: string;
    building: string | null;
    telephone: string;
  }): Promise<void> {
    try {
      await this.axios.post("user_addresses/validate_for_create", {
        userAddress: {
          fullName: data.fullName,
          postcode: data.postcode,
          prefecture: data.prefecture,
          city: data.city,
          street: data.street,
          building: data.building,
          telephone: data.telephone
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createUserAddress(data: {
    fullName: string;
    postcode: string;
    prefecture: string;
    city: string;
    street: string;
    building: string | null;
    telephone: string;
  }): Promise<UserAddress> {
    try {
      const response = await this.axios.post("user_addresses", {
        userAddress: {
          fullName: data.fullName,
          postcode: data.postcode,
          prefecture: data.prefecture,
          city: data.city,
          street: data.street,
          building: data.building,
          telephone: data.telephone
        }
      });
      return new UserAddress(response.data.userAddress);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async validateForUpdateUserAddress(
    userAddressId: number,
    data: {
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<void> {
    try {
      await this.axios.post(
        `user_addresses/${userAddressId}/validate_for_update`,
        {
          userAddress: {
            fullName: data.fullName,
            postcode: data.postcode,
            prefecture: data.prefecture,
            city: data.city,
            street: data.street,
            building: data.building,
            telephone: data.telephone
          }
        }
      );
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async updateUserAddress(
    userAddressId: number,
    data: {
      fullName: string;
      postcode: string;
      prefecture: string;
      city: string;
      street: string;
      building: string | null;
      telephone: string;
    }
  ): Promise<UserAddress> {
    try {
      const response = await this.axios.patch(
        `user_addresses/${userAddressId}`,
        {
          userAddress: {
            fullName: data.fullName,
            postcode: data.postcode,
            prefecture: data.prefecture,
            city: data.city,
            street: data.street,
            building: data.building,
            telephone: data.telephone
          }
        }
      );
      return new UserAddress(response.data.userAddress);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async makeDefaultUserAddress(userAddressId: number): Promise<UserAddress> {
    try {
      const response = await this.axios.put(
        `user_addresses/${userAddressId}/make_default`
      );
      return new UserAddress(response.data.userAddress);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async deleteUserAddress(userAddressId: number): Promise<void> {
    try {
      await this.axios.delete(`user_addresses/${userAddressId}`);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async registerKid(data: {
    issuedCode: string;
    groupId: number;
    kidName: string;
    kidBirthDate: string;
  }): Promise<Kid> {
    try {
      const response = await this.axios.post("kids/register", {
        kid: {
          issuedCode: data.issuedCode,
          groupId: data.groupId,
          kidName: data.kidName,
          kidBirthDate: data.kidBirthDate
        }
      });
      return new Kid(response.data.kid);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async transferKid(data: {
    issuedCode: string;
    groupId: number;
    kidId: number;
    kidBirthDate: string;
  }): Promise<Kid> {
    try {
      const response = await this.axios.post("kids/transfer", {
        kid: {
          issuedCode: data.issuedCode,
          groupId: data.groupId,
          kidId: data.kidId,
          kidBirthDate: data.kidBirthDate
        }
      });
      return new Kid(response.data.kid);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getOrganizations(): Promise<Array<Organization>> {
    try {
      const response = await this.axios.get("organizations");
      return response.data.organizations.map((o: any) => new Organization(o));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getOrganization(id: number): Promise<Organization> {
    try {
      const response = await this.axios.get(`organizations/${id}`);
      return new Organization(response.data.organization);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getSalesManagements(
    organizationId: number
  ): Promise<Array<SalesManagement>> {
    try {
      const response = await this.axios.get(
        `organizations/${organizationId}/sales_managements`
      );
      return response.data.salesManagements.map(
        (sm: any) => new SalesManagement(sm)
      );
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async pagesSalesManagement(
    organizationId: number,
    id: number
  ): Promise<{
    organization: Organization;
    groups: Array<Group>;
    salesManagement: SalesManagement;
    cart: Cart;
    purchasedPictureIds: Set<number>;
    purchasedVideoIds: Set<number>;
  }> {
    try {
      const response = await this.axios.get(
        `pages/organizations/${organizationId}/sales_managements/${id}`
      );
      return {
        organization: new Organization(response.data.organization),
        groups: response.data.groups.map((g: any) => new Group(g)),
        salesManagement: new SalesManagement(response.data.salesManagement),
        cart: new Cart(response.data.cart),
        purchasedPictureIds: new Set<number>(response.data.purchasedPictureIds),
        purchasedVideoIds: new Set<number>(response.data.purchasedVideoIds)
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async pagesSalesManagementSelectPhotoBookType(
    organizationId: number,
    id: number
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
  }> {
    try {
      const response = await this.axios.get(
        `pages/organizations/${organizationId}/sales_managements/${id}/select_photo_book_type`
      );
      return {
        organization: new Organization(response.data.organization),
        salesManagement: new SalesManagement(response.data.salesManagement),
        cart: new Cart(response.data.cart)
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async pagesCart(
    organizationId: number,
    salesManagementId: number
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
    pictures: Array<Picture>;
    videos: Array<Video>;
    photoBookPictures: Array<Picture>;
    purchasedPictureIds: Set<number>;
    purchasedVideoIds: Set<number>;
  }> {
    try {
      const response = await this.axios.get(
        `pages/organizations/${organizationId}/sales_managements/${salesManagementId}/cart`
      );
      return {
        organization: new Organization(response.data.organization),
        salesManagement: new SalesManagement(response.data.salesManagement),
        cart: new Cart(response.data.cart),
        pictures: response.data.pictures.map((p: any) => new Picture(p)),
        videos: response.data.videos.map((v: any) => new Video(v)),
        photoBookPictures: response.data.photoBookPictures.map(
          (p: any) => new Picture(p)
        ),
        purchasedPictureIds: new Set<number>(response.data.purchasedPictureIds),
        purchasedVideoIds: new Set<number>(response.data.purchasedVideoIds)
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getCart(
    organizationId: number,
    salesManagementId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.get(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async addPictureToCart(
    organizationId: number,
    salesManagementId: number,
    data: { pictureId: number; count: number }
  ): Promise<Cart> {
    try {
      const response = await this.axios.put(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/pictures`,
        {
          cartPicture: {
            id: data.pictureId,
            count: data.count
          }
        }
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async removePictureFromCart(
    organizationId: number,
    salesManagementId: number,
    pictureId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.delete(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/pictures/${pictureId}`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async addVideoToCart(
    organizationId: number,
    salesManagementId: number,
    data: {
      videoId: number;
      count: number;
    }
  ): Promise<Cart> {
    try {
      const response = await this.axios.put(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/videos`,
        {
          cartVideo: {
            id: data.videoId,
            count: data.count
          }
        }
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async removeVideoFromCart(
    organizationId: number,
    salesManagementId: number,
    videoId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.delete(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/videos/${videoId}`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async updateCartPhotoBook(
    organizationId: number,
    salesManagementId: number,
    mediaType: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.put(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book`,
        {
          cartPhotoBook: { mediaType }
        }
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async deleteCartPhotoBook(
    organizationId: number,
    salesManagementId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.delete(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async updateCartPhotoBookOrderAmount(
    organizationId: number,
    salesManagementId: number,
    amount: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.patch(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book/order_amount`,
        {
          cartPhotoBookOrderAmount: { amount }
        }
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async addPhotoBookPictureToCart(
    organizationId: number,
    salesManagementId: number,
    pictureId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.post(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book/pictures`,
        {
          cartPhotoBookPicture: { pictureId }
        }
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async removePhotoBookPictureFromCart(
    organizationId: number,
    salesManagementId: number,
    pictureId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.delete(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book/pictures/${pictureId}`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async removeAllPhotoBookPicturesFromCart(
    organizationId: number,
    salesManagementId: number
  ): Promise<Cart> {
    try {
      const response = await this.axios.delete(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/cart/photo_book/pictures`
      );
      return new Cart(response.data.cart);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getEvents(
    organizationId: number,
    salesManagementId: number,
    params: {
      groupId: number;
    }
  ): Promise<Array<Event>> {
    try {
      const response = await this.axios.get(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/events`,
        {
          params: {
            groupId: params.groupId
          }
        }
      );
      return response.data.events.map((e: any) => new Event(e));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getSalesItems(
    organizationId: number,
    salesManagementId: number,
    data: {
      groupId: number;
      eventId: number;
      page: number;
    }
  ): Promise<{ salesItems: Array<SalesItem>; pagination: any }> {
    try {
      const response = await this.axios.get(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/sales_items`,
        {
          params: {
            groupId: data.groupId,
            eventId: data.eventId,
            page: data.page
          }
        }
      );
      const salesItems = response.data.salesItems.map(
        (si: any) => new SalesItem(si)
      );
      const pagination = response.data.meta.pagination;
      return { salesItems, pagination };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getOrders(): Promise<Array<Order>> {
    try {
      const response = await this.axios.get(`orders`);
      return response.data.orders.map((o: any) => new Order(o));
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getOrder(id: number): Promise<Order> {
    try {
      const response = await this.axios.get(`orders/${id}`);
      return new Order(response.data.order);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async cancelOrder(id: number): Promise<object> {
    try {
      return await this.axios.delete(`orders/${id}/order_cancel`);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getOriginalPictureUrl(orderId: number, id: number): Promise<string> {
    try {
      const response = await this.axios.get(
        `orders/${orderId}/order_details/${id}/original_picture_url`
      );
      return response["data"]["originalPictureUrl"];
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async pagesShowStore(
    organizationId: number,
    salesManagementId: number
  ): Promise<{
    organization: Organization;
    salesManagement: SalesManagement;
    cart: Cart;
    pictures: Array<Picture>;
    videos: Array<Video>;
    photoBookPictures: Array<Picture>;
  }> {
    try {
      const response = await this.axios.get(
        `pages/organizations/${organizationId}/sales_managements/${salesManagementId}/store`
      );
      return {
        organization: new Organization(response.data.organization),
        salesManagement: new SalesManagement(response.data.salesManagement),
        cart: new Cart(response.data.cart),
        pictures: response.data.pictures.map((p: any) => new Picture(p)),
        videos: response.data.videos.map((v: any) => new Video(v)),
        photoBookPictures: response.data.photoBookPictures.map(
          (p: any) => new Picture(p)
        )
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async estimateOrder(
    organizationId: number,
    salesManagementId: number,
    data: {
      orderType: number;
      paymentMethod: number;
      pictureOrders: Array<PictureOrder>;
      videoOrders: Array<VideoOrder>;
      photoBookOrder: {
        mediaType: number;
        orderAmount: number;
        pictureIds: Array<number>;
      };
    }
  ): Promise<StoreEstimate> {
    try {
      const response = await this.axios.post(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/store/estimate`,
        {
          estimateForm: {
            orderType: data.orderType,
            paymentMethod: data.paymentMethod,
            pictureOrders: data.pictureOrders,
            videoOrders: data.videoOrders,
            photoBookOrder: data.photoBookOrder
          }
        }
      );
      return new StoreEstimate(response.data.storeEstimate);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async purchaseOrder(
    organizationId: number,
    salesManagementId: number,
    data: {
      orderType: number;
      paymentMethod: number;
      ownerName: string;
      ownerEmail: string;
      userAddressId: number | null;
      pictureOrders: Array<PictureOrder>;
      videoOrders: Array<VideoOrder>;
      photoBookOrder: {
        mediaType: number;
        orderAmount: number;
        pictureIds: Array<number>;
      };
    }
  ): Promise<{
    orderId: number;
    orderOrderedDateString: string;
    paymentUrl: string;
  }> {
    try {
      const response = await this.axios.post(
        `organizations/${organizationId}/sales_managements/${salesManagementId}/store/purchase`,
        {
          purchaseForm: {
            orderType: data.orderType,
            paymentMethod: data.paymentMethod,
            ownerName: data.ownerName,
            ownerEmail: data.ownerEmail,
            userAddressId: data.userAddressId,
            pictureOrders: data.pictureOrders,
            videoOrders: data.videoOrders,
            photoBookOrder: data.photoBookOrder
          }
        }
      );
      const orderId = response.data.purchaseResult.orderId;
      const orderOrderedDateString =
        response.data.purchaseResult.orderOrderedDateString;
      const paymentUrl = response.data.purchaseResult.paymentUrl;
      return { orderId, orderOrderedDateString, paymentUrl };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  // ======================= NOTIFICATIONS =======================
  async initNotifications(): Promise<Notifications> {
    try {
      const response = await this.axios.get("pages/notifications");
      return new Notifications(response.data);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async fetchAnnouncement(id: number): Promise<Announcement> {
    try {
      const response = await this.axios.get(`announcements/${id}`);
      return new Announcement(response.data.announcement);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async fetchReminder(id: number): Promise<Reminder> {
    try {
      const response = await this.axios.get(`reminders/${id}`);
      return new Reminder(response.data.reminder);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }
  // ============================ END ============================

  async pagesFrPurchasedPictures(): Promise<{
    purchasedPictures: Array<OrderDetailPicture>;
  }> {
    try {
      const response = await this.axios.get("pages/fr_purchased_pictures");
      return {
        purchasedPictures: response.data.purchasedPictures.map(
          (p: any) => new OrderDetailPicture(p)
        )
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getFaceRegistration(id: number): Promise<FaceRegistration> {
    try {
      const response = await this.axios.get(`face_registrations/${id}`);
      return new FaceRegistration(response.data.faceRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createFaceRegistration(data: {
    kidId: number;
    sourceType: number;
    orderDetailPictureId?: number;
  }): Promise<FaceRegistration> {
    try {
      const response = await this.axios.post("face_registrations", {
        faceRegistration: {
          kidId: data.kidId,
          sourceType: data.sourceType,
          orderDetailPictureId: data.orderDetailPictureId
        }
      });
      return new FaceRegistration(response.data.faceRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async startFaceDetectionFaceRegistration(
    id: number
  ): Promise<FaceRegistration> {
    try {
      const response = await this.axios.post(
        `face_registrations/${id}/start_face_detection`
      );
      return new FaceRegistration(response.data.faceRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async startFaceRegistrationFaceRegistration(
    id: number,
    faceIndex: number
  ): Promise<FaceRegistration> {
    try {
      const response = await this.axios.post(
        `face_registrations/${id}/start_face_registration`,
        {
          faceRegistration: { faceIndex }
        }
      );
      return new FaceRegistration(response.data.faceRegistration);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async deleteFaceRegistration(id: number): Promise<void> {
    try {
      await this.axios.delete(`face_registrations/${id}`);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  //===================== Inquiry =========================
  async pagesNewInquiry(): Promise<{ organizations: Array<Organization> }> {
    try {
      const response = await this.axios.get("pages/inquiries/new");
      const organizations = response.data.organizations.map(
        (o: any) => new Organization(o)
      );
      return { organizations };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async validateNewInquiry(data: {
    name: string;
    email: string;
    tel: string;
    organizationId: number | null;
    organizationName: string;
    category: number;
    issuedCode: string;
    text: string | undefined;
  }): Promise<void> {
    try {
      await this.axios.post("inquiries/validate", {
        inquiry: {
          name: data.name,
          email: data.email,
          tel: data.tel,
          organizationId: data.organizationId,
          organizationName: data.organizationName,
          category: data.category,
          issuedCode: data.issuedCode,
          text: data.text
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createInquiry(data: {
    name: string;
    email: string;
    tel: string;
    organizationId: number | null;
    organizationName: string;
    category: number;
    issuedCode: string;
    text: string | undefined;
  }): Promise<void> {
    try {
      await this.axios.post("inquiries", {
        inquiry: {
          name: data.name,
          email: data.email,
          tel: data.tel,
          organizationId: data.organizationId,
          organizationName: data.organizationName,
          category: data.category,
          issuedCode: data.issuedCode,
          text: data.text
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }
  //======================= END ===========================

  // ======================= Karte ========================
  async getKarte(): Promise<Karte> {
    try {
      const response = await this.axios.get("karte");
      return new Karte(response.data);
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }
  //======================= END ===========================

  // ======================= KIDS =========================
  async getKids(): Promise<{
    kids: Array<Kid>;
    kidsBelongs: Array<KidsBelong>;
    groups: Array<Group>;
    organizations: Array<Organization>;
  }> {
    try {
      const response = await this.axios.get("pages/kids");
      const kids = response.data.kids.map((k: any) => new Kid(k));
      const kidsBelongs = response.data.kidsBelongs.map(
        (kb: any) => new KidsBelong(kb)
      );
      const groups = response.data.groups.map((g: any) => new Group(g));
      const organizations = response.data.organizations.map(
        (o: any) => new Organization(o)
      );
      return { kids, kidsBelongs, groups, organizations };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async getKid(
    kidId: number
  ): Promise<{
    kid: Kid;
    kidsBelongs: Array<KidsBelong>;
    groups: Array<Group>;
    organizations: Array<Organization>;
  }> {
    try {
      const response = await this.axios.get(`pages/kids/${kidId}`);
      const kid = new Kid(response.data.kid);
      const kidsBelongs = response.data.kidsBelongs.map(
        (kb: any) => new KidsBelong(kb)
      );
      const groups = response.data.groups.map((g: any) => new Group(g));
      const organizations = response.data.organizations.map(
        (o: any) => new Organization(o)
      );
      return { kid, kidsBelongs, groups, organizations };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }
  // ======================= END ==========================

  // =================== Family Invitation ================
  async sendFamilyInvitation(data: { email: string }): Promise<void> {
    try {
      await this.axios.post("family_invitations", {
        family_invitation: { email: data.email }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async verifyInvitationToken(
    token: string
  ): Promise<{
    family: Family;
    familyInvitation: FamilyInvitation;
  }> {
    try {
      const response = await this.axios.post("family_invitations/verify", {
        family_invitation: { token: token }
      });
      return {
        family: new Family(response.data.family),
        familyInvitation: new FamilyInvitation(response.data.familyInvitation)
      };
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createInvitedUser(data: {
    familyId: number;
    token: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    termsAgreed: boolean;
    privacyPolicyAgreed: boolean;
  }): Promise<void> {
    try {
      await this.axios.post("invited_users", {
        invited_user: {
          familyId: data.familyId,
          token: data.token,
          email: data.email,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          termsAgreed: data.termsAgreed,
          privacyPolicyAgreed: data.privacyPolicyAgreed
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async createFamiliesUser(data: {
    email: string;
    password: string;
    token: string;
    familyId: number;
  }): Promise<void> {
    try {
      await this.axios.post("families_users", {
        families_user: {
          email: data.email,
          password: data.password,
          token: data.token,
          familyId: data.familyId
        }
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  // ======================= END ==========================

  async getAgreementVersions(): Promise<AgreementVersion[]> {
    try {
      const response = await this.axios.get("agreement_versions");
      return response.data.agreementVersions.map(
        (av: any) => new AgreementVersion(av)
      );
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async agreeTermsAndPolicy(data: {
    id: number;
    category: string;
  }): Promise<void> {
    try {
      await this.axios.patch(`agreement_versions/${data.id}/agree`, {
        category: data.category
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  async skipTermsAndPolicy(data: {
    id: number;
    category: string;
  }): Promise<void> {
    try {
      await this.axios.patch(`agreement_versions/${data.id}/skip`, {
        category: data.category
      });
    } catch (error) {
      throw ApiClient.convertError(error);
    }
  }

  static convertError(axiosError: AxiosError): ApiErrors {
    const response = axiosError.response;
    if (response) {
      if (response.data.errors) {
        const errors = response.data.errors.map((e: any) => new ApiError(e));
        return new ApiErrors({
          status: response.status,
          errors: errors,
          response: response
        });
      } else {
        return new ApiErrors({
          status: response.status,
          errors: ApiErrors.defaultServerErrors(),
          response: response
        });
      }
    } else {
      return new ApiErrors({
        status: 0,
        errors: ApiErrors.defaultNetworkErrors()
      });
    }
  }
}

export default ApiClient;
