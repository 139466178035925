<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">help_outline</v-icon
        ><br />お問い合わせ
      </h1>
      <div class="pa-3">
        <p>
          お問い合わせの前に<a
            href="https://lookmee.tayori.com/faq/6dee8ffd1c5ae520a999d257ebf2fc8d2b2f835d"
            target="_blank"
            >よくある質問</a
          >をご確認ください。
        </p>
        <p class="caption">
          よくある質問で解決しない場合は以下のフォームにご入力のうえ、お問い合わせください。<br />
          3営業日以内にご回答させていただきます。
        </p>
      </div>

      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <section>
          <h2 class="title-bar">お名前</h2>
          <v-layout pa-3>
            <v-flex>
              <v-text-field
                v-model="form.name"
                label="お名前"
                outline
                validate-on-blur
                required
                :rules="form.nameRules()"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">メールアドレス</h2>
          <v-layout pa-3>
            <v-flex>
              {{ form.email }}
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">電話番号　※半角</h2>
          <v-layout pa-3>
            <v-flex>
              <v-text-field
                v-model="form.tel"
                label="電話番号"
                outline
                validate-on-blur
                required
                :rules="form.telRules()"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </section>

        <section>
          <h2 class="title-bar">園・施設</h2>
          <div class="pa-3">
            <template v-if="organizations.length > 0">
              <v-radio-group
                v-model="organizationId"
                :rules="form.organizationIdRules()"
              >
                <v-radio
                  v-for="organization in organizations"
                  :key="organization.uniqueKey"
                  :label="organization.name"
                  :value="organization.id"
                ></v-radio>
              </v-radio-group>
            </template>
            <template v-else>
              <v-flex>
                <v-text-field
                  v-model="form.organizationName"
                  label="法人名"
                  outline
                  validate-on-blur
                  required
                  :rules="form.organizationNameRules()"
                ></v-text-field>
              </v-flex>
            </template>
          </div>
        </section>

        <section>
          <h2 class="title-bar">お問い合わせ分類</h2>
          <div class="pa-3">
            <v-radio-group
              v-model="form.category"
              :rules="form.categoryRules()"
            >
              <v-radio label="登録用コード期限切れ" value="0"></v-radio>
              <v-radio label="販売終了・再販予定について" value="2"></v-radio>
              <v-radio label="ご意見・その他" value="1"></v-radio>
            </v-radio-group>
          </div>
        </section>

        <section v-if="isRegExpireCode">
          <h2 class="title-bar">期限切れコードの入力</h2>
          <div class="pa-3">
            <v-layout align-center justify-center row fill-height>
              <v-flex xs4 sm3 text-xs-center>
                <v-text-field
                  ref="code1Field"
                  v-model="form.issuedCode1"
                  maxlength="5"
                  placeholder="12345"
                  outline
                  single-line
                  hide-details
                  validate-on-blur
                  class="text-xs-center"
                  required
                  :rules="form.issuedCode1Rules()"
                ></v-text-field>
              </v-flex>
              <v-flex xs1 sm1 text-xs-center>
                ー
              </v-flex>
              <v-flex xs3 sm3 text-xs-center>
                <v-text-field
                  ref="code2Field"
                  v-model="form.issuedCode2"
                  maxlength="3"
                  placeholder="123"
                  outline
                  single-line
                  hide-details
                  validate-on-blur
                  required
                  :rules="form.issuedCode2Rules()"
                ></v-text-field>
              </v-flex>
              <v-flex xs1 sm1 text-xs-center>
                ー
              </v-flex>
              <v-flex xs3 sm3 text-xs-center>
                <v-text-field
                  ref="code3Field"
                  v-model="form.issuedCode3"
                  maxlength="3"
                  placeholder="456"
                  outline
                  single-line
                  hide-details
                  validate-on-blur
                  required
                  :rules="form.issuedCode3Rules()"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </div>
          <div class="pa-3">
            <ul class="px-3 caption error--text">
              <template v-if="$refs.code1Field">
                <li
                  v-for="(msg, i) in $refs.code1Field.validations"
                  :key="`code1FieldErrorMessage-${i}`"
                >
                  {{ msg }}
                </li>
              </template>
              <template v-if="$refs.code2Field">
                <li
                  v-for="(msg, i) in $refs.code2Field.validations"
                  :key="`code2FieldErrorMessage-${i}`"
                >
                  {{ msg }}
                </li>
              </template>
              <template v-if="$refs.code3Field">
                <li
                  v-for="(msg, i) in $refs.code3Field.validations"
                  :key="`code3FieldErrorMessage-${i}`"
                >
                  {{ msg }}
                </li>
              </template>
            </ul>
          </div>
        </section>

        <section v-if="isOtherInquiry">
          <h2 class="title-bar">ご意見・お問い合わせ内容</h2>
          <div class="pa-3">
            <v-textarea
              v-model="form.text"
              outline
              single-line
              required
              :rules="form.textRules()"
            ></v-textarea>
          </div>
        </section>

        <invalid-form-error v-if="$refs.form" :form="$refs.form" />

        <section v-if="errors.length > 0" class="py-3">
          <v-layout justify-center="center" row>
            <div class="caption error--text">
              <ul v-for="err in errors" :key="err.message">
                <li>{{ err.message }}</li>
              </ul>
            </div>
          </v-layout>
        </section>

        <section class="py-3">
          <v-layout justify-center row>
            <v-btn
              type="submit"
              :loading="submitting"
              :disabled="submitting"
              class="v-btn--minwidth"
              color="primary"
            >
              確認する
            </v-btn>
          </v-layout>
        </section>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";
import InquiryForm from "../../../forms/InquiryForm";
import InvalidFormError from "../../../components/InvalidFormError";
import { mapState } from "vuex";

export default {
  name: "InputSubPage",
  components: {
    "invalid-form-error": InvalidFormError
  },
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: InquiryForm,
      required: true
    }
  },
  data() {
    return {
      organizations: [],
      errors: [],
      submitting: false
    };
  },
  computed: {
    isRegExpireCode() {
      return this.form.category === "0";
    },
    isOtherInquiry() {
      return this.form.category === "1";
    },
    isSaleResale() {
      return this.form.category === "2";
    },
    // TODO: Use the following computed properties in the form
    name: {
      get() {
        return this.form.name;
      },
      set(val) {
        this.form.name = val;
      }
    },
    tel: {
      get() {
        return this.form.tel;
      },
      set(val) {
        this.form.tel = val;
      }
    },
    organizationId: {
      get() {
        return this.form.organizationId;
      },
      set(val) {
        this.form.organizationId = val;
      }
    },
    organizationName: {
      get() {
        return this.form.organizationName;
      },
      set(val) {
        this.form.organizationName = val;
      }
    },
    category: {
      get() {
        return this.form.category;
      },
      set(val) {
        this.form.category = val;
      }
    },
    issuedCode1: {
      get() {
        return this.form.issuedCode1;
      },
      set(val) {
        this.form.issuedCode1 = val;
      }
    },
    issuedCode2: {
      get() {
        return this.form.issuedCode2;
      },
      set(val) {
        this.form.issuedCode2 = val;
      }
    },
    issuedCode3: {
      get() {
        return this.form.issuedCode3;
      },
      set(val) {
        this.form.issuedCode3 = val;
      }
    },
    text: {
      get() {
        return this.form.text;
      },
      set(val) {
        this.form.text = val;
      }
    }
  },
  watch: {
    category(val) {
      // Registration code expire selected
      if (val === "0") {
        this.form.text = "";
      }
      // Other opinion selected
      if (val === "1") {
        this.form.issuedCode1 = "";
        this.form.issuedCode2 = "";
        this.form.issuedCode3 = "";
      }
      // Sale/Resale selected
      if (val === "2") {
        this.form.issuedCode1 = "";
        this.form.issuedCode2 = "";
        this.form.issuedCode3 = "";
        this.form.text = "";
      }
    },
    organizationId(val) {
      if (val) {
        this.organizationName = this.organizations.find(organization => {
          return organization.id === this.form.organizationId;
        }).name;
      }
    }
  },
  created() {
    this.pagesNewInquiry();
  },
  methods: {
    async pagesNewInquiry() {
      this.$store.dispatch("startLoading");
      try {
        let response = await this.$store.dispatch("inquiry/pagesNewInquiry");
        this.organizations = response["organizations"];
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async submitForm() {
      if (this.isSaleResale) {
        this.$router.push({ hash: "confirm" });
        return;
      }

      if (this.submitting) {
        return;
      }

      this.resetErrors();
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      this.$store.dispatch("startLoading");

      try {
        let response = await this.$store.dispatch(
          "inquiry/validateNewInquiry",
          this.form.toParams()
        );
        this.$router.push({ hash: "confirm" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors["errors"];
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
        this.$store.dispatch("endLoading");
      }
    },
    resetErrors() {
      this.errors = [];
    }
  }
};
</script>
