class FamilyInvitation {
  familyId: number;
  inviterUserId: number;
  email: string;
  token: string;

  constructor(data: {
    familyId: number;
    inviterUserId: number;
    email: string;
    token: string;
  }) {
    this.familyId = data.familyId;
    this.inviterUserId = data.inviterUserId;
    this.email = data.email;
    this.token = data.token;
  }
}

export default FamilyInvitation;
