<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />お届け先の情報
      </h1>

      <user-address-confirm-remove-dialog
        :user-address-id="removeUserAddressId"
        @cancel-remove="removeUserAddressId = null"
      ></user-address-confirm-remove-dialog>

      <v-card>
        <v-card-title>お届け先</v-card-title>

        <div
          v-for="(userAddress, i) in userAddresses"
          :key="userAddress.uniqueKey"
        >
          <user-address-row
            :user-address="userAddress"
            :index="i + 1"
            :show-make-default-button="userAddresses.length >= 2"
            @click-remove-link="removeUserAddressId = userAddress.id"
            @click-make-default-button="makeDefaultUserAddress"
          >
          </user-address-row>

          <v-divider
            v-if="i !== userAddresses.length - 1"
            class="mx-3"
          ></v-divider>
        </div>

        <div class="pa-4">
          <div class="btn-group text-xs-center">
            <v-btn
              class="v-btn--minwidth"
              color="primary"
              :to="{ name: 'newUserAddress' }"
            >
              新しいお届け先を追加する
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import UserAddressRow from "./components/UserAddressRow";
import UserAddressConfirmRemoveDialog from "./components/UserAddressConfirmRemoveDialog";

export default {
  components: {
    "user-address-row": UserAddressRow,
    "user-address-confirm-remove-dialog": UserAddressConfirmRemoveDialog
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      userAddresses: [],
      removeUserAddressId: null
    };
  },
  created() {
    this.reloadUserAddresses();
  },
  methods: {
    async reloadUserAddresses() {
      this.$store.dispatch("startLoading");
      try {
        this.userAddresses = await this.$store.dispatch(
          "family/getUserAddresses"
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async makeDefaultUserAddress({ userAddressId }) {
      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("family/makeDefaultUserAddress", {
          userAddressId
        });

        this.userAddresses = await this.$store.dispatch(
          "family/getUserAddresses"
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
