<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">email</v-icon>
        <br />園・施設からのお知らせ
      </h1>

      <cancel-dialog
        :dialog="dialog"
        @close-dialog="dialog = false"
      ></cancel-dialog>

      <v-card>
        <v-card-text>
          <p>
            園・施設からの一斉連絡メールを受け取るために、「設定する」リンクから電話番号の登録をしてください。
          </p>
          <p v-if="!user.isActivated">
            メールアドレスが表示されていない場合は、
            <router-link :to="{ name: 'listInactivatedEmails' }">
              こちら
            </router-link>
            をご確認ください。
          </p>
        </v-card-text>

        <v-card-title>メールアドレス</v-card-title>
        <template>
          <div class="pa-3">
            <div v-if="user.isActivated">
              <p class="mb-3">
                <template v-if="user.emergency">
                  <v-chip
                    label
                    color="primary"
                    text-color="white"
                    class="text-xs-center"
                    >設定済
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip
                    label
                    text-color="white"
                    class="text-xs-center chip-notset"
                    >未設定
                  </v-chip>
                </template>
                {{ user.email }}
              </p>
              <p class="text-xs-right">
                <router-link :to="{ name: 'editUserEmergency' }">
                  設定する
                </router-link>
                <a v-if="user.emergency" class="ml-3" @click="dialog = true">
                  解除する
                </a>
              </p>
            </div>
          </div>
        </template>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import CancelDialog from "./CancelDialog";

export default {
  components: {
    "cancel-dialog": CancelDialog
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState("user", { user: state => state.user })
  }
};
</script>
