import Announcement from "./Announcement";
import Reminder from "./Reminder";

class Notifications {
  announcements: Array<Announcement>;
  reminders: Array<Reminder>;

  constructor(data: { announcements: Array<any>; reminders: Array<any> }) {
    this.announcements = data.announcements
      ? data.announcements.map(a => new Announcement(a))
      : [];
    this.reminders = data.reminders
      ? data.reminders.map(r => new Reminder(r))
      : [];
  }
}

export default Notifications;
