import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { KarteState } from "./types";
import Karte from "../../models/Karte";

export const actions: ActionTree<KarteState, RootState> = {
  async getKarte(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<Karte> {
    const karte = await context.rootState.apiClient.getKarte();
    context.commit("setKarte", karte);
    return karte;
  }
};
