<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />ルクミーフォトのご案内
      </h1>
      <v-card>
        <v-card-text>
          <p class="text-xs-center">
            ルクミーフォトではお子さまの写真の閲覧や購入をすることができます。<br />
            お子さまの顔写真を登録すると、お子さまの写真を簡単に探せるようになります。
          </p>
          <img src="../../assets/images/completed.png" alt="" />
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth"
              color="primary"
              :to="{ name: 'listFrKids' }"
            >
              顔認識の登録をする
            </v-btn>
          </v-layout>
          <v-layout justify-center row>
            <v-btn
              class="v-btn--minwidth multi-line"
              color="negative"
              :to="organizationTopLink"
            >
              写真販売を見る
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      organization: "organization"
    }),
    organizationTopLink() {
      if (this.organization.id) {
        return {
          name: "organizationTop",
          params: { organizationId: this.organization.id }
        };
      } else {
        return { name: "organizations" };
      }
    }
  }
};
</script>
