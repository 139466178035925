const ESCAPE_CHAR_MAP = new Map<string, string>([
  ["&", "&amp;"],
  ["<", "&lt;"],
  [">", "&gt;"],
  ['"', "&quot;"],
  ["'", "&#39;"]
]);

/**
 * Return html escaped string.
 *
 * @param {string} str String that you want to escape.
 * @returns {string} html escaped string.
 */
export function escapeHtml(str: string): string {
  return str.replace(/[&<>"']/g, chr => ESCAPE_CHAR_MAP.get(chr) || "");
}

/**
 * Return padded string with specified char.
 *
 * @param {string | number} original
 * @param {number} length
 * @param {string} padChar, default '0'
 * @returns {string}
 */
export function padding(
  original: string | number,
  length: number,
  padChar = "0"
): string {
  const str = original.toString();
  const padNum = Math.max(length - str.length, 0);
  return padChar.repeat(padNum) + str;
}
