import OrderDetailPicture from "./OrderDetailPicture";
import Video from "./Video";

class OrderDetail {
  id: number;
  mediaType: number;
  quantity: number;
  picture: OrderDetailPicture | undefined;
  video: Video | undefined;

  constructor(data: {
    id: number;
    mediaType: number;
    quantity: number;
    picture?: any;
    video?: any;
  }) {
    this.id = data.id;
    this.mediaType = data.mediaType;
    this.quantity = data.quantity;
    if (data.picture) {
      this.picture = new OrderDetailPicture(data.picture);
    }
    if (data.video) {
      this.video = new Video(data.video);
    }
  }

  get uniqueKey() {
    return `orderDetail-${this.id}`;
  }
}

export default OrderDetail;
