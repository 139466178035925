import EnumValue from "./EnumValue";

class Picture {
  id: number;
  year: number;
  fileNo: number;
  takenDatetime: Date | null;
  width: number;
  height: number;
  sizeType: EnumValue<number>;
  printMediaType: number;
  dataMediaType: number;
  printAspectRatio: number;
  printAreaRatio: number;
  groupPhoto: boolean;
  thumbnailUrl: string | null;
  thumbnailBigUrl: string | null;
  faceKidIds: Array<number>;

  constructor(data: {
    id: number;
    year: number;
    fileNo: number;
    takenDatetime: string | null;
    width: number;
    height: number;
    sizeType: EnumValue<number>;
    printMediaType: number;
    dataMediaType: number;
    printAspectRatio: number;
    printAreaRatio: number;
    groupPhoto: boolean;
    thumbnailUrl: string;
    thumbnailBigUrl: string;
    faceKidIds: Array<number>;
  }) {
    this.id = data.id;
    this.year = data.year;
    this.fileNo = data.fileNo;
    this.takenDatetime =
      data.takenDatetime !== null ? new Date(data.takenDatetime) : null;
    this.width = data.width;
    this.height = data.height;
    this.sizeType = data.sizeType;
    this.printMediaType = data.printMediaType;
    this.dataMediaType = data.dataMediaType;
    this.printAspectRatio = data.printAspectRatio;
    this.printAreaRatio = data.printAreaRatio;
    this.groupPhoto = data.groupPhoto;
    this.thumbnailUrl = data.thumbnailUrl;
    this.thumbnailBigUrl = data.thumbnailBigUrl;
    this.faceKidIds = data.faceKidIds;
  }

  get uniqueKey(): string {
    return `picture-${this.id}`;
  }

  get sizeTypeLabel(): string {
    return this.sizeType ? this.sizeType.label : "";
  }

  get aspectRatio(): number {
    return this.width / this.height;
  }

  isTrimmingX(): boolean {
    return this.aspectRatio > this.printAspectRatio;
  }

  hasFace(kidIds: Set<number>): boolean {
    return !!this.faceKidIds.find(id => kidIds.has(id));
  }

  isLowResolution(): boolean {
    return this.height < 720 || this.width < 720;
  }
}

export default Picture;
