import Kid from "./Kid";
import MobilePhone from "./MobilePhone";
import Organization from "./Organization";

class Family {
  id: number;
  name: string;
  promotionMailDeliverable: boolean;
  kids: Array<Kid>;
  mobilePhones: Array<MobilePhone>;
  organizations: Array<Organization>;

  constructor(data: {
    id: number;
    name: string;
    promotionMailDeliverable: boolean;
    kids: Array<any>;
    mobilePhones: Array<any>;
    organizations: Array<any>;
  }) {
    this.id = data.id;
    this.name = data.name;
    this.promotionMailDeliverable = data.promotionMailDeliverable;
    this.kids = data.kids ? data.kids.map(k => new Kid(k)) : [];
    this.mobilePhones = data.mobilePhones
      ? data.mobilePhones.map(p => new MobilePhone(p))
      : [];
    this.organizations = data.organizations
      ? data.organizations.map(o => new Organization(o))
      : [];
  }
}

export default Family;
