<template>
  <div>
    <div
      class="square-wrapper"
      :class="{ border: border, 'no-cursor': noCursor }"
      :style="wrapperStyles"
    >
      <div class="square-spacer"></div>
      <div class="square-content">
        <div class="video-frame">
          <img
            :src="video.thumbnailUrl"
            class="video-thumbnail"
            @click="handleClicked"
          />
          <div
            v-if="!hidePlayIcon"
            class="video-play-icon"
            @click="handleClicked"
          >
            <v-icon large dark>
              play_circle_outline
            </v-icon>
          </div>
        </div>

        <div v-if="!hidePlayTime" class="video-frame-overlay video-play-time">
          {{ video.formattedPlayTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from "../models/Video";

export default {
  props: {
    video: {
      type: Video,
      required: true
    },
    hidePlayIcon: {
      type: Boolean,
      default: false
    },
    hidePlayTime: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    noCursor: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "#000000"
    }
  },
  computed: {
    wrapperStyles() {
      return {
        "background-color": `${this.backgroundColor}`
      };
    }
  },
  methods: {
    handleClicked() {
      this.$emit("play");
    }
  }
};
</script>

<style scoped>
.square-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.square-wrapper.border {
  border: 1px solid #d8d5d5;
}

.square-wrapper.no-cursor {
  cursor: default;
}

.square-wrapper .square-spacer {
  width: 100%;
  padding-bottom: 100%;
}

.square-wrapper .square-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video-frame {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-frame .video-thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
}

.video-frame .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.video-frame-overlay {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-play-time {
  left: 0;
  bottom: 0;
  color: #ffffff;
}
</style>
