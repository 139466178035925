class MobilePhone {
  id: number;
  phoneNumber: string;

  constructor(data: { id: number; phoneNumber: string }) {
    this.id = data.id;
    this.phoneNumber = data.phoneNumber;
  }

  get uniqueKey(): string {
    return `mobilePhone-${this.id}`;
  }
}

export default MobilePhone;
