<template>
  <v-toolbar
    fixed
    app
    flat
    class="toolbar"
    :style="{ 'border-color': $vuetify.theme.secondary }"
  >
    <v-toolbar-side-icon
      v-show="!hideIcons"
      class="ma-0"
      @click.native="toggleDrawer"
    >
    </v-toolbar-side-icon>

    <v-toolbar-title class="text-xs-center ml-0" style="flex-grow: 1;">
      <router-link :to="headerLinkParams">
        <img
          src="../assets/images/title.svg"
          alt="ルクミーフォト"
          class="title-image"
        />
      </router-link>
    </v-toolbar-title>

    <v-toolbar-items>
      <v-btn
        v-show="!hideIcons"
        flat
        class="cart-icon"
        :disabled="!cart"
        active-class=""
        @click.native="onClickCartIcon"
      >
        <v-badge left color="red" :value="!cartIsEmpty">
          <span slot="badge">{{ cartItemCountLabel }}</span>
          <v-icon text-xs-right>shopping_cart</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    hideIcons: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      organization: "organization"
    }),
    ...mapState("cart", {
      cart: "cart"
    }),
    ...mapGetters({
      isAuthenticated: "user/isAuthenticated",
      cartIsEmpty: "cart/isEmpty",
      cartItemCount: "cart/itemCount"
    }),
    headerLinkParams() {
      if (!this.isAuthenticated) {
        return { name: "login" };
      }

      if (this.cart) {
        return {
          name: "organizationMain",
          params: {
            organizationId: this.cart.organizationId,
            salesManagementId: this.cart.salesManagementId
          }
        };
      } else if (this.organization.id) {
        return {
          name: "organizationTop",
          params: { organizationId: this.organization.id }
        };
      } else {
        return { name: "organizations" };
      }
    },
    cartItemCountLabel() {
      return this.cartItemCount > 99 ? "99+" : this.cartItemCount.toString();
    }
  },
  watch: {
    // // // // // // // //
    // Karte / dataLayer //
    // // // // // // // //
    cart: {
      handler(newCart, oldCart) {
        // newCart is null after buying pictures/videos or photobook
        // So update dataLayer to show the emptied cart is now empty
        if (oldCart && newCart === null) {
          if (oldCart.photoBook.mediaType) {
            dataLayer.push({
              album_cart_quantity: 0
            });
          }
          if (oldCart.pictureAndVideoCount() > 0) {
            dataLayer.push({
              photo_quantity: 0
            });
          }
        } else if (
          oldCart &&
          newCart &&
          oldCart.salesManagementId === newCart.salesManagementId
        ) {
          // User has updated a cart
          // Find which cart was updated
          // If cart is empty we cannot know the cart type...
          // But either oldCart or newCart should not be empty (the cart is not changed from empty to empty)
          // The 2 carts in this case belong to the same sales management so are the same cart
          let cartType;
          if (oldCart.photoBook.mediaType || newCart.photoBook.mediaType) {
            cartType = "photobook";
          }
          if (
            oldCart.pictureAndVideoCount() > 0 ||
            newCart.pictureAndVideoCount() > 0
          ) {
            cartType = "picture_video";
          }

          // Update the correct cart
          if (cartType === "photobook") {
            dataLayer.push({
              event: "cart_update",
              album_cart_quantity: newCart.itemCount()
            });
          } else if (cartType === "picture_video") {
            dataLayer.push({
              event: "cart_update",
              photo_quantity: newCart.itemCount()
            });
          }
        }
      },
      deep: true
    }
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("toggleDrawer");
    },
    onClickCartIcon() {
      if (!this.cart) {
        return;
      }

      this.$router.push({
        name: "organizationCart",
        params: {
          organizationId: this.cart.organizationId,
          salesManagementId: this.cart.salesManagementId
        }
      });
    }
  }
};
</script>

<style scoped>
.toolbar {
  background-color: #ffffff;
  border-style: solid;
  border-width: 0 0 5px 0;
}

.title-image {
  width: auto;
  height: 36px;
}

.cart-icon {
  min-width: 0;
  padding: 0 0 0 12px;
}
</style>
