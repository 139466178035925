<template>
  <v-layout row wrap>
    <v-flex xs12 text-xs-left>
      {{ salesManagementPrice.mediaType.label }}
    </v-flex>

    <v-flex xs6 text-xs-left>
      <p class="caption">
        ページ数：{{ salesManagementPrice.photoBookPageNum }}ページ<br />
        写真選択枚数：{{
          salesManagementPrice.photoBookMaxPictureCount
        }}枚まで<br />
      </p>
    </v-flex>

    <v-flex xs6 text-xs-right>
      <div>
        <v-btn
          flat
          icon
          color="primary"
          :disabled="!canPhotoBookDecrement"
          @click.native="
            $emit('update-photo-book-order-amount', orderAmount - 1)
          "
        >
          <v-icon :large="$vuetify.breakpoint.name !== 'xs'">
            remove_circle
          </v-icon>
        </v-btn>

        <span
          class="font-weight-bold my-2"
          :class="{ title: $vuetify.breakpoint.name !== 'xs' }"
        >
          {{ orderAmount }} 冊
        </span>

        <v-btn
          flat
          icon
          color="primary"
          :disabled="!canPhotoBookIncrement"
          @click.native="
            $emit('update-photo-book-order-amount', orderAmount + 1)
          "
        >
          <v-icon :large="$vuetify.breakpoint.name !== 'xs'">
            add_circle
          </v-icon>
        </v-btn>
      </div>

      <ul class="list-vertical text-xs-right">
        <li>
          <a
            href="#"
            class="remove-item-link"
            @click.stop.prevent="$emit('remove-photo-book-order')"
            >削除する</a
          >
        </li>
      </ul>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  CART_ITEM_MIN_LIMIT,
  CART_ITEM_MAX_LIMIT
} from "../../../../../../models/Cart";

export default {
  props: {
    priceMap: {
      type: Map, // Map<number, number>
      required: true
    },
    mediaType: {
      type: Number,
      required: true
    },
    orderAmount: {
      type: Number,
      required: true
    }
  },
  computed: {
    salesManagementPrice() {
      return this.priceMap.get(this.mediaType);
    },
    canPhotoBookIncrement() {
      return this.orderAmount < CART_ITEM_MAX_LIMIT;
    },
    canPhotoBookDecrement() {
      return this.orderAmount > CART_ITEM_MIN_LIMIT;
    }
  }
};
</script>

<style scoped>
a.remove-item-link {
  font-size: 12px;
  color: #545454;
  text-decoration: underline;
}
</style>
