<template>
  <v-layout row wrap>
    <confirm-remove-cart-item-dialog
      :target="removeTarget"
      @complete="removeTarget = null"
      @cancel="removeTarget = null"
    >
    </confirm-remove-cart-item-dialog>

    <remove-photo-book-order-dialog
      :dialog="showRemovePhotoBookOrderDialog"
      :remove-photo-book-order="removePhotoBookOrder"
      @close="showRemovePhotoBookOrderDialog = false"
    ></remove-photo-book-order-dialog>

    <v-flex xs12 text-xs-center>
      <h1 class="primary--text subheading mb-2 font-weight-bold">
        数量選択・金額の確認
      </h1>
    </v-flex>

    <v-flex xs12 text-xs-center>
      <h2 class="store-title">注文方法</h2>

      <v-radio-group v-model="mutableOrderType" class="px-2">
        <v-radio
          v-for="ot in selectableOrderTypes"
          :key="`orderType-${ot.value}`"
          :label="ot.label"
          :value="ot.value"
          class="my-2"
        ></v-radio>
      </v-radio-group>
    </v-flex>

    <v-flex xs12 md6 text-xs-center px-0 mb-3>
      <template v-if="salesManagement.isPhotoAndVideo">
        <h2 class="store-title">注文写真</h2>

        <template v-if="cartPictures.length === 0">
          <p class="px-2">写真はありません。</p>
        </template>
        <template v-else>
          <div
            v-for="(p, index) in cartPictures"
            :key="p.uniqueKey"
            class="px-2 my-1"
          >
            <picture-order-row
              :picture="p"
              :order-amount="pictureCount(p.id)"
              :price-map="priceMap"
              :order-amount-changeable="orderForm.pictureCountable()"
              @update-picture-order-count="updatePictureCount($event)"
              @remove-picture-order="removeTarget = $event"
            ></picture-order-row>

            <div v-if="index !== cartPictures.length - 1" class="my-2">
              <v-divider></v-divider>
            </div>
          </div>
        </template>

        <template v-if="cartVideos.length > 0">
          <h2 class="store-title">注文動画</h2>

          <div
            v-for="(v, index) in cartVideos"
            :key="v.uniqueKey"
            class="px-2 my-1"
          >
            <video-order-row
              :video="v"
              :order-amount="videoCount(v.id)"
              :price-map="priceMap"
              @remove-video-order="removeTarget = $event"
            ></video-order-row>

            <div v-if="index !== cartVideos.length - 1" class="xs12 my-2">
              <v-divider></v-divider>
            </div>
          </div>
        </template>
      </template>

      <template v-if="salesManagement.isPhotoBook">
        <h2 class="store-title">フォトブックの種類</h2>

        <div class="px-2 my-1">
          <template v-if="cartPhotoBookMediaType">
            <photo-book-order-row
              :price-map="priceMap"
              :media-type="cartPhotoBookMediaType"
              :order-amount="cartPhotoBookOrderAmount"
              @update-photo-book-order-amount="
                updatePhotoBookOrderCount($event)
              "
              @remove-photo-book-order="showRemovePhotoBookOrderDialog = true"
            ></photo-book-order-row>
          </template>
          <template v-else>
            <p>フォトブック注文がありません。</p>
          </template>
        </div>
      </template>
    </v-flex>

    <v-flex xs12 md6 text-xs-center>
      <h2 class="store-title">購入金額</h2>

      <div class="px-2">
        <template v-if="estimateLoading">
          <v-progress-circular indeterminate></v-progress-circular>
        </template>
        <template v-else-if="errors.length > 0">
          <v-alert :value="true" color="error" class="text-xs-left mb-3">
            <ul v-for="err in errors" :key="err.message">
              <li>{{ err.message }}</li>
            </ul>
          </v-alert>
        </template>
        <template v-else-if="estimate">
          <table class="order-estimate">
            <thead>
              <tr>
                <th class="text-xs-left">明細種別</th>
                <th>単価</th>
                <th>数量</th>
                <th>合計</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="s in estimate.summaries" :key="s.mediaType">
                <td class="text-xs-left">{{ s.label }}</td>
                <td>{{ s.unitPrice }}円</td>
                <td>{{ s.quantity }}</td>
                <td class="text-xs-right">{{ s.total }}円</td>
              </tr>
            </tbody>
          </table>

          <v-layout row wrap align-center class="px-3 my-5">
            <v-flex xs-6 text-xs-left class="title">
              合計金額
            </v-flex>
            <v-flex xs-6 text-xs-right class="display-1 font-weight-bold">
              {{ estimate.sumTotal }} 円
            </v-flex>
          </v-layout>

          <v-card
            v-for="notice in estimate.notices"
            :key="notice"
            flat
            dark
            color="warning"
            class="order-estimate-notice my-2 text-xs-left"
          >
            <v-layout row wrap align-center>
              <v-flex xs2 text-xs-center>
                <v-icon dark large>error_outline</v-icon>
              </v-flex>
              <v-flex xs10>
                <v-card-text>
                  <span class="title font-weight-bold">{{ notice }}</span>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
        </template>

        <v-layout row wrap text-xs-center>
          <v-flex xs12>
            <v-btn
              large
              color="primary"
              class="navigation-button"
              :disabled="!isValidForm"
              @click.native="onClickNextStepButton"
            >
              次へ
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-btn
              dark
              large
              color="negative"
              class="navigation-button"
              :to="{
                name: 'organizationCart',
                params: {
                  organizationId: organization.id,
                  salesManagementId: salesManagement.id
                }
              }"
            >
              戻る
            </v-btn>
          </v-flex>

          <v-flex xs12 my-3>
            <router-link
              :to="{
                name: 'organizationMain',
                params: {
                  organizationId: organization.id,
                  salesManagementId: salesManagement.id
                }
              }"
            >
              写真一覧へ戻る ≫
            </router-link>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { compact } from "lodash";

import ApiErrorHandler from "../../../../../mixins/ApiErrorHandler";

import { ORDER_TYPES } from "../../../../../data/constants";
import { API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE } from "../../../../../lib/lookmee_photo/ApiErrorCodes";

import OrderForm from "../../../../../forms/OrderForm";
import Organization from "../../../../../models/Organization";
import SalesManagement from "../../../../../models/SalesManagement";
import {
  CART_ITEM_MIN_LIMIT,
  CART_ITEM_MAX_LIMIT
} from "../../../../../models/Cart";

import ConfirmRemoveCartItemDialog from "../../../../../components/ConfirmRemoveCartItemDialog";
import RemovePhotoBookOrderDialog from "../../components/RemovePhotoBookOrderDialog";
import PictureOrderRow from "./components/PictureOrderRow";
import VideoOrderRow from "./components/VideoOrderRow";
import PhotoBookOrderRow from "./components/PhotoBookOrderRow";

export default {
  components: {
    "confirm-remove-cart-item-dialog": ConfirmRemoveCartItemDialog,
    "remove-photo-book-order-dialog": RemovePhotoBookOrderDialog,
    "picture-order-row": PictureOrderRow,
    "video-order-row": VideoOrderRow,
    "photo-book-order-row": PhotoBookOrderRow
  },
  mixins: [ApiErrorHandler],
  props: {
    organization: {
      type: Organization,
      required: true
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    pictureMap: {
      type: Map,
      required: true
    },
    videoMap: {
      type: Map,
      required: true
    },
    orderForm: {
      type: OrderForm,
      required: true
    }
  },
  data() {
    return {
      estimate: null,
      estimateLoading: false,
      removeTarget: null,
      showRemovePhotoBookOrderDialog: false,
      errors: []
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    // TODO: Do not refer vuex store directly, should be passed from parent.
    ...mapState("cart", {
      cart: "cart"
    }),
    ...mapGetters({
      cartIsEmpty: "cart/isEmpty",
      cartPictureCount: "cart/pictureCount",
      cartVideoCount: "cart/videoCount",
      cartPhotoBookMediaType: "cart/photoBookMediaType",
      cartPhotoBookOrderAmount: "cart/photoBookOrderAmount",
      cartPhotoBookPictureIds: "cart/photoBookPictureIds"
    }),
    orderType() {
      return this.orderForm.orderType;
    },
    mutableOrderType: {
      get() {
        return this.orderType;
      },
      set(val) {
        this.$emit("update:orderType", val);
      }
    },
    selectableOrderTypes() {
      // 動画のみ注文の場合、「データ注文」で固定したい
      if (
        this.cart.pictureOrders.length === 0 &&
        this.cart.videoOrders.length > 0
      ) {
        return this.salesManagement.orderTypes.filter(
          ot => ot.value === ORDER_TYPES.data
        );
      } else {
        return this.salesManagement.selectableOrderTypes;
      }
    },
    priceMap() {
      const priceMap = new Map();
      if (this.salesManagement.prices) {
        this.salesManagement.prices.forEach(p =>
          priceMap.set(p.mediaType.value, p)
        );
      }
      return priceMap;
    },
    cartPictures() {
      return compact(
        this.cart.pictureOrders.map(o => this.pictureMap.get(o.id))
      );
    },
    cartVideos() {
      return compact(this.cart.videoOrders.map(o => this.videoMap.get(o.id)));
    },
    isValidForm() {
      return !this.cartIsEmpty && this.errors.length === 0 && !!this.estimate;
    }
  },
  watch: {
    selectableOrderTypes() {
      if (
        this.orderType &&
        this.selectableOrderTypes.find(t => this.orderType === t.value)
      ) {
        return;
      }
      this.setDefaultOrderType();
    },
    cart() {
      this.reloadEstimate();
    },
    orderType() {
      this.reloadEstimate();
    }
  },
  created() {
    if (!this.mutableOrderType && this.selectableOrderTypes.length === 1) {
      // Call #reloadEstimate via watch orderType prop.
      this.setDefaultOrderType();
    } else {
      this.reloadEstimate();
    }
  },
  methods: {
    setDefaultOrderType() {
      this.mutableOrderType = this.selectableOrderTypes.map(ot => ot.value)[0];
    },
    canPictureIncrement(pictureId) {
      return (
        this.orderForm.pictureCountable() &&
        this.cartPictureCount(pictureId) < CART_ITEM_MAX_LIMIT
      );
    },
    canPictureDecrement(pictureId) {
      return (
        this.orderForm.pictureCountable() &&
        this.cartPictureCount(pictureId) > CART_ITEM_MIN_LIMIT
      );
    },
    pictureCount(pictureId) {
      return this.orderForm.pictureCountable()
        ? this.cartPictureCount(pictureId)
        : 1;
    },
    videoCount(videoId) {
      return this.orderForm.pictureCountable()
        ? this.cartVideoCount(videoId)
        : 1;
    },
    onClickNextStepButton() {
      if (!this.isValidForm) {
        return;
      }

      this.$emit("next-step");
    },
    async reloadEstimate() {
      if (!this.orderType) {
        return;
      }

      const organizationId = this.organization.id;
      const salesManagementId = this.salesManagement.id;

      this.estimate = null;
      this.errors = [];
      this.estimateLoading = true;
      try {
        this.estimate = await this.apiClient.estimateOrder(
          organizationId,
          salesManagementId,
          {
            orderType: this.orderType,
            paymentMethod:
              this.orderForm.paymentMethod ||
              this.salesManagement.defaultPaymentMethod.value,
            pictureOrders: this.cart.pictureOrders,
            videoOrders: this.cart.videoOrders,
            photoBookOrder: {
              mediaType: this.cartPhotoBookMediaType,
              orderAmount: this.cartPhotoBookOrderAmount,
              pictureIds: Array.from(this.cartPhotoBookPictureIds)
            }
          }
        );
      } catch (errors) {
        if (
          !this.isGlobalApiErrors(errors) ||
          (errors.status === 503 &&
            errors.getErrorCode() ===
              API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE)
        ) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.estimateLoading = false;
      }
    },
    async updatePictureCount({ id, orderAmount }) {
      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("cart/addPictureToCart", {
          pictureId: id,
          count: orderAmount
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async updatePhotoBookOrderCount(amount) {
      this.$store.dispatch("startLoading");
      try {
        await this.$store.dispatch("cart/updateCartPhotoBookOrderAmount", {
          amount
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async removePhotoBookOrder() {
      try {
        await this.$store.dispatch("cart/deleteCartPhotoBook");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    }
  }
};
</script>

<style scoped>
table.order-estimate {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table.order-estimate tr {
  border-bottom: 1px dotted #dedede;
}

table.order-estimate tr th,
table.order-estimate tr td {
  padding: 8px 0 8px;
}
</style>
