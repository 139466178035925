<template>
  <v-app>
    <lookmee-photo-drawer v-if="!hideDrawer"></lookmee-photo-drawer>
    <lookmee-photo-toolbar v-if="!hideToolbar" :hide-icons="hideToolbarIcons">
    </lookmee-photo-toolbar>

    <v-content class="main-content" :class="{ 'plain-pages': isPlainPages }">
      <v-container fluid :fill-height="fillHeight">
        <loading-dialog :loading="$store.state.loading"></loading-dialog>
        <require-login-dialog v-model="loginRequired"></require-login-dialog>
        <require-refresh-dialog
          v-model="refreshRequired"
          :show-cart-caption="true"
        >
        </require-refresh-dialog>
        <error-dialog
          v-model="errorDialog"
          :error-messages="errorMessages"
        ></error-dialog>

        <router-view></router-view>
      </v-container>
    </v-content>

    <lookmee-photo-footer></lookmee-photo-footer>
  </v-app>
</template>

<script>
import dayjs from "dayjs";

import { mapGetters } from "vuex";
import { mapState } from "vuex";

import { addToDataLayerObjectFromArray } from "../lib/utils/data_layer";

import LoadingDialog from "./dialogs/LoadingDialog";
import RequireLoginDialog from "./dialogs/RequireLoginDialog";
import RequireRefreshDialog from "./dialogs/RequireRefreshDialog";
import ErrorDialog from "./dialogs/ErrorDialog";

import Drawer from "./Drawer";
import Toolbar from "./Toolbar";
import Footer from "./Footer";

export default {
  components: {
    "lookmee-photo-drawer": Drawer,
    "lookmee-photo-toolbar": Toolbar,
    "loading-dialog": LoadingDialog,
    "require-login-dialog": RequireLoginDialog,
    "require-refresh-dialog": RequireRefreshDialog,
    "error-dialog": ErrorDialog,
    "lookmee-photo-footer": Footer
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "user/isAuthenticated"
    }),
    ...mapState({
      environment: "environment",
      user: "user",
      family: "family",
      karte: "karte"
    }),
    fillHeight() {
      return this.$route.matched.some(record => record.meta.fillHeight);
    },
    hideDrawer() {
      return (
        !this.isAuthenticated ||
        this.$route.matched.some(record => record.meta.hideDrawer)
      );
    },
    hideToolbar() {
      return this.$route.matched.some(record => record.meta.hideToolbar);
    },
    hideToolbarIcons() {
      return (
        !this.isAuthenticated ||
        this.$route.matched.some(record => record.meta.hideToolbarIcons)
      );
    },
    isPlainPages() {
      return this.$route.matched.some(record => {
        if (typeof record.meta.isPlainPages === "function") {
          return record.meta.isPlainPages(this.$route);
        } else {
          return record.meta.isPlainPages;
        }
      });
    },
    loginRequired: {
      get() {
        return this.$store.state.loginRequired;
      },
      set(val) {
        this.$store.commit("setLoginRequired", val);
      }
    },
    refreshRequired: {
      get() {
        return this.$store.state.refreshRequired;
      },
      set(val) {
        this.$store.commit("setRefreshRequired", val);
      }
    },
    errorDialog: {
      get() {
        return this.$store.state.errorDialog;
      },
      set() {
        this.$store.dispatch("closeErrorDialog");
      }
    },
    errorMessages() {
      return this.$store.state.errorMessages;
    }
  },
  created() {
    if (this.isAuthenticated === true) {
      const user = this.user.user;

      let userId;

      // append env name to user ids so if development or staging
      // data are sent to karte
      // the data is not mixed with production data
      if (this.environment === "production") {
        userId = `${user.id}`;
      } else {
        userId = `${this.environment}_${user.id}`;
      }

      // Both user firstName and user lastName can be undefined
      // depending on the signup method
      dataLayer.push({
        user_id: userId,
        mail_address: user.email,
        user_firstname: user.firstName,
        user_familyname: user.lastName
      });

      // When user has only 1 family, family and karte are set
      // So send to dataLayer
      // If there is > 1 family, then send after family selection
      if (this.family.family) {
        let dataLayerData = {
          mail_ok: this.family.family.promotionMailDeliverable
        };

        const kidsBirthdays = this.family.kids
          .filter(k => k.birthDate)
          .map(k => {
            const dayjsDate = dayjs(k.birthDate);
            return dayjsDate.format("YYYY-MM-DD");
          });

        dataLayerData = addToDataLayerObjectFromArray(
          "child_birthday",
          dataLayerData,
          kidsBirthdays,
          10
        );

        dataLayer.push(dataLayerData);
      }
      if (this.karte) {
        dataLayer.push({
          setting_fr: this.karte.isFrUsed,
          setting_fr_previous: this.karte.previousFrRegisteredDate,
          setting_address: this.karte.isFamilyUserAddressExisting
        });
      }
    }
  }
};
</script>
