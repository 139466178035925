<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />新規登録
      </h1>

      <v-card>
        <v-card-title>メールアドレスの登録</v-card-title>
        <v-card-text>
          <p v-if="invalidUrlError" class="error--text">
            認証URLの有効期限が切れている、もしくは不正なURLです。<br />
            お手数ですが、いま一度ご登録をお願いいたします。
          </p>

          <p>
            新規登録には登録用コードが必要です。<br />
            事前に園・施設から配布された<span class="font-weight-bold"
              >登録用コードをお手元にご準備</span
            >ください。
          </p>

          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="submitUserRegistrationForm"
          >
            <v-text-field
              v-model="email"
              type="email"
              label="メールアドレス"
              validate-on-blur
              required
              :rules="form.emailRules()"
              class="mt-4"
            ></v-text-field>

            <v-checkbox
              v-model="termsAgreed"
              validate-on-blur
              :rules="form.termsAgreedRules()"
            >
              <template slot="label">
                <a href="/site/terms" target="_blank" @click.stop="">利用規約</a
                >に同意します。
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="privacyPolicyAgreed"
              validate-on-blur
              :rules="form.privacyPolicyAgreedRules()"
            >
              <template slot="label">
                <a href="/site/privacy_policy" target="_blank" @click.stop=""
                  >個人情報の取扱い</a
                >に同意します。
              </template>
            </v-checkbox>

            <div class="caption error--text">
              <p v-show="isDuplicatedError">
                メールアドレスが既に使用されています。<br />
                ルクミーフォトに登録済みの方は、<router-link to="/"
                  >こちら</router-link
                >からログインしてください。<br />
                登録した覚えの無い方はこちらまでお問い合わせください。0120-886-659
              </p>

              <ul
                v-for="err in errors"
                v-show="errors.length > 0"
                :key="err.message"
              >
                <li>{{ err.message }}</li>
              </ul>
            </div>

            <v-layout justify-center row class="my-3">
              <v-btn
                type="submit"
                :loading="submitting"
                :disabled="submitting"
                color="primary"
              >
                このメールアドレスで新規登録する
              </v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { API_ERROR_CODE_VALIDATION_TAKEN } from "../../lib/lookmee_photo/ApiErrorCodes";

import UserRegistrationForm from "../../forms/UserRegistrationForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    invalidUrlError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: new UserRegistrationForm({}),
      submitting: false,
      isDuplicatedError: false,
      errors: [],
      privacyPolicyText: ""
    };
  },
  computed: {
    email: {
      get() {
        return this.form.email;
      },
      set(val) {
        this.form.email = val;
      }
    },
    termsAgreed: {
      get() {
        return this.form.termsAgreed;
      },
      set(val) {
        this.form.termsAgreed = val;
      }
    },
    privacyPolicyAgreed: {
      get() {
        return this.form.privacyPolicyAgreed;
      },
      set(val) {
        this.form.privacyPolicyAgreed = val;
      }
    }
  },
  methods: {
    async submitUserRegistrationForm() {
      if (this.submitting) {
        return;
      }

      this.resetError();
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("createUserRegistration", {
          email: this.form.email
        });
        this.$router.push({ name: "sendEmailUserRegistration" });
      } catch (errors) {
        if (errors.status === 400) {
          if (errors.getErrorCode() === API_ERROR_CODE_VALIDATION_TAKEN) {
            this.isDuplicatedError = true;
          } else {
            this.errors = errors.getErrorMessages();
          }
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    resetError() {
      this.isDuplicatedError = false;
      this.errors = [];
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
