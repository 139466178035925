<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8>
      <v-card elevation-1>
        <v-card-text class="text-xs-center">
          <p>
            別世帯が選択されているため、表示することができません。
          </p>

          <p>
            お手数をおかけいたしますが、以下「世帯の切り替えへ」より世帯をご選択ください。
          </p>

          <p>
            <router-link :to="{ name: 'listFamilies' }">
              世帯の切り替えへ >>
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
