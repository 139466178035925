<template>
  <div>
    <h1 class="title-page primary--text">クラスの登録</h1>

    <v-form ref="form" lazy-validation @submit.prevent="submitForm">
      <section>
        <h2 class="title-bar">園名</h2>
        <div class="pa-3">
          <span class="txt-mute font-weight-bold">{{ organizationName }}</span>
        </div>
      </section>

      <section>
        <h2 class="title-bar">クラスの選択</h2>
        <div class="pa-3">
          <v-radio-group v-model="groupId" :rules="form.groupIdRules()">
            <v-radio
              v-for="g in form.groups"
              :key="g.uniqueKey"
              :label="g.name"
              :value="g.id"
            ></v-radio>
          </v-radio-group>
        </div>
      </section>

      <v-layout justify-center row>
        <v-btn type="submit" class="v-btn--minwidth" color="primary">
          次へ
        </v-btn>
      </v-layout>
      <v-layout justify-center row>
        <v-btn class="v-btn--minwidth" color="negative" @click="$emit('prev')">
          戻る
        </v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import UserForm from "../../forms/UserForm";

export default {
  props: {
    form: {
      type: UserForm,
      required: true
    }
  },
  computed: {
    organizationName() {
      return this.form.organization ? this.form.organization.name : "";
    },
    groupId: {
      get() {
        return this.form.groupId;
      },
      set(val) {
        this.form.groupId = val;
      }
    }
  },
  created() {
    const selectableGroupIds = this.form.groups.map(g => g.id);
    if (!selectableGroupIds.includes(this.groupId)) {
      this.groupId = undefined;
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("next");
    }
  }
};
</script>
