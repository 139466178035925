class PhotoDimension {
  width: number;
  height: number;

  constructor(data: { width: number; height: number }) {
    this.width = data.width;
    this.height = data.height;
  }
}

class DetectedFace {
  left: number;
  top: number;
  right: number;
  bottom: number;

  constructor(data: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  }) {
    this.left = data.left;
    this.top = data.top;
    this.right = data.right;
    this.bottom = data.bottom;
  }

  get width(): number {
    return this.right - this.left;
  }

  get height(): number {
    return this.bottom - this.top;
  }

  get uniqueKey(): string {
    return `detectedFace-${this.left}-${this.top}-${this.right}-${this.bottom}`;
  }
}

class FaceDetectionResult {
  detectedFaces: Array<DetectedFace>;
  photoDimension: PhotoDimension;

  constructor(data: {
    detectedFaces: Array<{
      left: number;
      top: number;
      right: number;
      bottom: number;
    }>;
    photoDimension: { width: number; height: number };
  }) {
    this.detectedFaces = data.detectedFaces.map(df => new DetectedFace(df));
    this.photoDimension = new PhotoDimension(data.photoDimension);
  }

  hasDetectedFaces(): boolean {
    return this.detectedFaces.length > 0;
  }
}

export default FaceDetectionResult;
