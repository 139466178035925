<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons">airport_shuttle</v-icon
        ><br />園・施設の選択
      </h1>
      <v-card elevation-1>
        <template v-if="organizations">
          <template v-if="organizations.length === 0">
            <div class="pa-3">
              <p>
                当年度で所属している園・施設がありません。
                <router-link :to="{ name: 'newKidsClassRegistration' }">
                  こちら
                </router-link>
                から登録してください。<br />
                ※
                過去年度で園・施設に所属されていた場合、販売が開始されたら閲覧・購入可能となります。
              </p>
              <p class="red--text">
                ※入園前の場合は登録の必要はありません。
              </p>
            </div>
          </template>
          <template v-else>
            <v-list>
              <template v-for="(org, index) in organizations">
                <v-list-tile
                  :key="org.id"
                  :to="{
                    name: 'organizationTop',
                    params: { organizationId: org.id }
                  }"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ org.name }}</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-icon>navigate_next</v-icon>
                  </v-list-tile-action>
                </v-list-tile>

                <v-divider
                  v-if="index !== organizations.length - 1"
                  :key="`divider-${org.id}`"
                ></v-divider>
              </template>
            </v-list>
          </template>
        </template>
      </v-card>
    </v-flex>
    <terms-and-policy
      v-if="agreementVersions"
      :agreement-versions="agreementVersions"
    />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import TermsAndPolicy from "../../components/TermsAndPolicy.vue";

export default {
  components: {
    "terms-and-policy": TermsAndPolicy
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      organizations: null,
      agreementVersions: undefined
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    })
  },
  async created() {
    await this.fetchInitData();

    // For Karte - when changing from a family with an organization to
    // a family with no organizations, clear the previously pushed en_id
    if (this.organizations.length === 0) {
      dataLayer.push({
        en_id: undefined
      });
    }
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        this.organizations = await this.apiClient.getOrganizations();
        this.agreementVersions = await this.apiClient.getAgreementVersions();
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    }
  }
};
</script>
