<template>
  <v-layout row wrap align-center>
    <v-flex xs12 offset-md1 md10>
      <v-layout row wrap align-center>
        <v-flex xs12 md6 text-xs-center>
          <div class="my-4">
            <img
              src="../assets/images/logo_with_title.png"
              class="logo"
              alt="ルクミーフォト"
            />
          </div>
          <brand-site device-type="login-banner-pc"></brand-site>
        </v-flex>
        <v-flex xs12 md6>
          <v-card class="elevation-1">
            <v-card-text>
              <v-alert :value="errors.length > 0" color="error" class="mb-3">
                <ul v-for="err in errors" :key="err.message">
                  <li>{{ err.message }}</li>
                </ul>
              </v-alert>

              <v-form @submit.prevent="submitLoginForm">
                <v-text-field
                  v-model="loginForm.email"
                  type="email"
                  label="メールアドレス"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="loginForm.password"
                  type="password"
                  label="パスワード"
                  required
                ></v-text-field>

                <v-checkbox
                  v-model="loginForm.rememberMe"
                  label="次回から自動的にログインする"
                ></v-checkbox>

                <v-btn
                  block
                  type="submit"
                  color="primary"
                  :loading="submitting"
                  :disabled="submitting"
                >
                  ログイン
                </v-btn>
              </v-form>

              <div class="mt-4">
                <p class="text-xs-center mb-2">
                  <a
                    href="/site/password_resets/new"
                    target="_blank"
                    class="grey--text lighten-1"
                    >パスワードを忘れた場合はこちら</a
                  >
                </p>

                <p class="text-xs-center">
                  <router-link
                    :to="{ name: 'staticMailNotReceived' }"
                    class="grey--text lighten-1"
                  >
                    メールが届かない方はこちら
                  </router-link>
                </p>
              </div>
            </v-card-text>
          </v-card>

          <v-card elevation-1 class="mt-4 text-xs-center">
            <v-card-text class="py-3">
              <a
                href="/site/user_registrations/new"
                color="primary"
                class="subheading font-weight-bold"
                >新規登録はこちら</a
              >
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 md6 text-xs-center>
          <brand-site device-type="login-banner-mobile"></brand-site>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import BrandSite from "../components/BrandSite";
import ApiErrorHandler from "../mixins/ApiErrorHandler";

export default {
  components: {
    "brand-site": BrandSite
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
        rememberMe: true
      },
      submitting: false,
      errors: []
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    })
  },
  methods: {
    async submitLoginForm() {
      this.errors = [];
      this.submitting = true;
      try {
        window.location.href = await this.apiClient.login(this.loginForm);
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errors = errors.errors;
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped>
.logo {
  max-width: 53%;
  max-height: 24vw;
}
.v-form {
  margin-top: 20px;
}

/* For PC device */
@media (min-width: 960px) {
  .login-banner-pc {
    margin: 0 25px;
  }
  .login-banner-mobile {
    display: none;
  }
}

/* For Mobile device */
@media (max-width: 959px) {
  .login-banner-pc {
    display: none;
  }
}
</style>
