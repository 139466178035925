<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />新規登録
      </h1>

      <v-card class="text-xs-center">
        <v-card-text>
          <template v-if="errorCause === 'kid_not_found'">
            <p>
              ご本人様情報の照会ができませんでした。<br />
              入力内容を再度ご確認ください。<br />
              （エラーコード：{{ errorCode }}）
            </p>
            <p>
              ＜よくある間違い＞<br />
              ・お子さまの情報を誤って入力されていませんか？<br />
              ・お名前の漢字に旧漢字（環境依存文字）が含まれていませんか？
            </p>
            <p>
              問題が解消されない場合はお手数ですが、以下のフォームに情報をご入力いただいた上、送信ください。<br />
              お問合せ内容の確認後、担当者より折り返しご連絡いたします。
            </p>
          </template>
          <template v-else-if="errorCause === 'multiple_kids'">
            <p>
              ご本人様情報の照会ができませんでした。<br />
              （エラーコード：{{ errorCode }}）
            </p>
            <p>
              お手数ですが、以下のフォームに情報をご入力いただいた上、送信ください。<br />
              お問合せ内容の確認後、担当者より折り返しご連絡いたします。
            </p>
          </template>

          <!-- When LOOKME-8511 is over, return to email address and phone number -->
          <!-- LOOKME-9281 (2021-01-07) -->
          <p>
            <a
              :href="
                `https://docs.google.com/forms/d/e/1FAIpQLSevnwT96dnRXVj8r17P_-MvH-oE7JysahbGutS_vDATvJnBOw/viewform?usp=sf_link`
              "
              target="_blank"
            >
              お問い合わせフォームはこちら
            </a>
          </p>

          <div class="mt-5">
            <a @click.prevent="$emit('back')">
              登録用コード・お子さまの登録画面へ戻る >>
            </a>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  CUSTOMER_SUPPORT_EMAIL,
  CUSTOMER_SUPPORT_TEL
} from "../../data/constants";

export default {
  props: {
    errorCause: {
      type: String,
      default: ""
    },
    errorCode: {
      type: String,
      default: ""
    }
  },
  computed: {
    customerSupportEmail() {
      return CUSTOMER_SUPPORT_EMAIL;
    },
    customerSupportTel() {
      return CUSTOMER_SUPPORT_TEL;
    }
  }
};
</script>
