<template>
  <div class="video-download-notice">
    <template v-if="organizationVideoDownloadable">
      ※購入するとダウンロードできます（MP4形式・パソコンのみ）。
    </template>
    <template v-else>
      ※購入した動画は注文履歴からいつでも見ることができます。また動画のデータをダウンロードすることはできません。
    </template>
  </div>
</template>

<script>
export default {
  props: {
    organizationVideoDownloadable: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.video-download-notice {
  color: var(--v-error-base);
}
</style>
