<template>
  <v-dialog v-model="mutableDialog" max-width="290">
    <v-card>
      <h3 class="title-contents text-xs-center pt-5">
        フォトブックの種類を変更
      </h3>

      <v-card-text>
        <v-radio-group v-model="selectedMediaType">
          <template v-for="price in salesManagement.prices">
            <v-radio
              :key="price.uniqueKey"
              :value="price.mediaType.value"
              :label="price.mediaType.label"
            >
            </v-radio>
            <p :key="'p-' + price.uniqueKey" class="body-1 ml-4 pl-2">
              ページ数：{{ price.photoBookPageNum }}ページ<br />
              写真選択枚数：{{ price.photoBookMaxPictureCount }}枚まで<br />
              価格：{{ price.price }}円
            </p>
          </template>
        </v-radio-group>

        <v-card-actions class="justify-center">
          <v-btn
            :loading="submitting"
            :disabled="submitting"
            color="primary"
            @click="submit"
          >
            OK
          </v-btn>
          <v-btn :disabled="submitting" color="negative" @click="close">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card-text>

      <div class="v-card__close">
        <v-icon color="primary" @click="close">
          highlight_off
        </v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import SalesManagement from "../../../../models/SalesManagement";

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    mediaType: {
      type: Number,
      required: true
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    changePhotoBookType: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedMediaType: this.mediaType,
      submitting: false
    };
  },
  computed: {
    mutableDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        if (!val) {
          this.close();
        }
      }
    }
  },
  methods: {
    async submit() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;
      try {
        await this.changePhotoBookType(this.selectedMediaType);

        this.$emit("close");
      } finally {
        this.submitting = false;
      }
    },
    close() {
      if (this.submitting) {
        return;
      }
      this.selectedMediaType = this.mediaType;
      this.$emit("close");
    }
  }
};
</script>
