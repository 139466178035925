<template>
  <section v-if="!isValid" class="py-3">
    <v-layout justify-center="center" row>
      <div class="caption error--text">
        <p>入力内容に不備があります。上部のエラー箇所をご確認ください。</p>
      </div>
    </v-layout>
  </section>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    isValid() {
      return (
        this.form.inputs.filter(v => v.hasError && v.hasMessages).length === 0
      );
    }
  }
};
</script>
