<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />パスワードの設定
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">設定を完了しました。</p>
          <p class="text-xs-center my-4">
            <img src="../../assets/images/illust_ok.png" />
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
