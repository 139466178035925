import { GetterTree } from "vuex";
import { RootState } from "../types";
import { CartState } from "./types";

export const getters: GetterTree<CartState, RootState> = {
  isEmpty(state: CartState): boolean {
    return !state.cart || state.cart.isEmpty();
  },
  itemCount(state: CartState): number {
    return state.cart ? state.cart.itemCount() : 0;
  },
  pictureIds(state: CartState): Set<number> {
    return state.cart ? state.cart.pictureIds() : new Set();
  },
  videoIds(state: CartState): Set<number> {
    return state.cart ? state.cart.videoIds() : new Set();
  },
  pictureCountMap(state: CartState): Map<number, number> {
    return state.cart ? state.cart.pictureCountMap() : new Map();
  },
  pictureCount(_: CartState, getters: any): (id: number) => number {
    return (id: number): number => {
      return getters.pictureCountMap.get(id) || 0;
    };
  },
  videoCountMap(state: CartState): Map<number, number> {
    return state.cart ? state.cart.videoCountMap() : new Map();
  },
  videoCount(_: CartState, getters: any): (id: number) => number {
    return (id: number): number => {
      return getters.videoCountMap.get(id) || 0;
    };
  },
  photoBookMediaType(state: CartState): number | null | undefined {
    return state.cart && state.cart.photoBook.mediaType;
  },
  photoBookOrderAmount(state: CartState): number | null | undefined {
    return state.cart && state.cart.photoBook.orderAmount;
  },
  photoBookPictureIds(state: CartState): Set<number> {
    return new Set((state.cart && state.cart.photoBook.pictureIds) || []);
  }
};
