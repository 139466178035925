<template>
  <div>
    <v-list-tile
      :to="{
        name: 'showNotification',
        params: { notificationId: reminder.id, notificationType: 'reminder' }
      }"
    >
      <v-list-tile-content>
        <v-list-tile-sub-title>
          <v-chip
            v-if="reminder.isNew"
            label
            color="red"
            text-color="white"
            class="text-xs-center"
            >NEW
          </v-chip>
        </v-list-tile-sub-title>
        <v-list-tile-title>
          {{ reminder.title }}
        </v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <i class="material-icons">chevron_right</i>
      </v-list-tile-action>
    </v-list-tile>
  </div>
</template>

<script>
import Reminder from "../../../models/Reminder";
export default {
  props: {
    reminder: {
      type: Reminder,
      required: true
    }
  }
};
</script>
