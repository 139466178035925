class Kid {
  id: number;
  name: string;
  kana: string;
  birthDate: Date | null;

  constructor(data: {
    id: number;
    name: string;
    kana: string;
    birthDate: string | null;
  }) {
    this.id = data.id;
    this.name = data.name;
    this.kana = data.kana;
    this.birthDate = data.birthDate ? new Date(data.birthDate) : null;
  }

  get uniqueKey() {
    return `kid-${this.id}`;
  }
}

export default Kid;
