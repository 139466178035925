<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8>
      <v-card elevation-1>
        <v-card-text>
          <p>
            販売期間が終了したため、処理を続けることができません。<br />
            次回の販売をお待ちください。
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("cart/clearCart");
  }
};
</script>
