<template>
  <div class="my-4" :class="deviceType">
    <a href="https://lookmee.jp/" target="_blank">
      <img
        src="../assets/images/brand_site.png"
        class="brand-site-image"
        alt="ブランドサイト"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
/* For PC device */
@media (min-width: 960px) {
  .brand-site-image {
    max-height: 250px;
  }
}
</style>
