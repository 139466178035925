<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">face</v-icon>
        <br />顔写真の登録
      </h1>

      <v-card v-if="kid && faces">
        <v-card-text>
          <ul v-if="!hasKidsBelongs" class="pink lighten-5 mb-3">
            <li>クラスに所属していないため、顔写真の登録が出来ません。</li>
            <li>
              登録用コードをご在籍の園・施設へお問い合わせの上、サイドメニューの「園・施設の登録」から「既に登録済みのお子さまを選択」を選択して登録用コードを登録してください。
            </li>
          </ul>
          <p>
            お持ちの写真データ、またはルクミーフォトでこれまでに購入した写真から登録できます。
          </p>
          <p class="font-weight-bold caption mb-1">顔写真登録のポイント</p>
          <ul class="list-indent caption">
            <li>①正面を向いて顔全体が写っているものを選ぼう</li>
            <li>②登録する枚数が多いほど精度が上がるよ</li>
            <li>
              ③お子さまは成長も早く顔つきも変化するので定期的に顔写真を更新しよう
            </li>
          </ul>
          <p v-if="hasKidsBelongs" class="mt-3 mb-1">
            ※顔写真の登録には、数十分～数時間かかる場合があります。
          </p>
        </v-card-text>

        <section>
          <h2 class="title-bar">{{ kid.name }}さんの写真</h2>
          <div class="pa-3">
            <v-layout wrap class="photo-list">
              <v-flex
                v-for="face in faces"
                :key="face.uniqueKey"
                xs4
                mb-3
                class="no-border"
              >
                <face-cell
                  v-if="face.hasFrFaces"
                  :face="face"
                  @delete="deleteFaceUuid = face.uuid"
                ></face-cell>
                <fr-processing-face-cell
                  v-else
                  :face="face"
                  :has-kids-belongs="hasKidsBelongs"
                  @delete="deleteFaceUuid = face.uuid"
                ></fr-processing-face-cell>
              </v-flex>
              <v-flex
                v-for="(_, i) in noFaces"
                :key="`noFace-${i}`"
                xs4
                mb-3
                class="no-border"
              >
                <no-face-cell
                  :has-kids-belongs="hasKidsBelongs"
                  @register="uploadDialog = true"
                ></no-face-cell>
              </v-flex>
            </v-layout>

            <p class="caption">
              ※1枚あたり8メガバイト以内でJPEG形式の画像を5枚まで登録できます。
            </p>
          </div>

          <delete-confirmation-dialog
            :kid-id="kidId"
            :face-uuid="deleteFaceUuid"
            @complete="completeFaceDeletion"
            @cancel="deleteFaceUuid = null"
          ></delete-confirmation-dialog>

          <select-upload-type-dialog
            :dialog="uploadDialog"
            :kid-id="kidId"
            @close="uploadDialog = false"
          ></select-upload-type-dialog>
        </section>

        <section v-if="organizationId" class="text-xs-center pb-4">
          <router-link
            :to="{ name: 'organizationTop', params: { organizationId } }"
          >
            園トップページへ>>
          </router-link>
        </section>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import FaceCell from "./components/FaceCell";
import NoFaceCell from "./components/NoFaceCell";
import FrProcessingFaceCell from "./components/FrProcessingFaceCell";
import DeleteConfirmationDialog from "./components/DeleteConfirmationDialog";
import SelectUploadTypeDialog from "./components/SelectUploadTypeDialog";

const MINIMUM_FACE_CELLS_NUM = 5;

export default {
  components: {
    "face-cell": FaceCell,
    "no-face-cell": NoFaceCell,
    "fr-processing-face-cell": FrProcessingFaceCell,
    "delete-confirmation-dialog": DeleteConfirmationDialog,
    "select-upload-type-dialog": SelectUploadTypeDialog
  },
  mixins: [ApiErrorHandler],
  props: {
    kidId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      faces: null,
      deleteFaceUuid: null,
      uploadDialog: false,
      hasKidsBelongs: false
    };
  },
  computed: {
    ...mapState("organization", {
      organizationId: state => state.id
    }),
    ...mapState("family", {
      kids: state => state.kids
    }),
    kid() {
      return this.kids.find(k => k.id === this.kidId);
    },
    noFaces() {
      const noFaceCount = Math.max(
        MINIMUM_FACE_CELLS_NUM - this.faces.length,
        0
      );
      return new Array(noFaceCount);
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const [faces, kid] = await Promise.all([
          this.$store.dispatch("fr/getFaces", { kidId: this.kidId }),
          this.$store.dispatch("kid/getKid", { kidId: this.kidId })
        ]);
        this.faces = faces;
        this.hasKidsBelongs = kid.kidsBelongs.length > 0;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    completeFaceDeletion(deletedFaceUuid) {
      this.faces = this.faces.filter(f => f.uuid !== deletedFaceUuid);
      this.deleteFaceUuid = null;
    }
  }
};
</script>
