<template>
  <v-layout>
    <template v-if="isMatchingError">
      <matching-error
        :error-cause="matchingErrorCause"
        :error-code="matchingErrorCode"
        @back="clearMatchingError"
      ></matching-error>
    </template>
    <template v-else>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1"
            >登録用コード・お子様の登録</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2"
            >クラスの登録</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3"
            >世帯情報の登録</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 4" step="4"
            >内容確認</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items v-show="step > 0">
          <v-stepper-content step="1">
            <step1
              v-if="step === 1"
              :form="form"
              @next="nextStep('#select_group')"
            >
            </step1>
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2
              v-if="step === 2"
              :form="form"
              @prev="prevStep('#input_code')"
              @next="nextStep('#input_family')"
            >
            </step2>
          </v-stepper-content>

          <v-stepper-content step="3">
            <step3
              v-if="step === 3"
              :form="form"
              @prev="prevStep('#select_group')"
              @next="nextStep('#confirm')"
            >
            </step3>
          </v-stepper-content>

          <v-stepper-content step="4">
            <step4
              v-if="step === 4"
              :token="token"
              :form="form"
              @prev="prevStep('#input_family')"
              @next="completeUserRegistration"
              @matching-error="handleMatchingError"
            >
            </step4>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-layout>
</template>

<script>
import UserForm from "../../forms/UserForm";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import Step1 from "./_Step1";
import Step2 from "./_Step2";
import Step3 from "./_Step3";
import Step4 from "./_Step4";
import MatchingError from "./_MatchingError";

export default {
  components: {
    step1: Step1,
    step2: Step2,
    step3: Step3,
    step4: Step4,
    "matching-error": MatchingError
  },
  mixins: [ApiErrorHandler],
  data() {
    return {
      token: this.$route.query.token,
      form: new UserForm(),
      matchingErrorCause: null,
      matchingErrorCode: null
    };
  },
  computed: {
    step: {
      get() {
        switch (this.$route.hash) {
          case "#input_code":
            return 1;
          case "#select_group":
            return 2;
          case "#input_family":
            return 3;
          case "#confirm":
            return 4;
          default:
            return 0;
        }
      },
      set(_) {
        // Stepperのv-modelにセットする場合、setterがないと警告が出るため追加。
      }
    },
    isMatchingError() {
      return this.$route.hash === "#matching_error";
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const registration = await this.$store.dispatch("getUserRegistration", {
          token: this.token
        });
        this.form.email = registration.email;
        this.$router.replace({ hash: "#input_code" });
      } catch (errors) {
        if (errors.status === 404) {
          this.$router.replace({
            name: "newUserRegistration",
            query: { invalidUrlError: true }
          });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    prevStep(hash) {
      this.$vuetify.goTo(0, { duration: 50 });
      this.$router.push({ hash });
    },
    nextStep(hash) {
      this.$vuetify.goTo(0, { duration: 50 });
      this.$router.push({ hash });
    },
    handleMatchingError({ errorCause, errorCode }) {
      this.matchingErrorCause = errorCause;
      this.matchingErrorCode = errorCode;
      this.$router.push({ hash: "#matching_error" });
    },
    clearMatchingError() {
      this.$router.push({ hash: "#input_code" });
      this.matchingErrorCause = null;
      this.matchingErrorCode = null;
    },
    completeUserRegistration() {
      this.$router.push({ name: "completedUserRegistration" });
    }
  }
};
</script>
