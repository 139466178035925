import { uniq } from "lodash";

import Family from "../models/Family";
import {
  PHONE_NUMBER_AVAILABLE_CHARS_PATTERN,
  PHONE_NUMBER_FIXED_PATTERN,
  PHONE_NUMBER_MOBILE_PATTERN
} from "../data/constants";

export class MobilePhoneForm {
  static counter: number = 0;

  id: number | undefined;
  phoneNumber: string | undefined;
  deleteFlag: boolean;
  uniqueNo: number;

  constructor(data: { id?: number; phoneNumber?: string }) {
    this.id = data.id;
    this.phoneNumber = data.phoneNumber || "";
    this.deleteFlag = false;
    this.uniqueNo = MobilePhoneForm.incrementCount();
  }

  static incrementCount() {
    return ++MobilePhoneForm.counter;
  }

  phoneNumberRules(): Array<Function> {
    if (this.deleteFlag) {
      return [];
    }

    return [
      (v?: string) => (!!v && v.length > 0) || "電話番号を入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PHONE_NUMBER_AVAILABLE_CHARS_PATTERN)) ||
        "電話番号は半角数字とハイフン(-)で入力してください。",
      (v?: string) =>
        (!!v &&
          (!!v.match(PHONE_NUMBER_FIXED_PATTERN) ||
            !!v.match(PHONE_NUMBER_MOBILE_PATTERN))) ||
        "電話番号に誤りがあります。正しい番号が入力されているか確認してください。"
    ];
  }

  toParams(): { id?: number; phoneNumber?: string; deleteFlag: boolean } {
    return {
      id: this.id,
      phoneNumber: this.deleteFlag ? undefined : this.phoneNumber,
      deleteFlag: this.deleteFlag
    };
  }

  get uniqueKey(): string {
    return `mobilePhoneForm-${this.uniqueNo}`;
  }
}

export class FamilyForm {
  name: string;
  mobilePhoneForms: Array<MobilePhoneForm>;

  constructor(family: Family) {
    this.name = family.name || "";
    this.mobilePhoneForms = family.mobilePhones.map(
      m => new MobilePhoneForm(m)
    );
  }

  get enabledMobilePhoneForms(): Array<MobilePhoneForm> {
    return this.mobilePhoneForms.filter(e => !e.deleteFlag);
  }

  nameRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "世帯名を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 40) || "世帯名は40文字以内で入力してください。"
    ];
  }

  validateDuplicatedValues(): Array<{ message: string }> {
    let errors = [];

    const phoneNumbers = this.enabledMobilePhoneForms.map(m => m.phoneNumber);
    if (phoneNumbers.length !== uniq(phoneNumbers).length) {
      errors.push({ message: "同じ電話番号が指定されています。" });
    }

    return errors;
  }

  toParams(): {
    name: string;
    mobilePhones: Array<{
      id?: number;
      phoneNumber?: string;
      deleteFlag: boolean;
    }>;
  } {
    return {
      name: this.name,
      mobilePhones: this.mobilePhoneForms.map(m => m.toParams())
    };
  }
}

export default FamilyForm;
