<template>
  <v-layout row wrap>
    <v-flex xs5 mb-3 py-0 pl-3 pr-0 text-xs-center class="order-row-thumbnail">
      <template v-if="order.representativeDetail">
        <square-picture-frame
          v-if="order.representativeDetail.picture"
          :picture="order.representativeDetail.picture"
          hide-zoom-icon
          no-cursor
        >
        </square-picture-frame>
        <square-video-frame
          v-else-if="order.representativeDetail.video"
          :video="order.representativeDetail.video"
          hide-play-icon
          hide-play-time
          no-cursor
        >
        </square-video-frame>
      </template>
      <template v-else-if="order.isPhotoBookOrder">
        <img
          src="../../../assets/images/photobook/order_thumbnail.png"
          alt=""
          class="photo-book-thumbnail"
        />
      </template>
    </v-flex>
    <v-flex pl-2 xs7 text-xs-left>
      <table class="table-vertical caption">
        <tr>
          <td colspan="2">
            {{ order.salesManagementTitle }}
          </td>
        </tr>
        <tr>
          <th>注文日:</th>
          <td class="text-xs-right">{{ orderedDate }}</td>
        </tr>
        <tr>
          <th>注文者：</th>
          <td class="text-xs-right">{{ order.ownerName }}</td>
        </tr>
        <tr>
          <th>決済：</th>
          <td class="text-xs-right">
            <template v-if="order.paymentStatus === 'unpaid'">
              <span v-if="isOrderUserCurrentUser" class="red--text">
                未了（<a :href="paymentLink" class="red--text">決済画面を開く</a
                >）
              </span>
              <span v-else class="red--text">未了</span>
            </template>
            <template v-else-if="order.paymentStatus === 'paid'">
              完了
            </template>
            <template v-else-if="order.paymentStatus === 'expired'">
              有効期限切れ
            </template>
          </td>
        </tr>
      </table>

      <div
        v-if="order.isPaid && order.isDataDownloadOrder"
        class="btn-group text-xs-center"
      >
        <v-btn
          v-if="order.isPhotoDataOrder"
          small
          color="primary"
          :disabled="!order.isPhotoDataDownloadable"
          :to="{ name: 'downloadPictures', params: { orderId: order.id } }"
        >
          写真のDL<span v-if="!order.isPhotoDataDownloadable">(準備中)</span>
        </v-btn>
        <v-btn
          v-if="
            order.isVideoDataOrder && order.organization.videoDownloadAvailable
          "
          small
          color="primary"
          :disabled="!order.isVideoDataDownloadable"
          :to="{ name: 'downloadVideos', params: { orderId: order.id } }"
        >
          動画のDL<span v-if="!order.isVideoDataDownloadable">(準備中)</span>
        </v-btn>
      </div>
      <div class="text-xs-right mt-2">
        <router-link
          v-if="order.type !== 'PostcardOrder'"
          :to="{ name: 'showOrderHistory', params: { orderId: order.id } }"
        >
          詳細を見る
        </router-link>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import dayjs from "dayjs";

import { DATE_FORMAT } from "../../../data/constants";

import Order from "../../../models/Order";

import SquarePictureFrame from "../../../components/SquarePictureFrame";
import SquareVideoFrame from "../../../components/SquareVideoFrame";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame,
    "square-video-frame": SquareVideoFrame
  },
  props: {
    order: {
      type: Order,
      required: true
    }
  },
  computed: {
    orderedDate() {
      return dayjs(this.order.orderedDate).format(DATE_FORMAT);
    },
    paymentLink() {
      return `/site/orders/${this.order.id}/payment`;
    },
    isOrderUserCurrentUser() {
      return this.order.userId === this.$store.state.user.user.id;
    }
  }
};
</script>

<style scoped>
.order-row-thumbnail {
  line-height: 0;
}

.order-row-thumbnail .photo-book-thumbnail {
  width: 100%;
}
</style>
