<template>
  <v-layout row wrap>
    <v-flex xs12 md6 offset-md3>
      <h1 class="primary--text subheading mb-2 font-weight-bold text-xs-center">
        注文者・お届け先の入力
      </h1>

      <v-fade-transition>
        <template v-if="!openUserAddressForm">
          <v-form ref="form" lazy-validation>
            <h2 class="store-title">注文者名</h2>

            <div class="px-2">
              <v-text-field
                v-model="mutableOwnerName"
                label="お名前"
                outline
                :rules="orderForm.ownerNameRules()"
                class="my-2"
              >
              </v-text-field>

              <h2 class="store-title">メールアドレス</h2>

              <v-text-field
                :value="user.email"
                class="my-2"
                :rules="orderForm.ownerEmailRules(user.isActivated)"
                disabled
              >
              </v-text-field>

              <div v-if="!user.isActivated" class="red--text">
                メールアドレスが仮登録状態です。<br />
                <router-link :to="{ name: 'listInactivatedEmails' }"
                  >コチラ</router-link
                >から本登録してください。<br />
                ※カートに入れた写真は販売期間内であればそのままご購入いただけます。
              </div>
              <v-tooltip slot="append-outer" fixed bottom>
                <v-icon slot="activator" color="primary">
                  help
                </v-icon>
                「注文確定メール」をお送りします。
              </v-tooltip>
            </div>

            <template v-if="orderForm.isRequireUserAddress()">
              <h2 id="userAddressRadioButtons" class="store-title">
                登録済みのお届け先
              </h2>

              <div class="px-2">
                <template v-if="userAddresses">
                  <p v-if="userAddresses.length < 1">
                    お届け先が登録されていません。「新しいお届け先を追加する」ボタンから追加してください。
                  </p>

                  <v-radio-group
                    v-model="mutableUserAddressId"
                    :rules="orderForm.userAddressIdRules()"
                  >
                    <div
                      v-for="(addr, i) in userAddresses"
                      :key="addr.uniqueKey"
                      class="mb-2"
                    >
                      <v-radio :value="addr.id" class="mb-0">
                        <template slot="label">
                          お届け先{{ i + 1 }}<br />
                          {{ addr.fullName }} 様<br />
                          住所： {{ addr.fullAddress }}<br />
                          電話番号： {{ addr.telephone }}
                        </template>
                      </v-radio>
                      <div class="text-xs-right">
                        <a
                          class="edit-user-address-link"
                          @click="onClickEditUserAddressButton(addr)"
                        >
                          編集
                        </a>
                      </div>
                    </div>
                  </v-radio-group>
                </template>
                <template v-else>
                  <div class="text-xs-center">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </div>
                </template>

                <v-btn
                  block
                  class="mb-3"
                  @click.native="onClickNewUserAddressButton"
                >
                  新しいお届け先を追加する
                </v-btn>
              </div>
            </template>

            <v-layout row wrap text-xs-center px-2>
              <v-flex xs12>
                <v-btn
                  large
                  color="primary"
                  class="navigation-button"
                  @click="onClickNextStepButton"
                >
                  次へ
                </v-btn>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  dark
                  large
                  color="negative"
                  class="navigation-button"
                  @click="onClickPrevStepButton"
                >
                  戻る
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </template>
        <template v-else>
          <user-address-form
            :user-address="targetUserAddress"
            @complete="handleCompleteUserAddressFormDialog"
            @cancel="handleCancelUserAddressFormDialog"
          >
          </user-address-form>
        </template>
      </v-fade-transition>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../../../../../mixins/ApiErrorHandler";

import OrderForm from "../../../../../forms/OrderForm";
import SalesManagement from "../../../../../models/SalesManagement";

import UserAddressForm from "../../../../../components/UserAddressForm";

export default {
  components: {
    "user-address-form": UserAddressForm
  },
  mixins: [ApiErrorHandler],
  props: {
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    orderForm: {
      type: OrderForm,
      required: true
    }
  },
  data() {
    return {
      userAddresses: null,
      openUserAddressForm: false,
      targetUserAddress: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient"
    }),
    user: {
      get() {
        return this.$store.state.user.user;
      }
    },
    userAddressId() {
      return this.orderForm.userAddressId;
    },
    mutableOwnerName: {
      get() {
        return this.orderForm.ownerName;
      },
      set(val) {
        this.$emit("update:ownerName", val);
      }
    },
    mutableUserAddressId: {
      get() {
        return this.userAddressId;
      },
      set(val) {
        this.$emit("update:userAddressId", val);
      }
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const [_family, userAddresses] = await Promise.all([
          this.$store.dispatch("family/fetchFamily"),
          this.orderForm.isRequireUserAddress()
            ? this.apiClient.getUserAddresses()
            : Promise.resolve([])
        ]);
        this.userAddresses = userAddresses;
        this.$emit("update:ownerEmail", this.user.email);

        if (this.orderForm.isRequireUserAddress() && !this.userAddressId) {
          const defaultUserAddress = this.userAddresses.find(
            ua => ua.defaultAddress
          );
          if (defaultUserAddress) {
            this.mutableUserAddressId = defaultUserAddress.id;
          }
        }
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    onClickNewUserAddressButton() {
      this.targetUserAddress = null;
      this.openUserAddressForm = true;
    },
    onClickEditUserAddressButton(userAddress) {
      this.targetUserAddress = userAddress;
      this.openUserAddressForm = true;
    },
    handleCompleteUserAddressFormDialog(userAddress) {
      const index = this.userAddresses.findIndex(
        ua => ua.id === userAddress.id
      );
      if (index > -1) {
        this.userAddresses.splice(index, 1, userAddress);
      } else {
        this.userAddresses.push(userAddress);
      }
      this.mutableUserAddressId = userAddress.id;

      this.openUserAddressForm = false;
      this.targetUserAddress = null;
    },
    handleCancelUserAddressFormDialog() {
      this.openUserAddressForm = false;
      this.targetUserAddress = null;
    },
    onClickPrevStepButton() {
      this.$emit("prev-step");
    },
    onClickNextStepButton() {
      if (!this.$refs.form.validate()) {
        const firstErrorElem = this.$refs.form.inputs.find(el => el.hasError);
        this.$vuetify.goTo(firstErrorElem, { offset: -80 });
        return;
      }
      this.$emit("next-step");
    }
  }
};
</script>

<style scoped>
a.edit-user-address-link {
  font-size: 12px;
  color: #545454;
  text-decoration: underline;
}
</style>
