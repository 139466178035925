import {
  ISSUED_CODE1_PATTERN,
  ISSUED_CODE2_PATTERN,
  ISSUED_CODE3_PATTERN,
  PHONE_NUMBER_AVAILABLE_CHARS_PATTERN
} from "../data/constants";

class InquiryForm {
  id?: string;
  name: string;
  email: string;
  tel: string;
  organizationId: number | null;
  organizationName: string;
  category: number;
  issuedCode1?: string | undefined;
  issuedCode2?: string | undefined;
  issuedCode3?: string | undefined;
  text?: string | undefined;

  constructor(data: {
    name: string;
    email: string;
    tel: string;
    organizationId: number | null;
    organizationName: string;
    category: number;
    issuedCode1?: string;
    issuedCode2?: string;
    issuedCode3?: string;
    text?: string;
  }) {
    this.name = data.name;
    this.email = data.email;
    this.tel = data.tel;
    this.organizationId = data.organizationId;
    this.organizationName = data.organizationName;
    this.category = data.category;
    this.text = data.text;
    this.issuedCode1 = data.issuedCode1;
    this.issuedCode2 = data.issuedCode2;
    this.issuedCode3 = data.issuedCode3;
  }

  issuedCode(): string {
    return [
      this.issuedCode1 || "",
      this.issuedCode2 || "",
      this.issuedCode3 || ""
    ].join("");
  }

  issuedCode1Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（左）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 3 && v.length <= 5) ||
        "登録用コード（左）は3文字以上5文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE1_PATTERN)) ||
        "登録用コード（左）は半角数字で入力してください。"
    ];
  }

  issuedCode2Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（中央）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（中央）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE2_PATTERN)) ||
        "登録用コード（中央）は半角数字で入力してください。"
    ];
  }

  issuedCode3Rules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "登録用コード（右）を入力してください。",
      (v?: string) =>
        (!!v && v.length === 3) ||
        "登録用コード（右）は3文字で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(ISSUED_CODE3_PATTERN)) ||
        "登録用コード（右）は半角数字で入力してください。"
    ];
  }

  nameRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "お名前を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 50) || "お名前は255文字以内で入力してください。"
    ];
  }

  emailRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "メールアドレスを入力してください。"
    ];
  }

  telRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "電話番号を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 10 && v.length <= 13) ||
        "電話番号は10文字以上13文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PHONE_NUMBER_AVAILABLE_CHARS_PATTERN)) ||
        "電話番号は半角数字とハイフンで入力してください。"
    ];
  }

  textRules(): Array<Function> {
    return [
      (v?: string) => (!!v && v.length > 0) || "本文を入力してください。",
      (v?: string) =>
        (!!v && v.length <= 10000) || "本文は10000文字以内で入力してください。"
    ];
  }

  categoryRules(): Array<Function> {
    return [(v?: number) => !!v || "お問い合わせ分類を入力してください。"];
  }

  organizationIdRules(): Array<Function> {
    return [(v?: number) => !!v || "園・施設を選択してください。"];
  }

  organizationNameRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "園・施設名を選択してください。。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "園・施設名は255文字以内で入力してください。"
    ];
  }

  toParams(): {
    name: string;
    email: string;
    tel: string;
    organizationId: number | null;
    organizationName: string;
    category: number;
    issuedCode: string;
    text: string | undefined;
  } {
    return {
      name: this.name,
      email: this.email,
      tel: this.tel,
      organizationId: this.organizationId || null,
      organizationName: this.organizationName,
      category: this.category,
      issuedCode: this.issuedCode(),
      text: this.text
    };
  }
}

export default InquiryForm;
