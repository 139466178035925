<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />お子さまの情報
      </h1>

      <v-card>
        <v-card-title>お子さまのお名前</v-card-title>
        <template v-if="kids.length !== 0">
          <v-list two-line subheader>
            <div
              v-for="({ kid, group, organization }, i) in kidsList"
              :key="i + '-' + kid.uniqueKey"
            >
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-sub-title class="caption">
                    {{ organization && organization.name }}
                    {{ group && group.name }}
                  </v-list-tile-sub-title>
                  <v-list-tile-title>{{ kid.name }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider class="mx-3"></v-divider>
            </div>
          </v-list>
        </template>
        <v-layout justify-center row class="py-3">
          <v-btn
            class="v-btn--minwidth"
            color="primary"
            :to="{ name: 'newKid' }"
          >
            お子さまを追加する
          </v-btn>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      kids: [],
      kidsBelongs: [],
      groups: [],
      organizations: []
    };
  },
  computed: {
    kidsList() {
      const kidsList = [];
      this.kids.forEach(kid => {
        let groupOrganizations = this.getGroupOrganization(kid.id);
        if (groupOrganizations.length === 0) {
          kidsList.push({
            kid: kid,
            group: undefined,
            organization: undefined
          });
        } else {
          groupOrganizations.forEach(groupOrg => {
            kidsList.push({
              kid: kid,
              group: groupOrg.group,
              organization: groupOrg.organization
            });
          });
        }
      });
      return kidsList;
    }
  },
  created() {
    this.getKids();
  },
  methods: {
    async getKids() {
      this.$store.dispatch("startLoading");
      try {
        const {
          kids,
          kidsBelongs,
          groups,
          organizations
        } = await this.$store.dispatch("kid/getKids");

        this.kids = kids;
        this.kidsBelongs = kidsBelongs;
        this.groups = groups;
        this.organizations = organizations;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    getGroupOrganization(kidId) {
      let kidGroupsOrganizations = [];
      this.kidsBelongs.forEach(kb => {
        if (kb.kidId === kidId) {
          let group = this.getGroup(kb.groupId);
          if (group) {
            let organization = this.getOrganization(group.organizationId);
            kidGroupsOrganizations.push({
              group: group,
              organization: organization
            });
          }
        }
      });
      return kidGroupsOrganizations;
    },
    getGroup(id) {
      return this.groups.find(group => {
        return group.id === id;
      });
    },
    getOrganization(id) {
      return this.organizations.find(organization => {
        return organization.id === id;
      });
    }
  }
};
</script>
