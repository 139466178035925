class Karte {
  isFrUsed?: boolean;
  previousFrRegisteredDate?: string;
  isFamilyUserAddressExisting?: boolean;

  constructor(data: {
    isFrUsed?: boolean;
    previousFrRegisteredDate?: string;
    isFamilyUserAddressExisting?: boolean;
  }) {
    this.isFrUsed = data.isFrUsed;
    this.previousFrRegisteredDate = data.previousFrRegisteredDate;
    this.isFamilyUserAddressExisting = data.isFamilyUserAddressExisting;
  }
}

export default Karte;
