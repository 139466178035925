<template>
  <v-layout row wrap>
    <v-flex xs12 offset-sm2 sm8 offset-md2 md8>
      <v-dialog
        v-if="galleryDialog"
        v-model="galleryDialog"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '80%'"
        content-class="picture-gallery-dialog mx-1"
      >
        <picture-gallery
          :pictures="cartPhotoBookPictures"
          :initial-index="galleryIndex"
          :purchased-picture-ids="purchasedPictureIds"
          :offset-count="0"
          :all-count="cartPhotoBookPictures.length"
          @move="onMovePictureGallery"
          @close="closePictureGallery"
        ></picture-gallery>
      </v-dialog>

      <too-many-photo-book-pictures-error-dialog
        :dialog="showTooManyPicturesDialog"
        :max-pictures-limit="salesManagementPrice.photoBookMaxPictureCount"
        @close="showTooManyPicturesDialog = false"
      ></too-many-photo-book-pictures-error-dialog>

      <too-few-photo-book-pictures-warn-dialog
        :dialog="showTooFewPicturesDialog"
        @ok="goStorePage"
        @cancel="showTooFewPicturesDialog = false"
      >
      </too-few-photo-book-pictures-warn-dialog>

      <remove-photo-book-order-dialog
        :dialog="showRemovePhotoBookOrderDialog"
        :remove-photo-book-order="removePhotoBookOrder"
        @close="showRemovePhotoBookOrderDialog = false"
      ></remove-photo-book-order-dialog>

      <h1 class="title-page">
        <v-icon class="material-icons">shopping_cart</v-icon>
        <br />カートの中身
      </h1>

      <section v-if="salesManagementPrice">
        <h2 class="title-bar">フォトブックの種類</h2>

        <div class="pa-3">
          {{ salesManagementPrice.mediaType.label }}
          <p class="caption">
            ページ数：{{ salesManagementPrice.photoBookPageNum }}ページ<br />
            写真選択枚数：{{
              salesManagementPrice.photoBookMaxPictureCount
            }}枚まで<br />
          </p>

          <ul class="list-vertical text-xs-right">
            <li>
              <a
                href="#"
                @click.stop.prevent="showRemovePhotoBookOrderDialog = true"
                >削除する</a
              >
            </li>
          </ul>
        </div>
      </section>

      <section>
        <h2 class="title-bar">選択写真</h2>

        <div class="pa-3">
          <v-layout row wrap class="photo-list">
            <v-flex
              v-for="picture in cartPhotoBookPictures"
              :id="picture.uniqueKey"
              :key="picture.uniqueKey"
              xs4
              md3
              mb-3
            >
              <square-picture-frame
                :picture="picture"
                @click-picture="openPictureGallery(picture)"
              >
              </square-picture-frame>

              <table class="table-vertical">
                <tr>
                  <td>
                    <v-icon
                      v-if="picture.hasFace(familyKidIds)"
                      small
                      color="warning"
                    >
                      face
                    </v-icon>
                    <v-icon
                      v-if="purchasedPictureIds.has(picture.id)"
                      small
                      color="error"
                    >
                      photo_filter
                    </v-icon>
                  </td>
                  <td class="text-xs-right id-num">{{ picture.fileNo }}</td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </div>
      </section>

      <v-layout row wrap text-xs-center>
        <v-flex xs12>
          <v-btn
            large
            color="primary"
            class="navigation-button"
            @click="onClickGoStorePageButton"
          >
            注文手続きへ進む
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-btn
            dark
            large
            color="negative"
            class="navigation-button"
            :to="{
              name: 'organizationMain',
              params: { organizationId, salesManagementId }
            }"
          >
            写真一覧へ戻る
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

import ApiErrorHandler from ".././../../../../mixins/ApiErrorHandler";

import SalesManagement from "../../../../../models/SalesManagement";

import SquarePictureFrame from "../../../../../components/SquarePictureFrame";
import PictureGallery from "../../../../../components/PictureGallery";

import TooManyPhotoBookPicturesErrorDialog from "../../components/TooManyPhotoBookPicturesErrorDialog";
import TooFewPhotoBookPicturesWarnDialog from "../../components/TooFewPhotoBookPicturesWarnDialog";
import RemovePhotoBookOrderDialog from "../../components/RemovePhotoBookOrderDialog";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame,
    "picture-gallery": PictureGallery,
    "too-many-photo-book-pictures-error-dialog": TooManyPhotoBookPicturesErrorDialog,
    "too-few-photo-book-pictures-warn-dialog": TooFewPhotoBookPicturesWarnDialog,
    "remove-photo-book-order-dialog": RemovePhotoBookOrderDialog
  },
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    photoBookPictures: {
      type: Array, // Array<Picture>
      required: true
    },
    purchasedPictureIds: {
      type: Set, // Set<number>
      required: true
    }
  },
  data() {
    return {
      galleryDialog: false,
      galleryIndex: null,
      showRemovePhotoBookOrderDialog: false,
      showTooManyPicturesDialog: false,
      showTooFewPicturesDialog: false
    };
  },
  computed: {
    ...mapGetters({
      familyKidIds: "family/kidIds",
      cartPhotoBookMediaType: "cart/photoBookMediaType",
      cartPhotoBookPictureIds: "cart/photoBookPictureIds"
    }),
    salesManagementPrice() {
      return this.salesManagement.prices.find(
        p => p.mediaType.value === this.cartPhotoBookMediaType
      );
    },
    cartPhotoBookPictures() {
      return this.photoBookPictures.filter(p =>
        this.cartPhotoBookPictureIds.has(p.id)
      );
    }
  },
  methods: {
    openPictureGallery(picture) {
      this.galleryIndex = this.cartPhotoBookPictures.findIndex(
        p => p === picture
      );
      this.galleryDialog = true;
    },
    onMovePictureGallery({ picture }) {
      this.$vuetify.goTo(`#${picture.uniqueKey}`, {
        duration: 100,
        offset: -100
      });
    },
    closePictureGallery() {
      this.galleryIndex = null;
      this.galleryDialog = false;
    },
    async removePhotoBookOrder() {
      try {
        await this.$store.dispatch("cart/deleteCartPhotoBook");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    },
    onClickGoStorePageButton() {
      if (
        this.cartPhotoBookPictureIds.size >
        this.salesManagementPrice.photoBookMaxPictureCount
      ) {
        this.showTooManyPicturesDialog = true;
        return;
      }
      if (
        this.cartPhotoBookPictureIds.size <
        this.salesManagementPrice.photoBookWarnPictureCount
      ) {
        this.showTooFewPicturesDialog = true;
        return;
      }

      this.goStorePage();
    },
    goStorePage() {
      this.$router.push({
        name: "organizationStore",
        params: {
          organizationId: this.organizationId,
          salesManagementId: this.salesManagementId
        }
      });
    }
  }
};
</script>

<style>
.picture-gallery-dialog {
  max-height: 98% !important;
}
</style>
