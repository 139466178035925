<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">vpn_key</v-icon>
        <br />パスワードの再設定
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">再設定を完了しました。</p>

          <p>
            「ログイン画面へ」ボタンからメールアドレスとパスワードを入力してログインしてください。<br />
            <span class="red--text"
              >また、同じアカウントをご利用のご家族さまにパスワードを変更したことをお伝えください。</span
            >
          </p>

          <div class="my-3">
            <img src="../../assets/images/illust_ok.png" />
          </div>

          <v-btn block color="primary" :to="{ name: 'login' }">
            ログイン画面へ
          </v-btn>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
