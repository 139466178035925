<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">face</v-icon>
        <br />写真の選択
      </h1>

      <fr-processing-dialog
        :dialog="frProcessingDialog"
        label="お顔を検出中です"
      ></fr-processing-dialog>

      <div class="px-3">
        <p>
          正面を向いている写真や、顔全体が写っている写真を選択してください。
        </p>
      </div>

      <div v-if="errorMessage" class="px-3 pb-3 caption error--text">
        {{ errorMessage }}
      </div>

      <section>
        <h2 class="title-bar">{{ familyName }}家の最近購入した写真</h2>
        <div v-if="purchasedPictures" class="pa-3">
          <v-layout wrap class="photo-list">
            <v-flex
              v-for="picture in purchasedPictures"
              :key="picture.uniqueKey"
              xs4
              md3
              mb-3
            >
              <square-picture-frame :picture="picture" hide-zoom-icon no-cursor>
              </square-picture-frame>

              <table class="table-vertical">
                <tr>
                  <td class="text-xs-right">{{ picture.fileNo }}</td>
                </tr>
              </table>

              <div>
                <v-btn
                  color="primary"
                  small
                  block
                  @click="startFaceDetection(picture.id)"
                >
                  選択する
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </section>

      <v-layout justify-center row pb-4>
        <v-btn
          class="v-btn--minwidth"
          color="negative"
          :to="{ name: 'listFrKidFaces', params: { kidId } }"
        >
          戻る
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import ApiErrors from "../../lib/lookmee_photo/ApiErrors";

import SquarePictureFrame from "../../components/SquarePictureFrame";
import FrProcessingDialog from "./components/FrProcessingDialog";

import { SOURCE_TYPE_PURCHASED } from "../../models/FaceRegistration";

const POLLING_TRY_LIMIT = 20;
const POLLING_INTERVAL = 1000;

export default {
  components: {
    "square-picture-frame": SquarePictureFrame,
    "fr-processing-dialog": FrProcessingDialog
  },
  mixins: [ApiErrorHandler],
  props: {
    kidId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null,
      frProcessingDialog: false,
      purchasedPictures: null
    };
  },
  computed: {
    ...mapGetters("family", {
      familyName: "familyName"
    })
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const data = await this.$store.dispatch("fr/pagesFrPurchasedPictures");
        this.purchasedPictures = data.purchasedPictures;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    async startFaceDetection(purchasedPictureId) {
      if (this.frProcessingDialog) {
        return;
      }

      let faceRegistration = null;
      this.errorMessage = null;
      this.frProcessingDialog = true;
      try {
        faceRegistration = await this.createFaceRegistration(
          this.kidId,
          purchasedPictureId
        );

        faceRegistration = await this.detectFacesAsync(faceRegistration.id);

        await new Promise(resolve => setTimeout(resolve, 2000));

        faceRegistration = await this.pollingUntilFaceDetectionFinished(
          faceRegistration.id
        );

        const faceDetectionResult = faceRegistration.faceDetectionResult;
        if (!faceDetectionResult || !faceDetectionResult.hasDetectedFaces()) {
          throw "選択した写真の中にお顔が検出されませんでした。別の写真を選択してください。";
        }

        this.frProcessingDialog = false;

        this.$router.push({
          name: "selectFrKidFace",
          params: { kidId: this.kidId, faceRegistrationId: faceRegistration.id }
        });
      } catch (error) {
        if (typeof error === "string" || error instanceof String) {
          this.errorMessage = error;
        } else if (error instanceof ApiErrors) {
          this.handleApiErrors(error, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.errorMessage = error.message;
        }

        if (faceRegistration) {
          this.$store.dispatch("fr/deleteFaceRegistration", {
            id: faceRegistration.id
          });
        }
      } finally {
        if (this.errorMessage) {
          this.$vuetify.goTo(0);
        }
        this.frProcessingDialog = false;
      }
    },
    async createFaceRegistration(kidId, orderDetailPictureId) {
      try {
        return await this.$store.dispatch("fr/createFaceRegistration", {
          kidId,
          sourceType: SOURCE_TYPE_PURCHASED,
          orderDetailPictureId
        });
      } catch (errors) {
        if (errors.status === 400) {
          throw errors.getErrorMessage();
        } else {
          throw errors;
        }
      }
    },
    async detectFacesAsync(faceRegistrationId) {
      try {
        return await this.$store.dispatch(
          "fr/startFaceDetectionFaceRegistration",
          { id: faceRegistrationId }
        );
      } catch (errors) {
        if (this.sentry) {
          this.sentry.captureMessage(errors.getErrorMessage());
        }
        throw "顔検出中にシステムエラーが発生しました。お手数ですが、再度写真を選択してください。";
      }
    },
    async pollingUntilFaceDetectionFinished(faceRegistrationId) {
      for (let i = 0; i < POLLING_TRY_LIMIT; i++) {
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));

        const faceRegistration = await this.$store.dispatch(
          "fr/getFaceRegistration",
          {
            id: faceRegistrationId
          }
        );

        if (faceRegistration.isFaceDetectionFinished()) {
          return faceRegistration;
        } else if (faceRegistration.isFailed()) {
          throw faceRegistration.errorMessage;
        }
      }

      throw "顔検出がタイムアウトしました。お手数ですが、再度写真を選択してください。";
    }
  }
};
</script>
