<template>
  <div>
    <v-layout row justify-space-between align-center pt-3 pl-3 pr-1>
      <v-flex>
        お届け先{{ index
        }}<span v-show="userAddress.defaultAddress">(既定)</span>
      </v-flex>

      <v-flex text-xs-right>
        <v-btn
          v-show="showMakeDefaultButton"
          small
          class="v-btn--minwidth"
          color="primary"
          :disabled="userAddress.defaultAddress"
          @click="
            $emit('click-make-default-button', {
              userAddressId: userAddress.id
            })
          "
        >
          既定のお届け先にする
        </v-btn>
      </v-flex>
    </v-layout>

    <div class="pa-3">
      <p class="mb-1">
        {{ userAddress.fullName }} 様<br />
        住所： {{ userAddress.fullAddress }}<br />
        電話番号： {{ userAddress.telephone }}
      </p>

      <ul class="list-vertical text-xs-right">
        <li>
          <router-link
            :to="{
              name: 'editUserAddress',
              params: { userAddressId: userAddress.id }
            }"
          >
            編集する
          </router-link>
        </li>
        <li>
          <a @click="$emit('click-remove-link')">削除する</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UserAddress from "../../../models/UserAddress";

export default {
  props: {
    userAddress: {
      type: UserAddress,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    showMakeDefaultButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>
