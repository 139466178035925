<template>
  <div>
    <div v-for="event in salesItemEvents" :key="event.uniqueKey">
      <h2 class="text-xs-left py-1 pl-2 mt-5 mb-2">
        {{ event.eventNameWithCount }}
      </h2>

      <p
        v-if="event.comment && event.comment.length > 0"
        class="event-comment caption text-xs-left pb-1 mx-2"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="event.commentForHtml"></span>
      </p>

      <v-layout row wrap class="photo-list">
        <template v-if="event.isPicture">
          <v-flex
            v-for="picture in eventPicturesMap.get(event.id)"
            :id="picture.uniqueKey"
            :key="picture.uniqueKey"
            xs4
            mb-3
          >
            <square-picture-frame
              :picture="picture"
              @click-picture="$emit('click-picture', picture)"
            ></square-picture-frame>

            <table class="table-vertical">
              <tr>
                <td>
                  <v-icon v-if="picture.hasFace(kidIds)" small color="warning">
                    face
                  </v-icon>
                  <v-icon
                    v-if="purchasedPictureIds.has(picture.id)"
                    small
                    color="error"
                  >
                    photo_filter
                  </v-icon>
                </td>
                <td class="text-xs-right id-num">{{ picture.fileNo }}</td>
              </tr>
            </table>

            <div class="text-xs-center">
              <cart-picture-button
                v-if="isPhotoAndVideoSale"
                :organization-id="organizationId"
                :sales-management-id="salesManagementId"
                :picture="picture"
                :small="true"
                html-class="caption pa-0 mx-0"
              >
              </cart-picture-button>
              <cart-photo-book-picture-button
                v-else-if="isPhotoBookSale"
                :picture-id="picture.id"
                :is-low-resolution="picture.isLowResolution()"
                :is-in-cart="cartPhotoBookPictureIds.has(picture.id)"
                :small="true"
                :add-to-cart="addPhotoBookPictureToCart"
                :remove-from-cart="removePhotoBookPictureFromCart"
              ></cart-photo-book-picture-button>
            </div>
          </v-flex>
        </template>

        <template v-else-if="isPhotoAndVideoSale && event.isVideo">
          <v-flex
            v-for="video in eventVideosMap.get(event.id)"
            :key="video.uniqueKey"
            xs4
            mb-3
          >
            <square-video-frame
              :video="video"
              @play="$emit('click-video', video)"
            >
            </square-video-frame>

            <table class="table-vertical">
              <tr>
                <td>
                  <v-icon
                    v-if="purchasedVideoIds.has(video.id)"
                    small
                    color="error"
                  >
                    photo_filter
                  </v-icon>
                </td>
                <td class="text-xs-right id-num">{{ video.fileNo }}</td>
              </tr>
            </table>

            <div class="text-xs-center">
              <cart-video-button
                :organization-id="organizationId"
                :sales-management-id="salesManagementId"
                :video="video"
                :small="true"
                html-class="caption pa-0 mx-0"
              >
              </cart-video-button>
            </div>
          </v-flex>
        </template>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { SALES_TYPES } from "../../../../data/constants";

import SquarePictureFrame from "../../../../components/SquarePictureFrame";
import SquareVideoFrame from "../../../../components/SquareVideoFrame";
import CartPictureButton from "./CartPictureButton";
import CartVideoButton from "./CartVideoButton";
import CartPhotoBookPictureButton from "./CartPhotoBookPictureButton";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame,
    "square-video-frame": SquareVideoFrame,
    "cart-picture-button": CartPictureButton,
    "cart-photo-book-picture-button": CartPhotoBookPictureButton,
    "cart-video-button": CartVideoButton
  },
  props: {
    kidIds: {
      type: Set, // Set<number>
      required: true
    },
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    salesType: {
      type: Number,
      required: true
    },
    events: {
      type: Array, // Array<Event>
      required: true
    },
    salesItems: {
      type: Array, // Array<SalesItem>
      required: true
    },
    purchasedPictureIds: {
      type: Set, // Set<number>
      default() {
        return new Set();
      }
    },
    purchasedVideoIds: {
      type: Set, // Set<number>
      default() {
        return new Set();
      }
    },
    cartPhotoBookPictureIds: {
      type: Set, // Set<number>
      default() {
        return new Set();
      }
    },
    addPhotoBookPictureToCart: {
      type: Function,
      default() {
        return [];
      }
    },
    removePhotoBookPictureFromCart: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isPhotoAndVideoSale() {
      return this.salesType === SALES_TYPES.photoAndVideo;
    },
    isPhotoBookSale() {
      return this.salesType === SALES_TYPES.photoBook;
    },
    salesItemEvents() {
      const eventIdSet = new Set(this.salesItems.map(s_i => s_i.eventId));
      return this.events.filter(e => eventIdSet.has(e.id));
    },
    eventPicturesMap() {
      const map = new Map();
      this.salesItems
        .filter(s_i => s_i.isPicture())
        .forEach(s_i => {
          const pictures = map.get(s_i.eventId) || [];
          pictures.push(s_i.picture);
          map.set(s_i.eventId, pictures);
        });
      return map;
    },
    eventVideosMap() {
      const map = new Map();
      this.salesItems
        .filter(s_i => s_i.isVideo())
        .forEach(s_i => {
          const videos = map.get(s_i.eventId) || [];
          videos.push(s_i.video);
          map.set(s_i.eventId, videos);
        });
      return map;
    }
  }
};
</script>

<style scoped>
h2 {
  font-size: 16pt;
  font-weight: normal;
  border-bottom: none;
  border-left: solid 3px #dbe171;
}

p.event-comment {
  font-size: 11pt;
  color: #939393;
  border-bottom: 1px solid #dedede;
}
</style>
