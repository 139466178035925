import { render, staticRenderFns } from "./InputOrderer.vue?vue&type=template&id=37757482&scoped=true&"
import script from "./InputOrderer.vue?vue&type=script&lang=js&"
export * from "./InputOrderer.vue?vue&type=script&lang=js&"
import style0 from "./InputOrderer.vue?vue&type=style&index=0&id=37757482&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37757482",
  null
  
)

export default component.exports