<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />追加完了
      </h1>
      <v-card>
        <v-card-text>
          <p class="text-xs-center">
            お子さまの追加が完了しました。
          </p>
          <v-layout justify-center row>
            <img src="../../../assets/images/completed.png" alt="" />
          </v-layout>
          <div class="my-4">
            <v-layout justify-center row>
              <v-btn
                block
                class="v-btn--minwidth"
                color="primary"
                :to="{ name: 'listFrKids' }"
              >
                続けて顔認識の登録をする
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                block
                class="v-btn--minwidth"
                color="negative"
                :to="{
                  name: 'organizationTop',
                  params: { organizationId: form.organization.id }
                }"
              >
                後で登録する（お子さまの写真を見る）
              </v-btn>
            </v-layout>
          </div>
          <p class="text-xs-center">
            <router-link :to="{ name: 'listKids' }"
              >お子さまの情報へ戻る≫</router-link
            >
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import KidsClassRegistrationForm from "../../../forms/KidsClassRegistrationForm";

export default {
  props: {
    form: {
      type: KidsClassRegistrationForm,
      required: true
    }
  }
};
</script>
