<template>
  <v-layout wrap row>
    <v-flex xs5 text-xs-center class="pr-3">
      <square-picture-frame :picture="picture" hide-zoom-icon no-cursor>
      </square-picture-frame>
    </v-flex>

    <v-flex xs7 text-xs-left class="order-detail px-2">
      <table>
        <tr>
          <td>写真番号</td>
          <td class="text-xs-right">{{ picture.fileNo }}</td>
        </tr>
        <tr>
          <td>写真サイズ</td>
          <td class="text-xs-right">{{ picture.sizeType.label }}</td>
        </tr>
        <tr>
          <td>プリント価格</td>
          <td class="text-xs-right" :class="{ 'red--text': !printPrice }">
            {{ printPrice ? printPrice.price + " 円" : "購入不可" }}
          </td>
        </tr>
        <tr>
          <td>データ価格</td>
          <td class="text-xs-right" :class="{ 'red--text': !dataPrice }">
            {{ dataPrice ? dataPrice.price + " 円" : "購入不可" }}
          </td>
        </tr>
      </table>

      <v-divider></v-divider>

      <v-layout row wrap align-center justify-center>
        <v-flex xs4 text-xs-center>
          <v-btn
            flat
            icon
            color="primary"
            :disabled="!canPictureDecrement"
            @click.native="decrementOrderAmount"
          >
            <v-icon :large="$vuetify.breakpoint.name !== 'xs'">
              remove_circle
            </v-icon>
          </v-btn>
        </v-flex>
        <v-flex
          xs4
          text-xs-center
          class="font-weight-bold my-2"
          :class="{ title: $vuetify.breakpoint.name !== 'xs' }"
        >
          {{ orderAmount }} 枚
        </v-flex>
        <v-flex xs4 text-xs-center>
          <v-btn
            flat
            icon
            color="primary"
            :disabled="!canPictureIncrement"
            @click.native="incrementOrderAmount"
          >
            <v-icon :large="$vuetify.breakpoint.name !== 'xs'">
              add_circle
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>

      <div class="text-xs-right">
        <a
          href="#"
          class="remove-item-link"
          @click.stop.prevent="$emit('remove-picture-order', picture)"
        >
          削除する
        </a>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import Picture from "../../../../../../models/Picture";

import {
  CART_ITEM_MIN_LIMIT,
  CART_ITEM_MAX_LIMIT
} from "../../../../../../models/Cart";

import SquarePictureFrame from "../../../../../../components/SquarePictureFrame";

export default {
  components: {
    "square-picture-frame": SquarePictureFrame
  },
  props: {
    picture: {
      type: Picture,
      required: true
    },
    orderAmount: {
      type: Number,
      required: true
    },
    priceMap: {
      type: Map, // Map<number, number>
      required: true
    },
    orderAmountChangeable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    printPrice() {
      return this.priceMap.get(this.picture.printMediaType);
    },
    dataPrice() {
      return this.priceMap.get(this.picture.dataMediaType);
    },
    canPictureIncrement() {
      return (
        this.orderAmountChangeable && this.orderAmount < CART_ITEM_MAX_LIMIT
      );
    },
    canPictureDecrement() {
      return (
        this.orderAmountChangeable && this.orderAmount > CART_ITEM_MIN_LIMIT
      );
    }
  },
  methods: {
    incrementOrderAmount() {
      if (!this.canPictureIncrement) {
        return;
      }
      this.$emit("update-picture-order-count", {
        id: this.picture.id,
        orderAmount: this.orderAmount + 1
      });
    },
    decrementOrderAmount() {
      if (!this.canPictureDecrement) {
        return;
      }
      this.$emit("update-picture-order-count", {
        id: this.picture.id,
        orderAmount: this.orderAmount - 1
      });
    }
  }
};
</script>

<style scoped>
.order-detail table {
  width: 100%;
  font-size: 11px;
}

.order-detail table th {
  width: 50%;
}

a.remove-item-link {
  font-size: 12px;
  color: #545454;
  text-decoration: underline;
}
</style>
