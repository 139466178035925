import LoginPage from "./Login";

import UserRegistrationNewPage from "./user_registration/New";
import UserRegistrationSendEmailPage from "./user_registration/SendEmail";
import UserRegistrationContinuePage from "./user_registration/Continue";
import UserRegistrationCompletedPage from "./user_registration/Completed";

import PasswordResetNewPage from "./password_reset/New";
import PasswordResetSendEmailPage from "./password_reset/SendEmail";
import PasswordResetContinuePage from "./password_reset/Continue";
import PasswordResetCompletedPage from "./password_reset/Completed";

import OrganizationsListPage from "./organizations/List";
import OrganizationsShowPage from "./organizations/Show";
import SalesManagementsShowPage from "./organizations/sales_managements/Show";
import SalesManagementsSelectPhotoBookTypePage from "./organizations/sales_managements/SelectPhotoBookType";
import CartShowPage from "./organizations/sales_managements/cart/Show";
import StoreShowPage from "./organizations/sales_managements/store/Show";

import NotificationListPage from "./notification/List";
import NotificationShowPage from "./notification/Show";

import OrderHistoryListPage from "./order_history/List";
import OrderHistoryShowPage from "./order_history/Show";
import OrderHistoryDownloadPicturesPage from "./order_history/DownloadPictures";
import OrderHistoryDownloadPicturePage from "./order_history/DownloadPicture";
import OrderHistoryDownloadVideosPage from "./order_history/DownloadVideos";
import OrderHistoryCancelCompletedPage from "./order_history/CancelCompleted";

import OrdersPaymentCompletedPage from "./orders/PaymentCompleted";

import FrListKidsPage from "./fr/ListKids";
import FrAboutPage from "./fr/About";
import FrListKidFacesPage from "./fr/ListKidFaces";
import FrListPurchasedPicturesPage from "./fr/ListPurchasedPictures";
import FrSelectKidFacePage from "./fr/SelectKidFace";

import FamilyListPage from "./family/List";
import FamilyShowPage from "./family/Show";
import FamilyEditPage from "./family/Edit";

import UserEmailShowPage from "./user_email/Show";
import UserEmailEditPage from "./user_email/Edit";

import UserActivatePage from "./user/Activate";

import InactivatedEmailListPage from "./inactivated_email/List";
import sendActivateMailsCompletedPage from "./inactivated_email/SendActicationMails";

import KidListPage from "./kid/List";
import KidNewPage from "./kid/New";

import UserAddressListPage from "./user_address/List";
import UserAddressNewPage from "./user_address/New";
import UserAddressEditPage from "./user_address/Edit";
import UserAddressRemoveCompletedPage from "./user_address/RemoveComplete";

import PasswordChangeShowPage from "./password_change/Show";
import PasswordChangeCompletedPage from "./password_change/Completed";

import KidsClassRegistrationNewPage from "./kids_class_registrations/New";

import InquiryNewPage from "./inquiry/New";

import UserEmergencyListPage from "./user_emergency/List";
import UserEmergencyEditPage from "./user_emergency/Edit";

import FamilyInvitationPage from "./family_invitation/New";
import InvitedFamilyRegistrationPage from "./family_invitation/Registration";
import InvitedFamilyLoginPage from "./family_invitation/Login";
import InvitedFamilyLoginCompletePage from "./family_invitation/LoginComplete";
import invitedFamilyInvalidUrlPage from "./family_invitation/InvalidUrl";

import WithdrawalConfirmationPage from "./withdrawal/Confirmation";

import StaticLawPage from "./static/Law";
import StaticMailNotReceivedPage from "./static/MailNotReceived";

import ErrorInvalidRequestPage from "./error/InvalidRequest";
import ErrorSalesFinishedPage from "./error/SalesFinished";
import ErrorNotFoundForFamilyPage from "./error/NotFoundForFamily";
import ErrorNotFoundPage from "./error/NotFound";
import ErrorServerErrorPage from "./error/ServerError";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    meta: {
      title: "ログイン",
      fillHeight: true,
      hideToolbar: true,
      hideDrawer: true,
      familyNotRequired: true
    }
  },
  // user registration
  {
    path: "/site/user_registration/new",
    name: "newUserRegistration",
    component: UserRegistrationNewPage,
    props: route => ({ invalidUrlError: !!route.query.invalidUrlError }),
    meta: {
      title: "新規登録",
      hideDrawer: true,
      hideToolbarIcons: true,
      familyNotRequired: true
    }
  },
  {
    path: "/site/user_registration/send_email",
    name: "sendEmailUserRegistration",
    component: UserRegistrationSendEmailPage,
    meta: {
      title: "新規登録（まだご登録は完了していません）",
      hideDrawer: true,
      hideToolbarIcons: true,
      familyNotRequired: true
    }
  },
  {
    path: "/site/user_registration/continue",
    name: "continueUserRegistration",
    component: UserRegistrationContinuePage,
    meta: {
      title: "新規登録",
      hideDrawer: true,
      hideToolbarIcons: true,
      isPlainPages(route) {
        return route.hash !== "#matching_error";
      },
      switchSubPageByHash: true,
      familyNotRequired: true
    }
  },
  {
    path: "/site/user_registration/completed",
    name: "completedUserRegistration",
    component: UserRegistrationCompletedPage,
    meta: {
      title: "登録完了",
      familyNotRequired: true
    }
  },
  // password reset
  {
    path: "/site/password_reset/new",
    name: "newPasswordReset",
    component: PasswordResetNewPage,
    props: route => ({ invalidUrlError: !!route.query.invalidUrlError }),
    meta: {
      title: "パスワードを忘れた場合",
      familyNotRequired: true
    }
  },
  {
    path: "/site/password_reset/send_email",
    name: "sendEmailPasswordReset",
    component: PasswordResetSendEmailPage,
    meta: {
      title: "パスワードを忘れた場合（メールをご確認ください）",
      familyNotRequired: true
    }
  },
  {
    path: "/site/password_reset/continue",
    name: "continuePasswordReset",
    component: PasswordResetContinuePage,
    props: route => ({ token: route.query.token }),
    meta: {
      title: "パスワードの再設定",
      familyNotRequired: true
    }
  },
  {
    path: "/site/password_reset/completed",
    name: "completedPasswordReset",
    component: PasswordResetCompletedPage,
    meta: {
      title: "パスワードの再設定完了",
      familyNotRequired: true
    }
  },
  // organizations
  {
    path: "/site/organizations",
    name: "organizations",
    component: OrganizationsListPage,
    meta: {
      title: "園・施設の選択"
    }
  },
  {
    path: "/site/organizations/:organizationId",
    name: "organizationTop",
    component: OrganizationsShowPage,
    props: route => ({ organizationId: parseInt(route.params.organizationId) }),
    meta: {
      title: "販売一覧"
    }
  },
  {
    path:
      "/site/organizations/:organizationId/sales_managements/:salesManagementId/select_photo_book_type",
    name: "organizationSelectPhotoBookType",
    component: SalesManagementsSelectPhotoBookTypePage,
    props: route => {
      return {
        organizationId: parseInt(route.params.organizationId),
        salesManagementId: parseInt(route.params.salesManagementId)
      };
    },
    meta: {
      title: "フォトブックの作成"
    }
  },
  {
    path:
      "/site/organizations/:organizationId/sales_managements/:salesManagementId",
    name: "organizationMain",
    component: SalesManagementsShowPage,
    props: route => {
      return {
        organizationId: parseInt(route.params.organizationId),
        salesManagementId: parseInt(route.params.salesManagementId),
        groupId: parseInt(route.query.groupId) || undefined,
        eventId:
          parseInt(route.query.eventId) || route.query.eventId || undefined, // "today" や "video" といった値が入るとparseIntできない点を考慮。
        page: parseInt(route.query.page) || 1
      };
    },
    meta: {
      title: "写真・動画一覧",
      isPlainPages: true
    }
  },
  {
    path:
      "/site/organizations/:organizationId/sales_managements/:salesManagementId/cart",
    name: "organizationCart",
    component: CartShowPage,
    props: route => {
      return {
        organizationId: parseInt(route.params.organizationId),
        salesManagementId: parseInt(route.params.salesManagementId)
      };
    },
    meta: {
      title: "カートの中身",
      isPlainPages: true
    }
  },
  {
    path:
      "/site/organizations/:organizationId/sales_managements/:salesManagementId/store",
    name: "organizationStore",
    component: StoreShowPage,
    props: route => {
      return {
        organizationId: parseInt(route.params.organizationId),
        salesManagementId: parseInt(route.params.salesManagementId)
      };
    },
    meta: {
      title: "注文手続き",
      hideDrawer: true,
      hideToolbarIcons: true,
      isPlainPages: true
    }
  },
  // notification
  {
    path: "/site/notifications",
    name: "listNotifications",
    component: NotificationListPage,
    props: routes => ({ reload: routes.params.reload }),
    meta: {
      title: "お知らせ一覧"
    }
  },
  {
    path: "/site/notifications/:notificationType/:notificationId",
    name: "showNotification",
    component: NotificationShowPage,
    props: route => {
      return {
        notificationId: parseInt(route.params.notificationId),
        notificationType: route.params.notificationType
      };
    },
    meta: {
      title: "お知らせ詳細"
    }
  },
  // orders
  {
    path: "/site/order_histories",
    name: "listOrderHistories",
    component: OrderHistoryListPage,
    meta: {
      title: "注文履歴",
      isPlainPages: true
    }
  },
  {
    path: "/site/order_histories/:orderId",
    name: "showOrderHistory",
    component: OrderHistoryShowPage,
    props: route => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: "注文内容の詳細",
      isPlainPages: true
    }
  },
  {
    path: "/site/order_histories/:orderId/download_pictures",
    name: "downloadPictures",
    component: OrderHistoryDownloadPicturesPage,
    props: route => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: "写真のダウンロード",
      isPlainPages: true
    }
  },
  {
    path: "/site/order_histories/:orderId/download_pictures/:orderDetailId",
    name: "downloadPicture",
    component: OrderHistoryDownloadPicturePage,
    props: route => ({
      orderId: parseInt(route.params.orderId),
      orderDetailId: parseInt(route.params.orderDetailId)
    }),
    meta: {
      title: "スマートフォンへ写真を保存",
      isPlainPages: true
    }
  },
  {
    path: "/site/order_histories/:orderId/download_videos",
    name: "downloadVideos",
    component: OrderHistoryDownloadVideosPage,
    props: route => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: "動画のダウンロード",
      isPlainPages: true
    }
  },
  {
    path: "/site/order_histories/:orderId/cancel_completed",
    name: "orderCancelCompleted",
    component: OrderHistoryCancelCompletedPage,
    meta: {
      title: "注文キャンセルの完了"
    }
  },
  {
    path: "/site/orders/:orderId/payment_completed",
    name: "orderPaymentCompleted",
    component: OrdersPaymentCompletedPage,
    props: route => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: "決済完了"
    }
  },
  // face recognition
  {
    path: "/site/fr",
    name: "listFrKids",
    component: FrListKidsPage,
    meta: {
      title: "顔認識機能"
    }
  },
  {
    path: "/site/fr/about",
    name: "aboutFr",
    component: FrAboutPage,
    meta: {
      title: "顔認識のメリット",
      isPlainPages: true
    }
  },
  {
    path: "/site/fr/kids/:kidId/faces",
    name: "listFrKidFaces",
    component: FrListKidFacesPage,
    props: route => ({
      kidId: parseInt(route.params.kidId)
    }),
    meta: {
      title: "顔写真の登録"
    }
  },
  // 購入写真からの選択と顔の選択を1画面でやる
  {
    path: "/site/fr/kids/:kidId/purchased_pictures",
    name: "listFrPurchasedPictures",
    component: FrListPurchasedPicturesPage,
    props: route => ({ kidId: parseInt(route.params.kidId) }),
    meta: {
      title: "写真の選択",
      isPlainPages: true
    }
  },
  {
    path: "/site/fr/kids/:kidId/select_face/:faceRegistrationId",
    name: "selectFrKidFace",
    component: FrSelectKidFacePage,
    props: route => ({
      kidId: parseInt(route.params.kidId),
      faceRegistrationId: parseInt(route.params.faceRegistrationId)
    }),
    meta: {
      title: "お子さまの選択"
    }
  },
  // family
  {
    path: "/site/families",
    name: "listFamilies",
    component: FamilyListPage,
    meta: {
      title: "世帯の選択",
      familyNotRequired: true
    }
  },
  {
    path: "/site/family",
    name: "showFamily",
    component: FamilyShowPage,
    meta: {
      title: "世帯情報"
    }
  },
  // 入力、確認、完了を1ページで行う
  {
    path: "/site/family/edit",
    name: "editFamily",
    component: FamilyEditPage,
    meta: {
      title: "世帯電話番号の変更",
      isPlainPages(route) {
        return route.hash === "" || route.hash === "#input";
      },
      switchSubPageByHash: true
    }
  },
  // user
  {
    path: "/site/users/email",
    name: "showUserEmail",
    component: UserEmailShowPage,
    meta: {
      title: "メールアドレス"
    }
  },
  {
    path: "/site/users/activate",
    name: "activateUser",
    component: UserActivatePage,
    props: route => {
      return {
        token: route.query.token
      };
    },
    meta: {
      title: "メールアドレスの登録完了"
    }
  },
  // inactivated_email
  {
    path: "/site/inactivated_emails",
    name: "listInactivatedEmails",
    component: InactivatedEmailListPage,
    meta: {
      title: "仮登録状態のメールアドレスがあります"
    }
  },
  {
    path: "/site/inactivated_emails/send_activate_mails",
    name: "sendActivateMailsCompleted",
    component: sendActivateMailsCompletedPage,
    meta: {
      title: "メールが送信されました"
    }
  },
  // kid
  {
    path: "/site/kids",
    name: "listKids",
    component: KidListPage,
    meta: {
      title: "お子さまの情報"
    }
  },
  // 入力、確認、完了を1ページで行う
  {
    path: "/site/kids/new",
    name: "newKid",
    component: KidNewPage,
    meta: {
      title: "お子さまの追加",
      switchSubPageByHash: true
    }
  },
  // user address
  {
    path: "/site/user_addresses",
    name: "listUserAddresses",
    component: UserAddressListPage,
    meta: {
      title: "お届け先の情報"
    }
  },
  {
    path: "/site/user_addresses/remove_completed",
    name: "userAddressRemoveCompleted",
    component: UserAddressRemoveCompletedPage,
    meta: {
      title: "お届け先の情報"
    }
  },
  // 入力、確認、完了を1ページで行う
  {
    path: "/site/user_addresses/new",
    name: "newUserAddress",
    component: UserAddressNewPage,
    meta: {
      title: "お届け先の追加",
      isPlainPages(route) {
        return route.hash === "#input" || route.hash === "#confirm";
      },
      switchSubPageByHash: true
    }
  },
  // 入力、確認、完了を1ページで行う
  {
    path: "/site/user_addresses/:userAddressId/edit",
    name: "editUserAddress",
    component: UserAddressEditPage,
    props: route => ({ userAddressId: parseInt(route.params.userAddressId) }),
    meta: {
      title: "お届け先の編集",
      isPlainPages(route) {
        return route.hash === "#input" || route.hash === "#confirm";
      },
      switchSubPageByHash: true
    }
  },
  // password
  {
    path: "/site/password_change",
    name: "showPasswordChange",
    component: PasswordChangeShowPage,
    meta: {
      title: "パスワードの設定"
    }
  },
  {
    path: "/site/password_change/completed",
    name: "completedPasswordChange",
    component: PasswordChangeCompletedPage,
    meta: {
      title: "パスワードの設定"
    }
  },
  // kids_belong
  // 入力1,2、確認、完了を1ページで行う
  {
    path: "/site/kids_class_registrations/new",
    name: "newKidsClassRegistration",
    component: KidsClassRegistrationNewPage,
    meta: {
      title: "園・施設の登録",
      switchSubPageByHash: true
    }
  },
  // inquiry
  // 入力1,2、確認、完了を1ページで行う
  {
    path: "/site/inquiries/new",
    name: "newInquiry",
    component: InquiryNewPage,
    meta: {
      title: "お問い合わせ",
      isPlainPages(route) {
        return route.hash === "" || route.hash === "#confirm";
      },
      switchSubPageByHash: true
    }
  },
  // user
  {
    path: "/site/user/emergencies",
    name: "listUserEmergencies",
    component: UserEmergencyListPage,
    meta: {
      title: "園・施設からのお知らせ"
    }
  },
  {
    path: "/site/user/emergencies/edit",
    name: "editUserEmergency",
    component: UserEmergencyEditPage,
    meta: {
      title: "園・施設からのお知らせ"
    }
  },
  {
    path: "/site/invited_family/registration",
    name: "invitedFamilyRegistration",
    component: InvitedFamilyRegistrationPage,
    props: route => ({ token: route.query.token }),
    meta: {
      title: "保護者情報の登録",
      hideDrawer: true,
      hideToolbarIcons: true,
      isPlainPages(route) {
        return route.hash === "" || route.hash === "#input";
      },
      switchSubPageByHash: true,
      familyNotRequired: true
    }
  },
  {
    path: "/site/invited_family/invalid_url",
    name: "invitedFamilyInvalidUrl",
    component: invitedFamilyInvalidUrlPage,
    meta: {
      title: "保護者情報の登録",
      hideDrawer: true,
      hideToolbarIcons: true,
      familyNotRequired: true
    }
  },
  {
    path: "/site/invited_family/login",
    name: "invitedFamilyLogin",
    component: InvitedFamilyLoginPage,
    props: route => ({ token: route.params.token }),
    meta: {
      title: "被招待導線ログイン",
      familyNotRequired: true
    }
  },
  {
    path: "/site/invited_family/login_complete",
    name: "invitedFamilyLoginComplete",
    component: InvitedFamilyLoginCompletePage,
    meta: {
      title: "被招待導線ログイン完了",
      familyNotRequired: false
    }
  },
  // withdrawal
  {
    path: "/site/withdrawals/confirmation",
    name: "withdrawalConfirmation",
    component: WithdrawalConfirmationPage,
    meta: {
      title: "退会"
    }
  },
  // static contents
  {
    path: "/site/law",
    name: "staticLaw",
    component: StaticLawPage,
    meta: {
      title: "特定商取引法に基づく表記"
    }
  },
  {
    path: "/site/mail_not_received",
    name: "staticMailNotReceived",
    component: StaticMailNotReceivedPage,
    meta: {
      title: "ルクミーからのメールが届かない方は"
    },
    props: route => {
      return {
        backTo: route.query.backTo
      };
    }
  },
  // error
  {
    path: "/site/error/invalid_request",
    name: "invalidRequest",
    component: ErrorInvalidRequestPage,
    meta: {
      title: "不正なリクエストです"
    }
  },
  {
    path: "/site/error/sales_finished",
    name: "salesFinished",
    component: ErrorSalesFinishedPage,
    meta: {
      title: "販売が終了しています"
    }
  },
  {
    path: "/site/error/not_found_for_family",
    name: "notFoundForFamily",
    component: ErrorNotFoundForFamilyPage,
    meta: {
      title: "ページが見つかりません"
    }
  },
  {
    path: "/site/error/not_found",
    name: "notFound",
    component: ErrorNotFoundPage,
    meta: {
      title: "ページが見つかりません"
    }
  },
  {
    path: "/site/error/server_error",
    name: "serverError",
    component: ErrorServerErrorPage,
    props: route => ({ errorNo: route.query.errorNo }),
    meta: {
      title: "サーバーでエラーが発生しています"
    }
  },
  // no route match
  {
    path: "*",
    component: ErrorNotFoundPage,
    meta: {
      title: "ページが見つかりません"
    }
  }
];

export default routes;
