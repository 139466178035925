import { GetterTree } from "vuex";
import { RootState } from "../types";
import { NotificationsState } from "./types";
import Announcement from "packs/site/models/Announcement";
import Reminder from "packs/site/models/Reminder";

export const getters: GetterTree<NotificationsState, RootState> = {
  isAnnouncementsAvail(state: NotificationsState): boolean {
    return state.announcements.length === 0;
  },
  isRemindersAvail(state: NotificationsState): boolean {
    return state.reminders.length === 0;
  },
  isNotificationsAvail(_: NotificationsState, getters): boolean {
    return getters.isAnnouncementsAvail && getters.isRemindersAvail;
  },
  getNotifications(state: NotificationsState): Array<Announcement | Reminder> {
    const notifications: Array<Announcement | Reminder> = [];
    state.announcements.forEach(announcement => {
      notifications.push(announcement);
    });
    state.reminders.forEach(reminder => {
      notifications.push(reminder);
    });
    notifications.sort(function(
      obj1: Announcement | Reminder,
      obj2: Announcement | Reminder
    ) {
      // Decending: by startDate
      if (obj1.startDate < obj2.startDate) return 1;
      if (obj1.startDate > obj2.startDate) return -1;
      return 0;
    });
    return notifications;
  }
};
