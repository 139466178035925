class PasswordReset {
  email: string;
  token: string | undefined;

  constructor(data: { email: string; token?: string }) {
    this.email = data.email;
    this.token = data.token;
  }
}

export default PasswordReset;
