<template>
  <div v-if="form">
    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentSubPage"
          v-bind="{ form }"
          @back-to-input="$router.push({ hash: '#input' })"
          @confirm="$router.push({ hash: '#confirm' })"
          @complete="onComplete"
        >
        </component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";

import FamilyForm from "../../forms/FamilyForm";

import InputSubPage from "./Edit/Input";
import ConfirmSubPage from "./Edit/Confirm";
import CompleteSubPage from "./Edit/Complete";

export default {
  mixins: [ApiErrorHandler],
  data() {
    return {
      form: null
    };
  },
  computed: {
    currentSubPage() {
      switch (this.$route.hash) {
        case "#confirm":
          return ConfirmSubPage;
        case "#complete":
          return CompleteSubPage;
        default:
          return InputSubPage;
      }
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.$store.dispatch("startLoading");
      try {
        const family = await this.$store.dispatch("family/fetchFamily");
        this.form = new FamilyForm(family);
        this.$router.replace({ hash: "#input" });
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.$store.dispatch("endLoading");
      }
    },
    onComplete() {
      this.$router.replace({ hash: "#complete" });
    }
  }
};
</script>
