import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { NotificationsState } from "./types";
import Announcement from "../../models/Announcement";
import Reminder from "../../models/Reminder";

export const actions: ActionTree<NotificationsState, RootState> = {
  async initNotifications(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<{
    announcements: Array<Announcement>;
    reminders: Array<Reminder>;
  }> {
    const notifications = await context.rootState.apiClient.initNotifications();
    context.commit("init", notifications);
    return notifications;
  },
  async fetchAnnouncement(
    context: {
      commit: Commit;
      rootState: RootState;
    },
    payload: { announcementId: number }
  ): Promise<Announcement> {
    return await context.rootState.apiClient.fetchAnnouncement(
      payload.announcementId
    );
  },
  async fetchReminder(
    context: { commit: Commit; rootState: RootState },
    payload: { reminderId: number }
  ): Promise<Reminder> {
    return await context.rootState.apiClient.fetchReminder(payload.reminderId);
  }
};
