import * as dayjs from "dayjs";

class OrderDetailPicture {
  id: number;
  year: number;
  fileNo: number;
  takenDatetime: Date | undefined;
  width: number;
  height: number;
  sizeType: number;
  printAspectRatio: number;
  printAreaRatio: number;
  thumbnailUrl: string;

  constructor(data: {
    id: number;
    year: number;
    fileNo: number;
    takenDatetime: string | null;
    width: number;
    height: number;
    sizeType: number;
    printAspectRatio: number;
    printAreaRatio: number;
    thumbnailUrl: string;
  }) {
    this.id = data.id;
    this.year = data.year;
    this.fileNo = data.fileNo;
    if (data.takenDatetime) {
      this.takenDatetime = dayjs(data.takenDatetime).toDate();
    }
    this.width = data.width;
    this.height = data.height;
    this.sizeType = data.sizeType;
    this.printAspectRatio = data.printAspectRatio;
    this.printAreaRatio = data.printAreaRatio;
    this.thumbnailUrl = data.thumbnailUrl;
  }

  get uniqueKey(): string {
    return `orderDetailPicture-${this.id}`;
  }

  get aspectRatio(): number {
    return this.width / this.height;
  }

  isTrimmingX(): boolean {
    return this.aspectRatio > this.printAspectRatio;
  }
}

export default OrderDetailPicture;
