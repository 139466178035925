<template>
  <div class="px-3 caption error--text">
    <p v-if="errorType === 'notFound'">
      登録用コードが正しくありません。<br />
      入力間違いが無いかご確認のうえ、再度お試しください。
    </p>
    <p v-else-if="errorType === 'used'">
      登録用コードが使用済みのため、登録できません。<br />
      園・施設へ、登録用コードの再発行をご依頼ください。
    </p>
    <p v-else-if="errorType === 'expired'">
      登録用コードの有効期限が切れています。<br />
      園・施設へ、登録用コードの再発行をご依頼ください。
    </p>
    <p v-else-if="errorType === 'alreadyBelongs'">
      選択したお子様は既にこの園に所属しています。
    </p>
    <p v-else-if="errorType === 'noGroups'">
      クラスが存在しないため、登録できません。<br />
      園・施設へ、クラスの作成をご依頼ください。
    </p>
  </div>
</template>

<script>
export default {
  props: {
    errorType: {
      type: String,
      default: null
    }
  }
};
</script>
