import { MAIL_ADDRESS_PATTERN } from "../data/constants";

class PasswordResetNewForm {
  email?: string;

  emailRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "メールアドレスを入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "メールアドレスは255文字以内で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(MAIL_ADDRESS_PATTERN)) ||
        "メールアドレスの書式が正しくありません。"
    ];
  }

  toParams(): { email: string } {
    return {
      email: this.email || ""
    };
  }
}

export default PasswordResetNewForm;
