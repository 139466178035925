<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />保護者情報の登録
      </h1>

      <v-card>
        <v-card-text>
          <p class="error--text">
            URLの有効期限が切れている、もしくは不正なURLです。<br /><br />
            お手数ですが、招待いただいた方へ、招待メールの再送をご依頼ください。
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
