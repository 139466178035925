<template>
  <v-dialog v-model="dialog" max-width="290" persistent>
    <v-card>
      <div class="pt-2">
        <h2 class="title-page">
          <v-icon class="material-icons-outlined">face</v-icon>
          <br />{{ label }}
        </h2>
      </div>
      <div class="face-loading pb-4" :class="{ 'is-anime': dialog }">
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_l.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_o.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_o.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_k.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_f.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_o.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_r.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_a.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_f.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_a.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_c.png"
            alt=""
          />
        </div>
        <div class="face-loading-item">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_e.png"
            alt=""
          />
        </div>
        <div class="face-loading-frame">
          <img
            src="../../../../common/assets/images/mimamori/mimamori_frame.png"
            alt=""
          />
        </div>
      </div>
      <p class="text-xs-center caption pt-2 pb-4 mb-0">
        そのままお待ちください…
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
