import { PASSWORD_PATTERN } from "../data/constants";

class PasswordChangeForm {
  currentPassword?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;

  currentPasswordRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "現在のパスワードを入力してください。"
    ];
  }

  newPasswordRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "新しいパスワードを入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) ||
        "新しいパスワードは8文字以上で入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "新しいパスワードは255文字以下で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "新しいパスワードは半角英数字記号で入力してください。"
    ];
  }

  newPasswordConfirmationRules(): Array<Function> {
    return [
      (v?: string) =>
        (!!v && v.length > 0) || "新しいパスワード（確認）を入力してください。",
      (v?: string) =>
        (!!v && v.length >= 8) ||
        "新しいパスワード（確認）は8文字以上で入力してください。",
      (v?: string) =>
        (!!v && v.length <= 255) ||
        "新しいパスワード（確認）は255文字以下で入力してください。",
      (v?: string) =>
        (!!v && !!v.match(PASSWORD_PATTERN)) ||
        "新しいパスワード（確認）は半角英数字記号で入力してください。",
      (v?: string) =>
        (!!v && v === this.newPassword) ||
        "新しいパスワードと新しいパスワード（確認）が一致しません。"
    ];
  }

  toParams(): { currentPassword: string; newPassword: string } {
    return {
      currentPassword: this.currentPassword || "",
      newPassword: this.newPassword || ""
    };
  }
}

export default PasswordChangeForm;
