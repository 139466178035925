import Picture from "./Picture";
import Video from "./Video";

class SalesItem {
  eventId: number;
  eventType: string;
  pictureId: number | null;
  videoId: number | null;
  picture: Picture | null;
  video: Video | null;

  constructor(data: {
    eventId: number;
    eventType: string;
    picture: any;
    video: any;
  }) {
    this.eventId = data.eventId;
    this.eventType = data.eventType;
    this.pictureId = data.picture && data.picture.id;
    this.videoId = data.video && data.video.id;
    this.picture = data.picture ? new Picture(data.picture) : null;
    this.video = data.video ? new Video(data.video) : null;
  }

  get id(): number | null {
    return this.object && this.object.id;
  }

  get year(): number | null {
    return this.object && this.object.year;
  }

  get fileNo(): number | null {
    return this.object && this.object.fileNo;
  }

  get object(): Picture | Video | null {
    if (this.isPicture()) {
      return this.picture;
    } else if (this.isVideo()) {
      return this.video;
    }
    return null;
  }

  get uniqueKey(): string {
    return `${this.eventType}-${this.object && this.object.id}`;
  }

  isPicture(): boolean {
    return !!this.pictureId;
  }

  isVideo(): boolean {
    return !!this.videoId;
  }
}

export default SalesItem;
