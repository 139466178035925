<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md4 md4>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">vpn_key</v-icon>
        <br />パスワードの再設定
      </h1>

      <v-card v-if="form">
        <v-card-text>
          <p>
            新しいパスワードを入力して「再設定する」ボタンを押してください。
          </p>

          <v-form ref="form" lazy-validation @submit.prevent="submitForm">
            <section>
              <h2 class="body-1">新しいパスワード</h2>
              <p class="caption error--text mb-1">
                ※8桁以上の半角英数字で入力してください。
              </p>
              <v-text-field
                v-model="password"
                type="password"
                single-line
                outline
                validate-on-blur
                required
                :rules="form.passwordRules()"
              ></v-text-field>
            </section>

            <section>
              <h2 class="body-1">新しいパスワード（確認）</h2>
              <v-text-field
                v-model="passwordConfirmation"
                type="password"
                single-line
                outline
                validate-on-blur
                required
                :rules="form.passwordConfirmationRules()"
              ></v-text-field>
            </section>

            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-btn
              block
              type="submit"
              color="primary"
              :loading="submitting"
              :disabled="submitting"
            >
              再設定する
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import { API_ERROR_CODE_VALIDATION_PASSWORD_RESET_TOKEN_NOT_FOUND } from "../../lib/lookmee_photo/ApiErrorCodes";

import PasswordResetContinueForm from "../../forms/PasswordResetContinueForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: null,
      submitting: false,
      errors: []
    };
  },
  computed: {
    password: {
      get() {
        return this.form && this.form.password;
      },
      set(val) {
        if (this.form) {
          this.form.password = val;
        }
      }
    },
    passwordConfirmation: {
      get() {
        return this.form && this.form.passwordConfirmation;
      },
      set(val) {
        if (this.form) {
          this.form.passwordConfirmation = val;
        }
      }
    }
  },
  created() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      try {
        await this.$store.dispatch("verifyPasswordReset", {
          token: this.token
        });

        this.form = new PasswordResetContinueForm({ token: this.token });
      } catch (errors) {
        if (
          errors.status === 400 &&
          errors.getErrorCode() ===
            API_ERROR_CODE_VALIDATION_PASSWORD_RESET_TOKEN_NOT_FOUND
        ) {
          this.$router.replace({
            name: "newPasswordReset",
            query: { invalidUrlError: true }
          });
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      }
    },
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitting = true;
      try {
        await this.$store.dispatch("doPasswordReset", this.form.toParams());

        this.$router.push({ name: "completedPasswordReset" });
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
