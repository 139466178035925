<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon
        ><br />お問い合わせ
      </h1>
      <v-card>
        <v-card-text>
          <p class="text-xs-center mb-4">
            <img src="../../../assets/images/illust_ok.png" width="280" />
          </p>
          <p class="text-xs-center title">送信が完了しました。</p>
          <p class="text-xs-center">
            4営業日以内にルクミーカスタマーサポートより返信いたします。
          </p>
          <p class="mt-4 text-xs-left text-lg-center caption">
            ※
            繁忙期（3月中旬～5月上旬）は最大7営業日かかる場合がございます。ご了承ください。<br />
            ※
            カスタマーサポートは年中無休で対応しておりますが、ここでの「営業日」は平日のみとなります。<br />
            ※ info@lookme-e.com
            からのメールを受信できるよう、予め受信設定をご確認ください。
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
