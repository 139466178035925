export const CART_ITEM_MIN_LIMIT = 1;
export const CART_ITEM_MAX_LIMIT = 99;

export class PictureOrder {
  id: number;
  count: number;

  constructor(data: { id: number; count: number }) {
    this.id = data.id;
    this.count = data.count;
  }
}

export class VideoOrder {
  id: number;
  count: number;

  constructor(data: { id: number; count: number }) {
    this.id = data.id;
    this.count = data.count;
  }
}

export class PhotoBook {
  mediaType: number;
  orderAmount: number;
  pictureIds: Array<number>;

  constructor(data: {
    mediaType: number;
    orderAmount: number;
    pictureIds: Array<number>;
  }) {
    this.mediaType = data.mediaType;
    this.orderAmount = data.orderAmount;
    this.pictureIds = data.pictureIds || [];
  }
}

class Cart {
  userId: number;
  familyId: number;
  organizationId: number;
  salesManagementId: number;
  pictureOrders: Array<PictureOrder>;
  videoOrders: Array<VideoOrder>;
  photoBook: PhotoBook;

  constructor(data: {
    userId: number;
    familyId: number;
    organizationId: number;
    salesManagementId: number;
    pictures: Array<any>;
    videos: Array<any>;
    photoBook: any;
  }) {
    this.userId = data.userId;
    this.familyId = data.familyId;
    this.organizationId = data.organizationId;
    this.salesManagementId = data.salesManagementId;
    this.pictureOrders = data.pictures
      ? data.pictures.map(p => new PictureOrder(p))
      : [];
    this.videoOrders = data.videos
      ? data.videos.map(v => new VideoOrder(v))
      : [];
    this.photoBook = new PhotoBook(data.photoBook || {});
  }

  itemCount(): number {
    if (this.photoBook.mediaType) {
      return 1;
    } else {
      return this.pictureOrders.length + this.videoOrders.length;
    }
  }

  pictureAndVideoCount(): number {
    return this.pictureOrders.length + this.videoOrders.length;
  }

  isEmpty(): boolean {
    return this.itemCount() < 1;
  }

  pictureIds(): Set<number> {
    return new Set(this.pictureOrders.map(o => o.id));
  }

  videoIds(): Set<number> {
    return new Set(this.videoOrders.map(o => o.id));
  }

  pictureCountMap(): Map<number, number> {
    const result: Map<number, number> = new Map();
    this.pictureOrders.forEach(o => result.set(o.id, o.count));
    return result;
  }

  videoCountMap(): Map<number, number> {
    const result: Map<number, number> = new Map();
    this.videoOrders.forEach(o => result.set(o.id, o.count));
    return result;
  }
}

export default Cart;
