import { ActionTree } from "vuex";
import { RootState } from "../types";
import { FamilyInvitationState } from "./types";
import Family from "../../models/Family";
import FamilyInvitation from "packs/site/models/FamilyInvitation";

export const actions: ActionTree<FamilyInvitationState, RootState> = {
  async sendFamilyInvitation(
    context: { rootState: RootState },
    payload: { email: string }
  ): Promise<void> {
    await context.rootState.apiClient.sendFamilyInvitation(payload);
  },
  async verifyInvitationToken(
    context: { rootState: RootState },
    payload: { token: string }
  ): Promise<{
    family: Family;
    familyInvitation: FamilyInvitation;
  }> {
    return await context.rootState.apiClient.verifyInvitationToken(
      payload.token
    );
  },

  async createInvitedUser(
    context: { rootState: RootState },
    payload: {
      familyId: number;
      token: string;
      email: string;
      password: string;
      passwordConfirmation: string;
      termsAgreed: boolean;
      privacyPolicyAgreed: boolean;
    }
  ): Promise<void> {
    await context.rootState.apiClient.createInvitedUser(payload);
  },

  async createFamiliesUser(
    context: { rootState: RootState },
    payload: {
      email: string;
      password: string;
      familyId: number;
      token: string;
    }
  ): Promise<void> {
    await context.rootState.apiClient.createFamiliesUser(payload);
  }
};
