<template>
  <v-layout>
    <v-flex xs12 offset-md2 md8>
      <v-card elevation-1>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 text-xs-center>
              <img src="../../assets/images/error_404.png" class="image" />
            </v-flex>

            <v-flex xs12 offset-md2 md8>
              <p>
                ご指定のページが見つかりませんでした。<br />
                下記エラーの原因をご確認ください。
              </p>

              <ul>
                <li>
                  URLが途中で途切れていたり、入力ミスをしていないか、ご確認ください。
                </li>
                <li>正しいユーザーでログインしているか、ご確認ください。</li>
                <li>
                  園・教室内のページにアクセスしている場合、対象の園・教室に所属されているか、ご確認ください。
                </li>
                <li>
                  画面に表示されている情報が古い可能性があるため、最新の画面を開き、アクセスしてください。
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>

<style scoped>
.image {
  max-width: 100%;
}
</style>
