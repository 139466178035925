<template>
  <div>
    <v-list-tile
      :to="{
        name: 'showNotification',
        params: {
          notificationType: 'announcement',
          notificationId: announcement.id
        }
      }"
    >
      <v-list-tile-content>
        <v-list-tile-sub-title>
          {{ announcement.displayStartDate }}
          <v-chip
            v-if="announcement.isNew"
            label
            color="red"
            text-color="white"
            class="text-xs-center"
            >NEW
          </v-chip>
        </v-list-tile-sub-title>
        <v-list-tile-title>
          {{ announcement.title }}
        </v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <i class="material-icons">chevron_right</i>
      </v-list-tile-action>
    </v-list-tile>
  </div>
</template>

<script>
import Announcement from "../../../models/Announcement";

export default {
  props: {
    announcement: {
      type: Announcement,
      required: true
    }
  }
};
</script>
