import { MutationTree } from "vuex";
import { CartState } from "./types";
import Cart from "../../models/Cart";

export const mutations: MutationTree<CartState> = {
  init(state: CartState, data: { cart?: Cart }): any {
    state.cart = data.cart;
  },
  setCart(state: CartState, cart: Cart) {
    state.cart = cart;
  },
  clearCart(state: CartState) {
    state.cart = null;
  }
};
