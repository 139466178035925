import { MutationTree } from "vuex";
import { FamilyState } from "./types";
import Family from "../../models/Family";

const setProps = function(state: FamilyState, family: Family): any {
  state.id = family.id;
  state.name = family.name;
  state.kids = family.kids;
  state.organizations = family.organizations;
  state.family = family;
};

export const mutations: MutationTree<FamilyState> = {
  init(state: FamilyState, data: { family?: Family }): any {
    if (data.family) {
      setProps(state, data.family);
    }
  },
  setFamily(state: FamilyState, family: Family): any {
    setProps(state, family);
  }
};
