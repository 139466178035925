<template>
  <v-layout row wrap>
    <v-flex xs12 offset-md2 md8>
      <remove-all-photo-book-pictures-dialog
        :dialog="showRemoveAllDialog"
        :remove-all-photo-book-pictures="removeAllPhotoBookPictures"
        @close="showRemoveAllDialog = false"
      ></remove-all-photo-book-pictures-dialog>

      <change-photo-book-type-dialog
        :dialog="showChangeTypeDialog"
        :sales-management="salesManagement"
        :media-type="cartPhotoBookMediaType"
        :change-photo-book-type="changePhotoBookType"
        @close="showChangeTypeDialog = false"
      >
      </change-photo-book-type-dialog>

      <too-many-photo-book-pictures-error-dialog
        :dialog="showTooManyPicturesDialog"
        :max-pictures-limit="salesManagementPrice.photoBookMaxPictureCount"
        @close="showTooManyPicturesDialog = false"
      ></too-many-photo-book-pictures-error-dialog>

      <too-few-photo-book-pictures-warn-dialog
        :dialog="showTooFewPicturesDialog"
        @ok="goCartPage"
        @cancel="showTooFewPicturesDialog = false"
      >
      </too-few-photo-book-pictures-warn-dialog>

      <v-dialog
        v-if="galleryDialog"
        v-model="galleryDialog"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '80%'"
        content-class="picture-gallery-dialog mx-1"
      >
        <picture-gallery
          :pictures="salesItemPictures"
          :initial-index="galleryIndex"
          :purchased-picture-ids="purchasedPictureIds"
          :offset-count="currentOffsetPictureCount"
          :all-count="allPictureCount"
          @move="onMovePictureGallery"
          @close="closePictureGallery"
        >
          <div slot-scope="{ targetPicture }">
            <v-divider class="my-2"></v-divider>

            <cart-photo-book-picture-button
              :picture-id="targetPicture.id"
              :is-low-resolution="targetPicture.isLowResolution()"
              :is-in-cart="cartPhotoBookPictureIds.has(targetPicture.id)"
              :small="false"
              :add-to-cart="addPhotoBookPictureToCart"
              :remove-from-cart="removePhotoBookPictureFromCart"
            ></cart-photo-book-picture-button>
          </div>
        </picture-gallery>
      </v-dialog>

      <template v-if="organization.id && salesManagement">
        <h1 class="title-page">
          <i class="fas fa-book"></i>
          <br />【フォトブック】{{ salesManagement.title }}
        </h1>

        <div class="px-3 mb-3">
          <p class="subheading mb-2">
            フォトブックに使う写真を選択してください。
          </p>
          <ul class="list-indent caption">
            <li>
              ※ フォトブックの種類によって選択できる写真枚数に上限があります。
            </li>
            <li>
              ※
              選択枚数が少なすぎる場合は、空白のページができてしまいますので、できるだけ多くの写真を選択してください。
            </li>
            <li>
              ※
              画素数720px未満の写真は、仕上がりが粗くなる可能性があるため選択できません。
            </li>
          </ul>
        </div>

        <v-layout>
          <v-flex xs12 offset-md1 md10 mx-3>
            <group-event-select-box
              :groups="groups"
              :group-id="currentGroupId"
              :events="selectableEvents"
              :event-id="eventId"
              @change-group="onChangeGroup"
              @change-event="onChangeEvent"
            ></group-event-select-box>

            <sales-descriptions></sales-descriptions>

            <v-divider id="salesItemsTop"></v-divider>

            <template v-if="reloadingSalesItems">
              <div class="my-5">
                <v-progress-circular
                  size="50"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
            <template v-else-if="salesItems">
              <template v-if="salesItemEvents.length < 1">
                <p>写真はありません。</p>
              </template>
              <template v-else>
                <sales-item-list
                  :kid-ids="familyKidIds"
                  :organization-id="organizationId"
                  :sales-management-id="salesManagementId"
                  :sales-type="salesManagement.salesType.value"
                  :events="events"
                  :sales-items="salesItems"
                  :purchased-picture-ids="purchasedPictureIds"
                  :cart-photo-book-picture-ids="cartPhotoBookPictureIds"
                  :add-photo-book-picture-to-cart="addPhotoBookPictureToCart"
                  :remove-photo-book-picture-from-cart="
                    removePhotoBookPictureFromCart
                  "
                  @click-picture="openPictureGallery($event)"
                ></sales-item-list>
              </template>
            </template>
          </v-flex>
        </v-layout>

        <div v-if="allPages > 0" class="text-xs-center py-2 my-3">
          <v-pagination
            :value="page"
            :length="allPages"
            circle
            @input="onChangePage"
          >
          </v-pagination>
        </div>

        <div class="text-xs-center pb-5">
          <v-btn
            large
            color="negative"
            block
            class="mb-5 navigation-button"
            :to="{
              name: 'organizationSelectPhotoBookType',
              params: { organizationId, salesManagementId }
            }"
          >
            戻る
          </v-btn>
        </div>

        <photo-book-bottom-sheet
          :current-pictures-count="cartPhotoBookPictureIds.size"
          :max-pictures-count="salesManagementPrice.photoBookMaxPictureCount"
          @on-click-remove-all-link="showRemoveAllDialog = true"
          @on-click-change-size-link="showChangeTypeDialog = true"
          @on-click-go-cart-page-button="onClickGoCartPageButton"
        ></photo-book-bottom-sheet>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import ApiErrorHandler from "../../../../mixins/ApiErrorHandler";

import Event from "../../../../models/Event";
import SalesManagement from "../../../../models/SalesManagement";

import PictureGallery from "../../../../components/PictureGallery";

import CartPhotoBookPictureButton from "../components/CartPhotoBookPictureButton";
import RemoveAllPhotoBookPicturesDialog from "../components/RemoveAllPhotoBookPicturesDialog";
import ChangePhotoBookTypeDialog from "../components/ChangePhotoBookTypeDialog";
import TooManyPhotoBookPicturesErrorDialog from "../components/TooManyPhotoBookPicturesErrorDialog";
import TooFewPhotoBookPicturesWarnDialog from "../components/TooFewPhotoBookPicturesWarnDialog";
import GroupEventSelectBox from "../components/GroupEventSelectBox";
import SalesDescriptions from "../components/SalesDescriptions";
import SalesItemList from "../components/SalesItemList";
import PhotoBookBottomSheet from "../components/PhotoBookBottomSheet";

export default {
  components: {
    "picture-gallery": PictureGallery,
    "cart-photo-book-picture-button": CartPhotoBookPictureButton,
    "remove-all-photo-book-pictures-dialog": RemoveAllPhotoBookPicturesDialog,
    "change-photo-book-type-dialog": ChangePhotoBookTypeDialog,
    "too-many-photo-book-pictures-error-dialog": TooManyPhotoBookPicturesErrorDialog,
    "too-few-photo-book-pictures-warn-dialog": TooFewPhotoBookPicturesWarnDialog,
    "group-event-select-box": GroupEventSelectBox,
    "sales-descriptions": SalesDescriptions,
    "sales-item-list": SalesItemList,
    "photo-book-bottom-sheet": PhotoBookBottomSheet
  },
  mixins: [ApiErrorHandler],
  props: {
    organizationId: {
      type: Number,
      required: true
    },
    salesManagementId: {
      type: Number,
      required: true
    },
    groupId: {
      type: Number,
      default: undefined
    },
    eventId: {
      type: [Number, String],
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    },
    salesManagement: {
      type: SalesManagement,
      required: true
    },
    groups: {
      type: Array, // Array<Group>
      required: true
    },
    purchasedPictureIds: {
      type: Set, // Set<number>
      required: true
    }
  },
  data() {
    return {
      showRemoveAllDialog: false,
      showChangeTypeDialog: false,
      showTooManyPicturesDialog: false,
      showTooFewPicturesDialog: false,
      reloadingSalesItems: false,
      galleryDialog: false,
      galleryIndex: 0,
      events: [],
      allPictureCount: 0,
      allPages: 0,
      perPage: 0,
      salesItems: null
    };
  },
  computed: {
    ...mapState({
      apiClient: "apiClient",
      organization: "organization"
    }),
    ...mapGetters({
      familyKidIds: "family/kidIds",
      cartPhotoBookMediaType: "cart/photoBookMediaType",
      cartPhotoBookPictureIds: "cart/photoBookPictureIds"
    }),
    salesManagementPrice() {
      return this.salesManagement.prices.find(
        p => p.mediaType.value === this.cartPhotoBookMediaType
      );
    },
    defaultGroup() {
      return this.groups[0];
    },
    currentGroupId() {
      return (
        this.groupId || (this.defaultGroup ? this.defaultGroup.id : undefined)
      );
    },
    selectableEvents() {
      let selectableEvents = [];

      // In case of photo book sales, there are no video events.

      // Summarize today events and autoclip events to one event.
      const todayOrAutoclipEvents = this.events.filter(
        e => e.isTodayOrAutoclip
      );
      if (todayOrAutoclipEvents.length > 0) {
        const e = todayOrAutoclipEvents[0];
        selectableEvents.push(
          new Event({
            ...e,
            id: "today_or_autoclip",
            eventName: "日常写真",
            picturesCount: todayOrAutoclipEvents.reduce(
              (sum, e) => sum + e.picturesCount,
              0
            ),
            videosCount: 0
          })
        );
      }

      selectableEvents = selectableEvents.concat(
        this.events.filter(e => !e.isVideo && !e.isTodayOrAutoclip)
      );
      return selectableEvents.filter(e => e.itemCount > 0);
    },
    currentOffsetPictureCount() {
      return Math.max(this.perPage * (this.page - 1), 0);
    },
    salesItemEvents() {
      const eventIdSet = new Set(this.salesItems.map(s_i => s_i.eventId));
      return this.events.filter(e => eventIdSet.has(e.id));
    },
    eventPicturesMap() {
      const map = new Map();
      this.salesItems
        .filter(s_i => s_i.isPicture())
        .forEach(s_i => {
          const pictures = map.get(s_i.eventId) || [];
          pictures.push(s_i.picture);
          map.set(s_i.eventId, pictures);
        });
      return map;
    },
    salesItemPictures() {
      return this.salesItems.filter(s => s.isPicture()).map(s => s.picture);
    }
  },
  watch: {
    groupId: function() {
      this.reloadEvents();
    },
    $route: function() {
      this.reloadSalesItems();
    }
  },
  async created() {
    this.reloadEvents();
    this.reloadSalesItems();
  },
  methods: {
    async reloadEvents() {
      if (!this.currentGroupId) {
        return;
      }

      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;
      try {
        this.events = await this.apiClient.getEvents(
          organizationId,
          salesManagementId,
          { groupId: this.currentGroupId }
        );
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    },
    async reloadSalesItems() {
      if (!this.currentGroupId) {
        return;
      }

      const organizationId = this.organizationId;
      const salesManagementId = this.salesManagementId;

      this.salesItems = [];
      this.allPages = 0;
      this.reloadingSalesItems = true;
      try {
        const { salesItems, pagination } = await this.apiClient.getSalesItems(
          organizationId,
          salesManagementId,
          {
            groupId: this.currentGroupId,
            eventId: this.eventId,
            page: this.page
          }
        );
        this.salesItems = salesItems;
        // TODO: paginationはオブジェクトそのままdataに使いたい。dataが多い。
        this.allPictureCount = pagination.allPictureCount;
        this.allPages = pagination.allPages;
        this.perPage = pagination.perPage;
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      } finally {
        this.reloadingSalesItems = false;
      }
    },
    onChangeGroup(groupId) {
      if (groupId === this.currentGroupId) {
        return;
      }

      this.$router.replace({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: groupId
        }
      });
    },
    onChangeEvent(eventId) {
      if (eventId === this.eventId) {
        return;
      }

      this.$router.replace({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: this.groupId,
          eventId: eventId
        }
      });
    },
    onChangePage(page) {
      if (page === this.page) {
        return;
      }

      this.$vuetify.goTo("#salesItemsTop", { offset: -75 });
      this.$router.push({
        name: "organizationMain",
        params: this.$route.params,
        query: {
          groupId: this.groupId,
          eventId: this.eventId,
          page: page
        }
      });
    },
    openPictureGallery(picture) {
      this.galleryIndex = this.salesItemPictures.findIndex(p => p === picture);
      this.galleryDialog = true;
    },
    onMovePictureGallery({ picture }) {
      this.$vuetify.goTo(`#${picture.uniqueKey}`, {
        duration: 20,
        offset: -100
      });
    },
    closePictureGallery() {
      this.galleryIndex = null;
      this.galleryDialog = false;
    },
    async addPhotoBookPictureToCart(pictureId) {
      try {
        await this.$store.dispatch("cart/addPhotoBookPictureToCart", {
          pictureId
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      }
    },
    async removePhotoBookPictureFromCart(pictureId) {
      try {
        await this.$store.dispatch("cart/removePhotoBookPictureFromCart", {
          pictureId
        });
      } catch (errors) {
        if (this.isGlobalApiErrors(errors)) {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        } else {
          this.$store.dispatch("openErrorDialog", {
            messages: errors.getErrorMessages()
          });
        }
      }
    },
    async removeAllPhotoBookPictures() {
      try {
        await this.$store.dispatch("cart/removeAllPhotoBookPicturesFromCart");
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    },
    async changePhotoBookType(mediaType) {
      try {
        await this.$store.dispatch("cart/updateCartPhotoBook", { mediaType });
      } catch (errors) {
        this.handleApiErrors(errors, {
          store: this.$store,
          router: this.$router,
          sentry: this.sentry
        });
      }
    },
    onClickGoCartPageButton() {
      if (
        this.cartPhotoBookPictureIds.size >
        this.salesManagementPrice.photoBookMaxPictureCount
      ) {
        this.showTooManyPicturesDialog = true;
        return;
      }
      if (
        this.cartPhotoBookPictureIds.size <
        this.salesManagementPrice.photoBookWarnPictureCount
      ) {
        this.showTooFewPicturesDialog = true;
        return;
      }

      this.goCartPage();
    },
    goCartPage() {
      this.$router.push({
        name: "organizationCart",
        params: {
          organizationId: this.organizationId,
          salesManagementId: this.salesManagementId
        }
      });
    }
  }
};
</script>

<style>
.picture-gallery-dialog {
  max-height: 98% !important;
}
</style>

<style scoped>
h2 {
  font-size: 16pt;
  font-weight: normal;
  border-bottom: none;
  border-left: solid 3px #dbe171;
}

p.event-comment {
  font-size: 11pt;
  color: #939393;
  border-bottom: 1px solid #dedede;
}

p.cart-picture-button-description {
  font-size: 10px;
  color: #666666;
}
</style>
