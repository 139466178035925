<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />退会
      </h1>

      <v-card>
        <v-card-text>
          <p class="text-xs-center">
            <img src="../../assets/images/illust_stop.png" alt />
          </p>

          <h2 class="text-xs-center error--text mb-3">
            退会をご検討の保護者様へ
          </h2>

          <p>
            お子さまの在園中に退会してしまうと、今後販売される写真の購入ができなくなります。
          </p>
          <p>
            ルクミーサービスについてご不明点がある場合は、以下の「よくあるご質問」をお読みください。
          </p>
          <v-layout justify-center row class="mb-2">
            <v-btn
              href="https://lookmee.tayori.com/faq/6dee8ffd1c5ae520a999d257ebf2fc8d2b2f835d"
              target="_blank"
              class="v-btn--minwidth"
              color="primary"
            >
              よくあるご質問
              <v-icon class="ml-1">open_in_new</v-icon>
            </v-btn>
          </v-layout>

          <p>それでも解決しない場合は、以下よりお問合せください。</p>
          <v-layout justify-center row class="mb-3">
            <v-btn
              class="v-btn--minwidth"
              color="primary"
              :to="{ name: 'newInquiry' }"
            >
              お問い合わせ
            </v-btn>
          </v-layout>

          <h3 class="mb-2">お子さまの退園・卒園後の退会について</h3>

          <div class="v-sheet mb-3">
            <h4 class="text-xs-center error--text mb-1">
              ＜ご確認ください！＞
            </h4>
            <ul>
              <li class="pt-1 pb-1">
                <span class="font-weight-bold"
                  >購入し忘れている写真はありませんか？</span
                ><br />
                退会後は、新たに写真を購入することはできません。卒園後に販売開始されるイベントもありますので、ご注意ください。
              </li>
              <li class="pt-1 pb-1">
                <span class="font-weight-bold"
                  >購入後にお手元に届いていない写真、ダウンロードし忘れている写真はありませんか？</span
                ><br />
                退会後は、注文履歴の閲覧ができません。退会前に、今一度ご確認ください。
              </li>
            </ul>
            <div class="text-xs-center mt-2 mb-2">
              <router-link :to="{ name: 'listOrderHistories' }">
                注文履歴はこちら
              </router-link>
            </div>
            <ul>
              <li class="pt-1">
                転園による退会をご検討の場合は、新しい園に入園されるまで退会をお待ちください。転園先の施設がルクミーをご使用の場合は、同じアカウントを引き続きご使用いただけます。
              </li>
            </ul>
          </div>

          <p>上記に該当しない場合は、退会にお進みください。</p>

          <v-layout justify-center row>
            <v-btn
              href="/site/withdrawals/new"
              target="_blank"
              class="v-btn--minwidth"
              color="primary"
            >
              上記内容を理解し、退会する
              <v-icon class="ml-1">open_in_new</v-icon>
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
