// TODO: Duplicated app/errors/site/api/error_code.rb

// BadRequest(400) errors
export const API_ERROR_CODE_VALIDATION_ACCEPTED = "400_0000";
export const API_ERROR_CODE_VALIDATION_BLANK = "400_0001";
export const API_ERROR_CODE_VALIDATION_PRESENT = "400_0002";
export const API_ERROR_CODE_VALIDATION_CONFIRMATION = "400_0003";
export const API_ERROR_CODE_VALIDATION_EMPTY = "400_0004";
export const API_ERROR_CODE_VALIDATION_EQUAL_TO = "400_0005";
export const API_ERROR_CODE_VALIDATION_EVEN = "400_0006";
export const API_ERROR_CODE_VALIDATION_EXCLUSION = "400_0007";
export const API_ERROR_CODE_VALIDATION_GREATER_THAN = "400_0008";
export const API_ERROR_CODE_VALIDATION_GREATER_THAN_OR_EQUAL_TO = "400_0009";
export const API_ERROR_CODE_VALIDATION_INCLUSION = "400_0010";
export const API_ERROR_CODE_VALIDATION_INVALID = "400_0011";
export const API_ERROR_CODE_VALIDATION_LESS_THAN = "400_0012";
export const API_ERROR_CODE_VALIDATION_LESS_THAN_OR_EQUAL_TO = "400_0013";
export const API_ERROR_CODE_VALIDATION_MODEL_INVALID = "400_0014";
export const API_ERROR_CODE_VALIDATION_NOT_A_NUMBER = "400_0015";
export const API_ERROR_CODE_VALIDATION_NOT_AN_INTEGER = "400_0016";
export const API_ERROR_CODE_VALIDATION_ODD = "400_0017";
export const API_ERROR_CODE_VALIDATION_REQUIRED = "400_0018";
export const API_ERROR_CODE_VALIDATION_TAKEN = "400_0019";
export const API_ERROR_CODE_VALIDATION_TOO_SHORT = "400_0020";
export const API_ERROR_CODE_VALIDATION_TOO_LONG = "400_0021";
export const API_ERROR_CODE_VALIDATION_WRONG_LENGTH = "400_0022";
export const API_ERROR_CODE_VALIDATION_OTHER_THAN = "400_0023";
export const API_ERROR_CODE_VALIDATION_RESTRICT_DEPENDENT_DESTROY_HAS_ONE =
  "400_0100";
export const API_ERROR_CODE_VALIDATION_RESTRICT_DEPENDENT_DESTROY_HAS_MANY =
  "400_0101";
export const API_ERROR_CODE_VALIDATION_NOT_SHIFT_JIS_STRING = "400_0200";
export const API_ERROR_CODE_VALIDATION_UNABLE_TO_SET_DEFAULT_ADDRESS =
  "400_0201";
export const API_ERROR_CODE_VALIDATION_LOGIN_AUTHENTICATION_FAILED = "400_1000";
export const API_ERROR_CODE_VALIDATION_ISSUED_CODE_NOT_FOUND = "400_1100";
export const API_ERROR_CODE_VALIDATION_ISSUED_CODE_USED = "400_1101";
export const API_ERROR_CODE_VALIDATION_ISSUED_CODE_EXPIRED = "400_1102";
export const API_ERROR_CODE_VALIDATION_KID_ALREADY_BELONGS = "400_1103";
export const API_ERROR_CODE_VALIDATION_PASSWORD_RESET_EMAIL_NOT_FOUND =
  "400_1110";
export const API_ERROR_CODE_VALIDATION_PASSWORD_RESET_TOKEN_NOT_FOUND =
  "400_1111";
export const API_ERROR_CODE_VALIDATION_MATCHING_ERROR_KID_NOT_FOUND =
  "400_1120";
export const API_ERROR_CODE_VALIDATION_MATCHING_ERROR_MULTIPLE_KIDS =
  "400_1121";

export const API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_NOT_FOUND = "400_1130";
export const API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_USED = "400_1131";
export const API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_EXPIRED = "400_1132";

export const API_ERROR_CODE_VALIDATION_SALES_FINISHED = "400_2000";
export const API_ERROR_CODE_VALIDATION_ORDER_NOT_SELECTABLE = "400_2002";
export const API_ERROR_CODE_VALIDATION_ORDER_INVALID_COUNT = "400_2003";
export const API_ERROR_CODE_VALIDATION_ORDER_NO_PRICE = "400_2004";
export const API_ERROR_CODE_VALIDATION_PHOTO_BOOK_TOO_MANY_PHOTOS = "400_2005";
export const API_ERROR_CODE_VALIDATION_FR_KID_TOO_MANY_FACES = "400_3000";
export const API_ERROR_CODE_VALIDATION_CURRENT_PASSWORD_NOT_MATCHED =
  "400_3000";
export const API_ERROR_CODE_VALIDATION_INVITATION_TOKEN_FAMILY_NOT_MATCH =
  "400_3003";
export const API_ERROR_CODE_VALIDATION_FR_KID_BELONGS_NO_GROUPS = "400_3004";

export const API_ERROR_CODE_VALIDATION_OTHERS = "400_9999";

// Unauthenticated(401) error
export const API_ERROR_CODE_UNAUTHENTICATED = "401_0000";
export const API_ERROR_CODE_FAMILY_NOT_SELECTED = "401_0001";

// Forbidden(403) error
export const API_ERROR_CODE_FORBIDDEN = "403_0000";

// Not found(404) error
export const API_ERROR_CODE_NO_ROUTE_MATCH_OR_NOT_FOUND = "404_0000";
export const API_ERROR_CODE_NOT_FOUND_FOR_SELECTED_FAMILY = "404_0001";

// Server(5xx) error
export const API_ERROR_CODE_UNEXPECTED_ERROR = "500_0000";
export const API_ERROR_CODE_DEFAULT_SERVER_ERROR_CODE = "500_9999";

export const API_ERROR_CODE_PAYMENT_SERVICE_MAINTENANCE = "503_0000";

// Client error
export const API_ERROR_CODE_DEFAULT_CLIENT_ERROR_CODE = "000_9999";
