<template>
  <div class="text-xs-center">
    <v-dialog v-model="showDialog" persistent width="400">
      <v-card>
        <v-card-text class="text-xs-center">
          <p>
            有効期限切れのため、再ログインが必要です。<br />
            <span class="caption"
              >※カートに入っている商品は削除されません。</span
            >
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-xs-center">
          <v-btn color="primary" href="/">
            ログイン画面へ
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
