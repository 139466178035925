import { MutationTree } from "vuex";
import { NotificationsState } from "./types";
import Announcement from "../../models/Announcement";
import Reminder from "../../models/Reminder";

const setProps = function(
  state: NotificationsState,
  notifications: {
    announcements: Array<Announcement>;
    reminders: Array<Reminder>;
  }
) {
  state.announcements = notifications.announcements;
  state.reminders = notifications.reminders;
};

export const mutations: MutationTree<NotificationsState> = {
  init(
    state: NotificationsState,
    notifications: {
      announcements: Array<Announcement>;
      reminders: Array<Reminder>;
    }
  ) {
    setProps(state, notifications);
  }
};
