import {
  DATA_PICTURE_MEDIA_TYPES,
  MEDIA_TYPES,
  PHOTO_BOOK_MEDIA_TYPES,
  PRINT_PICTURE_MEDIA_TYPES,
  PRO_PICTURE_MEDIA_TYPES,
  TEACHER_PICTURE_MEDIA_TYPES,
  VIDEO_MEDIA_TYPES
} from "../data/constants";

class StoreEstimateSummary {
  label: string;
  mediaType: number;
  unitPrice: number | null;
  quantity: number | null;
  total: number;

  constructor(data: {
    label: string;
    mediaType: number;
    unitPrice: number | null;
    quantity: number | null;
    total: number;
  }) {
    this.label = data.label;
    this.mediaType = data.mediaType;
    this.unitPrice = data.unitPrice;
    this.quantity = data.quantity;
    this.total = data.total;
  }
}

class StoreEstimate {
  summaries: Array<StoreEstimateSummary>;
  notices: Array<string>;
  sumTotal: number;

  constructor(data: {
    summaries: Array<any>;
    notices: Array<string>;
    sumTotal: number;
  }) {
    this.summaries = data.summaries.map(s => new StoreEstimateSummary(s));
    this.notices = data.notices;
    this.sumTotal = data.sumTotal;
  }

  // Teacher pictures

  teacherPictureSummaries(): StoreEstimateSummary[] {
    return this.summaries.filter(s =>
      TEACHER_PICTURE_MEDIA_TYPES.includes(s.mediaType)
    );
  }
  teacherPicturePriceTotal(): number {
    return this.teacherPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + s.total,
      0
    );
  }
  teacherPictureQuantity(): number {
    return this.teacherPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + (s.quantity || 0),
      0
    );
  }

  // Pro pictures

  proPictureSummaries(): StoreEstimateSummary[] {
    return this.summaries.filter(s =>
      PRO_PICTURE_MEDIA_TYPES.includes(s.mediaType)
    );
  }
  proPicturePriceTotal(): number {
    return this.proPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + s.total,
      0
    );
  }
  proPictureQuantity(): number {
    return this.proPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + (s.quantity || 0),
      0
    );
  }

  // Pictures print

  printPictureSummaries(): StoreEstimateSummary[] {
    return this.summaries.filter(s =>
      PRINT_PICTURE_MEDIA_TYPES.includes(s.mediaType)
    );
  }
  printPicturePriceTotal(): number {
    return this.printPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + s.total,
      0
    );
  }
  printPictureQuantity(): number {
    return this.printPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + (s.quantity || 0),
      0
    );
  }

  // Pictures - data

  dataPictureSummaries(): StoreEstimateSummary[] {
    return this.summaries.filter(s =>
      DATA_PICTURE_MEDIA_TYPES.includes(s.mediaType)
    );
  }
  dataPicturePriceTotal(): number {
    return this.dataPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + s.total,
      0
    );
  }
  dataPictureQuantity(): number {
    return this.dataPictureSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + (s.quantity || 0),
      0
    );
  }

  // Videos

  videoSummaries(): StoreEstimateSummary[] {
    return this.summaries.filter(s => VIDEO_MEDIA_TYPES.includes(s.mediaType));
  }
  videoPriceTotal(): number {
    return this.videoSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + s.total,
      0
    );
  }
  videoQuantity(): number {
    return this.videoSummaries().reduce(
      (sum: number, s: StoreEstimateSummary) => sum + (s.quantity || 0),
      0
    );
  }

  // Photo total

  photoQuantityTotal(): number {
    return (
      this.dataPictureQuantity() +
      this.printPictureQuantity() +
      this.videoQuantity()
    );
  }

  // Photobook

  photobookSummary(): StoreEstimateSummary | undefined {
    return this.summaries.find(s =>
      PHOTO_BOOK_MEDIA_TYPES.includes(s.mediaType)
    );
  }

  photobookQuantity(): number {
    const summary = this.photobookSummary();

    return summary && summary.quantity !== null ? summary.quantity : 0;
  }

  photobookSize(): string | undefined {
    const summary = this.photobookSummary();

    if (summary) {
      return summary.mediaType === MEDIA_TYPES["photo_book_mini"]
        ? "photo_book_mini"
        : "photo_book_standard";
    } else {
      return;
    }
  }

  // Postage

  postagePriceTotal(): number {
    return this.summaries
      .filter(s => s.mediaType === MEDIA_TYPES["postage"])
      .reduce(function(sum: number, s: StoreEstimateSummary) {
        return (
          sum +
          (s.quantity !== null && s.unitPrice !== null
            ? s.quantity * s.unitPrice
            : 0)
        );
      }, 0);
  }
}

export default StoreEstimate;
