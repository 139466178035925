<template>
  <v-layout>
    <v-flex xs12 offset-sm2 sm8 offset-md3 md6>
      <h1 class="title-page">
        <v-icon class="material-icons-outlined">account_circle</v-icon>
        <br />変更内容の確認
      </h1>

      <v-form @submit.prevent="submitForm">
        <v-card>
          <v-card-title>世帯名</v-card-title>
          <div class="pa-3">
            {{ form.name }}
          </div>

          <v-card-title>電話番号</v-card-title>
          <div
            v-for="(mobilePhoneForm, i) in form.enabledMobilePhoneForms"
            :key="mobilePhoneForm.uniqueKey"
          >
            <div class="pa-3">
              {{ mobilePhoneForm.phoneNumber }}
            </div>

            <v-divider
              v-if="i !== form.enabledMobilePhoneForms.length - 1"
              class="mx-3"
            ></v-divider>
          </div>

          <section class="mt-3 pb-4">
            <div v-if="errors.length > 0" class="ma-3 caption error--text">
              <ul class="px-3">
                <li v-for="err in errors" :key="err.message">
                  {{ err.message }}
                </li>
              </ul>
            </div>

            <v-layout justify-center row>
              <v-btn
                type="submit"
                :disabled="submitting"
                :loading="submitting"
                class="v-btn--minwidth"
                color="primary"
              >
                登録する
              </v-btn>
            </v-layout>
            <v-layout justify-center row>
              <v-btn
                :disabled="submitting"
                class="v-btn--minwidth"
                color="negative"
                @click="$emit('back-to-input')"
              >
                戻る
              </v-btn>
            </v-layout>
          </section>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiErrorHandler from "../../../mixins/ApiErrorHandler";

import FamilyForm from "../../../forms/FamilyForm";

export default {
  mixins: [ApiErrorHandler],
  props: {
    form: {
      type: FamilyForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      this.submitting = true;
      try {
        const _family = await this.$store.dispatch(
          "family/updateFamily",
          this.form.toParams()
        );
        this.$emit("complete");
      } catch (errors) {
        if (errors.status === 400) {
          this.errors = errors.errors;
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
