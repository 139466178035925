<template>
  <div>
    <h1 class="title-page primary--text">登録内容の確認</h1>

    <v-form @submit.prevent="submitForm">
      <section>
        <h2 class="title-bar">お子さまの氏名（漢字）</h2>
        <div class="pa-3">{{ form.familyName }} {{ form.kidName }}</div>
      </section>
      <section>
        <h2 class="title-bar">お子さまの生年月日</h2>
        <div class="pa-3">{{ formattedKidBirthDate }}（{{ kidAge }}歳）</div>
      </section>
      <section>
        <h2 class="title-bar">園名</h2>
        <div class="pa-3">
          {{ organizationName }}
        </div>
      </section>
      <section>
        <h2 class="title-bar">クラスの選択</h2>
        <div class="pa-3">
          {{ selectedGroupName }}
        </div>
      </section>
      <section>
        <h2 class="title-bar">メールアドレス</h2>
        <div class="pa-3">
          {{ form.email }}
        </div>
      </section>
      <section>
        <h2 class="title-bar">電話番号</h2>
        <div class="pa-3">
          {{ form.phoneNumber }}
        </div>
      </section>
      <section>
        <h2 class="title-bar">パスワード</h2>
        <div class="pa-3">
          ＊＊＊＊＊＊＊＊
        </div>
      </section>

      <div class="px-3 caption error--text">
        <ul v-for="err in errors" v-show="errors.length > 0" :key="err.message">
          <li>{{ err.fullMessage }}</li>
        </ul>
      </div>

      <v-layout justify-center row>
        <v-btn
          type="submit"
          :loading="submitting"
          :disabled="submitting"
          class="v-btn--minwidth"
          color="primary"
        >
          次へ
        </v-btn>
      </v-layout>
      <v-layout justify-center row>
        <v-btn
          :disabled="submitting"
          class="v-btn--minwidth"
          color="negative"
          @click="$emit('prev')"
        >
          戻る
        </v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import dayjs from "dayjs";

import { mapState } from "vuex";

import UserForm from "../../forms/UserForm";

import ApiErrorHandler from "../../mixins/ApiErrorHandler";
import {
  API_ERROR_CODE_VALIDATION_MATCHING_ERROR_KID_NOT_FOUND,
  API_ERROR_CODE_VALIDATION_MATCHING_ERROR_MULTIPLE_KIDS
} from "../../lib/lookmee_photo/ApiErrorCodes";

export default {
  mixins: [ApiErrorHandler],
  props: {
    token: {
      type: String,
      required: true
    },
    form: {
      type: UserForm,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      errors: []
    };
  },
  computed: {
    ...mapState({
      environment: "environment"
    }),
    kidBirthDate() {
      return dayjs(this.form.kidBirthDate);
    },
    formattedKidBirthDate() {
      return this.kidBirthDate.format("YYYY年M月D日");
    },
    kidAge() {
      const now = dayjs();
      return now.diff(this.kidBirthDate, "year");
    },
    organizationName() {
      return this.form.organization ? this.form.organization.name : "";
    },
    selectedGroupName() {
      const selectedGroup = this.form.groups.find(
        g => g.id === this.form.groupId
      );
      return selectedGroup ? selectedGroup.name : "";
    }
  },
  methods: {
    async submitForm() {
      if (this.submitting) {
        return;
      }

      this.errors = [];
      this.submitting = true;
      try {
        const user = await this.$store.dispatch("registerUser", {
          token: this.token,
          ...this.form.toRegisterUserParams()
        });

        this.setGtmData(user);

        await Promise.all([
          this.$store.dispatch("user/fetchCurrentUser"),
          this.$store.dispatch("family/fetchFamily"),
          this.$store.dispatch("organization/fetchOrganization", {
            organizationId: this.form.organization.id
          })
        ]);

        this.$emit("next");
      } catch (errors) {
        if (errors.status === 400) {
          switch (errors.getErrorCode()) {
            case API_ERROR_CODE_VALIDATION_MATCHING_ERROR_KID_NOT_FOUND:
              this.$emit("matching-error", {
                errorCause: "kid_not_found",
                errorCode: errors.getErrorNo()
              });
              break;
            case API_ERROR_CODE_VALIDATION_MATCHING_ERROR_MULTIPLE_KIDS:
              this.$emit("matching-error", {
                errorCause: "multiple_kids",
                errorCode: errors.getErrorNo()
              });
              break;
            default:
              this.errors = errors.errors;
          }
        } else {
          this.handleApiErrors(errors, {
            store: this.$store,
            router: this.$router,
            sentry: this.sentry
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    setGtmData(user) {
      const dayjsCreatedAt = dayjs(user.createdAt);
      let userId;

      // append env name to user ids so if development or staging
      // data are sent to karte
      // the data is not mixed with production data
      if (this.environment === "production") {
        userId = `${user.id}`;
      } else {
        userId = `${this.environment}_${user.id}`;
      }

      dataLayer.push({
        user_id: userId,
        mail_address: user.email,
        user_familyname: user.lastName,
        mail_ok: true,
        en_id: this.form.organization.id,
        created_at: dayjsCreatedAt.format("YYYY-MM-DD"),
        class_id: this.form.groupId
      });
    }
  }
};
</script>
