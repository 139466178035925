/**
 * Add multiple data for an attribute to object from an array
 * Made for dataLayer attributes with the same name
 * Ex
 * addToDataLayerObjectFromArray(
 *   'child_birthday', {}, ['2021-03-04', '2021-05-06', '2021-07-08'], 2
 * )
 * Returns
 * {
 *  child_birthday1: '2021-03-04',
 *  child_birthday2: '2021-05-06',
 * }
 *
 * @param {string} attributeName
 * @param {any} dataLayerObject
 * @param {Array} dataArray
 * @param {number} maxAttributes
 * @returns {any}
 */
export function addToDataLayerObjectFromArray(
  attributeName: string,
  dataLayerObject: any,
  dataArray: any[],
  maxAttributes: number
): any {
  const newDataLayerObject = { ...dataLayerObject };
  let n = 0;
  while (n < maxAttributes) {
    if (dataArray[n]) {
      const item = dataArray[n];
      newDataLayerObject[`${attributeName}${n + 1}`] = item;
    } else {
      // Clears previous data from Data Layer
      newDataLayerObject[`${attributeName}${n + 1}`] = undefined;
    }
    n++;
  }

  return newDataLayerObject;
}
