import { GetterTree } from "vuex";
import { RootState } from "../types";
import { FamilyState } from "./types";
import Email from "../../models/Email";

export const getters: GetterTree<FamilyState, RootState> = {
  isFamilySelected(state: FamilyState): boolean {
    return !!state.family;
  },
  familyName(state: FamilyState): string {
    return (state.family && state.family.name) || "";
  },
  kidIds(state: FamilyState): Set<number> {
    return new Set(state.kids.map(k => k.id));
  },
  activatedEmails(_: FamilyState): Array<Email> {
    // TODO: Removed
    console.log("Deprecated, family/getters/activatedEmails");
    return [];
  },
  inActivatedEmails(_: FamilyState): Array<Email> {
    // TODO: Removed
    console.log("Deprecated, family/getters/inActivatedEmails");
    return [];
  },
  defaultActivatedEmail(_: FamilyState): string | undefined {
    // TODO: Removed
    console.log("Deprecated, family/getters/defaultActivatedEmail");
    return undefined;
  },
  isOrganizationEmergencyEnabled(state: FamilyState): boolean {
    return state.organizations.filter(o => o.isEmergencyEnabled).length > 0;
  }
};
