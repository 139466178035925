import { ActionTree, Commit } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";
import User from "../../models/User";

export const actions: ActionTree<UserState, RootState> = {
  async fetchCurrentUser(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<User> {
    const user = await context.rootState.apiClient.getCurrentUser();
    context.commit("setUser", user);
    return user;
  },

  async sendActivationEmail(context: { rootState: RootState }) {
    await context.rootState.apiClient.sendActivationEmail();
  },

  async validateForUpdateUserEmail(
    context: { commit: Commit; rootState: RootState },
    payload: { email: string }
  ): Promise<boolean> {
    return await context.rootState.apiClient.validateForUpdateUserEmail(
      payload
    );
  },

  async updateUserEmail(
    context: { commit: Commit; rootState: RootState },
    payload: { email: string }
  ): Promise<User> {
    const user = await context.rootState.apiClient.updateUserEmail(payload);
    context.commit("setUser", user);
    return user;
  },

  async setEmergency(
    context: { commit: Commit; rootState: RootState },
    payload: {
      emergency: number;
      emergencyPhoneNumber: string;
    }
  ): Promise<void> {
    const user = await context.rootState.apiClient.setEmergency(payload);
    context.commit("setUser", user);
  },

  async disableEmergency(context: {
    commit: Commit;
    rootState: RootState;
  }): Promise<void> {
    const user = await context.rootState.apiClient.setEmergency({
      emergency: 0,
      emergencyPhoneNumber: null
    });
    context.commit("setUser", user);
  }
};
